import styled, { css } from 'styled-components';
export const StyledHeader04Style = css`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  display: flex;
`;
export const StyledHeader01 = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 36px;
  min-width: 36px;
  color: var(--color-dark-navy);
`;
export const StyledHeader02 = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--color-mist);
`;
export const StyledHeader04 = styled.div`
  ${StyledHeader04Style}
`;
export const StyledFlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledFLex = styled.div`
  display: flex;
`;
export const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
`;
export const ColorOrange = css`
  color: var(--color-orange);
`;
export const ColorSalmon = css`
  color: var(--color-salmon);
`;
export const ColorCoolGrey = css`
  color: var(--color-cool-grey);
`;
export const StyledColorRangeSpan = styled.span`
  ${ColorOrange}
`;
export const StyledColorSalmonSpan = styled.span`
  ${ColorSalmon}
`;
export const StyledColorCoolGreySpan = styled.span`
  ${ColorCoolGrey}
`;
export const StyledIcon = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 8px;
`;

export const HideScrollBar = css`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: white;
  }
  &::-webkit-scrollbar-corner {
    background: white;
  }
`;
