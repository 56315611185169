import { Drawer, DrawerProps, notification, Skeleton, Tooltip, DatePicker } from 'antd';
import { Close, KeyboardArrowRight, Today } from '@styled-icons/material';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useFeatureFlag } from 'src/featureSwitches';
import { Subcategories } from 'src/types/mission';
import {
  DrawerHeader,
  HeaderContainer,
  PathfinderGoalList,
  CardListContainer,
  CardContainer,
  CardDescription,
  LevelPoints,
  LevelTitle,
  PointsAndLevelContainer,
  DrawerContentContainer,
  IdeaSection,
  IdeaSectionList,
  PathfinderGoalItem,
  Category,
  CardHeader,
  Badge,
  StyledSearch,
  HowToContainer,
  LoadingContainer,
  FormContainer,
  ButtonGroup,
  BreadCrumbItem,
  DatePickerContainer,
  DatePickerHeader,
  DatePickerRow,
} from './index.style';
import {
  LevelType,
  firstCharUpperCase,
  skeletonLength,
  listArrowRightStyle,
  categoryArrowRightStyle,
  badgeCategoriesMap,
} from './index.util';
import { missionGroupSubcategories } from '../CreateMissonFromPathfinderGoalDrawer/index.util';
import md from 'markdown-it';
import * as _ from 'lodash';
import { ExplorePathfinderIdeasContext } from 'src/context/ExplorePathfinderIdeasContext';
import { CreateMission, getGroupedRecommendedGoals } from 'src/graphql/Pathfinder';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { EmptyPlaceHolder, LightbulbCircle } from './Icons';
import { StyledButtonNoBorder, StyledButtonFilled, StyledButtonDisabled } from '../CrButton';
import { subcategoriesMap } from './index.util';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { CategoryOverviewCommunicationContext } from 'src/context/CategoryOverviewCommunicationContext';
import dayjs from 'dayjs';
import { EmptyIcon } from '../PathfinderPointHistoryModal/Icons';
import useRoadmapId from 'src/hooks/useRoadmapId';

const { RangePicker } = DatePicker;

type IdeaDataType = {
  category: string;
  subcategory: string;
  goalCards: Array<BadgeIdea>;
};

type BadgeIdea = {
  id: string;
  title: string;
  achievedLevel: string;
  category: string;
  v2category: string;
  subcategory: string;
  v2subcategory: string;
  note: string;
  description: string;
  levels: Array<LevelType>;
};

const defaultDataParams = {
  startDate: '',
  endDate: '',
};

const ExploreAllPathfinderIdeas = ({
  visible,
  setDrawerVisible,
  onDrawerClose,
  pathfinderType,
  ...rest
}: DrawerProps & {
  onDrawerClose: () => void;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pathfinderType: string;
}): JSX.Element => {
  const { setVisible, setActivedSubCategory } = useContext(ExplorePathfinderIdeasContext);
  const [levels, setLevels] = useState<Array<LevelType>>([]);
  const [goalTitle, setGoalTitle] = useState('');
  const [howTo, setHowTo] = useState('');
  const { studentInfo, editPermitted } = useContext(CurrentUserContext);
  const roadmapId = useRoadmapId();
  const { roadmapApiClient, pathfinderApiClient } = useContext(ApolloClientContext);
  const [badgeIdeasData, setBadgeIdeasData] = useState<Array<IdeaDataType> | undefined>(undefined);
  const [keyWords, setKeyWords] = useState('');
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [dateParams, setDateParams] = useState<{ startDate: string; endDate: string }>(defaultDataParams);
  const [highestLevelIndex, setHighestLevelIndex] = useState<number>(-1);
  const [activeLevelIndex, setActiveLevelIndex] = useState<number>(-1);
  const [goal, setGoal] = useState<BadgeIdea>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { setRefetch } = useContext(CategoryOverviewCommunicationContext);
  const GROUP_MISSION_FLAG = useFeatureFlag('GROUP_MISSION');

  const {
    data: badgeIdeasDataRaw,
    loading: badgeIdeasLoading,
    refetch: refetchBadgeIdeas,
  } = useQuery<{ getGroupedRecommendedGoals: Array<IdeaDataType> }>(getGroupedRecommendedGoals, {
    variables: {
      studentId: studentInfo?.userId,
      keyword: keyWords,
      // pathfinderType, // fetch all pf types
      // category, // fetch all categories
    },
    skip: !studentInfo?.userId || !visible,
    fetchPolicy: 'network-only',
    client: pathfinderApiClient,
  });
  const [createMission, { loading, error }] = useMutation(CreateMission, {
    client: roadmapApiClient,
  });

  useEffect(() => {
    if (!loading && error) {
      notification.error({
        message: 'Create mission failed',
      });
    }
  }, [loading, error]);
  useEffect(() => {
    if (keyWords && visible) {
      refetchBadgeIdeas();
    }
  }, [visible, keyWords, refetchBadgeIdeas]);
  useEffect(() => {
    if (!badgeIdeasDataRaw && visible) {
      refetchBadgeIdeas();
    }
  }, [visible, badgeIdeasDataRaw, refetchBadgeIdeas]);
  useEffect(() => {
    if (!badgeIdeasLoading) {
      setBadgeIdeasData(badgeIdeasDataRaw?.getGroupedRecommendedGoals || []);
    } else {
      setGoalTitle('');
      setSkeletonLoading(true);
      setTimeout(() => {
        setSkeletonLoading(false);
      }, 1000);
    }
  }, [badgeIdeasDataRaw, badgeIdeasLoading]);

  useEffect(() => {
    if (levels.length !== 0) {
      setHighestLevelIndex(Math.max(_.findLastIndex(levels, 'isAssigned')));
    }
  }, [levels]);

  const handleDrawerClose = () => {
    console.log(pathfinderType);
    setVisible(false);
    setBadgeIdeasData(undefined);
    setActivedSubCategory(goal?.v2subcategory || '');
    setGoalTitle('');
    setHowTo('');
    setKeyWords('');
    setLevels([]);
    setActiveLevelIndex(-1);
    setDateParams(defaultDataParams);
    onDrawerClose();
  };

  const onSubmit = async () => {
    setSubmitting(true);
    const isMissionsGroupEnabled =
      missionGroupSubcategories.includes(goal?.v2subcategory as Subcategories) && GROUP_MISSION_FLAG;
    if (isMissionsGroupEnabled) {
      // when enabled, batch create missions that are higher than the highest assigned level
      // and smaller than the current selected/active level
      const promises = [];
      for (let i = highestLevelIndex + 1; i <= activeLevelIndex; i++) {
        const level = levels[i];
        const promise = createMission({
          variables: {
            input: {
              roadmapId,
              userId: studentInfo?.userId,
              missions: [
                {
                  action: 'add',
                  integrationSource: 'pathfinder',
                  status: 'PLANNED',
                  title: goal?.title + ' - ' + firstCharUpperCase(level.level),
                  linkId: goal?.id,
                  category: goal?.v2category,
                  subcategory: goal?.v2subcategory,
                  description: level.description,
                  startAt: dateParams.startDate,
                  attr: JSON.stringify({
                    levelIds: _.slice(levels, highestLevelIndex + 1, i + 1).map((level) => level.id),
                    points: _.slice(levels, highestLevelIndex + 1, i + 1).map((level) => level.points),
                    goalId: goal?.id,
                    description: level.description,
                    level: firstCharUpperCase(level.level),
                  }),
                  dueDate: dateParams.endDate,
                },
              ],
            },
          },
        });
        promises.push(promise);
      }
      try {
        await Promise.all(promises);
        if (promises.length > 1) {
          notification.open({
            message: 'Missions Created',
            description: 'New missions have been created successfully.',
          });
        } else {
          notification.open({
            message: 'Mission Created',
            description: 'A new mission has been created successfully.',
          });
        }
        handleDrawerClose();
      } catch (e) {
        console.log(e);
        notification.error({
          message: 'Create missions failed',
        });
      }
    } else {
      const currentLevel = levels[activeLevelIndex];
      const { data } = await createMission({
        variables: {
          input: {
            roadmapId,
            userId: studentInfo?.userId,
            missions: [
              {
                action: 'add',
                integrationSource: 'pathfinder',
                status: 'PLANNED',
                title: goal?.title + ' - ' + firstCharUpperCase(currentLevel.level),
                linkId: goal?.id,
                category: goal?.v2category,
                subcategory: goal?.v2subcategory,
                description: currentLevel.description,
                startAt: dateParams.startDate,
                attr: JSON.stringify({
                  levelIds: _.slice(levels, highestLevelIndex + 1, activeLevelIndex + 1).map((level) => level.id),
                  points: _.slice(levels, highestLevelIndex + 1, activeLevelIndex + 1).map((level) => level.points),
                  goalId: goal?.id,
                  description: currentLevel.description,
                  level: firstCharUpperCase(currentLevel.level),
                }),
                dueDate: dateParams.endDate,
              },
            ],
          },
        },
      });
      if (data) {
        notification.open({
          message: 'Mission Created',
          description: 'A new mission has been created successfully.',
        });
        handleDrawerClose();
      }
    }
    setSubmitting(false);
    setRefetch(true);
  };

  const getClassName = (index: number): string => {
    return index <= highestLevelIndex ? 'disabled' : index <= activeLevelIndex ? 'assigned' : '';
  };

  return (
    <Drawer
      {...rest}
      placement="right"
      onClose={() => {
        setDrawerVisible(false);
        handleDrawerClose();
      }}
      visible={visible}
      destroyOnClose
    >
      <DrawerHeader>
        <Close
          style={{
            width: '14px',
            height: '14px',
            color: 'var(--color-stone)',
            cursor: 'pointer',
            position: 'absolute',
            top: '25px',
            right: '25px',
          }}
          onClick={() => {
            setDrawerVisible(false);
            handleDrawerClose();
          }}
        />
      </DrawerHeader>
      <HeaderContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Pathfinder Goals & Badges
        </div>
        <div
          style={{
            border: '1px solid #E3E7ED',
            boxShadow: '0px 1px 2px rgba(184, 200, 224, 0.2)',
            borderRadius: '30px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '24px',
          }}
        >
          <StyledSearch
            bordered={false}
            allowClear
            style={{ width: '277px' }}
            placeholder="Search by Keywords"
            onPressEnter={(e) => setKeyWords(e?.target?.value)}
            onSearch={(value) => setKeyWords(value)}
          />
        </div>
      </HeaderContainer>
      <DrawerContentContainer>
        <PathfinderGoalList>
          {skeletonLoading || badgeIdeasLoading ? (
            <LoadingContainer>
              {Array.from({ length: skeletonLength }, (...args) => args[1]).map((item) => (
                <Skeleton.Button
                  key={item}
                  active={true}
                  size="large"
                  shape="default"
                  block={true}
                  style={{
                    height: '48px',
                    border: '1px solid white',
                    marginBottom: '12px',
                  }}
                />
              ))}
            </LoadingContainer>
          ) : (
            <LoadingContainer>
              {badgeIdeasData?.length === 0 && (
                <div
                  style={{
                    height: '550px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <EmptyIcon />
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: 'var(--color-stone)',
                      marginTop: '40px',
                      maxWidth: 425,
                      textAlign: 'center',
                    }}
                  >
                    No matches found, please try a different word.
                  </p>
                </div>
              )}
              {badgeIdeasData?.length !== 0 &&
                badgeIdeasData?.map((idea, i) => (
                  <IdeaSectionList key={`${idea.category}-${idea.subcategory}-${i}`}>
                    <Category>
                      <span
                        style={{
                          color: 'var(--color-cool-grey)',
                        }}
                      >
                        {badgeCategoriesMap[idea.category as keyof typeof badgeCategoriesMap]}
                      </span>
                      <KeyboardArrowRight style={categoryArrowRightStyle} />

                      <BreadCrumbItem title={idea.subcategory}>
                        {subcategoriesMap[idea.subcategory as keyof typeof subcategoriesMap]}
                      </BreadCrumbItem>
                    </Category>
                    <IdeaSection>
                      {idea.goalCards.map((goal: BadgeIdea, i) => (
                        <PathfinderGoalItem
                          key={`${idea.subcategory}-${goal.title}-${i}`}
                          onClick={() => {
                            setLevels(goal?.levels);
                            setGoalTitle(goal?.title);
                            setHowTo(goal?.note);
                            setGoal(goal);
                            setActiveLevelIndex(-1);
                          }}
                        >
                          <Tooltip title={goal.title} placement="top">
                            <div>{goal.title}</div>
                          </Tooltip>
                          {goal?.achievedLevel && goal.achievedLevel !== 'NONE' && (
                            <Badge>
                              <img src={`/static/badges/${goal.achievedLevel.toLowerCase()}.svg`} />
                            </Badge>
                          )}
                          <KeyboardArrowRight style={listArrowRightStyle} />
                        </PathfinderGoalItem>
                      ))}
                    </IdeaSection>
                  </IdeaSectionList>
                ))}
            </LoadingContainer>
          )}
        </PathfinderGoalList>
        <CardListContainer>
          {goalTitle ? (
            <>
              <CardHeader>
                <div className="goal-title">{goalTitle}</div>
                <div className="level-points">Levels & Badges</div>
              </CardHeader>
              {levels &&
                levels.map((level, i) => (
                  <CardContainer
                    key={level.level}
                    onClick={() => {
                      setActiveLevelIndex((activeIndex) =>
                        i === activeIndex ? activeIndex - 1 : i > highestLevelIndex ? i : activeIndex,
                      );
                    }}
                    className={getClassName(i)}
                  >
                    <CardDescription>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: md({
                            html: true,
                          }).render(level.description as string),
                        }}
                      ></div>
                    </CardDescription>
                    <PointsAndLevelContainer>
                      <LevelPoints>
                        {level?.status && (
                          <div>
                            <img src={`/static/badges/${level?.level?.toLowerCase()}.svg`} />
                          </div>
                        )}
                      </LevelPoints>
                      <LevelTitle>{firstCharUpperCase(level?.level)}</LevelTitle>
                    </PointsAndLevelContainer>
                  </CardContainer>
                ))}
              <FormContainer>
                {/* initial design: 2 separate date pickers */}
                {/* 
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label={<DateLabel>Start Date</DateLabel>}
                        name="startDate"
                        colon={false}
                        required={false}
                        rules={[{ required: true, message: 'Please select a Start Date' }]}
                        style={{ maxWidth: '245px' }}
                      >
                        <DatePicker
                          allowClear={false}
                          suffixIcon={<Today fontVariant="rounded" style={{ height: '22px' }} />}
                          style={{ height: '40px', width: '245px' }}
                          disabledDate={(current) =>
                            current && dateParams.endDate !== '' && current >= dayjs(dateParams.endDate)
                          }
                          onChange={(date) =>
                            setDateParams({
                              ...dateParams,
                              startDate: `${date?.toISOString()}`,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={<DateLabel>End Date</DateLabel>}
                        name="testDate"
                        colon={false}
                        required={false}
                        style={{ maxWidth: '245px' }}
                      >
                        <DatePicker
                          allowClear={false}
                          suffixIcon={<Today fontVariant="rounded" style={{ height: '22px' }} />}
                          style={{ height: '40px', width: '245px' }}
                          disabledDate={(current) =>
                            current && dateParams.startDate !== '' && current <= dayjs(dateParams.startDate)
                          }
                          onChange={(date) =>
                            setDateParams({
                              ...dateParams,
                              endDate: `${date?.toISOString()}`,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row> 
                */}
                <DatePickerRow>
                  <DatePickerContainer>
                    <DatePickerHeader>Timeline</DatePickerHeader>
                    <RangePicker
                      allowClear={false}
                      onChange={(dates) =>
                        setDateParams({
                          startDate: `${dates?.[0]?.toISOString()}`,
                          endDate: `${dates?.[1]?.toISOString()}`,
                        })
                      }
                      suffixIcon={<Today style={{ height: '20px', color: 'var(--color-dark-navy)' }} />}
                    />
                  </DatePickerContainer>
                </DatePickerRow>
                <ButtonGroup>
                  <StyledButtonNoBorder style={{ color: 'var(--color-stone)' }} onClick={() => handleDrawerClose()}>
                    Cancel
                  </StyledButtonNoBorder>
                  {editPermitted &&
                  !submitting &&
                  dateParams.startDate &&
                  dateParams.endDate &&
                  (dayjs(dateParams.startDate).isSame(dayjs(dateParams.endDate)) ||
                    dayjs(dateParams.startDate).isBefore(dayjs(dateParams.endDate))) &&
                  activeLevelIndex > highestLevelIndex ? (
                    <StyledButtonFilled onClick={onSubmit}>Add Mission</StyledButtonFilled>
                  ) : (
                    <StyledButtonDisabled>Add Mission</StyledButtonDisabled>
                  )}
                </ButtonGroup>
              </FormContainer>
              {howTo && (
                <HowToContainer>
                  <p className="row bold-row">
                    <LightbulbCircle
                      style={{
                        marginRight: '4px',
                      }}
                    />
                    How to:
                  </p>
                  <p className="row">{howTo}</p>
                </HowToContainer>
              )}
            </>
          ) : (
            <section
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <EmptyPlaceHolder />
              <div
                style={{
                  width: '330px',
                  fontFamily: 'Montserrat',
                  lineHeight: '20px',
                  color: 'var(--color-stone)',
                  marginTop: '40px',
                  textAlign: 'center',
                  marginLeft: '77px',
                }}
              >
                Select a goal from the list on the left to view details, or search by keyword from the top bar.
              </div>
            </section>
          )}
        </CardListContainer>
      </DrawerContentContainer>
    </Drawer>
  );
};

export default ExploreAllPathfinderIdeas;
