import { useContext } from 'react';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useIsStaff, useIsStudent } from 'src/hooks/useMissionHook/useIsStaffHook';
import { useIsCW } from 'src/hooks/useTenantLevel';
import { StyledActionButton, StyledHeader } from 'src/pages/StudentDashboard/CareerQuiz';
import styled from 'styled-components';

const StyledContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

const StyledDesc = styled.div`
  margin-top: 10px;
  width: 192px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4d90f4;
`;

const CWStyledImgContainer = styled.div`
  width: 315px;
  height: 144px;
  position: relative;
`;

const CWStyledDescContainer = styled.div`
  width: 214px;
  height: 56px;
  color: #fff;
  font-family: 'Montserrat-SemiBold', 'NotoSansCJKsc-SemiBold';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  position: absolute;
  top: 20px;
  left: 24px;
`;

const CWStyledLink = styled.div`
  width: 98px;
  height: 28px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 24px;
  left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;

const RegisterForADiagnosticLink = `https://docs.google.com/forms/d/e/1FAIpQLSfRl-x3GbTfVLk1syGSU1a8tjFbx35RnMSCLsUChFJLMsCAJA/viewform`;
const TutoringPackagesLink = `https://go.collegewise.com/collegewisetutoring`;
const CollegeWiseTutoringBlogLink = `https://go.collegewise.com/tag/testing-test-prep`;

const MockTestCard: React.FC<{
  children?: React.ReactNode;
}> = () => {
  const { userId, currentUser } = useContext(CurrentUserContext);
  const canViewMockTestCard = useIsStaff(currentUser);
  const isCW = useIsCW();
  const isStudent = useIsStudent(currentUser);
  const mockTestCardModuleVisible = (!isCW && canViewMockTestCard) || (isCW && (canViewMockTestCard || isStudent));
  if (!mockTestCardModuleVisible) return null;

  return (
    <>
      {isCW ? (
        <>
          <CWStyledImgContainer>
            <img width="315px" src="/static/testing/register-for-a-diagnostic.svg" style={{ width: '100%' }} />
            <CWStyledDescContainer>Register for a Diagnostic</CWStyledDescContainer>
            <CWStyledLink
              onClick={() => {
                window.open(RegisterForADiagnosticLink, '_blank');
              }}
            >
              View Details
            </CWStyledLink>
          </CWStyledImgContainer>
          <CWStyledImgContainer>
            <img width="315px" src="/static/testing/tutoring-packages.svg" style={{ width: '100%' }} />
            <CWStyledDescContainer style={{ width: '130px' }}>Tutoring Packages</CWStyledDescContainer>
            <CWStyledLink
              onClick={() => {
                window.open(TutoringPackagesLink, '_blank');
              }}
            >
              View Details
            </CWStyledLink>
          </CWStyledImgContainer>
          <CWStyledImgContainer>
            <img width="315px" src="/static/testing/college-wise-tutoring-blog.svg" style={{ width: '100%' }} />
            <CWStyledDescContainer style={{ width: '180px' }}>Collegewise Tutoring Blog</CWStyledDescContainer>
            <CWStyledLink
              onClick={() => {
                window.open(CollegeWiseTutoringBlogLink, '_blank');
              }}
            >
              View Details
            </CWStyledLink>
          </CWStyledImgContainer>
        </>
      ) : (
        <StyledContainer>
          <img src="/static/testing/mock_test_card.svg" style={{ width: '100%' }} />
          <div style={{ position: 'absolute', top: '50%', left: '24px', transform: 'translateY(-50%)' }}>
            <StyledHeader>Practice Tests</StyledHeader>
            <StyledDesc>Measure your progress with test prep</StyledDesc>
            <StyledActionButton
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                if (window?.xprops?.history) {
                  window?.xprops?.history.push(`/users/${userId}/test-prep`);
                }
              }}
            >
              View Details
            </StyledActionButton>
          </div>
        </StyledContainer>
      )}
    </>
  );
};
export default MockTestCard;
