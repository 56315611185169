export type GroupDataItemType = {
  [key: string]: string[];
};

export type GroupDataType = {
  [key: string]: GroupDataItemType;
};
export const groupData: GroupDataType = {
  'School Research & Preparation': {
    'General Goals': [
      '4015d526-87ab-41f1-8b9e-fd5e7ca7d6f5',
      '1d46291a-9026-42ab-b2e1-64c8bd50194f',
      'bca697cb-0fd6-446f-b4dd-e2ab26ec1abb',
      '28f88f02-10a3-4276-ade2-ee028b066819',
      '1e8679e7-bc2a-4beb-b535-aea7ca6df91f',
      'a3a003d4-dc6a-42ff-978e-cc413f044e65',
      '81752a37-ad5a-4efe-b91d-4f0c10f85679',
      '6428a0a0-dd7f-4755-a083-c4b75821bb98',
      '1e01b7f1-6af7-4077-98d5-c542ccb73d24',
    ],
    'US-specific Goals': ['cc43b78b-7c2f-40cd-8221-714b42df7151'],
    'UK-specific Goals': [
      '68e79d4d-76c0-403a-9f78-3703266f58bb',
      '8a86bda1-8486-4b7d-9330-e1dc4c2115b8',
      '986ec3d4-7fe0-425f-a057-5c2a6d94450a',
      '1fdac8a4-1ce6-49c6-89be-85e30888dada',
      '7d9faa55-93ba-40ed-ae38-25e17ac8808b',
      '689af4f9-374e-4a0a-ad67-46b6313dff97',
      'ed681427-2bb8-4b85-8232-1081778beaf0',
      'adb04776-19cb-4844-ad1d-f6588405f123',
      '59d83290-c741-4d3a-a2d6-9970d9e6a57f',
      'a7ae84c3-06d8-4987-97d8-907de324193c',
    ],
  },
};
