import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useContext, useState, useEffect } from 'react';
import {
  Curriculum,
  Experience,
  ExtracurricularActivity,
  Honor,
  Maybe,
  MissionObjectType,
  StudentPortfolios,
  StudentPrograms,
  StudentResearch,
  Subjects,
  TestListObj,
} from 'src/__generated__/graphqlTypes';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { crimsonAppApiClient } from 'src/graphql';
import { GET_CURRICULUMS, LOAD_CATEGORIES_DATA } from 'src/graphql/LoadCategoriesData';
import { fetchProfilePageUserInfo } from 'src/graphql/User';
import { MissionItemType } from 'src/types/mission';
import firstCharUpperCase from 'src/utils/firstCharUpperCase';

export type ActivityType = {
  id: string;
  title: string;
  left: Array<string>;
  right: Array<string>;
};

export type PDFDataType = {
  loading: boolean;
  profile: {
    userId: string;
    Name: string;
    'Application Year': string;
    'Intended Majors': string;
  };
  education: {
    school: string;
    graduationDate: string;
    gradeLevel: string;
    curriculum: string;
    schoolWorks: Array<{ title: string; subjects: string }>;
  };
  competitionsHonors: Array<ActivityType>;
  testing: Array<{ title: string; description: string }>;
  activities: {
    extracurricular: Array<ActivityType>;
    universityCoursesSummerPrograms: Array<ActivityType>;
    academicResearchPublications: Array<ActivityType>;
    internshipWorkExperience: Array<ActivityType>;
    creativePortfolios: Array<ActivityType>;
  };
  refetch: () => Promise<void>;
};

export function useFetchPDFData({ isFeatureFlagOn }: { isFeatureFlagOn: boolean }): PDFDataType {
  const [testing, setTesting] = useState<Array<{ title: string; description: string }>>([]);
  const [loading, setLoading] = useState(true);
  const [schoolWorks, setSchoolWorks] = useState<Array<{ title: string; subjects: string }>>([]);
  const { userId } = useContext(CurrentUserContext);
  const [curriculum, setCurriculum] = useState<Array<string>>([]);
  const [competitionsHonors, setCompetitionsHonors] = useState<Array<ActivityType>>([]);
  const [activities, setActivities] = useState({
    extracurricular: [],
    universityCoursesSummerPrograms: [],
    academicResearchPublications: [],
    internshipWorkExperience: [],
    creativePortfolios: [],
  });

  const {
    data: profileData,
    refetch: refetchProfileData,
    loading: fetchProfilePageUserInfoLoading,
  } = useQuery(fetchProfilePageUserInfo, {
    fetchPolicy: 'network-only',
    variables: {
      userId: userId,
    },
    skip: !userId || !isFeatureFlagOn,
    client: crimsonAppApiClient,
  });

  const {
    data: curriculumData,
    refetch: refetchCurriculumData,
    loading: curriculumDataLoading,
  } = useQuery(GET_CURRICULUMS, {
    fetchPolicy: 'network-only',
    variables: { name: '' },
    skip: !isFeatureFlagOn,
  });

  const {
    data: categoriesData,
    refetch: refetchCategoriesData,
    loading: categoriesDataLoading,
  } = useQuery(LOAD_CATEGORIES_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    skip: !userId || !isFeatureFlagOn,
  });

  useEffect(() => {
    if (categoriesData?.competitionsHonorsData && categoriesData?.competitionsHonorsData.length > 0) {
      const competitionsHonors = categoriesData?.competitionsHonorsData?.map((item: MissionItemType) => {
        const { status, honor, title, id } = item;
        const { category, award, levelOfRecognition, schoolYear } =
          honor ||
          ({
            award: '',
            levelOfRecognition: '',
            schoolYear: [],
            category: '',
          } as unknown as Honor);
        return {
          id,
          title: title || 'General',
          left: ['Category', 'Award (Level of Recognition)', 'School year', 'Status'],
          right: [
            category || '\n',
            `${award || ''} (${levelOfRecognition || ''})` || '\n',
            `${schoolYear && schoolYear.length > 0 ? schoolYear.join(',') : '\n'}`,
            status ? firstCharUpperCase(status.toLowerCase().replace('_', ' ')).trim() : '',
          ],
        };
      });
      if (competitionsHonors.length > 0) {
        setCompetitionsHonors(competitionsHonors);
      }
    }
    if (categoriesData?.creativePortfoliosList && categoriesData?.creativePortfoliosList.length > 0) {
      const creativePortfolios = categoriesData?.creativePortfoliosList?.map((item: MissionItemType) => {
        const { status, portfolios, title, startAt, dueDate, category, id } = item;
        const { provider } = portfolios || ({ provider: '' } as unknown as StudentPortfolios);
        return {
          id,
          title: title || 'General',
          left: [`Mission Timeline`, `Category`, `Program Provider`, `Mission Status`],
          right: [
            !startAt && !dueDate
              ? '\n'
              : `${startAt ? dayjs(startAt).format('MMM DD, YYYY') : ''}${
                  dueDate ? ` - ${dayjs(dueDate).format('MMM DD, YYYY')}` : ''
                }`,
            `${category ? firstCharUpperCase(category.toLowerCase().replace('_', ' ')).trim() : ''}`,
            `${provider ? firstCharUpperCase(provider.toLowerCase().replace('_', ' ')).trim() : ''}`,
            `${status ? firstCharUpperCase(status.toLowerCase().replace('_', ' ')).trim() : ''}`,
          ],
        };
      });
      if (creativePortfolios.length > 0) {
        setActivities((prev) => ({ ...prev, creativePortfolios }));
      }
    }
    if (
      categoriesData?.academicResearchPublicationsList &&
      categoriesData?.academicResearchPublicationsList.length > 0
    ) {
      const academicResearchPublications = categoriesData?.academicResearchPublicationsList?.map(
        (item: MissionItemType) => {
          const { status, research, title, startAt, dueDate, id } = item;
          const { advisorName, date, affiliation, question, provider, field, publication, publisherName } =
            research ||
            ({
              advisorName: '',
              subField: '',
              affiliation: '',
              question: '',
              provider: '',
              publication: '',
              publisherName: '',
              field: '',
              date: '',
            } as unknown as StudentResearch);
          return {
            id,
            title: title || 'General',
            left: [
              'Mission Timeline',
              'Program Provider',
              'Advisor',
              'Affiliation',
              `${publication ? 'Pursuing Publication' : ''}`,
              'Date and Publisher',
              'Status',
              'Research Field',
              'Research Question',
            ],
            right: [
              !startAt && !dueDate
                ? '\n'
                : `${startAt ? dayjs(startAt).format('MMM DD, YYYY') : ''}${
                    dueDate ? ` - ${dayjs(dueDate).format('MMM DD, YYYY')}` : ''
                  }`,
              `${provider || '\n'}`,
              `${advisorName || '\n'}`,
              `${affiliation || '\n'}`,
              `${publication ? 'Y' : ''}`,
              `${date ? dayjs(date).format('MMM YYYY,') : ''}${publisherName || '\n'}`,
              `${status ? firstCharUpperCase(status.toLowerCase().replace('_', ' ')).trim() : '\n'}`,
              `${field || '\n'}`,
              `${question || '\n'}`,
            ],
          };
        },
      );
      if (academicResearchPublications.length > 0) {
        setActivities((prev) => ({ ...prev, academicResearchPublications }));
      }
    }
    if (categoriesData?.internshipExperience && categoriesData?.internshipExperience?.length > 0) {
      const internshipWorkExperience = categoriesData?.internshipExperience?.map((item: MissionItemType) => {
        const { title, experience, status, startAt, dueDate, id } = item;
        const { position, organizationName, programProvider, industry } =
          experience ||
          ({
            position: '',
            organizationName: '',
            programProvider: '',
            industry: '',
          } as unknown as Experience);
        return {
          id,
          title: title || 'General',
          left: [`Mission Timeline`, `Organization Name`, `Industry`, `Position/title`, `Program Provider`, `Status`],
          right: [
            !startAt && !dueDate
              ? '\n'
              : `${startAt ? dayjs(startAt).format('MMM DD, YYYY') : ''}${
                  dueDate ? ` - ${dayjs(dueDate).format('MMM DD, YYYY')}` : ''
                }`,
            `${organizationName || ''}`,
            `${industry || ''}`,
            `${position || ''}`,
            `${programProvider ? firstCharUpperCase(programProvider.toLowerCase().replace('_', ' ')).trim() : ''}`,
            `${status ? firstCharUpperCase(status.toLowerCase().replace('_', ' ')).trim() : ''}`,
          ],
        };
      });
      if (internshipWorkExperience.length > 0) {
        setActivities((prev) => ({ ...prev, internshipWorkExperience }));
      }
    }
    if (categoriesData?.loadMissions && categoriesData?.loadMissions?.length > 0) {
      const schoolWorks = categoriesData?.loadMissions
        .filter((item: MissionObjectType) => item?.subjects && item?.subjects?.length > 0)
        .map((item: MissionObjectType) => {
          const subjectsStr = item?.subjects
            ?.filter((item: Maybe<Subjects>) => (item?.version as number) === 2)
            .map(
              (item: Maybe<Subjects>) =>
                `${item?.subject?.name}-${item?.level}-${item?.subject?.curriculum?.shortName}${
                  item?.actualGrade ? `-${item?.actualGrade}` : ''
                }`,
            );
          return {
            title: item?.title,
            subjects: subjectsStr?.join('\n'),
          };
        });
      setSchoolWorks(schoolWorks);
    }
    if (categoriesData?.testMissionsData && categoriesData?.testMissionsData?.length > 0) {
      const testing = categoriesData?.testMissionsData
        .filter((item: TestListObj) => item?.list && item?.list?.length > 0)
        .map((item: TestListObj) => {
          return item?.list.length > 0
            ? item?.list?.map((listItem) => {
                return listItem?.list?.map((i) => {
                  const { isMockTest, type } = i;
                  const description = i?.testResult
                    ?.map((result) => {
                      return `${result?.key} ${result?.value ? `- ${result?.value}` : ''}`;
                    })
                    .join('\n');
                  return {
                    title: `${type} ${isMockTest ? '- Mock Test' : ''} ${
                      i?.testDate ? `- ${dayjs(i?.testDate).format('MMM DD, YYYY')}` : ''
                    }`,
                    description,
                  };
                });
              })
            : [];
        })
        .flat()
        .flat();
      if (testing && testing?.length > 0) {
        setTesting(testing);
      }
    }
    if (categoriesData?.extracurricularActivitiesList && categoriesData?.extracurricularActivitiesList?.length > 0) {
      const extracurricular = categoriesData?.extracurricularActivitiesList?.map((item: MissionItemType) => {
        const { title, id, extracurricular, description, status } = item;
        const { position, activityType, schoolYear, hoursPerWeek, weeksPerYear, timeOfParticipation } =
          extracurricular ||
          ({
            position: '',
            activityType: '',
            schoolYear: [],
            hoursPerWeek: '',
            weeksPerYear: '',
            timeOfParticipation: '',
          } as unknown as ExtracurricularActivity);
        return {
          id,
          title: activityType || 'Activity Type ____ ',
          left: [
            `${schoolYear && schoolYear.length > 0 ? schoolYear.join(', ') : 'Grade _____'}`,
            `${timeOfParticipation || 'Time of Participation _____'}`,
            `${hoursPerWeek ? `${hoursPerWeek} hr/wk` : '__ hr/wk'}, ${
              weeksPerYear ? `${weeksPerYear} wk/yr` : '__ wk/yr'
            }`,
            status ? firstCharUpperCase(status.toLowerCase().replace('_', ' ')).trim() : '',
          ],
          right: [`${title || ''}`, `${position || ''}`, `${description || ''}`],
        };
      });
      if (extracurricular.length > 0) {
        setActivities((prev) => ({ ...prev, extracurricular }));
      }
    }
    if (categoriesData?.universityProgramsList && categoriesData?.universityProgramsList?.length > 0) {
      const universityCoursesSummerPrograms = categoriesData?.universityProgramsList?.map((item: MissionItemType) => {
        const { status, program, id, title, startAt, dueDate } = item;
        const { resourceCategoryName, type, institution, actualGrade } =
          program ||
          ({
            resourceCategoryName: '',
            type: '',
            institution: '',
            actualGrade: '',
          } as StudentPrograms);
        return {
          id,
          title: title || 'General',
          left: ['Program Timeline', 'Institution', 'Category', 'Learning Format', 'Actual Grade', 'Status'],
          right: [
            `${startAt ? dayjs(startAt).format('MMM DD, YYYY') : ''}${
              dueDate ? ` - ${dayjs(dueDate).format('MMM DD, YYYY')}` : ''
            }`,
            `${institution || '\n'}`,
            `${resourceCategoryName || '\n'}`,
            `${type || '\n'}`,
            `${actualGrade || '\n'}`,
            `${status ? firstCharUpperCase(status.toLowerCase().replace('_', ' ')).trim() : ''}`,
          ],
        };
      });
      if (universityCoursesSummerPrograms.length > 0) {
        setActivities((prev) => ({ ...prev, universityCoursesSummerPrograms }));
      }
    }
  }, [categoriesData]);
  useEffect(() => {
    setLoading(fetchProfilePageUserInfoLoading || curriculumDataLoading || categoriesDataLoading);
  }, [fetchProfilePageUserInfoLoading, curriculumDataLoading, categoriesDataLoading]);
  useEffect(() => {
    if (
      profileData?.user?.studentInfo?.curriculums &&
      curriculumData &&
      curriculumData?.getCurriculums &&
      curriculumData?.getCurriculums?.length > 0
    ) {
      const curriculum = curriculumData?.getCurriculums
        ?.filter((item: Curriculum) => profileData?.user?.studentInfo?.curriculums?.includes(item.id))
        .map((item: Curriculum) => item.shortName);
      setCurriculum(curriculum);
    }
  }, [curriculumData, profileData]);

  return {
    loading,
    profile: {
      userId: profileData?.user?.id,
      Name: profileData?.user?.fullName,
      'Application Year': profileData?.user?.studentInfo?.yearOfApplication,
      'Intended Majors':
        profileData?.user?.studentInfo?.majors?.length > 0 ? profileData?.user?.studentInfo?.majors?.join(', ') : '',
    },
    education: {
      school: categoriesData?.educationData?.[0]?.schoolName,
      graduationDate: categoriesData?.educationData?.[0]?.graduationDate,
      curriculum: curriculum.join(', '),
      gradeLevel: categoriesData?.educationData?.[0]?.gradeLevel,
      schoolWorks,
    },
    testing,
    activities,
    competitionsHonors,
    refetch: async () => {
      await refetchProfileData();
      await refetchCurriculumData();
      await refetchCategoriesData();
    },
  };
}
