import { useState } from 'react';

interface Props {
  size?: number | string;
}

let nextId = 0;

export const Spark = ({ size = 24 }: Props): JSX.Element => {
  const [id] = useState(`spark-${nextId++}`);
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3 8.12498L18.225 5.62498L15.625 4.47498L18.225 3.34998L19.3 0.974976L20.375 3.34998L22.975 4.47498L20.375 5.62498L19.3 8.12498ZM19.3 22L18.225 19.6L15.625 18.475L18.225 17.35L19.3 14.825L20.375 17.35L22.975 18.475L20.375 19.6L19.3 22ZM8.325 19.15L6.025 14.225L1 11.975L6.025 9.72498L8.325 4.82498L10.65 9.72498L15.65 11.975L10.65 14.225L8.325 19.15Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient id={id} x1="2.62502" y1="19.4999" x2="23.25" y2="3.37494" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED4B53" />
          <stop offset="1" stopColor="#FDAA02" />
        </linearGradient>
      </defs>
    </svg>
  );
};
