/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// a function to retry loading a chunk to avoid chunk load error for out of date code
// https://www.codemzy.com/blog/fix-chunkloaderror-react
import React from 'react';
export const lazyRetry = <T extends React.ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  name = 'lazy',
) => {
  return React.lazy(async (): Promise<{ default: T }> => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');
    // try to import the component
    try {
      const component = await componentImport();
      window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
      return component;
    } catch (error) {
      if (!hasRefreshed) {
        // not been refreshed yet
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
        return window.location.reload() as any; // refresh the page
      }
      throw error; // Default error behaviour as already tried refresh
    }
  });
};
