import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { getFeatureFlagsByUserId } from 'src/graphql/User';

interface UseCustomFeatureFlagsResult {
  featureFlags: Record<string, boolean> | null;
  loading: boolean;
  error?: Error;
}

const useFetchFeatureFlagsByUserId = (userId: string, flags: string[]): UseCustomFeatureFlagsResult => {
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const { data, loading, error } = useQuery(getFeatureFlagsByUserId, {
    client: crimsonAppApiClient,
    variables: {
      userId,
      flags,
    },
  });

  return {
    featureFlags: data ? data.res : null,
    loading,
    error,
  };
};

export default useFetchFeatureFlagsByUserId;
