export type TDispatch<A extends { name: string; payload: unknown }> = (name: A['name'], payload?: A['payload']) => void;

export class Controller<S extends Record<string, unknown>, Actions extends { name: string; payload: unknown }> {
  public state?: S;
  public dispatch?: TDispatch<Actions>;

  setParams = (state: S, dispatch: TDispatch<Actions>): void => {
    this.state = state;
    this.dispatch = dispatch;
  };
}

export type EclDetailQueryStatus = Record<string, 'idle' | 'loading' | 'error'>;

type CommonInput = {
  resourceId: string;
  title: string;
  programStartDate: string;
  programEndDate: string;
  schoolYear: number[];
  description: string;
};

export type CreateCompetitionInput = {
  levelOfRecognition?: string;
  division?: string;
  divisionId?: string;
  categories: string[];
  award?: string;
  awardLevel?: string;
} & CommonInput;

type LevelOfRecognition = 'International' | 'National' | 'State_Regional' | 'School';

type CommonResponse = {
  id: string;
  userId: string;
  missionId: string;
};
export type CreateCompetitionResponse = {
  category?: string;
  award?: string;
  levelOfRecognition?: LevelOfRecognition;
  schoolYear: number[];
  resourceId?: string;
} & CommonResponse;

export type CreateSummerProgramInput = {
  location: string[];
  credit: boolean;
  actualGrade?: string;
  registrationDeadline?: string;
  category: 'ACTIVITIES' | 'ACADEMICS' | string;
  subcategory: 'University_Programs' | string;
  organizer?: string;
} & CommonInput;

export type CreateSummerProgramResponse = {
  type?: string;
  credit: boolean;
  actualGrade?: string;
  targetGrade?: string;
  maxGrade?: string;
  gradeFormat?: string;
  applicationPeriodStart?: string;
  applicationPeriodEnd?: string;
} & CommonResponse;
