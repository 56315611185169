import { gql } from '@apollo/client';

export type OnboardingCheckerCategoryNameType = 'PersonalInfo' | 'Honors' | 'Activities' | 'Testing' | 'Academics';

export type OnboardingCheckerResult = {
  completion: number;
  categories: {
    name: OnboardingCheckerCategoryNameType;
    completion: number;
    items: {
      name: string;
      value: string;
      completion: number;
    }[];
  }[];
};

export type OnboardingCheckerSkip = {
  userId: string;
  key: string;
  skip: boolean;
};

export const QUERY_ONBOARDING_CHECKER_RESULT = gql`
  query QueryOnboardingCheckerResult($studentUid: String!) {
    result: queryOnboardingFieldCheckerResult(studentUid: $studentUid) {
      completion
      categories {
        completion
        name
        items {
          completion
          name
          value
        }
      }
    }
  }
`;

export const UPSERT_ONBOARDING_CHECKER_SKIP = gql`
  mutation upsertOnboardingCheckerSkip($userId: String!, $key: String!, $skip: Boolean!) {
    result: upsertOnboardingFieldCheckerSkip(userId: $userId, key: $key, skip: $skip) {
      id
      userId
      key
      skip
    }
  }
`;
