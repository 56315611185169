const ERROR_STORAGE_KEY = 'ERROR_STORAGE_KEY';

export function updateErrorStorage(clear?: boolean): void {
  localStorage.setItem(ERROR_STORAGE_KEY, clear ? '' : location.pathname);
}

export function shouldPopup(): boolean {
  const currentPath = location.pathname;
  const errorStorage = localStorage.getItem(ERROR_STORAGE_KEY);
  const result = !errorStorage || errorStorage?.split('-')[0] !== currentPath;
  if (result) {
    updateErrorStorage();
  }
  return result;
}
