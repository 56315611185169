import { DateRange } from '@styled-icons/material/DateRange';
import styled from 'styled-components';

const DateRangeIcon = styled(DateRange)<{ width?: number; height?: number; color?: string }>`
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  height: ${(props) => (props?.height ? `${props.height}px` : 'auto')};
  color: ${(props) => (props?.color ? `${props.color}` : 'auto')};
`;

export default DateRangeIcon;
