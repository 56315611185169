import { URLSearchParams } from 'url';
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery, QueryResult, OperationVariables, ApolloQueryResult, NetworkStatus } from '@apollo/client';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { QUERY_SSA_TRACKING_RESULT, SsaTrackingResult, SsaTrackingCategoryNameType } from 'src/graphql/SsaTracking';

const shouldTrackSsaProgress = (params: URLSearchParams) => {
  if (params.get('prev') === '/applications/algorithm') {
    return true;
  }
  return false;
};

type SsaTrackingData = { result?: SsaTrackingResult; skip: boolean };

export const useSsaTracking = (): QueryResult<SsaTrackingData, OperationVariables> => {
  const [params] = useSearchParams();
  const { studentInfo: student } = useContext(CurrentUserContext);
  const skip = !shouldTrackSsaProgress(params) || !student?.userId;
  const result = useQuery(QUERY_SSA_TRACKING_RESULT, {
    variables: {
      studentUid: student?.userId || null,
    },
    skip,
  });
  return {
    ...result,
    data: { ...result.data, skip },
    refetch: async (variables?: Partial<OperationVariables>): Promise<ApolloQueryResult<SsaTrackingData>> => {
      if (shouldTrackSsaProgress(params)) {
        return result.refetch(variables);
      }
      return {
        data: {
          skip: true,
        },
        networkStatus: NetworkStatus.ready,
        loading: false,
      };
    },
  };
};

export const useIsSsaCategoryEmpty = (category: SsaTrackingCategoryNameType): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    return isCategoryEmpty(category, data?.result);
  }, [data, category]);
};

// Personal Information
export const useIsSsaNativeSpeaker = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    const found = data?.result?.categories
      .filter((c) => c.name === 'PersonalInfo')[0]
      ?.mappings.find((m) => m.nameInApp === 'englishProficiency');
    return found?.value === 'Native Speaker';
  }, [data]);
};

export const useIsSsaEnglishProficiencyEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    return checkIfAppFieldInCategoryEmpty('PersonalInfo', 'englishProficiency', data?.result);
  }, [data]);
};

export const useIsSsaGenderEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('PersonalInfo', 'gender', data?.result);
  }, [data]);
};

export const useIsSsaMaximumContributionEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('PersonalInfo', 'maximumContribution', data?.result);
  }, [data]);
};

export const useIsSsaEthnicityEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('PersonalInfo', 'ethnicity', data?.result);
  }, [data]);
};

export const useIsSsaCountryEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('PersonalInfo', 'country', data?.result);
  }, [data]);
};

// Academics
export const useIsSsaAcademicCurrentGradeLevelEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Academics', data?.result)) {
      return false;
    }
    return checkIfAppFieldInCategoryEmpty('Academics', 'currentGradeLevel', data?.result);
  }, [data]);
};

export const useIsSsaAcademicApplicationCycleEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Academics', data?.result)) {
      return false;
    }
    return checkIfAppFieldInCategoryEmpty('Academics', 'yearOfApplication', data?.result);
  }, [data]);
};

export const useIsSsaAcademicGPAScaleEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Academics', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Academics', 'curriculum', data?.result);
  }, [data]);
};

export const useIsSsaAcademicApsEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }

    if (!isCategoryEmpty('Academics', data?.result)) {
      return false;
    }

    return checkIfFieldInCategoryEmpty('Academics', 'aps', data?.result);
  }, [data]);
};

export const useIsSsaAcademicGPAScoreEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Academics', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Academics', 'gpaStandard', data?.result);
  }, [data]);
};

export const useIsSsaAcademicIBScoreEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Academics', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Academics', 'ib', data?.result);
  }, [data]);
};

export const useIsSsaAcademicALevelsEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Academics', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Academics', 'aLevels', data?.result);
  }, [data]);
};

// Testing
export const useIsSsaTestingSatReadingWritingEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Testing', data?.result)) {
      return false;
    }
    if (!isTestingScoreTypeMatch('ACT', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Testing', 'satReading', data?.result);
  }, [data]);
};

export const useIsSsaTestingSatMathEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Testing', data?.result)) {
      return false;
    }
    if (!isTestingScoreTypeMatch('ACT', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Testing', 'satMath', data?.result);
  }, [data]);
};

export const useIsSsaTestingActEnglishEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Testing', data?.result)) {
      return false;
    }
    if (!isTestingScoreTypeMatch('SAT', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Testing', 'actEnglish', data?.result);
  }, [data]);
};

export const useIsSsaTestingActMathEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Testing', data?.result)) {
      return false;
    }
    if (!isTestingScoreTypeMatch('SAT', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Testing', 'actMath', data?.result);
  }, [data]);
};

export const useIsSsaTestingActReadingEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Testing', data?.result)) {
      return false;
    }
    if (!isTestingScoreTypeMatch('SAT', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Testing', 'actReading', data?.result);
  }, [data]);
};

export const useIsSsaTestingActScienceEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Testing', data?.result)) {
      return false;
    }
    if (!isTestingScoreTypeMatch('SAT', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Testing', 'actScience', data?.result);
  }, [data]);
};

export const useIsSsaTestingTOEFLEmpty = (): boolean => {
  const { data } = useSsaTracking();
  return useMemo(() => {
    if (data?.skip) {
      return false;
    }
    if (!isCategoryEmpty('Testing', data?.result)) {
      return false;
    }
    return checkIfFieldInCategoryEmpty('Testing', 'toefl', data?.result);
  }, [data]);
};

const checkIfFieldInCategoryEmpty = (
  category: SsaTrackingCategoryNameType,
  field: string,
  result?: SsaTrackingResult,
): boolean => {
  return checkIfSsaFieldInCategoryEmpty(category, field, result);
};

// lookup nameInSsa
const checkIfSsaFieldInCategoryEmpty = (
  category: SsaTrackingCategoryNameType,
  field: string,
  result?: SsaTrackingResult,
): boolean => {
  const found = result?.categories.filter((c) => c.name === category)[0]?.mappings.find((m) => m.nameInSsa === field);
  if (!found) {
    return true;
  }
  return found.completion !== 100;
};

// lookup nameInApp
const checkIfAppFieldInCategoryEmpty = (
  category: SsaTrackingCategoryNameType,
  field: string,
  result?: SsaTrackingResult,
): boolean => {
  const found = result?.categories.filter((c) => c.name === category)[0]?.mappings.find((m) => m.nameInApp === field);
  if (!found) {
    return true;
  }
  return found.completion !== 100;
};

const isCategoryEmpty = (category: SsaTrackingCategoryNameType, result?: SsaTrackingResult): boolean => {
  return result?.categories.filter((c) => c.name === category)[0]?.completion !== 100;
};

const isTestingScoreTypeMatch = (match: 'SAT' | 'ACT', result?: SsaTrackingResult): boolean => {
  const found = result?.categories
    .filter((c) => c.name === 'Testing')[0]
    ?.mappings.find((m) => m.nameInSsa === 'scoreType');
  if (!found) {
    return true;
  }
  return found?.value === match;
};
