import { StyledHelp } from './style';
import { Tooltip } from 'antd';

const ImpactFactorTooltip = (): JSX.Element => {
  return (
    <Tooltip
      overlayInnerStyle={{
        borderRadius: 8,
        background: 'rgba(29, 30, 43, 0.8)',
        padding: 16,
        width: 268,
      }}
      title={
        'The number of rockets represents the impact factor. 5 rockets represents the most impressive opportunities.'
      }
      getTooltipContainer={(o) => o.parentElement as HTMLElement}
    >
      <StyledHelp />
    </Tooltip>
  );
};

export default ImpactFactorTooltip;
