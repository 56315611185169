import styled from 'styled-components';

import { Button, Tooltip } from 'antd';
import { HelpOutline } from '@styled-icons/material-outlined';
import { ReactNode } from 'react';

interface RecommenderIdeaCardProps {
  title: string;
  subtitle?: string;
  imageUrl?: string | null;
  extra?: ReactNode;
  description: string;
  viewUrl?: string;
  why?: string[];

  isSelected?: boolean;
  onSelect: () => void;
}

export const RecommenderIdeaCard = (props: RecommenderIdeaCardProps): JSX.Element => {
  const { extra, subtitle, imageUrl, title, description, why, isSelected, viewUrl, onSelect } = props;

  return (
    <IdeaCardRoot checked={isSelected} onClick={onSelect}>
      <IdeaCardTitleContainer>
        <IdeaCardTitleText>{title}</IdeaCardTitleText>{' '}
        {imageUrl && (
          <img src={imageUrl} alt="" height={32} width={32} style={{ minWidth: 32, borderRadius: 4, marginTop: 4 }} />
        )}
      </IdeaCardTitleContainer>
      {subtitle && <IdeaCardSubtitle>{subtitle}</IdeaCardSubtitle>}
      {description && <IdeaCardDescription>{description}</IdeaCardDescription>}
      {extra}
      <div style={{ flex: 1 }} />
      {why && why.length > 0 && (
        <Tooltip
          title={
            <IdeaCardWhyList>
              {why.map((reason, index) => (
                <li key={index}>{reason}</li>
              ))}
            </IdeaCardWhyList>
          }
          overlayInnerStyle={{
            background: '#1D1E2BCC',
            borderRadius: 8,
            padding: 16,
            width: 300,
          }}
        >
          <IdeaCardWhyLabel>
            <HelpOutline height="1em" width="1em" /> Why was this suggested?
          </IdeaCardWhyLabel>
        </Tooltip>
      )}
      {viewUrl && (
        <div style={{ textAlign: 'right' }}>
          <Button href={viewUrl} target="_blank">
            View more
          </Button>
        </div>
      )}
    </IdeaCardRoot>
  );
};

const IdeaCardRoot = styled.div.attrs({
  role: 'button',
})<{ checked?: boolean; disabled?: boolean }>`
  display: flex;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.55 : 1)};

  flex-basis: 32%;
  flex-direction: column;
  gap: 8px;
  padding: 20px 24px;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  border-color: ${(props) => (props.checked ? 'var(--color-indigo)' : 'var(--color-pale-grey)')};
  background: #fff;
  transition: 0.3s box-shadow ease;

  &:hover {
    cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
    box-shadow: 0px 14px 21px 0px #1d1e2b14;
  }
`;

const IdeaCardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  font-weight: 600;
  gap: 8px;
  justify-content: space-between;
  line-height: 26px;
`;

const IdeaCardTitleText = styled.div`
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-height: 3ch; */
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const IdeaCardDescription = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
`;

const IdeaCardSubtitle = styled.div`
  font-family: 'Montserrat';
  color: var(--color-stone);
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
`;

const IdeaCardWhyLabel = styled.div`
  color: var(--color-stone);
  font-family: 'Montserrat';
  font-weight: 400;
  line-height: 16px;

  &:hover {
    color: #464ac9;
    text-decoration: underline;
  }
`;

const IdeaCardWhyList = styled.ul`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  list-style-type: disc;
  padding-left: 16px;
`;
