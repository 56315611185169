import styled from 'styled-components';
import { Input } from 'antd';
import { HideScrollBar } from 'src/styles/common';

export const StyledInput = styled(Input)`
  font-size: 12px;
  width: auto;
  padding-left: 0;
  ${HideScrollBar}
`;
