import styled from 'styled-components';
import theme from 'src/web-shared-components/theme';
import { Input } from 'antd';
import { OpenInNew as OpenInNewIcon } from '@styled-icons/material';

export const StyledContainer = styled.div`
  background: white;
  border: 1px solid ${theme.common.paleGrey};
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  padding: 24px;
`;

export const StyledTitle = styled.div`
  color: ${theme.common.darkNavy};
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Divider = styled.div`
  border-top: 1px dashed var(--color-pale-grey);
  margin-top: 24px;
`;

export const StyledTitle2 = styled.div`
  color: ${theme.common.darkNavy};
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  column-gap: 6px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
`;

export const TextInput = styled(Input)`
  padding: 10px;
  color: var(--color-dark-navy);
  .ant-input::placeholder {
    color: var(--color-stone);
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OpenInNew = styled(({ isValidUrl, ...props }) => <OpenInNewIcon {...props} />)`
  color: ${({ isValidUrl }) => (isValidUrl ? 'var(--color-indigo)' : 'var(--color-stone)')};
  display: ${({ isValidUrl }) => (isValidUrl ? 'block' : 'none')};
  cursor: pointer;
`;
