import 'src/components/RoadmapBuilder/styles.less';

import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { Modal, notification } from 'antd';

import { CloseIcon } from 'src/web-shared-components/base/Icons';
import { CurrentUserContext } from 'src/context/CurrentUserContext';

import { INVALIDATE_RECOMMENDATIONS } from 'src/graphql/roadmapBuilder';

import ConfirmRecommendations from '../RoadmapBuilder/ConfirmRecommendations';
import Recommendations from '../RoadmapBuilder/Recommendations';
import * as Styled from '../RoadmapBuilder/styles';

interface StrategyRecommenderProps {
  isOpen?: boolean;
  roadmapBuilderId: string;
  userId: string;
  onClose?: VoidFunction;
}

export const StrategyRecommender = (props: StrategyRecommenderProps): JSX.Element => {
  const { isOpen = false, roadmapBuilderId, userId, onClose } = props;

  const { studentInfo } = useContext(CurrentUserContext);
  const [isGenerating, setIsGenerating] = useState(true);

  const [invalidateRoadmapBuilder, { error: invalidationError }] = useMutation(INVALIDATE_RECOMMENDATIONS);
  const [isReady, setIsReady] = useState(false);

  const [stepIndex, setStepIndex] = useState(0);

  const next = () => {
    const nextStep = stepIndex + 1;
    setStepIndex(nextStep);
  };

  const prev = () => {
    const nextStep = stepIndex - 1;
    setStepIndex(nextStep);
  };

  const steps =
    isReady && roadmapBuilderId
      ? [
          {
            title: "Select from Copilot's suggestions",
            content: (
              <Recommendations
                key="recommendations"
                forceRecommendations
                hideButtons={isGenerating}
                next={next}
                roadmapBuilderId={roadmapBuilderId}
                showExistingMissions
                userId={userId}
                onRoadmapBuilderError={() => {
                  onClose?.();
                }}
                onLoadingEnd={() => setIsGenerating(false)}
                onLoadingStart={(hard) => {
                  if (hard) setIsGenerating(true);
                }}
              />
            ),
          },
          {
            title: 'Confirm your selections',
            subtitle: `The items below will be automatically added to ${
              studentInfo?.firstName ?? 'the student'
            }’s Student Center, where they can be easily edited and modified. Additionally, any changes made will be seamlessly synced to the Roadmap as well.`,
            content: (
              <ConfirmRecommendations
                key="confirm"
                confirm={() => {
                  onClose?.();
                }}
                forceRecommendations
                hideSubheader
                roadmapBuilderId={roadmapBuilderId}
                showMissionCreated={() => {
                  // noop
                }}
                onRoadmapBuilderError={() => {
                  onClose?.();
                }}
                onBack={prev}
              />
            ),
          },
        ]
      : [
          { title: '', content: <div key={0} /> },
          { title: '', content: <div key={1} /> },
        ];

  // Invalidate any existing recommendations then mark ready when opened
  useEffect(() => {
    if (!isOpen || !roadmapBuilderId) return;

    invalidateRoadmapBuilder({
      variables: { roadmapBuilderId },
    })
      .then(() => {
        setIsReady(true);
      })
      .catch(() => {
        notification.error({
          message: 'Failed to retrigger initialization',
          description: 'Please try again',
        });
      });
  }, [invalidateRoadmapBuilder, isOpen, roadmapBuilderId]);

  // Reset state
  useEffect(() => {
    if (!isOpen) {
      setIsReady(false);
      setStepIndex(0);
    }
  }, [isOpen]);

  return (
    <Modal
      visible={isOpen}
      bodyStyle={{ padding: 0 }}
      width="800px"
      className="component-roadmap-builder-modal"
      closable={false}
      closeIcon={<CloseIcon />}
      footer={null}
      onCancel={onClose}
    >
      <Styled.ContentContainer padding="34px 49px 40px 49px">
        <Styled.TopBar>
          {stepIndex > 0 ? <Styled.PreviousButton onClick={() => prev()} /> : <div />}
          <div style={{ flex: 1 }} />
          <Styled.CloseButton onClick={onClose} />
        </Styled.TopBar>
        {invalidationError && <div>Failed to retrigger initialization.</div>}
        <div>
          {!isGenerating && steps[stepIndex].title && <Styled.Title>{steps[stepIndex].title}</Styled.Title>}
          {!isGenerating && steps[stepIndex].subtitle && <Styled.SubTitle>{steps[stepIndex].subtitle}</Styled.SubTitle>}
          <div>{steps[stepIndex].content}</div>
        </div>
      </Styled.ContentContainer>
    </Modal>
  );
};
