import { useContext, useEffect, useRef, useState } from 'react';
import { StyledDropdownOverlay, StyledDropdown, StyledDropdownOverlayItem } from '../EducationInformation/style';
import { StyledMissionStatus, StyledMissionStatusText } from '../SchoolPrograms/style';
import { StyledRightOutlined } from 'src/components/MissionDrawer/style';
import { missionLegacyStatusObject, missionStatusObject } from 'src/types/mission';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useIsStaff } from 'src/hooks/useMissionHook/useIsStaffHook';
import { notification } from 'antd';
import { useClickAway } from 'ahooks';
import { useFeatureFlag } from 'src/featureSwitches';

const MissionStatus = ({
  status,
  onSelectValue,
  editPermitted,
}: {
  status: keyof typeof missionStatusObject | undefined;
  onSelectValue: (value: keyof typeof missionStatusObject) => void;
  editPermitted: boolean;
}): JSX.Element => {
  const currentUserContext = useContext(CurrentUserContext);
  const STRATEGIST_KPI_FF = useFeatureFlag('STRATEGIST_KPI');
  const { currentUser } = currentUserContext;
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedKey, setSelectedKey] = useState<keyof typeof missionStatusObject>();
  useEffect(() => {
    status && setSelectedKey(status);
  }, [status]);
  const toggleShowDropdown = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    editPermitted && setShowDropdown(!showDropdown);
  };
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(() => {
    setShowDropdown(false);
  }, ref);

  const isStaff = useIsStaff(currentUser);

  const selectValue = (value: keyof typeof missionStatusObject) => {
    if (value === 'DONE') {
      if (!isStaff) {
        notification.info({ message: 'Only the Staff has the permission to mark it as completed' });
        return;
      }
    }
    if (value === 'EXPIRED' && !isStaff) {
      notification.info({ message: 'Only the Staff has the permission to mark it as expired' });
      return;
    }
    if (value === 'POSTPONED' && !isStaff) {
      notification.info({ message: 'Only the Staff has the permission to mark it as postponed' });
      return;
    }
    setSelectedKey(value);
    onSelectValue(value);
    setShowDropdown(false);
  };
  return (
    <StyledMissionStatus status={selectedKey} onClick={(e) => toggleShowDropdown(e)} disabled={!editPermitted}>
      <StyledDropdown>
        <StyledMissionStatusText disabled={!editPermitted}>
          {missionStatusObject[selectedKey as keyof typeof missionStatusObject]}
          <StyledRightOutlined />
        </StyledMissionStatusText>
        {showDropdown && (
          <StyledDropdownOverlay ref={ref}>
            {(
              Object.keys(STRATEGIST_KPI_FF ? missionStatusObject : missionLegacyStatusObject) as Array<
                keyof typeof missionStatusObject
              >
            ).map((key, index) => {
              return (
                <StyledDropdownOverlayItem
                  key={index}
                  onClick={(e) => {
                    selectValue(key);
                    e.stopPropagation();
                  }}
                >
                  <span
                    style={{
                      color:
                        ['DONE', 'EXPIRED', 'POSTPONED'].includes(key) && !isStaff
                          ? 'var(--color-cool-grey)'
                          : '73747d',
                    }}
                  >
                    {missionStatusObject[key]}
                  </span>
                </StyledDropdownOverlayItem>
              );
            })}
          </StyledDropdownOverlay>
        )}
      </StyledDropdown>
    </StyledMissionStatus>
  );
};

export default MissionStatus;
