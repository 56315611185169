import styled, { css } from 'styled-components';

export const SvgCommonStyle = css`
  width: var(--unit-one-em);
  height: var(--unit-one-em);
`;

export const FlexAlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexAlignCenter = css`
  display: flex;
  align-items: center;
`;
