import React, { createContext, ReactElement, useState } from 'react';
export interface Communication {
  refetch: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CategoryOverviewCommunicationContext = createContext<Communication>({
  refetch: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRefetch: () => {},
});

export interface CommunicationContextProviderProps {
  children: ReactElement | ReactElement[];
}

export const CommunicationContextProvider = (props: CommunicationContextProviderProps): JSX.Element => {
  const [refetch, setRefetch] = useState(false);
  return (
    <CategoryOverviewCommunicationContext.Provider
      value={{
        refetch,
        setRefetch,
      }}
    >
      {props.children}
    </CategoryOverviewCommunicationContext.Provider>
  );
};
