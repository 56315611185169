import { getConfig } from 'src/config';

const config = getConfig(window.process.env);

export const getBaseAppDomain = (): string =>
  window.process.env.ENVIRONMENT === 'production'
    ? 'https://app.crimsoneducation.org'
    : window.process.env.ENVIRONMENT === 'staging'
    ? 'https://staging.app.crimsoneducation.org'
    : '';
export const isProduction = (): boolean => window.process.env.ENVIRONMENT === 'production';
export const isStaging = (): boolean => window.process.env.ENVIRONMENT === 'staging';
export const isDev = (): boolean => !isProduction() && !isStaging();
export const launchDarkly = {
  clientSideId: config.launchDarklyClientSideId,
};
