import React, { useState, useEffect, useRef, useCallback } from 'react';
import { InputRef } from 'antd';
import { StyledErrorMessage } from 'src/components/TableEditableComponents/DatePicker/style';
import { StyledInput, StyledPlaceholder, StyledText, StyledNumberInput } from './style';

type Props = {
  name?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  onChangeProps?: (value: string) => void;
  maxLength?: number;
  max?: number;
  min?: number;
  type?: 'link' | 'number';
  disabled?: boolean;
  mode?: 'legacy' | 'sync' | 'async';
  delay?: number;
  validator?: (value: string) => {
    status: 'warning' | 'error' | 'valid';
    message?: string;
  };
  style?: React.CSSProperties;
  wrapperProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
};
const InputField = ({
  value,
  placeholder = 'Enter',
  onChangeProps,
  type,
  validator,
  max,
  min,
  disabled = false,
  wrapperProps,
  style,
  mode = 'legacy',
  delay = 1000,
  ...rest
}: Props): JSX.Element => {
  const inputRef = useRef<InputRef>(null);
  const [showError, setShowError] = useState(false);
  const [editing, setEditing] = useState(false);
  const focus = useRef<boolean>(false);
  const [showValue, setShowValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const submitTimerRef = useRef<NodeJS.Timeout | null>(null);
  mode = ['legacy', 'async', 'sync'].includes(mode) ? mode : 'legacy';
  const lastSubmit = useRef<string>('');
  // legacy==> only submit when pressenter(when cofig set) and blur
  // sync==> always submit when change(its ok when submit is sync function)
  // async==> do debounced submit with specific delay
  useEffect(() => {
    if (!focus.current || mode === 'legacy') {
      setShowValue(value);
    }
  }, [value, mode]);
  const doValidate = useCallback(() => {
    if (validator) {
      const result = validator(showValue);
      if (result.status !== 'valid') {
        setShowError(true);
        if (result.message) {
          setErrorMessage(result.message);
        }
        return;
      } else {
        setShowError(false);
        setErrorMessage('');
      }
    }
  }, [validator, showValue, setShowError, setErrorMessage]);
  // always do validate once local value is changed
  useEffect(() => {
    doValidate();
  }, [showValue, doValidate]);
  const doSubmit = useCallback(() => {
    // will not submit if validate failed
    if (validator) {
      const result = validator(showValue);
      if (result.status !== 'valid') {
        return;
      }
    }
    if (showValue) {
      if (String(showValue) === lastSubmit.current) {
        return;
      }
      lastSubmit.current = String(showValue);
      onChangeProps?.(showValue);
    }
  }, [showValue, onChangeProps, validator]);
  const toggleEdit = useCallback(() => {
    if (disabled) {
      return;
    }
    setEditing(!editing);
    focus.current = !focus.current;
  }, [disabled, setEditing, editing]);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus({
        cursor: 'end',
      });
    }
  }, [editing]);
  useEffect(() => {
    if (!focus.current || showValue === value || mode === 'legacy') {
      return;
    }
    if (mode === 'sync') {
      // just submit it
      doSubmit();
      return;
    }
    if (mode === 'async') {
      submitTimerRef.current && clearTimeout(submitTimerRef.current);
      submitTimerRef.current = setTimeout(() => {
        doSubmit();
        submitTimerRef.current = null;
      }, delay);
    }
  }, [doSubmit, value, showValue, mode, delay]);
  const handleBlur = useCallback(() => {
    setEditing(false);
    focus.current = false;
    if (mode === 'async') {
      submitTimerRef.current && clearTimeout(submitTimerRef.current);
    }
    doSubmit();
  }, [doSubmit, setEditing, mode]);

  return (
    <div {...wrapperProps}>
      {editing ? (
        type !== 'number' ? (
          <StyledInput
            ref={inputRef}
            disabled={disabled}
            value={showValue}
            placeholder={placeholder}
            bordered={false}
            onChange={(e) => {
              const value = e.target.value;
              setShowValue(value);
              if (validator) {
                if (showError) {
                  const result = validator(showValue);
                  if (result.status !== 'valid') {
                    setShowError(true);
                    if (result.message) {
                      setErrorMessage(result.message);
                    }
                    return;
                  } else {
                    setShowError(false);
                    setErrorMessage('');
                  }
                }
              }
            }}
            onBlur={handleBlur}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={style}
            {...rest}
          />
        ) : (
          <StyledNumberInput
            type="number"
            value={showValue}
            placeholder={placeholder}
            disabled={disabled}
            bordered={false}
            onChange={(value) => {
              if (value) {
                setShowValue(value + '');
                if (validator) {
                  if (showError) {
                    const result = validator(showValue);
                    if (result.status !== 'valid') {
                      setShowError(true);
                      if (result.message) {
                        setErrorMessage(result.message);
                      }
                      return;
                    } else {
                      setShowError(false);
                      setErrorMessage('');
                    }
                  }
                }
              }
            }}
            max={max}
            min={min}
            onBlur={handleBlur}
            controls={false}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyPress={(e) => {
              const invalidCharacter = ['-', '+', 'e', 'E'];
              if (invalidCharacter.indexOf(e.key) !== -1) {
                e.preventDefault();
              }
            }}
            style={style}
            {...rest}
          />
        )
      ) : (
        <StyledText style={style} onClick={toggleEdit} disabled={disabled}>
          {showValue ? (
            type ? (
              type === 'link' ? (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(showValue);
                  }}
                >
                  {showValue}
                </a>
              ) : (
                <span>{showValue}</span>
              )
            ) : (
              <span>{showValue}</span>
            )
          ) : (
            <StyledPlaceholder>{placeholder}</StyledPlaceholder>
          )}
        </StyledText>
      )}
      {showError && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </div>
  );
};

export default InputField;
