import styled from 'styled-components';
import theme from 'src/web-shared-components/theme';
import DebounceFetcherSelect from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/DebounceSelect';
import { ModeEdit } from '@styled-icons/material/ModeEdit';
import { StyledButtonOutlined, StyledButtonFilled } from '../CrButton';

export const StyledContainer = styled.div`
  position: relative;
  background: white;
  border: 1px solid ${theme.common.paleGrey};
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  padding: 24px;
`;
export const StyledUl = styled.ul<{ maxHeight?: string }>`
  margin-top: 32px;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '230px')};
  overflow: auto;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > div:first-child {
      width: 70%;
    }
    > div:nth-child(2) {
      width: 30%;
    }
  }
`;
export const StyledUserDesc = styled.div`
  margin-left: 12px;
  overflow: hidden;
  div:first-child {
    word-break: break-word;
  }
`;

export const StyledTeamHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSelect = styled(DebounceFetcherSelect)`
  width: 240px;
`;

//  Modal styles
export const Container = styled.div`
  min-height: 450px;
  padding: 12px 0 0 0;
  & .ant-form-item-label label {
    color: var(--color-dark-navy);
    font-weight: 600;
  }
  & .ant-form-item-explain-error {
    padding: 8px 0 15px 0;
  }
  & .ant-select-selector {
    > .ant-select-selection-item {
      font-size: 14px;
    }
    .ant-select-selection-overflow {
      align-items: center;
      max-width: 92%;
    }
    .ant-select-selection-overflow-item-rest {
      height: 24px;
      > .ant-select-selection-item {
        height: 22px;
        line-height: 22px;
        font-size: 14px;
      }
    }
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--color-placeholder-grey);
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1d1e2b;
  text-align: center;
  margin-bottom: 46px;
`;

export const ButtonContainer = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`;

export const CancelButton = styled(StyledButtonOutlined)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;

export const SubmitButton = styled(StyledButtonFilled)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;

export const StyledEditIcon = styled(ModeEdit)`
  width: 22px;
  color: var(--color-stone);
  &:hover {
    cursor: pointer;
    color: var(--color-indigo);
  }
`;
