import styled from 'styled-components';
import { theme } from 'src/web-shared-components/theme';
import { HelpOutline } from '@styled-icons/material-outlined';
import { StyledButtonOutlined, StyledButtonFilled } from 'src/web-shared-components/base/Buttons';
import { motion } from 'framer-motion';
import { Select } from 'antd';
export const InteractiveWrapper = styled.div`
  display: flex;
  border: 1px solid #e3e7ed;
  border-radius: 4px;
  padding: 0 12px;
  transition: border-color 0.4s;

  &:focus {
    border-color: ${theme.common.indigo};
  }
  &:focus-within {
    border-color: ${theme.common.indigo};
  }
  &:hover {
    border-color: ${theme.common.indigo};
  }
`;

export const StyledHelp = styled(HelpOutline)`
  width: 17px;
  height: 17px;
  :hover {
    color: #464ac9;
  }
  margin-left: 5px;
`;

export const SecionContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const SecionTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #1d1e2b;
  position: relative;
`;

export const GreyText = styled.div`
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
  color: #a9acc0;
`;

export const PrimaryButton = styled(StyledButtonFilled)`
  height: 40px;
  font-size: 14px;
`;
export const SecondaryButton = styled(StyledButtonOutlined)`
  height: 40px;
  font-size: 14px;
  border-width: 2px;
`;

export const DivWithCustomScrollBar = styled.div`
  &::-webkit-scrollbar {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e3e7ed;
    border-radius: 12px;
    width: 10px;
  }
`;

export const StyledSelect = styled(Select)`
  & .ant-select-selector {
    min-height: 43px !important;
    background: #ffffff;
    border: 1px solid #e3e7ed;
    border-radius: 4px;
    padding-left: 15px;
  }

  & .ant-select-selection-item {
    margin: auto;
  }
  & .ant-select-selection-overflow {
    gap: 4px;
  }
  & .ant-select-selection-placeholder {
    left: 20px;
    display: flex;
    align-items: center;
  }
`;

export const StyledOption = styled(Select.Option)`
  color: var(--color-stone);
  padding-left: 15px;
`;

export const WatchListCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  > span {
    font-weight: 500;
    font-size: 12px;

    color: #12c39a;
  }
`;
