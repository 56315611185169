import { StyledHelp } from './style';
import { Tooltip } from 'antd';

const CompetitivenessTooltop = (): JSX.Element => {
  return (
    <Tooltip
      overlayInnerStyle={{
        borderRadius: 8,
        background: 'rgba(29, 30, 43, 0.8)',
        padding: 16,
        width: 268,
      }}
      title={
        'The number of stars represents the difficulty level. 5 stars represents the most difficult and time consuming opportunities.'
      }
      getTooltipContainer={(o) => o.parentElement as HTMLElement}
    >
      <StyledHelp />
    </Tooltip>
  );
};

export default CompetitivenessTooltop;
