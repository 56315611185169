import styled from 'styled-components';
import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';

import { ArrowRightOutlined } from '@ant-design/icons';
import { CurrentUserContext } from 'src/context/CurrentUserContext';

import { StrategyRecommender } from './StrategyRecommender';
import { QUERY_ROADMAPBUIDLER_VISIBLE } from 'src/graphql/roadmapBuilder';

export const StrategyRecommenderNavCard = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const { studentInfo } = useContext(CurrentUserContext);
  const studentId = studentInfo?.userId;

  const { data: roadmapBuilderVisible } = useQuery<{
    roadmapBuilderVisible: {
      isInit: boolean;
      roadmapBuilderVisible: boolean;
      roadmapBuilderId: string | null;
      inProgress: boolean;
    };
  }>(QUERY_ROADMAPBUIDLER_VISIBLE, {
    variables: { userId: studentId },
    skip: !studentId,
  });

  if (
    !roadmapBuilderVisible ||
    !roadmapBuilderVisible.roadmapBuilderVisible.roadmapBuilderId ||
    roadmapBuilderVisible.roadmapBuilderVisible.inProgress
  ) {
    // We don't show AI strategy recommendations if the student hasn't been initialized
    return <></>;
  }

  return (
    <>
      <StyledContainer data-appcues-id="ai-strategy-cta">
        <StyledHeader>Copilot&apos;s strategy plan</StyledHeader>
        <StyledDesc>
          <span>Try it anytime when planning for the student&apos;s missions.</span>
        </StyledDesc>

        <StyledActionButton onClick={() => setIsOpen(true)}>
          <ArrowRightOutlined />
          &nbsp;&nbsp;<span>Start</span>
        </StyledActionButton>
      </StyledContainer>
      {studentInfo?.userId && (
        <StrategyRecommender
          isOpen={isOpen}
          roadmapBuilderId={roadmapBuilderVisible.roadmapBuilderVisible.roadmapBuilderId}
          userId={studentInfo.userId}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

const StyledContainer = styled.div`
  padding: 24px;
  background: url('/static/strategy_recommendation_nav_card_2.svg');
  background-size: cover;
  border-radius: 8px;
`;
const StyledHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
`;
const StyledDesc = styled.div`
  margin-top: 10px;
  width: 192px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-mist);
`;
export const StyledActionButton = styled.button`
  appearance: none;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  width: 82px;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background-color: #6c8acc;
  margin-top: 16px;
  transition: 0.3s ease background-color;

  &:hover {
    background-color: #91a7d9;
  }
  &:active {
    background-color: #4066b9;
  }
`;
