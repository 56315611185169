import { gql } from '@apollo/client';

export {
  ADD_OR_UPDATE_TEST,
  DELETE_TEST_MISSION,
  LOAD_TEST_BY_MISSION_ID,
  LOAD_TEST_MISSIONS,
  LOAD_TEST_OVERVIEW,
  UPDATE_TARGET_STATUS,
} from '@crimson-education/core-shared-graphql/testing';

export const loadTestOverViewAndMissionsGql = gql`
  query loadTestOverViewAndMissions($userId: String!) {
    loadTestOverview(userId: $userId) {
      id
      userId
      type
      bestScore
      count
      testDate
      targetReached
    }
    loadTestMissionsList(userId: $userId) {
      category
      list {
        type
        list {
          id
          creatorId
          userId
          missionId
          missionStatus
          type
          subCategory
          testDate
          totalScore
          targetScore
          isMockTest
          members
          testResult {
            id
            studentTestId
            key
            value
          }
        }
      }
    }
  }
`;

export const getTestOptOutGql = gql`
  query getTestOptOut($userId: String!) {
    getTestOptOut(userId: $userId) {
      userId
      testOptOutUS
      testOptOutUK
      testOptOutEU
      lngTestOptOutUS
      lngTestOptOutUK
      lngTestOptOutEU
    }
  }
`;

export const setTestOptOutGql = gql`
  mutation setTestOptOut($input: testOptOutInput!) {
    setTestOptOut(input: $input) {
      userId
      testOptOutUS
      testOptOutUK
      testOptOutEU
      lngTestOptOutUS
      lngTestOptOutUK
      lngTestOptOutEU
    }
  }
`;
