import { Cached as RegenerateIcon } from '@styled-icons/material';
import styled from 'styled-components';

interface RegenerateLinkProps {
  onClick: VoidFunction;
}

export const RegenerateLink = ({ onClick }: RegenerateLinkProps): JSX.Element => {
  return (
    <Text role="button" onClick={onClick}>
      <span className="label">Give me new ideas</span> <RegenerateIcon className="icon" />
    </Text>
  );
};

const Text = styled.span`
  color: var(--color-stone);
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  user-select: none;

  & .icon {
    height: 1.25em;
    width: 1.25em;
    transform: rotate(0deg);
    transition: 0.3s transform ease;
  }

  &:hover .label {
    text-decoration: underline;
  }
  &:hover .icon {
    transform: rotate(45deg);
  }
`;
