import { ReactNode, MouseEventHandler, FunctionComponent } from 'react';
import { useMenuContext } from './context';
import * as Styled from './styles';

interface OptionProps {
  children: ReactNode;
  onClick?: MouseEventHandler;
}
const Option: FunctionComponent<OptionProps> = ({ children, onClick }) => {
  const { visible, setVisibility } = useMenuContext('Menu.Option');
  const handleClick: MouseEventHandler = (event) => {
    if (onClick) {
      onClick(event);
    }
    setVisibility(!visible);
  };
  return <Styled.MenuOption onClick={handleClick}>{children}</Styled.MenuOption>;
};

export default Option;
