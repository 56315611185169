import styled from 'styled-components';
import { List, Button } from 'antd';
import { CheckCircleFilled, RightOutlined, InfoCircleOutlined } from '@ant-design/icons';

export const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  margin-bottom: 120px;
  width: 260px;
`;

export const PadContainer = styled.div<{ gone?: boolean }>`
  padding: 24px;
  background: linear-gradient(180deg, rgba(59, 43, 72, 1) 0%, rgba(19, 24, 44, 1) 100%);
  mix-blend-mode: normal;
  border-radius: 10px;
  display: ${({ gone }) => (gone ? 'none' : '')};
`;

export const DockContainer = styled.div`
  margin-top: 10px;
  width: 40px;
  height: 40px;
  background: #2f3457;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 24px;
  color: #ffffff;
`;

export const Desc = styled.div`
  margin-top: 12px;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Montserrat';
  line-height: 18px;
  color: #e3e7ed;
`;

export const CategoryItem = styled(List.Item)<{ $active: boolean }>`
  display: flex;
  gap: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #e3e7ed;
  margin-left: -4px;
  margin-right: -4px;
  padding-left: 4px;
  padding-right: 4px;

  &.ant-list-item {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    cursor: pointer;
  }

  background: ${(props) => {
    if (props.$active) {
      return 'rgba(255, 255, 255, 0.2)';
    }
  }};

  border-radius: ${(props) => {
    if (props.$active) {
      return '8px';
    }
  }};
`;

export const CategoryItemText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #e3e7ed;
  flex: 1;
`;

export const PrimaryButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  height: 32px;
  background: #464ac9;
  border-radius: 28px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  font-family: 'Montserrat';
  border: 0px;
`;

export const LeftCheckIcon = styled(CheckCircleFilled)`
  color: #12c39a;
  font-size: 20px;
`;

export const LeftInfoIcon = styled(InfoCircleOutlined)`
  color: #fdaa02;
  font-size: 20px;
`;

export const RightArrowIcon = styled(RightOutlined)`
  color: #e3e7ed;
  font-size: 18px;
`;
