import { createContext, useState, useRef, useContext, useEffect } from 'react';

type ContextType = {
  modalStates: React.MutableRefObject<Partial<Record<ModalNames, boolean>>>;
  activeModal: string | null;
  setActiveModal: React.Dispatch<React.SetStateAction<ModalNames | null>>;
};

const OrderedModalContext = createContext<ContextType>(null as unknown as ContextType);

type ModalNames = 'roadmapBuilder' | 'upgradePathfinder';

export const withOrderedModalContext = <P,>(
  Component: React.ComponentType<P>,
): ((props: P & JSX.IntrinsicAttributes) => JSX.Element) => {
  const Enhanced = (props: P & JSX.IntrinsicAttributes) => {
    const [activeModal, setActiveModal] = useState<ModalNames | null>(null);
    const modalStates = useRef<Partial<Record<ModalNames, boolean>>>({});
    return (
      <OrderedModalContext.Provider
        value={{
          modalStates,
          activeModal,
          setActiveModal,
        }}
      >
        <Component {...props} />
      </OrderedModalContext.Provider>
    );
  };
  return Enhanced;
};

export const useOrderedModal = (
  modalName: ModalNames,
  initialState: boolean,
): [isOpen: boolean, setModalOpen: (open: boolean) => void] => {
  const { modalStates, activeModal, setActiveModal } = useContext(OrderedModalContext);

  const isOpen = activeModal === modalName;

  useEffect(() => {
    modalStates.current = {
      ...modalStates.current,
      [modalName]: initialState,
    };
    if (initialState) {
      queueModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalName, initialState]);

  const closeModal = () => {
    modalStates.current = {
      ...modalStates.current,
      [modalName]: false,
    };
    const nextCandidate = Object.keys(modalStates.current).find((key) => modalStates.current[key as ModalNames]);
    setActiveModal((nextCandidate as ModalNames) || null);
  };

  const queueModal = () => {
    modalStates.current = {
      ...modalStates.current,
      [modalName]: true,
    };
    if (activeModal === null) {
      setActiveModal(modalName);
    }
  };

  const setModalOpen = (open: boolean) => {
    if (open) {
      queueModal();
    } else {
      closeModal();
    }
  };

  return [isOpen, setModalOpen];
};
