import { MouseEvent } from 'react';
import styled from 'styled-components';

import { CloseIcon } from 'src/web-shared-components/base/Icons';

import { Modal as AntModal, ModalProps } from 'antd';

import { Spark } from '../Spark';

type AIDialogProps = ModalProps;

export function AIDialog({ bodyStyle, children, footer, title, ...props }: AIDialogProps): JSX.Element {
  return (
    <Modal
      bodyStyle={{
        padding: 0,
        ...bodyStyle,
      }}
      closable
      closeIcon={<CloseIcon height={24} width={24} onClick={(e) => props.onCancel?.(e as MouseEvent<HTMLElement>)} />}
      footer={null}
      width="960px"
      {...props}
    >
      <Trim />
      <Container>
        {title && (
          <Title>
            <Icon>
              <img src="/static/rateMyProfile/robotTrigger.svg" alt="" />
              <div
                style={{
                  position: 'absolute',
                  right: -8,
                  top: 0,
                }}
              >
                <Spark />
              </div>
            </Icon>
            <span>{title}</span>
          </Title>
        )}

        {children}
        {footer && <Actions>{footer}</Actions>}
      </Container>
    </Modal>
  );
}

const Actions = styled.div`
  display: flex;
  padding: 16px 0 40px;
  justify-content: center;
`;

const Container = styled.div`
  background-color: #f3f6fa;
  max-height: 720px;
  overflow: hidden;
  padding: 40px 50px 0 50px;

  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
`;

const Icon = styled.div`
  position: relative;
`;

const Modal = styled(AntModal)`
  & .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
`;

const Title = styled.div`
  align-items: center;
  color: var(--color-dark-navy);
  display: flex;
  flex-direction: row;
  font-size: 24px;
  gap: 24px;
  justify-content: center;
  line-height: 29px;
  text-align: center;

  & span {
    font-family: Montserrat-SemiBold;
  }
`;

const Trim = styled.div`
  background: linear-gradient(90deg, rgba(170, 78, 231, 0.8) 0%, rgba(70, 74, 201, 0.8) 99.22%);
  height: 12px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
