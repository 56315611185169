import {
  StyledContainer,
  StyledSection,
  StyledSectionHeader,
  StyleSectionHeaderTitle,
} from '../CurrentAchievements/style';
import { CategoryMapping } from '../Recommendations';
import { SelectionType } from '../types';
import * as Styled from '../styles';
import { RecommendationIcon } from '../common/RecommendationIcon';
import {
  StyledMissionCardsContainer,
  StyledMissionCard,
  StyledSchoolYearsContainer,
  StyledSchoolYear,
  StyledCategoryText,
  SubHeader,
  StyledMissionIcon,
} from './style';
import { StyledFlexBetween } from 'src/styles/common';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_MISSION_FROM_ROADMAP_BUILDER, GET_RECOMMENDATIONS } from 'src/graphql/roadmapBuilder';
import { notification } from 'antd';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { useDebounceFn } from 'ahooks';
import { StudentInitInfoContext } from 'src/context/StudentInitInfoContext';
import { GradeLevelType } from 'src/__generated__/graphqlTypes';

type ConfirmSelectionType = {
  [schoolYear: number]: {
    category: string;
    id: string;
    schoolYears: number[];
    title: string;
  }[];
};
const ConfirmRecommendations = ({
  confirm,
  forceRecommendations,
  roadmapBuilderId,
  showMissionCreated,
  hideSubheader = false,
  onBack,
  onRoadmapBuilderError,
}: {
  confirm: () => void;
  forceRecommendations?: boolean;
  roadmapBuilderId: string | null;
  hideSubheader?: boolean;
  showMissionCreated: (categoryMissionCreated: { [key: string]: number }) => void;
  onBack?: () => void;
  onRoadmapBuilderError: () => void;
}): JSX.Element => {
  const [isPollingRecommendations, setIsPollingRecommendations] = useState(true);
  const { studentInitInfo } = useContext(StudentInitInfoContext);
  const { studentInfo } = useContext(CurrentUserContext);
  const [createMissionsFromRoadmapBuilder] = useMutation(CREATE_MISSION_FROM_ROADMAP_BUILDER);
  const [createMissionLoading, setCreateMissionLoading] = useState(false);
  const [selection, setSelection] = useState<SelectionType | undefined>();
  // In theory we shouldn't ever really _need_ to poll here because we _should_ have finished
  // generating recommendations during the last step, but better safe than sorry.
  const { loading: roadmapbuilderLoading, ...getRecommendationsQuery } = useQuery(GET_RECOMMENDATIONS, {
    variables: {
      force: forceRecommendations,
      roadmapBuilderId: roadmapBuilderId,
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5_000,
    skip: !isPollingRecommendations,
  });
  const roadmapBuilderData = getRecommendationsQuery.data ?? getRecommendationsQuery.previousData;
  const _roadmapBuilderData = useMemo(() => roadmapBuilderData?.getRoadmapBuilder?.data, [roadmapBuilderData]);

  // Poll for generated data
  useEffect(() => {
    if (!_roadmapBuilderData) return;
    setIsPollingRecommendations(_roadmapBuilderData.recommendationStatus !== 'DONE');
  }, [_roadmapBuilderData]);

  useEffect(() => {
    const message = roadmapBuilderData?.getRoadmapBuilder?.message;
    if (message) {
      notification.warning({
        message,
      });
      onRoadmapBuilderError();
    }
  }, [roadmapBuilderData, onRoadmapBuilderError]);
  useEffect(() => {
    if (!_roadmapBuilderData || _roadmapBuilderData.recommendationStatus !== 'DONE') return;
    const selectionData: SelectionType = {};
    Object.keys(_roadmapBuilderData.recommendationSelection).forEach((category: string) => {
      const categorySelection = _roadmapBuilderData.recommendationSelection[category];
      if (categorySelection && Array.isArray(categorySelection) && categorySelection.length > 0) {
        categorySelection.forEach(({ id, schoolYears }: { id: string; schoolYears: number[] }) => {
          const recItem = _roadmapBuilderData.recommendation[category]?.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => item.id === id,
          );
          const title = category === 'testing' || category === 'eclActivity' ? recItem?.name : recItem?.title;
          if (selectionData[category]) {
            selectionData[category][id] = { id, schoolYears, title };
          } else {
            selectionData[category] = {
              [id]: { id, schoolYears, title },
            };
          }
        });
      }
    });
    setSelection(selectionData);
  }, [_roadmapBuilderData]);

  const localTransformedSelection: ConfirmSelectionType = {};
  if (selection) {
    Object.keys(selection).forEach((category: string) => {
      const categorySelection = selection[category];
      Object.values(categorySelection).forEach(({ id, schoolYears, title }) => {
        schoolYears.forEach((schoolYear: number) => {
          if (localTransformedSelection[schoolYear]) {
            localTransformedSelection[schoolYear].push({ category, id, schoolYears, title });
          } else {
            localTransformedSelection[schoolYear] = [{ category, id, schoolYears, title }];
          }
        });
      });
    });
  }
  const confirmRecommendation = async () => {
    if (!selection) {
      return;
    }
    const {
      data: { createMissionsFromRoadmapBuilder: createResultData },
    } = await createMissionsFromRoadmapBuilder({
      variables: {
        force: forceRecommendations,
        roadmapBuilderId,
      },
    });
    if (createResultData) {
      const { testing, eclActivity, experience, researchPublication, reading, summerProgram, honor } = createResultData;
      const totalMissionCreated =
        testing + eclActivity + experience + summerProgram + reading + researchPublication + honor;
      showMissionCreated({ testing, eclActivity, experience, reading, researchPublication, summerProgram, honor });

      notification.success({
        message: <div>Great!</div>,
        description: (
          <div>
            {!forceRecommendations ? 'You have successfully initialized Pathfinder and Student Center! ' : ''}
            {totalMissionCreated} {totalMissionCreated > 1 ? 'Missions were' : 'Mission was'} created in Student Center!
          </div>
        ),
      });
      confirm();
    }

    setCreateMissionLoading(false);
  };
  const { run: debouncedOnFinish } = useDebounceFn(confirmRecommendation, { wait: 500 });
  return (
    <StyledContainer>
      {roadmapbuilderLoading && <LoadingIndicator />}
      {!roadmapbuilderLoading && (
        <>
          {!hideSubheader && (
            <SubHeader>
              These will be added into {studentInfo?.firstName}&apos;s Student Center and can be further edited there.
              They will also appear in {studentInfo?.firstName}&apos;s roadmaps too.
            </SubHeader>
          )}
          <div>
            <div style={{ height: '452px', overflow: 'auto' }}>
              {Object.keys(localTransformedSelection).map((schoolYear) => {
                const selectedArr = localTransformedSelection[parseInt(schoolYear)];
                return (
                  <StyledSection key={schoolYear}>
                    <StyledSectionHeader>
                      <StyleSectionHeaderTitle>
                        <img src="/static/roadmapBuilder/grade.png" />
                        {studentInitInfo?.gradeLevelType === GradeLevelType.Grade ? 'Grade' : 'Year'}
                        {` `}
                        {schoolYear}
                      </StyleSectionHeaderTitle>
                    </StyledSectionHeader>
                    <StyledMissionCardsContainer>
                      {selectedArr.map(({ schoolYears, id, category, title }) => {
                        const indexOfSchoolYears = schoolYears.indexOf(parseInt(schoolYear));
                        return (
                          <StyledMissionCard key={id}>
                            <StyledMissionIcon>
                              <RecommendationIcon category={category} id={id} title={title} />
                            </StyledMissionIcon>
                            <div style={{ flex: 1 }}>
                              <StyledFlexBetween>
                                <span style={{ color: 'var(--color-dark-navy)' }}>{title}</span>
                                {/* {category === 'testing' && (
                            <span style={{ fontSize: '12px', color: 'var(--color-stone)' }}>Target score: 1500</span>
                          )} */}
                              </StyledFlexBetween>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <StyledCategoryText>
                                  {CategoryMapping[category as keyof typeof CategoryMapping].showText}
                                </StyledCategoryText>
                                {schoolYears.length > 1 && (
                                  <StyledSchoolYearsContainer>
                                    {schoolYears.map((schoolYear, index) => {
                                      return <StyledSchoolYear key={schoolYear} active={index <= indexOfSchoolYears} />;
                                    })}
                                  </StyledSchoolYearsContainer>
                                )}
                              </div>
                            </div>
                          </StyledMissionCard>
                        );
                      })}
                    </StyledMissionCardsContainer>
                  </StyledSection>
                );
              })}
            </div>
            <Styled.ButtonContainer style={{ marginTop: '32px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                {onBack && <Styled.CancelButton onClick={onBack}>Back</Styled.CancelButton>}
                <Styled.NextButton
                  type="primary"
                  onClick={() => {
                    setCreateMissionLoading(true);
                    debouncedOnFinish();
                  }}
                  loading={createMissionLoading}
                >
                  Confirm
                </Styled.NextButton>
              </div>
            </Styled.ButtonContainer>
          </div>
        </>
      )}
    </StyledContainer>
  );
};
export default ConfirmRecommendations;
