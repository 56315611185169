import { gql } from '@apollo/client';

export const GetRecommendedGoals = gql`
  query GetRecommendedGoals($studentId: String!, $category: String!, $subcategory: String!) {
    getRecommendedGoals(studentId: $studentId, category: $category, subcategory: $subcategory) {
      id
      title
      achievedLevel
      levels {
        id
        points
        level
        description
        isAssigned
        status
      }
    }
  }
`;
export const getGroupedRecommendedGoals = gql`
  query getGroupedRecommendedGoals($studentId: String!, $category: String, $keyword: String, $pathfinderType: String) {
    getGroupedRecommendedGoals(
      studentId: $studentId
      keyword: $keyword
      pathfinderType: $pathfinderType
      category: $category
    ) {
      category
      subcategory
      goalCards {
        id
        category
        v2category
        note
        assignStatus
        targetLevel
        nextLevel
        nextLevelDescription
        nextLevelPoints
        achievedLevel
        targetLevelPoints
        achievedPoints
        possiblePoints
        assignedPoints
        reviewLevel
        reviewPoints
        subcategory
        v2subcategory
        title
        integrationType
        description
        levels {
          id
          points
          level
          description
          isAssigned
          status
        }
      }
    }
  }
`;
export const GetSubcategories = gql`
  query GetSubcategories($category: String!, $userId: String) {
    getSubcategories(category: $category, userId: $userId)
  }
`;

export const CreateMission = gql`
  mutation EditMissions($input: EditMissionsInput!) {
    editMissions(input: $input) {
      id
      action
    }
  }
`;

export const QUERY_DAYS_SINCE_LAST_ACHIEVE_POINT = gql`
  query getDaysSinceLastAchievePoint($userId: String!) {
    getDaysSinceLastAchievePoint(userId: $userId)
  }
`;

export const GET_CATEGORY_PROGRESS = gql`
  query categoryProgress($userId: String!, $pathfinderType: String, $version: Int) {
    categoryProgress(userId: $userId, pathfinderType: $pathfinderType, version: $version) {
      actual
      adjust
      achieved
      review
      goal
      category
      status {
        status
        points
        level
      }
      reviewStatus {
        status
        points
        level
      }
      targetLevel
    }
  }
`;
export const QUERY_STUDENT_OVERALL_GOAL = gql`
  query queryStudentOverallGoal($userId: String!) {
    studentOverallGoal(userId: $userId) {
      userId
      startDate
      endDate
      targetLevel
      isSelfInit
      pathfinderType
      version
    }
    latestVersion
  }
`;

export const UPDATE_YEAR_OF_APPLICATION = gql`
  mutation updateYearOfApplication($userId: String!, $yearOfApplication: String!) {
    updateYearOfApplication(userId: $userId, yearOfApplication: $yearOfApplication)
  }
`;

export const CREATE_STUDENT_OVERALL_GOAL = gql`
  mutation createStudentOverallGoal(
    $userId: String!
    $targetLevel: Level!
    $startDate: String!
    $applicationYear: Int!
    $isSelfInit: Boolean!
    $pathfinderType: PathfinderType!
  ) {
    createStudentOverallGoal(
      input: {
        userId: $userId
        targetLevel: $targetLevel
        startDate: $startDate
        applicationYear: $applicationYear
        isSelfInit: $isSelfInit
        pathfinderType: $pathfinderType
        version: 2
      }
    ) {
      userId
      endDate
      startDate
      targetLevel
      pathfinderType
    }
  }
`;

export const EDIT_STUDENT_OVERALL_GOAL = gql`
  mutation editStudentOverallGoal(
    $userId: String!
    $targetLevel: Level!
    $startDate: String!
    $isSelfInit: Boolean!
    $pathfinderType: PathfinderType!
    $applicationYear: Int
  ) {
    editStudentOverallGoal(
      input: {
        userId: $userId
        targetLevel: $targetLevel
        startDate: $startDate
        applicationYear: $applicationYear
        isSelfInit: $isSelfInit
        pathfinderType: $pathfinderType
        version: 2
      }
    ) {
      userId
      endDate
      startDate
      targetLevel
      pathfinderType
    }
  }
`;

export const GET_OVERALL_PROGRESS = gql`
  query GET_OVERALL_PROGRESS($userId: String!, $pathfinderType: String, $version: Int) {
    overallProgress(userId: $userId, pathfinderType: $pathfinderType, version: $version) {
      assignedPoints
      startPoints
      completedPoints
      reviewPoints
      allAchievedPoints
      goalPointsPerMonth
      actualPointsNeedPerMonth
      actualPointsAchievedPerMonth
      targetAchievedPointes
      lastThirtyDaysAchievedPoints
      targetOverallPoints
      lastMonthGoalPoints
      lastMonthAchievedPoints
      currentMonthGoalPoints
      currentMonthAchievedPoints
      recommendedTotalToDatePoints
      # The level for the student has achieved
      overallLevelStatus {
        status
        points
        level
      }
      # The overall target level for the student
      targetLevel
      reviewLevelStatus {
        status
        points
        level
      }
    }
  }
`;

export const GET_LEADER_BOARD = gql`
  query GET_LEADER_BOARD(
    $studentId: String!
    $isSameCountry: Boolean!
    $isSameAppYear: Boolean!
    $isSameTargetLevel: Boolean!
    $pathfinderType: String
  ) {
    studentPerformance(
      studentId: $studentId
      isSameCountry: $isSameCountry
      isSameAppYear: $isSameAppYear
      isSameTargetLevel: $isSameTargetLevel
      pathfinderType: $pathfinderType
    ) {
      user {
        userId
      }
      achievedPoints {
        total
        Academic
        SAT
        Personal
        Extracurricular
        Majors
        Subject
        Academics
        Testing
        Activities
        Honors
      }
      percentRanks {
        total
        Academic
        SAT
        Personal
        Extracurricular
        Majors
        Subject
        Academics
        Testing
        Activities
        Honors
      }
      gapToTop70p
    }
  }
`;

export const GET_CATEGORY_BADGES = gql`
  query GET_CATEGORY_BADGES(
    $studentId: String!
    $category: String
    $pathfinderType: String
    $start: String
    $end: String
  ) {
    getCategoryBadge(
      studentId: $studentId
      category: $category
      pathfinderType: $pathfinderType
      start: $start
      end: $end
    ) {
      scout
      seeker
      venturer
      voyager
      globetrotter
      initiative
    }
  }
`;

export const GET_IDEAS = gql`
  query getInspirationIdeas($studentId: String!, $category: String, $keyword: String, $pathfinderType: String) {
    getInspirationIdeas(
      studentId: $studentId
      keyword: $keyword
      pathfinderType: $pathfinderType
      category: $category
    ) {
      category
      subcategory
      ideas {
        id
        title
        achieved
        total
        category
        subcategory
        howTo
        levels {
          id
          level
          description
          status
          points
        }
        hidePoints
      }
    }
  }
`;

export const GET_POINTS_GAINED_REMINDER = gql`
  query getPointsGainedReminder($studentId: String!, $pathfinderType: String!, $before: String!) {
    getPointsGainedReminder(studentId: $studentId, pathfinderType: $pathfinderType, before: $before) {
      remind
    }
  }
`;

export const GET_ACHIEVED_IDEAS = gql`
  query getAchievedIdeas($studentId: String!, $category: String, $pathfinderType: String) {
    getAchievedIdeas(studentId: $studentId, category: $category, pathfinderType: $pathfinderType) {
      total
      ideas {
        id
        title
        achievedDescription
        achieved
        howTo
        category
        achievedAt
        achievedLevel
        levels {
          id
          level
          description
          points
        }
      }
    }
  }
`;

export const GET_ACHIEVED_BADGES = gql`
  query GetAchievedBadge($studentId: String!, $category: String, $pathfinderType: String) {
    getAchievedBadge(studentId: $studentId, category: $category, pathfinderType: $pathfinderType) {
      id
      title
      description
      category
      subcategory
      completedAt
      badge
    }
  }
`;

export const UPDATE_STUDENT_POINTS = gql`
  mutation updatePoints($studentId: String!) {
    updatePoints(studentId: $studentId)
  }
`;
