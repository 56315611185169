import styled, { css } from 'styled-components';
import { Input, InputNumber } from 'antd';
const sharedInputStyle = css`
  font-size: 12px;
  padding: 0;
  &&::placeholder {
    color: #a9acc099;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
export const StyledInput = styled(Input)`
  ${sharedInputStyle}
`;
export const StyledNumberInput = styled(InputNumber)`
  ${sharedInputStyle}
  .ant-input-number-input {
    height: auto;
    padding: 0;
  }
`;
export const StyledPlaceholder = styled.span`
  color: #a9acc099;
  line-height: 22px;
`;
export const StyledText = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
  text-overflow: hidden;
  overflow: auto;
  font-size: 12px;
  a {
    text-decoration: underline;
  }
`;
