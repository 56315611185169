import { gql } from '@apollo/client';

export const QUERY_ROADMAP = gql`
  query QUERY_ROADMAP($studentId: String!) {
    roadmap(studentId: $studentId) {
      id
    }
  }
`;

export const QUERY_MISSION_STATUS_BY_CATEGORY = gql`
  query QUERY_MISSION_STATUS_BY_CATEGORY($studentId: String!, $category: String!) {
    loadMissionStatusDataByCategory(studentId: $studentId, category: $category) {
      complete
      planned
      inProgress
    }
  }
`;

export const QUERY_TASK_STATUS_BY_CATEGORY = gql`
  query QUERY_TASK_STATUS_BY_CATEGORY($studentId: String!, $category: String!) {
    loadTasksStatusDataByCategory(studentId: $studentId, category: $category) {
      complete
      planned
    }
  }
`;

export const QUERY_ACTIONS = gql`
  query listActionItems(
    $studentId: String!
    $groupBy: String
    $roadmapId: String
    $status: [String!]
    $members: [String!]
    $dueDateStart: String
    $dueDateEnd: String
    $description: String
  ) {
    listActionItems(
      studentId: $studentId
      roadmapId: $roadmapId
      groupBy: $groupBy
      status: $status
      members: $members
      dueDateStart: $dueDateStart
      dueDateEnd: $dueDateEnd
      description: $description
    ) {
      category
      actionItemCount
      actionItems {
        id
        description
        finishedAt
        creatorId
        createdAt
        dueDate
        startAt
        status
        type
        category
        commentCount
        mission {
          id
          title
          category
        }
      }
    }
  }
`;

export const QUERY_TEMPLATE_ROADMAP = gql`
  query TemplateRoadmap {
    templateRoadmap {
      id
    }
  }
`;
export const QUERY_STUDENT_TEAM_MEMBERS = gql`
  query getTeamMembersByStudent($userId: String!) {
    getTeamMembersByStudent(userId: $userId) {
      userId
      firstName
      lastName
      profileImageUrl
    }
  }
`;

export const QUERY_MISSION_LIST = gql`
  query listMissions(
    $studentId: String!
    $groupBy: String
    $roadmapId: String
    $status: [String!]
    $members: [String!]
    $dueDateStart: String
    $dueDateEnd: String
    $description: String
    $title: String
  ) {
    listMissions(
      studentId: $studentId
      roadmapId: $roadmapId
      groupBy: $groupBy
      status: $status
      members: $members
      dueDateStart: $dueDateStart
      dueDateEnd: $dueDateEnd
      description: $description
      title: $title
    ) {
      category
      missionCount
      missions {
        id
        title
        dueDate
        startAt
        description
        status
        actionItemCount
        memberCount
        members
        integrationSource
        category
        secondaryCategory
        subcategory
        attr
        actionItems {
          type
          id
          description
          dueDate
          status
          startAt
          category
          commentCount
          taskGroup
          attr
        }
      }
    }
  }
`;
