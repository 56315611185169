import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { FlexAlignCenter } from '../CommonStyle';

const CommonButtonStyle = css`
  ${FlexAlignCenter}
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  line-height: 1.2;
  padding: var(--padding-button);
  transition: box-shadow 0.2s ease-in-out 0s;
`;

const CommonActiveButtonStyle = css`
  border: var(--border-button);
  color: var(--color-indigo);
`;

export const StyledButtonOutlined = styled(Button)<{ width?: number; fontSize?: string }>`
  ${CommonButtonStyle}
  color: var(--color-indigo);
  font-size: ${(props) => props.fontSize || '16px'};
  border-radius: 32px;
  border: var(--border-button);
  width: ${(props) => (props?.width ? `${props.width}px` : 'max-content')};
  &:active {
    background: transparent;
    ${CommonActiveButtonStyle}
  }
  &:focus {
    background: transparent;
    ${CommonActiveButtonStyle}
  }
  &:hover {
    ${CommonActiveButtonStyle}
    background-color: var(--color-indigo);
    color: white;
    box-shadow: var(--shadow-button);
  }
  &.block-add {
    border: var(--border-nocolor) var(--color-dark-navy);
    background: var(--color-light-gray);
    color: var(--color-cool-grey);
  }
`;

export const StyledButtonNoBorder = styled(Button)<{ width?: number; fontSize?: string }>`
  ${CommonButtonStyle}
  color: var(--color-indigo);
  font-size: ${(props) => props?.fontSize || '16px'};
  border-color: transparent;
  border-radius: 32px;
  width: ${(props) => (props?.width ? `${props?.width}px` : 'max-content')};
  &:active {
    background: transparent;
    color: var(--color-indigo);
    border-color: transparent;
  }
  &:focus {
    background: transparent;
    color: var(--color-indigo);
    border-color: transparent;
  }
  &:hover {
    background: transparent;
    color: var(--color-indigo);
    border-color: transparent;
  }
  &.block-add {
    border-color: var(--color-pale-grey);
    background: var(--color-light-gray);
    color: var(--color-cool-grey);
  }
`;

export const StyledButtonFilled = styled(Button)<{ width?: number; fontSize?: string; borderRadius?: string }>`
  ${CommonButtonStyle}
  background: var(--color-indigo);
  color: white;
  font-size: ${(props) => props?.fontSize || '16px'};
  border: var(--border-nocolor) rgb(64, 68, 184);
  border-radius: ${(props) => props?.fontSize || '32px'};
  width: ${(props) => (props?.width ? `${props.width}px` : 'max-content')};
  margin-bottom: 6px;
  margin-right: 6px;
  &:active {
    background: transparent;
    ${CommonActiveButtonStyle}
  }
  &:focus {
    background: transparent;
    ${CommonActiveButtonStyle}
  }
  &:hover {
    background: transparent;
    ${CommonActiveButtonStyle}
    box-shadow: var(--shadow-button);
  }
`;

export const StyledButtonDisabled = styled(Button)<{ border?: string; width?: number; fontSize?: string }>`
  ${CommonButtonStyle}
  background: var(--color-light-gray);
  color: var(--color-cool-grey);
  cursor: not-allowed;
  font-size: ${(props) => props?.fontSize || '16px'};
  border: ${(props) => `${props.border || '1px'} solid var(--color-pale-grey)`};
  border-radius: 32px;
  width: ${(props) => (props?.width ? `${props.width}px` : 'max-content')};
  &:hover {
    border-color: var(--color-pale-grey);
    background: var(--color-light-gray);
    color: var(--color-cool-grey);
  }
  &:active {
    border-color: var(--color-pale-grey);
    background: var(--color-light-gray);
    color: var(--color-cool-grey);
  }
  &:focus {
    border-color: var(--color-pale-grey);
    background: var(--color-light-gray);
    color: var(--color-cool-grey);
  }
`;
