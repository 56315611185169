import { gql } from '@apollo/client';

export {
  ADD_OR_UPDATE_CREATIVE_PORTFOLIOS,
  ADD_OR_UPDATE_EXTRACURRICULAR,
  ADD_OR_UPDATE_HONOR,
  ADD_OR_UPDATE_INTERNSHIP_EXPERIENCE,
  ADD_OR_UPDATE_RESEARCH,
  ADD_OR_UPDATE_UNIVERSITY_PROGRAM,
  ADD_OTHER_SUBCATEGORY_MISSION,
  ADD_READING_TO_MISSION,
  ASSIGN_MEMBER,
  ASSIGN_MEMBERS,
  CHANGE_MISSION_BADGE,
  CHANGE_MISSION_DESCRIPTION,
  CHANGE_MISSION_DUE_DATE,
  CHANGE_MISSION_START_DATE,
  CHANGE_MISSION_STATUS,
  CHANGE_MISSION_TITLE,
  EDIT_MISSION,
  GET_MISSION_ACTIVITY_LOG,
  GET_MISSION_BY_ID,
  GET_MISSION_TITLE_BY_ID,
  // LOAD_MISSION,
  LOAD_OVERVIEW_PANEL,
  REMOVE_MISSION,
  REMOVE_MISSION_BY_ID,
  UNASSIGN_MEMBER,
  UPDATE_INTENDED_STATUS,
} from '@crimson-education/core-shared-graphql/mission';

export const LOAD_MISSION = gql`
  query loadMissions($userId: String!, $category: String!, $subcategory: String!) {
    loadMissions(userId: $userId, category: $category, subcategory: $subcategory) {
      id
      creatorId
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      commonApp
      actionItems {
        description
      }
      members
      integrationSource
      attr
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
      }
      program {
        id
        userId
        missionId
        applicationPeriodStart
        applicationPeriodEnd
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        credit
        type
        impactFactor
        institution
        resourceCategoryId
        resourceCategoryName
        resourceId
      }
      research {
        id
        missionId
        provider
        field
        subField
        advisorUserId
        advisorName
        affiliation
        publication
        publisherName
        date
        link
        question
        criProgram
        impactFactor
      }
      portfolios {
        id
        type
        advisorUserId
        advisorName
        provider
        preparationStartDate
        preparationEndDate
        preparationRequired
      }
      experience {
        id
        userId
        missionId
        industry
        organizationName
        programProvider
        position
        applicationDeadline
        impactFactor
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        subject {
          id
          curriculumId
          name
        }
      }
      extracurricular {
        id
        missionId
        userId
        subType
        activityType
        schoolYear
        organizationName
        position
        timeOfParticipation
        crimsonCapstone
        hoursPerWeek
        weeksPerYear
        description
        impactFactor
        programProvider
      }
      honor {
        id
        userId
        missionId
        category
        award
        awardReceived
        awardLevel
        schoolYear
        levelOfRecognition
        resourceId
        divisionId
      }
      reading {
        id
        userId
        missionId
        title
        author
        type
        description
        resourceId
        bookCover
      }
      otherSubcategory {
        id
        userId
        missionId
        pathfinderDescription
        pathfinderId
        points
      }
    }
  }
`;

export const SET_MISSION_RESOURCES = gql`
  mutation SetMissionResources($input: SetMissionResourcesInput!) {
    setMissionResources(input: $input) {
      id
    }
  }
`;

export const addOrUpdateUniversityProgramGql = gql`
  mutation addOrUpdateUniversityProgram($input: studentProgramInput!) {
    addOrUpdateUniversityProgram(input: $input) {
      id
      userId
      missionId
      targetGrade
      actualGrade
      maxGrade
      gradeFormat
      applicationPeriodStart
      applicationPeriodEnd
      credit
      type
      impactFactor
      institution
      resourceId
      resourceCategoryId
      resourceCategoryName
      applicationOutcome
      applicationStatus
      crimsonSupport
    }
  }
`;

export const loadMissionGql = gql`
  query loadMissions($userId: String!, $category: String!, $subcategory: String!) {
    loadMissions(userId: $userId, category: $category, subcategory: $subcategory) {
      creatorId
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      badge
      actionItems {
        description
      }
      members
      integrationSource
      attr
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
      }
      program {
        id
        userId
        missionId
        applicationPeriodStart
        applicationPeriodEnd
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        credit
        type
        impactFactor
        institution
        resourceCategoryId
        resourceCategoryName
        resourceId
        applicationStatus
        applicationOutcome
        crimsonSupport
      }
      research {
        id
        missionId
        provider
        field
        subField
        advisorUserId
        advisorName
        affiliation
        publication
        publisherName
        date
        link
        question
        criProgram
        impactFactor
      }
      portfolios {
        id
        type
        advisorUserId
        advisorName
        provider
        preparationStartDate
        preparationEndDate
        preparationRequired
      }
      experience {
        id
        userId
        missionId
        industry
        organizationName
        programProvider
        position
        applicationDeadline
        impactFactor
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        subject {
          id
          curriculumId
          name
        }
      }
      extracurricular {
        id
        missionId
        userId
        subType
        activityType
        schoolYear
        organizationName
        position
        timeOfParticipation
        crimsonCapstone
        hoursPerWeek
        weeksPerYear
        description
        impactFactor
        programProvider
      }
      honor {
        id
        userId
        missionId
        category
        award
        awardLevel
        schoolYear
        levelOfRecognition
        resourceId
        divisionId
      }
      reading {
        id
        userId
        missionId
        title
        author
        type
        description
        resourceId
        bookCover
      }
      otherSubcategory {
        id
        userId
        missionId
        pathfinderDescription
        pathfinderId
        points
      }
    }
  }
`;
export const UPDATE_MISSION_IS_COMMON_APP = gql`
  mutation changeMissionIsCommonApp($input: ChangeMissionIsCommonAppInput!) {
    changeMissionIsCommonApp(input: $input) {
      success
    }
  }
`;
