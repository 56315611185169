export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: string;
  JSON: any;
};

/** achieved inspiration ideas */
export type AchievedInspirationIdeas = {
  __typename?: 'AchievedInspirationIdeas';
  ideas?: Maybe<Array<Maybe<InspirationIdeas>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ActionItem = {
  __typename?: 'ActionItem';
  createdAt?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

/** A batch operation result */
export type BatchOperationResult = {
  __typename?: 'BatchOperationResult';
  /** success or not */
  success: Scalars['Boolean'];
};

export enum Categories {
  Academics = 'ACADEMICS',
  Activities = 'ACTIVITIES',
  Careers = 'CAREERS',
  CompetitionsHonors = 'COMPETITIONS_HONORS',
  PersonalDevelopment = 'PERSONAL_DEVELOPMENT',
  Test = 'TEST',
}

export enum ClassRankType {
  Rank1 = 'rank1',
  Rank2 = 'rank2',
  Rank3 = 'rank3',
  Rank4 = 'rank4',
  Rank5 = 'rank5',
  Rank6 = 'rank6',
  Rank7 = 'rank7',
  Rank8 = 'rank8',
  Rank9 = 'rank9',
  Rank10 = 'rank10',
  Rank11 = 'rank11',
  Top10for25 = 'top10for25',
  Top10for50 = 'top10for50',
  Top10for80 = 'top10for80',
  Top10for100 = 'top10for100',
}

export type Comment = {
  __typename?: 'Comment';
  actionItemId?: Maybe<Scalars['String']>;
  category?: Maybe<CommentCategory>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  internalNoteId?: Maybe<Scalars['String']>;
  relatedItemId?: Maybe<Scalars['String']>;
  relatedItemType?: Maybe<ItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum CommentCategory {
  Escalations = 'ESCALATIONS',
  Reflection = 'REFLECTION',
}

export type CommentInput = {
  actionItemId?: Maybe<Scalars['String']>;
  category?: Maybe<CommentCategory>;
  content?: Maybe<Scalars['String']>;
  internalNoteId?: Maybe<Scalars['String']>;
  relatedItemId?: Maybe<Scalars['String']>;
  relatedItemType?: Maybe<ItemType>;
};

export type CreateMissionsFromRoadmapBuilderResult = {
  __typename?: 'CreateMissionsFromRoadmapBuilderResult';
  eclActivity?: Maybe<Scalars['Int']>;
  experience?: Maybe<Scalars['Int']>;
  honor?: Maybe<Scalars['Int']>;
  reading?: Maybe<Scalars['Int']>;
  researchPublication?: Maybe<Scalars['Int']>;
  summerProgram?: Maybe<Scalars['Int']>;
  testing?: Maybe<Scalars['Int']>;
};

export type CreateRecommenderFeedbackInput = {
  category: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  missionId?: Maybe<Scalars['String']>;
  roadmapId: Scalars['String'];
  sentiment: RecommenderFeedbackSentiment;
  subcategory: Scalars['String'];
};

export enum CriProgramType {
  Explorer = 'EXPLORER',
  Innovator = 'INNOVATOR',
  InnovatorTypeA = 'INNOVATOR_TYPE_A',
  InnovatorTypeB = 'INNOVATOR_TYPE_B',
  Researcher = 'RESEARCHER',
  ResearcherTypeA = 'RESEARCHER_TYPE_A',
  ResearcherTypeB = 'RESEARCHER_TYPE_B',
  Test = 'TEST',
}

export type Curriculum = {
  __typename?: 'Curriculum';
  id: Scalars['Int'];
  levels?: Maybe<Scalars['JSON']>;
  maxGrade?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shortName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<SubjectCategory>>;
  type?: Maybe<Scalars['String']>;
};

export type CurriculumType = {
  __typename?: 'CurriculumType';
  levels?: Maybe<Scalars['JSON']>;
  maxGrade?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  /** The type of grading this curriculum uses. Example: `percentage` */
  type?: Maybe<Scalars['String']>;
};

export type EclActivityRecommendation = {
  __typename?: 'EclActivityRecommendation';
  activityType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  majors?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
};

export type EclCompetitionHonorConversionInput = {
  award?: Maybe<Scalars['String']>;
  awardLevel?: Maybe<Scalars['String']>;
  categories: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  divisionId?: Maybe<Scalars['String']>;
  levelOfRecognition?: Maybe<Scalars['String']>;
  programEndDate?: Maybe<Scalars['String']>;
  programStartDate?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  schoolYear: Array<Scalars['Int']>;
  title: Scalars['String'];
};

export enum EclProviderType {
  CrimsonCapstone = 'CRIMSON_CAPSTONE',
  CrimsonEcl = 'CRIMSON_ECL',
  HighSchool = 'HIGH_SCHOOL',
  OtherProvider = 'OTHER_PROVIDER',
  SelfSourced = 'SELF_SOURCED',
  University = 'UNIVERSITY',
}

export type EclResource = {
  __typename?: 'EclResource';
  activityType: Scalars['String'];
  ageGroupMax?: Maybe<Scalars['Int']>;
  ageGroupMin?: Maybe<Scalars['Int']>;
  ageGroupRemarks?: Maybe<Scalars['String']>;
  awards?: Maybe<Array<Maybe<EclResourceAward>>>;
  categories?: Maybe<Array<Scalars['String']>>;
  competitiveness: Scalars['Int'];
  costs?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  divisions?: Maybe<Array<Maybe<EclResourceDivision>>>;
  eligibility?: Maybe<Scalars['String']>;
  forCredit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  impactFactor?: Maybe<Scalars['Int']>;
  inWatchlist?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  learningFormat?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Scalars['String']>>;
  locationRemarks?: Maybe<Scalars['String']>;
  occurrences: Array<EclResourceOccurrence>;
  organizer?: Maybe<Scalars['String']>;
  prizes?: Maybe<Scalars['String']>;
  programEndDate?: Maybe<Scalars['String']>;
  programStartDate?: Maybe<Scalars['String']>;
  programTimeline?: Maybe<Scalars['String']>;
  registrationDeadline?: Maybe<Scalars['String']>;
  requirements?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type EclResourceAward = {
  __typename?: 'EclResourceAward';
  displayName: Scalars['String'];
  division?: Maybe<EclResourceDivision>;
  id?: Maybe<Scalars['ID']>;
  tier?: Maybe<Scalars['String']>;
};

export type EclResourceCategory = {
  __typename?: 'EclResourceCategory';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type EclResourceDivision = {
  __typename?: 'EclResourceDivision';
  id?: Maybe<Scalars['ID']>;
  levelOfRecognition?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type EclResourceOccurrence = {
  __typename?: 'EclResourceOccurrence';
  notes?: Maybe<Scalars['String']>;
  programEnd?: Maybe<Scalars['DateTime']>;
  programStart?: Maybe<Scalars['DateTime']>;
  registrationEnd?: Maybe<Scalars['DateTime']>;
  registrationStart?: Maybe<Scalars['DateTime']>;
  status: EclResourceOccurrenceStatusType;
  title: Scalars['String'];
};

export enum EclResourceOccurrenceStatusType {
  ProgramFinished = 'PROGRAM_FINISHED',
  ProgramInProgress = 'PROGRAM_IN_PROGRESS',
  RegistrationClosed = 'REGISTRATION_CLOSED',
  RegistrationOpen = 'REGISTRATION_OPEN',
  RegistrationUpcoming = 'REGISTRATION_UPCOMING',
  Unknown = 'UNKNOWN',
}

export enum EclResourceType {
  Competitions = 'Competitions',
  SummerPrograms = 'SummerPrograms',
}

export type EclSummerProgramActivityConversionInput = {
  actualGrade?: Maybe<Scalars['String']>;
  category: Categories;
  credit: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  levelOfRecognition?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Scalars['String']>>;
  organizer?: Maybe<Scalars['String']>;
  programEndDate?: Maybe<Scalars['String']>;
  programStartDate?: Maybe<Scalars['String']>;
  registrationDeadline?: Maybe<Scalars['String']>;
  resourceCategoryId?: Maybe<Scalars['String']>;
  resourceCategoryName?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  schoolYear: Array<Scalars['Int']>;
  subcategory: Subcategories;
  title: Scalars['String'];
};

export enum EducationBackground {
  MiddleHighSchool = 'MIDDLE_HIGH_SCHOOL',
  University = 'UNIVERSITY',
}

export type EscalationInput = {
  attr?: Maybe<Scalars['JSON']>;
  category?: Maybe<InternalNotesCategory>;
  id?: Maybe<Scalars['String']>;
  missionId?: Maybe<Scalars['String']>;
  status?: Maybe<InternalNotesStatus>;
  subCategory?: Maybe<Scalars['String']>;
};

export type Experience = {
  __typename?: 'Experience';
  applicationDeadline?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  impactFactor?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  missionId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  programProvider?: Maybe<ExperienceProviderType>;
  userId: Scalars['String'];
};

export enum ExperienceProviderType {
  CrimsonCareers = 'CRIMSON_CAREERS',
  FamilyFriendSourced = 'FAMILY_FRIEND_SOURCED',
  HighSchool = 'HIGH_SCHOOL',
  OtherProvider = 'OTHER_PROVIDER',
  SelfSourced = 'SELF_SOURCED',
}

export type ExperienceRecommendation = {
  __typename?: 'ExperienceRecommendation';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ExtracurricularActivity = {
  __typename?: 'ExtracurricularActivity';
  activityType?: Maybe<Scalars['String']>;
  crimsonCapstone?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  hoursPerWeek?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  impactFactor?: Maybe<Scalars['Int']>;
  missionId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  programProvider?: Maybe<EclProviderType>;
  schoolYear?: Maybe<Array<Maybe<Scalars['Int']>>>;
  subType?: Maybe<Scalars['String']>;
  timeOfParticipation?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  weeksPerYear?: Maybe<Scalars['Float']>;
};

export type GetRoadmapBuilderInput = {
  /** Influences generation of AI recommendations */
  hint?: Maybe<Scalars['String']>;
  updateKeys?: Maybe<Array<Scalars['String']>>;
};

export type GetRoadmapBuilderResp = {
  __typename?: 'GetRoadmapBuilderResp';
  data?: Maybe<RoadmapData>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum GradeLevelType {
  Grade = 'Grade',
  Year = 'Year',
}

/** InspirationIdeas grouped by category */
export type GroupedInspirationIdeas = {
  __typename?: 'GroupedInspirationIdeas';
  category?: Maybe<Scalars['String']>;
  ideas?: Maybe<Array<Maybe<InspirationIdeas>>>;
  subcategory?: Maybe<Scalars['String']>;
};

export type Honor = {
  __typename?: 'Honor';
  award?: Maybe<Scalars['String']>;
  awardLevel?: Maybe<Scalars['String']>;
  awardReceived?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  divisionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  levelOfRecognition?: Maybe<LevelOfRecognitionType>;
  missionId: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  schoolYear?: Maybe<Array<Maybe<Scalars['Int']>>>;
  userId: Scalars['String'];
};

export type HookStatementReview = {
  __typename?: 'HookStatementReview';
  feedback?: Maybe<Scalars['String']>;
  hookStatement: Scalars['String'];
  id: Scalars['ID'];
  scores?: Maybe<Array<HookStatementReviewScore>>;
};

export type HookStatementReviewRubric = {
  __typename?: 'HookStatementReviewRubric';
  description: Scalars['String'];
  id: Scalars['ID'];
  tag: Scalars['String'];
  title: Scalars['String'];
};

export type HookStatementReviewScore = {
  __typename?: 'HookStatementReviewScore';
  rubric: HookStatementReviewRubric;
  value: Scalars['Int'];
};

export type InitStudentAcademicInput = {
  academicYearEnd: MonthType;
  academicYearStart: MonthType;
  educationBackground: EducationBackground;
  finalGradeLevel: Scalars['Int'];
  gradeLevelType: GradeLevelType;
  semesterBreak?: Maybe<Array<Maybe<SemesterBreakInput>>>;
  semesters?: Maybe<Scalars['Int']>;
  startGradeLevel: Scalars['Int'];
  /** userId */
  userId: Scalars['String'];
};

/** inspiration ideas detail */
export type InspirationIdeas = {
  __typename?: 'InspirationIdeas';
  achieved?: Maybe<Scalars['Int']>;
  achievedAt?: Maybe<Scalars['Date']>;
  achievedDescription?: Maybe<Scalars['String']>;
  achievedLevel?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  hidePoints?: Maybe<Scalars['Boolean']>;
  howTo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  levels?: Maybe<Array<Maybe<LevelInfo>>>;
  subcategory?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type InternalNoteType = {
  __typename?: 'InternalNoteType';
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  creatorUid?: Maybe<Scalars['String']>;
  creatorUser?: Maybe<UserType>;
  id?: Maybe<Scalars['ID']>;
  missionId?: Maybe<Scalars['String']>;
  studentUid?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
};

export type InternalNotes = {
  __typename?: 'InternalNotes';
  category?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserType>;
  creatorUid: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  missionId?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  solvedBy?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  studentUid: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  tasksCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum InternalNotesCategory {
  Counselingfollowup = 'COUNSELINGFOLLOWUP',
  Customerservice = 'CUSTOMERSERVICE',
  Escalations = 'ESCALATIONS',
  Essays = 'ESSAYS',
  General = 'GENERAL',
  Handover = 'HANDOVER',
  Payment = 'PAYMENT',
  Reminder = 'REMINDER',
  Sales = 'SALES',
  Studentcheck = 'STUDENTCHECK',
  Tutoringfollowup = 'TUTORINGFOLLOWUP',
}

export type InternalNotesInput = {
  attr?: Maybe<Scalars['JSON']>;
  category?: Maybe<InternalNotesCategory>;
  content: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  missionId?: Maybe<Scalars['String']>;
  status?: Maybe<InternalNotesStatus>;
  studentUid: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
};

export enum InternalNotesStatus {
  Monitoring = 'MONITORING',
  Open = 'OPEN',
  Refund = 'REFUND',
  Solved = 'SOLVED',
  WriteOff = 'WRITE_OFF',
}

export type InternalTaskInput = {
  assignee?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<TaskResourceType>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<TaskStatus>;
  subType?: Maybe<Scalars['String']>;
  targetUid?: Maybe<Array<Scalars['String']>>;
  targetUsers?: Maybe<Array<TargetUsersType>>;
  tenant?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type InternalTasks = {
  __typename?: 'InternalTasks';
  assignee?: Maybe<UserType>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<Scalars['String']>;
  completedUser?: Maybe<UserType>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorUid: Scalars['String'];
  creatorUser?: Maybe<UserType>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  internalNote?: Maybe<InternalNoteType>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  targetUid?: Maybe<Scalars['String']>;
  targetUser?: Maybe<UserType>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTasksData = {
  __typename?: 'InternalTasksData';
  pagination?: Maybe<PageInfo>;
  results?: Maybe<Array<Maybe<InternalTasks>>>;
};

export type InvalidateRoadmapBuilderResult = {
  __typename?: 'InvalidateRoadmapBuilderResult';
  success?: Maybe<Scalars['Boolean']>;
};

export enum ItemType {
  Escalations = 'ESCALATIONS',
  InternalTasks = 'INTERNAL_TASKS',
  Reflection = 'REFLECTION',
  SopCheckpoint = 'SOP_CHECKPOINT',
}

export type LevelInfo = {
  __typename?: 'LevelInfo';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export enum LevelOfRecognitionType {
  International = 'International',
  National = 'National',
  School = 'School',
  StateRegional = 'State_Regional',
}

export type Major = {
  __typename?: 'Major';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MissionAutocompleteCompletion = {
  __typename?: 'MissionAutocompleteCompletion';
  field: MissionAutocompleteFieldType;
  options?: Maybe<Array<Scalars['String']>>;
  type: MissionAutocompleteValueTypeType;
  value: Scalars['String'];
};

export enum MissionAutocompleteFieldType {
  ActivityType = 'ACTIVITY_TYPE',
  Description = 'DESCRIPTION',
  EclImpactFactor = 'ECL_IMPACT_FACTOR',
  EclOrganization = 'ECL_ORGANIZATION',
  EclProvider = 'ECL_PROVIDER',
  ExperienceImpactFactor = 'EXPERIENCE_IMPACT_FACTOR',
  ExperienceOrganization = 'EXPERIENCE_ORGANIZATION',
  HoursPerWeek = 'HOURS_PER_WEEK',
  Position = 'POSITION',
  ResearchImpactFactor = 'RESEARCH_IMPACT_FACTOR',
  ResearchQuestion = 'RESEARCH_QUESTION',
  SchoolYear = 'SCHOOL_YEAR',
  TimeOfParticipation = 'TIME_OF_PARTICIPATION',
  WeeksPerYear = 'WEEKS_PER_YEAR',
}

export type MissionAutocompleteInput = {
  fields: Array<MissionAutocompleteFieldType>;
  missionId: Scalars['ID'];
};

export type MissionAutocompleteResult = {
  __typename?: 'MissionAutocompleteResult';
  completions: Array<MissionAutocompleteCompletion>;
  mission: MissionObjectType;
};

export enum MissionAutocompleteValueTypeType {
  DateTime = 'DATE_TIME',
  Number = 'NUMBER',
  String = 'STRING',
}

/** Snapshot of a recommended mission to be created. */
export type MissionUpdateRecommendedMission = {
  __typename?: 'MissionUpdateRecommendedMission';
  data: MissionUpdateRecommendedMissionData;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startAt?: Maybe<Scalars['DateTime']>;
};

export type MissionUpdateRecommendedMissionData = {
  __typename?: 'MissionUpdateRecommendedMissionData';
  activityType?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  field?: Maybe<Array<Scalars['String']>>;
  industry?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type MissionUpdateRecord = {
  __typename?: 'MissionUpdateRecord';
  id: Scalars['ID'];
  mission?: Maybe<MissionObjectType>;
  /** For a `MissionUpdateRecord` of type `CREATE`, this represents the mission that is being recommended to create. */
  recommendation?: Maybe<MissionUpdateRecommendedMission>;
  subcategory: Scalars['String'];
  /** For a `MissionUpdateRecord` of type `UPDATE`, this is a list of suggested modifications to make to a particular mission. */
  suggestions: Array<Scalars['JSON']>;
  title: Scalars['String'];
  type: MissionUpdateRecordTypeType;
};

export enum MissionUpdateRecordTypeType {
  Create = 'CREATE',
  Update = 'UPDATE',
}

export type MissionUpdateSession = {
  __typename?: 'MissionUpdateSession';
  end: Scalars['DateTime'];
  /** Event ID */
  id: Scalars['ID'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
  student: MissionUpdateSessionUser;
};

export type MissionUpdateSessionUser = {
  __typename?: 'MissionUpdateSessionUser';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type MissionUpdatesAcceptInput = {
  recommendations?: Maybe<Array<MissionUpdatesAcceptInputRecommendation>>;
  sessionId: Scalars['ID'];
  suggestionIds: Array<Scalars['ID']>;
  suggestions?: Maybe<Array<MissionUpdatesAcceptInputSuggestions>>;
};

export type MissionUpdatesAcceptInputRecommendation = {
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  startAt?: Maybe<Scalars['String']>;
};

export type MissionUpdatesAcceptInputSuggestions = {
  action: Scalars['String'];
  id: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type MissionUpdatesAcceptResult = {
  __typename?: 'MissionUpdatesAcceptResult';
  /** Total number of suggestions which were applied */
  count: Scalars['Int'];
};

export type MissionUpdatesSkipInput = {
  sessionId: Scalars['ID'];
};

export type MissionUpdatesSkipResult = {
  __typename?: 'MissionUpdatesSkipResult';
  success: Scalars['Boolean'];
};

export enum MonthType {
  Apr = 'Apr',
  Aug = 'Aug',
  Dec = 'Dec',
  Feb = 'Feb',
  Jan = 'Jan',
  Jul = 'Jul',
  Jun = 'Jun',
  Mar = 'Mar',
  May = 'May',
  Nov = 'Nov',
  Oct = 'Oct',
  Sep = 'Sep',
}

export type Mutation = {
  __typename?: 'Mutation';
  addComment?: Maybe<Comment>;
  addOrUpdateExperience?: Maybe<Experience>;
  addOrUpdateExtracurricular?: Maybe<ExtracurricularActivity>;
  addOrUpdateHonors?: Maybe<Honor>;
  addOrUpdatePortfolios?: Maybe<StudentPortfolios>;
  addOrUpdateResearch?: Maybe<StudentResearch>;
  addOrUpdateSchool?: Maybe<StudentSchool>;
  addOrUpdateStudentAcademic?: Maybe<ResultType>;
  addOrUpdateTestData?: Maybe<MissionIdResult>;
  addOrUpdateUniversityProgram?: Maybe<StudentPrograms>;
  addOtherSubcategories?: Maybe<OtherSubcategories>;
  addReading?: Maybe<Reading>;
  addStudentSubjects?: Maybe<Array<Maybe<StudentSubject>>>;
  batchDeleteCommentsByActionItem?: Maybe<BatchOperationResult>;
  batchUpdateInternalTask?: Maybe<Array<Maybe<InternalTasks>>>;
  completeSopItem?: Maybe<Scalars['Boolean']>;
  convertStudentSubjects?: Maybe<Scalars['Boolean']>;
  copyStudentSubjects?: Maybe<ResultType>;
  createInternalNote?: Maybe<InternalNotes>;
  createInternalTask?: Maybe<Array<Maybe<InternalTasks>>>;
  createMissionsFromRoadmapBuilder?: Maybe<CreateMissionsFromRoadmapBuilderResult>;
  createOrUpdateBasicInfo?: Maybe<StudentBasicInfo>;
  createRecommenderFeedback?: Maybe<RecommenderFeedback>;
  createStudentActivityByEclSummerProgram?: Maybe<StudentPrograms>;
  createStudentHonorByEclCompetition?: Maybe<Honor>;
  createStudentSubject?: Maybe<ResultType>;
  deleteComment?: Maybe<Comment>;
  deleteInternalNote?: Maybe<InternalNotes>;
  deleteInternalTask?: Maybe<InternalTasks>;
  deleteProgram?: Maybe<ResultType>;
  deleteStudentSchool?: Maybe<ResultType>;
  deleteStudentSubject?: Maybe<ResultType>;
  duplicateInternalTask?: Maybe<Array<Maybe<Scalars['String']>>>;
  editSopItem?: Maybe<Scalars['Boolean']>;
  editSopStartDay?: Maybe<Scalars['Boolean']>;
  holdSopService?: Maybe<Scalars['Boolean']>;
  initRoadmapBuilder?: Maybe<InitRoadmapBuilderRes>;
  initSopInstance?: Maybe<Scalars['Boolean']>;
  initStudentInfo?: Maybe<InitStudentInfoResponseType>;
  invalidateRoadmapBuilder?: Maybe<InvalidateRoadmapBuilderResult>;
  migratePathfinderCards?: Maybe<Scalars['Boolean']>;
  migrateTesting?: Maybe<Scalars['Boolean']>;
  missionUpdatesAccept: MissionUpdatesAcceptResult;
  missionUpdatesSkip: MissionUpdatesSkipResult;
  pauseSopService?: Maybe<Scalars['Boolean']>;
  refreshSopGroups?: Maybe<Array<Maybe<SopCheckGroup>>>;
  refreshSopProgress?: Maybe<Scalars['Boolean']>;
  removeMissionById?: Maybe<ResultType>;
  removeUserByInternalTask?: Maybe<InternalTasks>;
  resumeHoldSopService?: Maybe<Scalars['Boolean']>;
  resumeSopService?: Maybe<Scalars['Boolean']>;
  setTestOptOut?: Maybe<TestOptOutResult>;
  /** Skip roadmap builder, requester must be creator */
  skipRoadmapBuilder?: Maybe<ResultType>;
  studentCheckResolve: StudentCheckResolveResult;
  syncSalesforceAwardData?: Maybe<Scalars['String']>;
  syncSalesforceEclData?: Maybe<Scalars['String']>;
  syncSalesforceICFData?: Maybe<Scalars['String']>;
  syncSalesforceTestData?: Maybe<Scalars['String']>;
  triggerEvent?: Maybe<TriggerEventResult>;
  unblockSopItem?: Maybe<Scalars['Boolean']>;
  updateComment?: Maybe<Comment>;
  updateEscalation?: Maybe<InternalNotes>;
  updateIntendedStatus?: Maybe<ResultType>;
  updateInternalNote?: Maybe<InternalNotes>;
  updateInternalTask?: Maybe<InternalTasks>;
  updateRecommenderFeedback?: Maybe<RecommenderFeedback>;
  updateRoadmapBuilderRecommendationSelection?: Maybe<RoadmapData>;
  updateSopItemStatus?: Maybe<Scalars['Boolean']>;
  updateStudentSubject?: Maybe<ResultType>;
  updateTargetStatus?: Maybe<ResultType>;
  upsertOnboardingFieldCheckerSkip?: Maybe<OnboardingFieldCheckerSkip>;
  upsertSsaTrackingSkip?: Maybe<SsaTrackingSkip>;
};

export type MutationAddCommentArgs = {
  input: CommentInput;
};

export type MutationAddOrUpdateExperienceArgs = {
  input: ExperienceInput;
};

export type MutationAddOrUpdateExtracurricularArgs = {
  input: ExtracurricularInput;
};

export type MutationAddOrUpdateHonorsArgs = {
  input: HonorInput;
};

export type MutationAddOrUpdatePortfoliosArgs = {
  input: StudentPortfoliosInput;
};

export type MutationAddOrUpdateResearchArgs = {
  input: StudentResearchInput;
};

export type MutationAddOrUpdateSchoolArgs = {
  input: SchoolInput;
};

export type MutationAddOrUpdateStudentAcademicArgs = {
  input: StudentAcademicInput;
};

export type MutationAddOrUpdateTestDataArgs = {
  input: TestInput;
};

export type MutationAddOrUpdateUniversityProgramArgs = {
  input: StudentProgramInput;
};

export type MutationAddOtherSubcategoriesArgs = {
  input: OtherInput;
};

export type MutationAddReadingArgs = {
  input: ReadingInput;
};

export type MutationAddStudentSubjectsArgs = {
  input: SubjectsInput;
};

export type MutationBatchDeleteCommentsByActionItemArgs = {
  actionItemId: Scalars['String'];
};

export type MutationBatchUpdateInternalTaskArgs = {
  assignee?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  status?: Maybe<TaskStatus>;
  targetUid?: Maybe<Scalars['String']>;
  targetUsers?: Maybe<TargetUsersType>;
  taskIds: Array<Scalars['String']>;
};

export type MutationCompleteSopItemArgs = {
  groupId: Scalars['String'];
  itemId: Scalars['String'];
  scope: Scalars['String'];
  sopInstanceId: Scalars['String'];
};

export type MutationConvertStudentSubjectsArgs = {
  missionId: Scalars['String'];
  studentId: Scalars['String'];
};

export type MutationCopyStudentSubjectsArgs = {
  input: CopySubjectsInput;
};

export type MutationCreateInternalNoteArgs = {
  input: InternalNotesInput;
};

export type MutationCreateInternalTaskArgs = {
  input: InternalTaskInput;
};

export type MutationCreateMissionsFromRoadmapBuilderArgs = {
  force?: Maybe<Scalars['Boolean']>;
  roadmapBuilderId: Scalars['String'];
};

export type MutationCreateOrUpdateBasicInfoArgs = {
  input: StudentBasicInfoInput;
};

export type MutationCreateRecommenderFeedbackArgs = {
  input: CreateRecommenderFeedbackInput;
};

export type MutationCreateStudentActivityByEclSummerProgramArgs = {
  input: EclSummerProgramActivityConversionInput;
  status: Scalars['String'];
  studentId: Scalars['String'];
};

export type MutationCreateStudentHonorByEclCompetitionArgs = {
  input: EclCompetitionHonorConversionInput;
  status: Scalars['String'];
  studentId: Scalars['String'];
};

export type MutationCreateStudentSubjectArgs = {
  input: CreateStudentSubjectInput;
};

export type MutationDeleteCommentArgs = {
  id: Scalars['String'];
};

export type MutationDeleteInternalNoteArgs = {
  noteId: Scalars['String'];
};

export type MutationDeleteInternalTaskArgs = {
  taskIds: Array<Scalars['String']>;
};

export type MutationDeleteProgramArgs = {
  missionId: Scalars['String'];
};

export type MutationDeleteStudentSchoolArgs = {
  studentSchoolId: Scalars['String'];
};

export type MutationDeleteStudentSubjectArgs = {
  studentSubjectId: Scalars['String'];
};

export type MutationDuplicateInternalTaskArgs = {
  taskIds: Array<Scalars['String']>;
};

export type MutationEditSopItemArgs = {
  changeType: Scalars['String'];
  context: Scalars['String'];
  itemId: Scalars['String'];
  newDate?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};

export type MutationEditSopStartDayArgs = {
  context: Scalars['String'];
  newStartDay: Scalars['String'];
  studentId: Scalars['String'];
};

export type MutationHoldSopServiceArgs = {
  endDate?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  sopInstanceId: Scalars['String'];
  startDate: Scalars['String'];
  targets: Array<Scalars['String']>;
};

export type MutationInitRoadmapBuilderArgs = {
  userId: Scalars['String'];
};

export type MutationInitSopInstanceArgs = {
  studentId: Scalars['String'];
};

export type MutationInitStudentInfoArgs = {
  input: InitStudentAcademicInput;
};

export type MutationInvalidateRoadmapBuilderArgs = {
  roadmapBuilderId: Scalars['String'];
};

export type MutationMigratePathfinderCardsArgs = {
  studentIds: Array<Scalars['String']>;
  synchronous?: Maybe<Scalars['Boolean']>;
};

export type MutationMigrateTestingArgs = {
  studentIds: Array<Scalars['String']>;
  synchronous?: Maybe<Scalars['Boolean']>;
};

export type MutationMissionUpdatesAcceptArgs = {
  input: MissionUpdatesAcceptInput;
};

export type MutationMissionUpdatesSkipArgs = {
  input: MissionUpdatesSkipInput;
};

export type MutationPauseSopServiceArgs = {
  endDate?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  studentId: Scalars['String'];
};

export type MutationRefreshSopGroupsArgs = {
  groupIds: Array<Scalars['String']>;
  studentId: Scalars['String'];
};

export type MutationRefreshSopProgressArgs = {
  days?: Maybe<Scalars['Int']>;
  studentId: Scalars['String'];
};

export type MutationRemoveMissionByIdArgs = {
  category: Scalars['String'];
  missionId: Scalars['String'];
  roadmapId: Scalars['String'];
  subcategory: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationRemoveUserByInternalTaskArgs = {
  taskId: Scalars['String'];
  type: Scalars['String'];
};

export type MutationResumeHoldSopServiceArgs = {
  endDate: Scalars['String'];
  itemId: Scalars['String'];
};

export type MutationResumeSopServiceArgs = {
  endDate?: Maybe<Scalars['String']>;
  studentId: Scalars['String'];
};

export type MutationSetTestOptOutArgs = {
  input: TestOptOutInput;
};

export type MutationSkipRoadmapBuilderArgs = {
  roadmapBuilderId: Scalars['String'];
};

export type MutationStudentCheckResolveArgs = {
  input: StudentCheckResolveInput;
};

export type MutationSyncSalesforceAwardDataArgs = {
  data: Array<SalesforceAwardDataInput>;
  userId: Scalars['String'];
};

export type MutationSyncSalesforceEclDataArgs = {
  data: Array<SalesforceEclDataInput>;
  userId: Scalars['String'];
};

export type MutationSyncSalesforceIcfDataArgs = {
  data?: Maybe<SalesforceIcfDataInput>;
  userId: Scalars['String'];
};

export type MutationSyncSalesforceTestDataArgs = {
  data: Array<SalesforceTestDataInput>;
  userId: Scalars['String'];
};

export type MutationTriggerEventArgs = {
  attr?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  missionId?: Maybe<Scalars['String']>;
  studentId: Scalars['String'];
};

export type MutationUnblockSopItemArgs = {
  blockId: Scalars['String'];
  sopInstanceId: Scalars['String'];
};

export type MutationUpdateCommentArgs = {
  id: Scalars['String'];
  input: CommentInput;
};

export type MutationUpdateEscalationArgs = {
  input: EscalationInput;
};

export type MutationUpdateIntendedStatusArgs = {
  input: IntendedInput;
  userId: Scalars['String'];
};

export type MutationUpdateInternalNoteArgs = {
  input: InternalNotesInput;
};

export type MutationUpdateInternalTaskArgs = {
  input: InternalTaskInput;
};

export type MutationUpdateRecommenderFeedbackArgs = {
  input: UpdateRecommenderFeedbackInput;
};

export type MutationUpdateRoadmapBuilderRecommendationSelectionArgs = {
  force?: Maybe<Scalars['Boolean']>;
  roadmapBuilderId: Scalars['String'];
  selection: RoadmapBuilderSelectionInput;
};

export type MutationUpdateSopItemStatusArgs = {
  id: Scalars['String'];
  status: Scalars['String'];
};

export type MutationUpdateStudentSubjectArgs = {
  input: StudentSubjectInput;
};

export type MutationUpdateTargetStatusArgs = {
  id: Scalars['String'];
  targetReached: Scalars['Boolean'];
};

export type MutationUpsertOnboardingFieldCheckerSkipArgs = {
  key: Scalars['String'];
  skip: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type MutationUpsertSsaTrackingSkipArgs = {
  key: Scalars['String'];
  skip: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type OrderBy = {
  key: Scalars['String'];
  order?: Maybe<SortOrder>;
};

export type OtherSubcategories = {
  __typename?: 'OtherSubcategories';
  id?: Maybe<Scalars['ID']>;
  missionId: Scalars['String'];
  pathfinderDescription?: Maybe<Scalars['String']>;
  pathfinderId?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type OverviewPanelType = {
  __typename?: 'OverviewPanelType';
  current?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<PanelDetailType>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  offset?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PageInfoInput = {
  offset?: Maybe<Scalars['Int']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount?: Maybe<Scalars['Int']>;
};

/** Reminder of points or badges gained */
export type PointsOrBadgesGainedReminder = {
  __typename?: 'PointsOrBadgesGainedReminder';
  remind?: Maybe<Scalars['Boolean']>;
};

export enum PortfoliosEnumType {
  Architecture = 'Architecture',
  Dance = 'Dance',
  Film = 'Film',
  Music = 'Music',
  Theatre = 'Theatre',
  VisualArts = 'Visual_Arts',
}

export enum PortfoliosProgramProviderType {
  Crimson = 'CRIMSON',
  HighSchool = 'HIGH_SCHOOL',
  OtherProvider = 'OTHER_PROVIDER',
  SelfSourced = 'SELF_SOURCED',
  UniversityPrograms = 'UNIVERSITY_PROGRAMS',
}

export enum ProgramProviderType {
  Cri = 'CRI',
  HighSchool = 'HIGH_SCHOOL',
  OtherProvider = 'OTHER_PROVIDER',
  SelfSourced = 'SELF_SOURCED',
  UniversityPrograms = 'UNIVERSITY_PROGRAMS',
}

export type Query = {
  __typename?: 'Query';
  /** get achieved ideas */
  getAchievedIdeas?: Maybe<AchievedInspirationIdeas>;
  getAutomatedPoints?: Maybe<Array<Maybe<CategoryPoints>>>;
  /** get reminder of badges gained */
  getBadgesGainedReminder?: Maybe<PointsOrBadgesGainedReminder>;
  getCommentsByActionItem?: Maybe<Array<Maybe<Comment>>>;
  getCommentsByResource?: Maybe<Array<Maybe<Comment>>>;
  getCurrentAchievements?: Maybe<UserAchievements>;
  getCurriculums?: Maybe<Array<Maybe<Curriculum>>>;
  getEclResourceCategories?: Maybe<Array<Maybe<EclResourceCategory>>>;
  getEclResourcesByIdsAndType: Array<EclResource>;
  /** get inspiration ideas */
  getInspirationIdeas?: Maybe<Array<Maybe<GroupedInspirationIdeas>>>;
  getMySopStudents?: Maybe<SopMyStudentsList>;
  /** get reminder of points gained */
  getPointsGainedReminder?: Maybe<PointsOrBadgesGainedReminder>;
  getRoadmapBuilder?: Maybe<GetRoadmapBuilderResp>;
  /** Load school lists */
  getSchoolLists?: Maybe<Array<Maybe<SchoolType>>>;
  getSopAllServiceEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSopGlobalResumeDate?: Maybe<SopResumeDate>;
  getSopHoldTargets?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSopInstance?: Maybe<SopInstance>;
  getSopRecentGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSopUpdateStartdayLog?: Maybe<Array<Maybe<SopCheckItemChangeLog>>>;
  /** Student Test Opt Out data */
  getTestOptOut?: Maybe<TestOptOutResult>;
  hasInitSop?: Maybe<Scalars['Boolean']>;
  hookStatementReview: HookStatementReview;
  hookStatementReviewRubrics: Array<Maybe<HookStatementReviewRubric>>;
  inferRegularMeetingCycle?: Maybe<Scalars['String']>;
  /** Load iinitialization information for current grade modal */
  initializationInfo?: Maybe<InitializationInfo>;
  /** Load current grade level */
  loadCurrentGradeLevel?: Maybe<Scalars['String']>;
  /** Load student education information */
  loadEducationInfo?: Maybe<Array<Maybe<StudentSchool>>>;
  loadMissions?: Maybe<Array<Maybe<MissionObjectType>>>;
  loadOverviewPanel?: Maybe<OverviewPanelType>;
  /** Load school lists */
  loadStudentCurrentClassRank?: Maybe<Scalars['String']>;
  loadSubjectsByCurriculumAndName?: Maybe<Array<Maybe<SubjectCategory>>>;
  /** Load test item data */
  loadTestMissionByMissionId?: Maybe<StudentTest>;
  /** Load test overview list data */
  loadTestMissionsList?: Maybe<Array<Maybe<TestListObj>>>;
  /** Load test overview list data */
  loadTestOverview?: Maybe<Array<Maybe<TestOverview>>>;
  majors: Array<Major>;
  mission?: Maybe<MissionObjectType>;
  missionAutocomplete: MissionAutocompleteResult;
  missionUpdatesBySession: Array<MissionUpdateRecord>;
  missionUpdatesSessionList: Array<Maybe<MissionUpdateSession>>;
  missions: Array<MissionObjectType>;
  /** Load activity total hours by year */
  queryActivityHoursByYear?: Maybe<Array<Maybe<YearStateType>>>;
  queryAssignedTasksByAssignee?: Maybe<TasksObjectForAssigneeType>;
  queryEscalationStudentsByStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  queryEssayInternalNotes?: Maybe<Array<Maybe<InternalNotes>>>;
  queryInternalNotes?: Maybe<Array<Maybe<InternalNotes>>>;
  queryInternalTasks?: Maybe<InternalTasksData>;
  queryInternalTasksCountByUser?: Maybe<TaskPanelType>;
  queryOnboardingFieldCheckerResult?: Maybe<OnboardingFieldCheckerResult>;
  queryServiceUpdates?: Maybe<Array<Maybe<InternalNotes>>>;
  querySsaTrackingResult?: Maybe<SsaTrackingResult>;
  queryStudentSubjects?: Maybe<Array<Maybe<Subjects>>>;
  recommendActivityCategories?: Maybe<Array<RecommenderActivityCategory>>;
  recommendActivityIdeas?: Maybe<Array<RecommenderActivityIdea>>;
  recommendActivityPoll?: Maybe<RecommenderActivity>;
  recommendActivityStart?: Maybe<RecommenderActivityJob>;
  recommendMissions?: Maybe<Array<RecommenderMission>>;
  recommendResearch: RecommenderMission;
  recommendResearchIdeas: Array<RecommenderResearchIdea>;
  roadmapBuilderVisible?: Maybe<RoadmapBuilderVisibleRes>;
  shouldShowEducationPopup?: Maybe<Scalars['Boolean']>;
  studentChecks: StudentCheck;
  studentProfileRating?: Maybe<StudentProfileRating>;
  subjects: Array<SubjectCategory>;
  syncEclData?: Maybe<Scalars['String']>;
  syncEclDesc?: Maybe<Scalars['String']>;
  syncHonorData?: Maybe<Scalars['String']>;
  syncTestData?: Maybe<Scalars['String']>;
  whyThisUniversity: WhyThisUniversityResult;
};

export type QueryGetAchievedIdeasArgs = {
  category?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  pathfinderType?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  studentId: Scalars['String'];
};

export type QueryGetAutomatedPointsArgs = {
  studentId: Scalars['String'];
  target?: Maybe<Scalars['String']>;
};

export type QueryGetBadgesGainedReminderArgs = {
  before: Scalars['String'];
  pathfinderType: Scalars['String'];
  studentId: Scalars['String'];
};

export type QueryGetCommentsByActionItemArgs = {
  actionItemId: Scalars['String'];
};

export type QueryGetCommentsByResourceArgs = {
  relatedItemId: Scalars['String'];
  relatedItemType: ItemType;
};

export type QueryGetCurrentAchievementsArgs = {
  userId: Scalars['String'];
};

export type QueryGetCurriculumsArgs = {
  name?: Maybe<Scalars['String']>;
  withInactive?: Maybe<Scalars['Boolean']>;
};

export type QueryGetEclResourcesByIdsAndTypeArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<EclResourceType>;
};

export type QueryGetInspirationIdeasArgs = {
  category?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  pathfinderType?: Maybe<Scalars['String']>;
  studentId: Scalars['String'];
  subcategory?: Maybe<Scalars['String']>;
};

export type QueryGetMySopStudentsArgs = {
  billingCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
};

export type QueryGetPointsGainedReminderArgs = {
  before: Scalars['String'];
  pathfinderType: Scalars['String'];
  studentId: Scalars['String'];
};

export type QueryGetRoadmapBuilderArgs = {
  force?: Maybe<Scalars['Boolean']>;
  input?: Maybe<GetRoadmapBuilderInput>;
  roadmapBuilderId: Scalars['String'];
};

export type QueryGetSchoolListsArgs = {
  name?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type QueryGetSopAllServiceEventsArgs = {
  days?: Maybe<Scalars['Int']>;
  studentId: Scalars['String'];
};

export type QueryGetSopGlobalResumeDateArgs = {
  studentId: Scalars['String'];
};

export type QueryGetSopHoldTargetsArgs = {
  sopInstanceId: Scalars['String'];
};

export type QueryGetSopInstanceArgs = {
  duedate?: Maybe<Scalars['String']>;
  staffs?: Maybe<Array<Scalars['String']>>;
  startDay?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Scalars['String']>>;
  studentId: Scalars['String'];
  targets?: Maybe<Array<Scalars['String']>>;
};

export type QueryGetSopRecentGroupsArgs = {
  days?: Maybe<Scalars['Int']>;
  studentId: Scalars['String'];
};

export type QueryGetSopUpdateStartdayLogArgs = {
  studentId: Scalars['String'];
};

export type QueryGetTestOptOutArgs = {
  userId: Scalars['String'];
};

export type QueryHasInitSopArgs = {
  studentId: Scalars['String'];
};

export type QueryHookStatementReviewArgs = {
  hook: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type QueryInferRegularMeetingCycleArgs = {
  meetingType: Scalars['String'];
  studentUid: Scalars['String'];
  year: Scalars['Int'];
};

export type QueryInitializationInfoArgs = {
  userId: Scalars['String'];
};

export type QueryLoadCurrentGradeLevelArgs = {
  userId: Scalars['String'];
};

export type QueryLoadEducationInfoArgs = {
  userId: Scalars['String'];
};

export type QueryLoadMissionsArgs = {
  category: Scalars['String'];
  subcategory: Scalars['String'];
  userId: Scalars['String'];
};

export type QueryLoadOverviewPanelArgs = {
  category: Scalars['String'];
  userId: Scalars['String'];
};

export type QueryLoadStudentCurrentClassRankArgs = {
  userId: Scalars['String'];
};

export type QueryLoadSubjectsByCurriculumAndNameArgs = {
  curriculumId: Scalars['Int'];
  name: Scalars['String'];
};

export type QueryLoadTestMissionByMissionIdArgs = {
  missionId: Scalars['String'];
};

export type QueryLoadTestMissionsListArgs = {
  userId: Scalars['String'];
};

export type QueryLoadTestOverviewArgs = {
  userId: Scalars['String'];
};

export type QueryMissionArgs = {
  id: Scalars['ID'];
};

export type QueryMissionAutocompleteArgs = {
  input: MissionAutocompleteInput;
};

export type QueryMissionUpdatesBySessionArgs = {
  sessionId: Scalars['ID'];
};

export type QueryMissionsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryQueryActivityHoursByYearArgs = {
  studentUid: Scalars['String'];
};

export type QueryQueryAssignedTasksByAssigneeArgs = {
  assignUserId: Scalars['String'];
};

export type QueryQueryEscalationStudentsByStatusArgs = {
  status?: Maybe<Array<InternalNotesStatus>>;
};

export type QueryQueryEssayInternalNotesArgs = {
  essayDocId?: Maybe<Scalars['String']>;
};

export type QueryQueryInternalNotesArgs = {
  missionId?: Maybe<Scalars['String']>;
  noteId?: Maybe<Scalars['String']>;
  studentUid?: Maybe<Scalars['String']>;
};

export type QueryQueryInternalTasksArgs = {
  assignee?: Maybe<Array<Scalars['String']>>;
  billingRegion?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['String']>;
  creatorUid?: Maybe<Array<Scalars['String']>>;
  dueDates?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  isStrategist?: Maybe<Scalars['Boolean']>;
  isTL?: Maybe<Scalars['Boolean']>;
  pagination: PageInfoInput;
  regions?: Maybe<Array<Scalars['String']>>;
  sortConfig: SortConfigType;
  source?: Maybe<TaskResourceType>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<Array<TaskStatus>>;
  targetUid?: Maybe<Array<Scalars['String']>>;
  tenant?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Scalars['String']>>;
};

export type QueryQueryInternalTasksCountByUserArgs = {
  tenant: Scalars['String'];
};

export type QueryQueryOnboardingFieldCheckerResultArgs = {
  studentUid: Scalars['String'];
};

export type QueryQueryServiceUpdatesArgs = {
  category?: Maybe<InternalNotesCategory>;
  createdAt?: Maybe<Scalars['String']>;
  noteId?: Maybe<Scalars['String']>;
  studentUid?: Maybe<Scalars['String']>;
};

export type QueryQuerySsaTrackingResultArgs = {
  studentUid: Scalars['String'];
};

export type QueryQueryStudentSubjectsArgs = {
  subjectIds: Array<Maybe<Scalars['String']>>;
};

export type QueryRecommendActivityCategoriesArgs = {
  input: RecommendActivityCategoriesInput;
};

export type QueryRecommendActivityIdeasArgs = {
  input: RecommendActivityIdeasInput;
};

export type QueryRecommendActivityPollArgs = {
  input: RecommendActivityInput;
};

export type QueryRecommendActivityStartArgs = {
  input: RecommendActivityInput;
};

export type QueryRecommendMissionsArgs = {
  input: RecommendMissionsInput;
};

export type QueryRecommendResearchArgs = {
  input: RecommendResearchInput;
};

export type QueryRecommendResearchIdeasArgs = {
  input: RecommendResearchIdeasInput;
};

export type QueryRoadmapBuilderVisibleArgs = {
  userId: Scalars['String'];
};

export type QueryShouldShowEducationPopupArgs = {
  studentId: Scalars['String'];
};

export type QueryStudentChecksArgs = {
  studentIds: Array<Scalars['ID']>;
};

export type QueryStudentProfileRatingArgs = {
  studentId: Scalars['ID'];
};

export type QuerySyncEclDataArgs = {
  include: Scalars['Boolean'];
  userIds: Array<Scalars['String']>;
};

export type QuerySyncHonorDataArgs = {
  include: Scalars['Boolean'];
  userIds: Array<Scalars['String']>;
};

export type QuerySyncTestDataArgs = {
  include: Scalars['Boolean'];
  userIds: Array<Scalars['String']>;
};

export type QueryWhyThisUniversityArgs = {
  input: WhyThisUniversityInput;
};

export type Reading = {
  __typename?: 'Reading';
  author?: Maybe<Scalars['String']>;
  bookCover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  missionId: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ReadingRecommendation = {
  __typename?: 'ReadingRecommendation';
  author: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
};

export type RecommendActivityCategoriesInput = {
  roadmapId: Scalars['String'];
  userId: Scalars['String'];
};

export type RecommendActivityIdeasInput = {
  category: Scalars['ID'];
  hint?: Maybe<Scalars['String']>;
  refresh?: Maybe<Scalars['Boolean']>;
  roadmapId: Scalars['String'];
  userId: Scalars['String'];
};

export type RecommendActivityInput = {
  idea: Scalars['ID'];
  roadmapId: Scalars['String'];
  userId: Scalars['String'];
};

export type RecommendMissionsInput = {
  hint?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  roadmapId: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type RecommendResearchIdeasInput = {
  hint?: Maybe<Scalars['String']>;
  roadmapId: Scalars['String'];
  userId: Scalars['String'];
};

export type RecommendResearchInput = {
  idea: Scalars['ID'];
  roadmapId: Scalars['String'];
  userId: Scalars['String'];
};

export type RecommenderActionItem = {
  __typename?: 'RecommenderActionItem';
  description: Scalars['String'];
  /** ISO 8601 date time */
  dueDate?: Maybe<Scalars['String']>;
};

export type RecommenderActivity = {
  __typename?: 'RecommenderActivity';
  actionItems: Array<RecommenderActionItem>;
  activityType: Scalars['String'];
  category: Scalars['String'];
  description: Scalars['String'];
  details: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  hoursPerWeek: Scalars['Float'];
  impactFactor: Scalars['Int'];
  organizationName: Scalars['String'];
  position: Scalars['String'];
  resources: Array<RecommenderResource>;
  startAt?: Maybe<Scalars['String']>;
  subcategory: Scalars['String'];
  timeOfParticipation: Scalars['String'];
  title: Scalars['String'];
  weeksPerYear: Scalars['Float'];
};

export type RecommenderActivityCategory = {
  __typename?: 'RecommenderActivityCategory';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type RecommenderActivityIdea = {
  __typename?: 'RecommenderActivityIdea';
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  why: Array<Scalars['String']>;
};

export type RecommenderActivityJob = {
  __typename?: 'RecommenderActivityJob';
  id: Scalars['ID'];
};

export type RecommenderFeedback = {
  __typename?: 'RecommenderFeedback';
  id: Scalars['ID'];
};

export enum RecommenderFeedbackSentiment {
  Negative = 'Negative',
  Positive = 'Positive',
}

export type RecommenderMission = {
  __typename?: 'RecommenderMission';
  actionItems: Array<Maybe<RecommenderActionItem>>;
  data?: Maybe<RecommenderMissionData>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resources: Array<Maybe<RecommenderResource>>;
  startAt?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  why?: Maybe<Array<Scalars['String']>>;
};

export type RecommenderMissionData =
  | RecommenderMissionDataHonor
  | RecommenderMissionDataInternship
  | RecommenderMissionDataReading
  | RecommenderMissionDataResearch
  | RecommenderMissionDataSummerProgram;

export type RecommenderMissionDataHonor = {
  __typename?: 'RecommenderMissionDataHonor';
  ageGroupMax?: Maybe<Scalars['Int']>;
  ageGroupMin?: Maybe<Scalars['Int']>;
  category: Scalars['String'];
  competitiveness: Scalars['Int'];
  /** The meaning of this field depends on `registrationState`. When `registrationState` is `UPCOMING` this refers to the date on which registration begins, and if `registrationState` is `OPEN` then this refers to the date on which the program begins. */
  date?: Maybe<Scalars['DateTime']>;
  impactFactor: Scalars['Int'];
  institution: Scalars['String'];
  level?: Maybe<LevelOfRecognitionType>;
  registrationState: SummerProgramRegistrationStateType;
  url: Scalars['String'];
};

export type RecommenderMissionDataInternship = {
  __typename?: 'RecommenderMissionDataInternship';
  applicationDeadline?: Maybe<Scalars['DateTime']>;
  impactFactor: Scalars['Int'];
  industry: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  provider?: Maybe<ExperienceProviderType>;
};

export type RecommenderMissionDataReading = {
  __typename?: 'RecommenderMissionDataReading';
  author: Scalars['String'];
  coverUrl?: Maybe<Scalars['String']>;
  genre: Scalars['String'];
  isbn?: Maybe<Scalars['String']>;
};

export type RecommenderMissionDataResearch = {
  __typename?: 'RecommenderMissionDataResearch';
  field: Scalars['String'];
  impactFactor: Scalars['Int'];
  provider: Scalars['String'];
  question: Scalars['String'];
  subfield: Scalars['String'];
};

export type RecommenderMissionDataSummerProgram = {
  __typename?: 'RecommenderMissionDataSummerProgram';
  ageGroupMax?: Maybe<Scalars['Int']>;
  ageGroupMin?: Maybe<Scalars['Int']>;
  competitiveness: Scalars['Int'];
  credit: Scalars['Boolean'];
  /** The meaning of this field depends on `registrationState`. When `registrationState` is `UPCOMING` this refers to the date on which registration begins, and if `registrationState` is `OPEN` then this refers to the date on which the program begins. */
  date?: Maybe<Scalars['DateTime']>;
  imageUrl?: Maybe<Scalars['String']>;
  impactFactor: Scalars['Int'];
  institution: Scalars['String'];
  registrationState: SummerProgramRegistrationStateType;
  resourceCategoryId?: Maybe<Scalars['String']>;
  resourceCategoryText?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  url: Scalars['String'];
};

export type RecommenderResearchIdea = {
  __typename?: 'RecommenderResearchIdea';
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  why: Array<Scalars['String']>;
};

export type RecommenderResource = {
  __typename?: 'RecommenderResource';
  externalId: Scalars['String'];
  title: Scalars['String'];
  type: RecommenderResourceType;
  url: Scalars['String'];
};

export enum RecommenderResourceType {
  Link = 'Link',
  Video = 'Video',
}

export type ResearchRecommendation = {
  __typename?: 'ResearchRecommendation';
  id: Scalars['String'];
  question: Scalars['String'];
  title: Scalars['String'];
};

export enum RoadmapBuilderRecommendationStatusType {
  Done = 'DONE',
  Error = 'ERROR',
  Generating = 'GENERATING',
  Idle = 'IDLE',
}

export type RoadmapBuilderSelectionInput = {
  eclActivity?: Maybe<Array<RoadmapBuilderSelectionDetailInput>>;
  experience?: Maybe<Array<RoadmapBuilderSelectionDetailInput>>;
  honor?: Maybe<Array<RoadmapBuilderSelectionDetailInput>>;
  reading?: Maybe<Array<RoadmapBuilderSelectionDetailInput>>;
  researchPublication?: Maybe<Array<RoadmapBuilderSelectionDetailInput>>;
  summerProgram?: Maybe<Array<RoadmapBuilderSelectionDetailInput>>;
  testing?: Maybe<Array<RoadmapBuilderSelectionDetailInput>>;
};

export enum RoadmapBuilderStatus {
  Done = 'DONE',
  ExistingUserSkipped = 'EXISTING_USER_SKIPPED',
  InProgress = 'IN_PROGRESS',
  Skipped = 'SKIPPED',
}

export type RoadmapData = {
  __typename?: 'RoadmapData';
  createdBy: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  recommendation?: Maybe<RoadmapRecommendation>;
  recommendationSelection?: Maybe<RoadmapRecommendationSelection>;
  recommendationStatus?: Maybe<RoadmapBuilderRecommendationStatusType>;
  status?: Maybe<RoadmapBuilderStatus>;
  studentUserId: Scalars['String'];
};

export type RoadmapRecommendation = {
  __typename?: 'RoadmapRecommendation';
  eclActivity?: Maybe<Array<Maybe<EclActivityRecommendation>>>;
  experience?: Maybe<Array<Maybe<ExperienceRecommendation>>>;
  honor?: Maybe<Array<Maybe<EclResource>>>;
  reading?: Maybe<Array<Maybe<ReadingRecommendation>>>;
  researchPublication?: Maybe<Array<Maybe<ResearchRecommendation>>>;
  summerProgram?: Maybe<Array<Maybe<EclResource>>>;
  testing?: Maybe<Array<Maybe<TestingRecommendation>>>;
};

export type RoadmapRecommendationSelection = {
  __typename?: 'RoadmapRecommendationSelection';
  eclActivity?: Maybe<Array<RoadmapBuilderSelectionDetail>>;
  experience?: Maybe<Array<RoadmapBuilderSelectionDetail>>;
  honor?: Maybe<Array<RoadmapBuilderSelectionDetail>>;
  reading?: Maybe<Array<RoadmapBuilderSelectionDetail>>;
  researchPublication?: Maybe<Array<RoadmapBuilderSelectionDetail>>;
  summerProgram?: Maybe<Array<RoadmapBuilderSelectionDetail>>;
  testing?: Maybe<Array<RoadmapBuilderSelectionDetail>>;
};

export type RoleType = {
  __typename?: 'RoleType';
  name?: Maybe<Scalars['String']>;
};

export type RolesType = {
  __typename?: 'RolesType';
  isPrimary?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleType>;
  roleId?: Maybe<Scalars['String']>;
};

export type SalesforceAwardDataInput = {
  award?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type SalesforceEclDataInput = {
  activityType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hoursPerWeek?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  schoolYear?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  timeOfParticipation?: Maybe<Scalars['String']>;
  weeksPerYear?: Maybe<Scalars['String']>;
};

export type SalesforceIcfDataInput = {
  schoolName?: Maybe<Scalars['String']>;
  yearOfApplication?: Maybe<Scalars['String']>;
};

export type SalesforceTestDataInput = {
  results?: Maybe<Array<SalesforceTestResultInput>>;
  subcategory?: Maybe<Scalars['String']>;
  testDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SalesforceTestResultInput = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum SopStatus {
  Completed = 'COMPLETED',
  CompletedButLate = 'COMPLETED_BUT_LATE',
  Delay = 'DELAY',
  InProgress = 'IN_PROGRESS',
  Normal = 'NORMAL',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE',
  Pause = 'PAUSE',
  Skipped = 'SKIPPED',
  Upcoming_72Hours = 'UPCOMING_72HOURS',
}

export type StudentAcademic = {
  __typename?: 'StudentAcademic';
  GPAScale?: Maybe<Scalars['Float']>;
  GPAWeight: Scalars['Boolean'];
  actualClassRank?: Maybe<ClassRankType>;
  actualGPA?: Maybe<Scalars['Float']>;
  classSize?: Maybe<Scalars['Int']>;
  cumulativeGPA?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isGPA: Scalars['Boolean'];
  missionId: Scalars['String'];
  targetClassRank?: Maybe<ClassRankType>;
  targetCumulativeGPA?: Maybe<Scalars['Float']>;
  targetGPA?: Maybe<Scalars['Float']>;
  userId: Scalars['String'];
};

export type StudentBasicInfo = {
  __typename?: 'StudentBasicInfo';
  accountBillingCountry?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type StudentBasicInfoInput = {
  accountBillingCountry?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type StudentCheck = {
  __typename?: 'StudentCheck';
  student: Array<StudentCheckStudent>;
};

export type StudentCheckCategory = {
  __typename?: 'StudentCheckCategory';
  id: Scalars['ID'];
  summary: StudentCheckSummary;
  title: Scalars['String'];
};

export enum StudentCheckFlagStatusType {
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export type StudentCheckResolveInput = {
  comment: Scalars['String'];
  studentCheckId: Scalars['ID'];
};

export type StudentCheckResolveResult = {
  __typename?: 'StudentCheckResolveResult';
  accepted: Scalars['Boolean'];
  description: Scalars['String'];
};

export type StudentCheckStudent = {
  __typename?: 'StudentCheckStudent';
  categories: Array<StudentCheckCategory>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type StudentCheckSummary = {
  __typename?: 'StudentCheckSummary';
  count: Scalars['Int'];
  items: Array<StudentCheckSummaryItem>;
  /** Overall status of this student check category. */
  status: StudentCheckFlagStatusType;
};

export type StudentCheckSummaryItem = {
  __typename?: 'StudentCheckSummaryItem';
  text: Scalars['String'];
};

export type StudentPortfolios = {
  __typename?: 'StudentPortfolios';
  advisorName?: Maybe<Scalars['String']>;
  advisorUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  missionId: Scalars['String'];
  preparationEndDate?: Maybe<Scalars['DateTime']>;
  preparationRequired?: Maybe<Scalars['Boolean']>;
  preparationStartDate?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<PortfoliosProgramProviderType>;
  type?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type StudentProfileRating = {
  __typename?: 'StudentProfileRating';
  id: Scalars['ID'];
  majors: Array<StudentProfileRatingMajor>;
};

export type StudentProfileRatingMajor = {
  __typename?: 'StudentProfileRatingMajor';
  categories: Array<StudentProfileRatingMajorCategory>;
  major: Scalars['String'];
  targetCountry: Scalars['String'];
};

export type StudentProfileRatingMajorCategory = {
  __typename?: 'StudentProfileRatingMajorCategory';
  items: Array<StudentProfileRatingMajorCategoryItem>;
  title: Scalars['String'];
};

export type StudentProfileRatingMajorCategoryItem = {
  __typename?: 'StudentProfileRatingMajorCategoryItem';
  description: Scalars['String'];
  flagId: Scalars['ID'];
  id: Scalars['ID'];
  status: StudentCheckFlagStatusType;
  title: Scalars['String'];
};

export type StudentPrograms = {
  __typename?: 'StudentPrograms';
  actualGrade?: Maybe<Scalars['String']>;
  applicationOutcome?: Maybe<Scalars['String']>;
  applicationPeriodEnd?: Maybe<Scalars['Date']>;
  applicationPeriodStart?: Maybe<Scalars['DateTime']>;
  applicationStatus?: Maybe<Scalars['String']>;
  credit: Scalars['Boolean'];
  crimsonSupport?: Maybe<Scalars['Boolean']>;
  gradeFormat?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  impactFactor?: Maybe<Scalars['Int']>;
  institution?: Maybe<Scalars['String']>;
  maxGrade?: Maybe<Scalars['String']>;
  missionId: Scalars['String'];
  resourceCategoryId?: Maybe<Scalars['String']>;
  resourceCategoryName?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  targetGrade?: Maybe<Scalars['String']>;
  /** Refers to the mode of attendance. e.g. `Online` or `On Campus`. */
  type?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type StudentResearch = {
  __typename?: 'StudentResearch';
  advisorName?: Maybe<Scalars['String']>;
  advisorUserId?: Maybe<Scalars['String']>;
  affiliation?: Maybe<Scalars['String']>;
  criProgram?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  impactFactor?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  missionId: Scalars['String'];
  provider?: Maybe<ProgramProviderType>;
  publication?: Maybe<Scalars['Boolean']>;
  publisherName?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  subField?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type StudentSubject = {
  __typename?: 'StudentSubject';
  actualGrade?: Maybe<Scalars['String']>;
  gradeFormat?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  level?: Maybe<Scalars['String']>;
  maxGrade?: Maybe<Scalars['String']>;
  missionId: Scalars['String'];
  selfStudy?: Maybe<Scalars['Boolean']>;
  semester?: Maybe<Scalars['String']>;
  subject?: Maybe<SubjectCategory>;
  subjectId: Scalars['String'];
  targetGrade?: Maybe<Scalars['String']>;
};

export type StudentTest = {
  __typename?: 'StudentTest';
  creatorId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isMockTest: Scalars['Boolean'];
  members?: Maybe<Array<Maybe<Scalars['String']>>>;
  missionId: Scalars['String'];
  missionStatus?: Maybe<Scalars['String']>;
  subCategory: Scalars['String'];
  targetScore?: Maybe<Scalars['Float']>;
  testDate?: Maybe<Scalars['DateTime']>;
  testResult?: Maybe<Array<Maybe<TestResult>>>;
  totalScore?: Maybe<Scalars['Float']>;
  type?: Maybe<TestTypeType>;
  userId: Scalars['String'];
};

export type SubTestListObj = {
  __typename?: 'SubTestListObj';
  list: Array<StudentTest>;
  type: Scalars['String'];
};

export enum Subcategories {
  AcademicSkill = 'Academic_Skill',
  Essential = 'Essential',
  ExtracurricularActivities = 'Extracurricular_Activities',
  Honor = 'Honor',
  InternshipExperience = 'Internship_Experience',
  Major = 'Major',
  Networking = 'Networking',
  Portfolios = 'Portfolios',
  Preparation = 'Preparation',
  Reading = 'Reading',
  Relationship = 'Relationship',
  Research = 'Research',
  SchoolAcademic = 'School_Academic',
  UniversityPrograms = 'University_Programs',
  Writing = 'Writing',
}

export type SubjectCategory = {
  __typename?: 'SubjectCategory';
  curriculum?: Maybe<CurriculumType>;
  curriculumId: Scalars['String'];
  /** The latest grade scheme for this subject, if one exists */
  gradeScheme?: Maybe<SubjectCategoryGradeScheme>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type SubjectCategoryGradeScheme = {
  __typename?: 'SubjectCategoryGradeScheme';
  goodGrade: Scalars['String'];
  /** Only available for curricula which use symbol grades (e.g. International A-Level is from grades A-E). */
  grades: Array<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  passGrade: Scalars['String'];
};

export enum SummerProgramRegistrationStateType {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Upcoming = 'UPCOMING',
}

export type TargetUsersType = {
  firstName?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TaskCountByType = {
  __typename?: 'TaskCountByType';
  count?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type TaskPanelType = {
  __typename?: 'TaskPanelType';
  dueInTwoDays?: Maybe<Scalars['Int']>;
  openThreeDays?: Maybe<Scalars['Int']>;
  overdue?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export enum TaskResourceType {
  Counselingfollowup = 'COUNSELINGFOLLOWUP',
  Customerservice = 'CUSTOMERSERVICE',
  Escalations = 'ESCALATIONS',
  Essays = 'ESSAYS',
  General = 'GENERAL',
  Handover = 'HANDOVER',
  ParentReportApproval = 'PARENT_REPORT_APPROVAL',
  Payment = 'PAYMENT',
  Reminder = 'REMINDER',
  Sales = 'SALES',
  SopCheckpoint = 'SOP_CHECKPOINT',
  Studentcheck = 'STUDENTCHECK',
  Tutoringfollowup = 'TUTORINGFOLLOWUP',
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export type TasksObjectForAssigneeType = {
  __typename?: 'TasksObjectForAssigneeType';
  tasks: Array<Maybe<TaskCountByType>>;
  total: Scalars['Int'];
};

export type TestListObj = {
  __typename?: 'TestListObj';
  category: Scalars['String'];
  list: Array<SubTestListObj>;
};

export type TestOptOutResult = {
  __typename?: 'TestOptOutResult';
  lngTestOptOutEU?: Maybe<Scalars['Boolean']>;
  lngTestOptOutUK?: Maybe<Scalars['Boolean']>;
  lngTestOptOutUS?: Maybe<Scalars['Boolean']>;
  testOptOutEU?: Maybe<Scalars['Boolean']>;
  testOptOutUK?: Maybe<Scalars['Boolean']>;
  testOptOutUS?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type TestOverview = {
  __typename?: 'TestOverview';
  bestScore?: Maybe<Scalars['Float']>;
  count: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  targetReached?: Maybe<Scalars['Boolean']>;
  testDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<TestTypeType>;
  userId: Scalars['String'];
};

export type TestResult = {
  __typename?: 'TestResult';
  id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  studentTestId: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum TestTypeType {
  Act = 'ACT',
  Ahcaat = 'AHCAAT',
  AptitudeTestingCsat = 'APTITUDE_TESTING_CSAT',
  AptitudeTestingEcaa = 'APTITUDE_TESTING_ECAA',
  AptitudeTestingEsatBiologyChemistry = 'APTITUDE_TESTING_ESAT_BIOLOGY_CHEMISTRY',
  AptitudeTestingEsatMathPhysics = 'APTITUDE_TESTING_ESAT_MATH_PHYSICS',
  ApSubjectsTest = 'AP_SUBJECTS_TEST',
  ArchaeologyAdmisAssess = 'ARCHAEOLOGY_ADMIS_ASSESS',
  ArchitectureAdmissions = 'ARCHITECTURE_ADMISSIONS',
  ArchitectureAdmissionsAssessment = 'ARCHITECTURE_ADMISSIONS_ASSESSMENT',
  ArchitectureTilTest = 'ARCHITECTURE_TIL_TEST',
  ArchAdmisAssess = 'ARCH_ADMIS_ASSESS',
  Bmat = 'BMAT',
  Bocconi = 'BOCCONI',
  BocconiOnline = 'BOCCONI_ONLINE',
  Bsmat = 'BSMAT',
  Cat = 'CAT',
  CcvxBiology = 'CCVX_BIOLOGY',
  CcvxChemistry = 'CCVX_CHEMISTRY',
  CcvxMathA = 'CCVX_MATH_A',
  CcvxMathB = 'CCVX_MATH_B',
  CcvxPhysics = 'CCVX_PHYSICS',
  ComeniusMedAdmisTest = 'COMENIUS_MED_ADMIS_TEST',
  Duolingo = 'Duolingo',
  Ebau = 'EBAU',
  EbauCombinedSc = 'EBAU_COMBINED_SC',
  EhlAdmissions = 'EHL_ADMISSIONS',
  Elat = 'ELAT',
  Enem = 'ENEM',
  Engaa = 'ENGAA',
  EnglishTolcE = 'ENGLISH_TOLC_E',
  EnglishTolcF = 'ENGLISH_TOLC_F',
  EnglishTolcI = 'ENGLISH_TOLC_I',
  EnglAdmisAssess = 'ENGL_ADMIS_ASSESS',
  EthZurich = 'ETH_ZURICH',
  Gamsat = 'GAMSAT',
  GeogAdmisAssess = 'GEOG_ADMIS_ASSESS',
  Gmat = 'GMAT',
  Gre = 'GRE',
  Hat = 'HAT',
  HistAdmisAssess = 'HIST_ADMIS_ASSESS',
  HistArtAdmisAssess = 'HIST_ART_ADMIS_ASSESS',
  HistSocPolSciAdmisAssess = 'HIST_SOC_POL_SCI_ADMIS_ASSESS',
  Hpat = 'HPAT',
  Humat = 'HUMAT',
  IbSubjectsTest = 'IB_SUBJECTS_TEST',
  Ielts = 'IELTS',
  IeUniversityAdmissions = 'IE_UNIVERSITY_ADMISSIONS',
  Imat = 'IMAT',
  Isat = 'ISAT',
  Isee = 'ISEE',
  LingAdmisAssess = 'LING_ADMIS_ASSESS',
  Lnat = 'LNAT',
  Lsat = 'LSAT',
  Mat = 'MAT',
  MathAdmisAssess = 'MATH_ADMIS_ASSESS',
  Mcat = 'MCAT',
  Mlat = 'MLAT',
  ModMedLangAdmisAssess = 'MOD_MED_LANG_ADMIS_ASSESS',
  MusAdmisAssess = 'MUS_ADMIS_ASSESS',
  Nsaa = 'NSAA',
  Olat = 'OLAT',
  OmptA = 'OMPT_A',
  OmptB = 'OMPT_B',
  OmptC = 'OMPT_C',
  OmptD = 'OMPT_D',
  Pat = 'PAT',
  Pce = 'PCE',
  PceCombinedSc = 'PCE_COMBINED_SC',
  Phil = 'PHIL',
  PhilAdmisAssess = 'PHIL_ADMIS_ASSESS',
  Psat = 'PSAT',
  PsychAdmisAssess = 'PSYCH_ADMIS_ASSESS',
  Pte = 'PTE',
  Sat = 'SAT',
  Ssat = 'SSAT',
  Step = 'STEP',
  Studienkolleg = 'STUDIENKOLLEG',
  Tmua = 'TMUA',
  Toefl = 'TOEFL',
  Tsa = 'TSA',
  Tumat = 'TUMAT',
  Ucat = 'UCAT',
  UcatCamillus = 'UCAT_CAMILLUS',
  UnisrMedAdmisAssess = 'UNISR_MED_ADMIS_ASSESS',
  VwoBiology = 'VWO_BIOLOGY',
  VwoChemistry = 'VWO_CHEMISTRY',
  VwoMathA = 'VWO_MATH_A',
  VwoMathB = 'VWO_MATH_B',
  VwoMathC = 'VWO_MATH_C',
  VwoPhysics = 'VWO_PHYSICS',
}

export type TestingRecommendation = {
  __typename?: 'TestingRecommendation';
  id: Scalars['String'];
  name: Scalars['String'];
  registrationDeadline?: Maybe<Scalars['String']>;
  testDate: Scalars['String'];
};

export enum UniqueProgressionType {
  GraduateEarly = 'GRADUATE_EARLY',
  GraduateLater = 'GRADUATE_LATER',
  None = 'NONE',
  TakeGapYear = 'TAKE_GAP_YEAR',
  TakeTimeOff = 'TAKE_TIME_OFF',
}

export type UpdateRecommenderFeedbackInput = {
  comments?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  missionId?: Maybe<Scalars['String']>;
  sentiment?: Maybe<RecommenderFeedbackSentiment>;
};

export type UserAchievements = {
  __typename?: 'UserAchievements';
  academic?: Maybe<UserAchievementsAcademic>;
  eclActivity?: Maybe<Array<Maybe<MissionObjectType>>>;
  honor?: Maybe<Array<Maybe<MissionObjectType>>>;
  reading?: Maybe<Array<Maybe<Reading>>>;
  summerProgram?: Maybe<Array<Maybe<MissionObjectType>>>;
  testing?: Maybe<Array<Maybe<UserAchievementsStudentTest>>>;
};

export type UserAchievementsAcademic = {
  __typename?: 'UserAchievementsAcademic';
  studentAcademic?: Maybe<StudentAcademic>;
  studentSubjects?: Maybe<Array<Maybe<Subjects>>>;
};

export type UserAchievementsStudentTest = {
  __typename?: 'UserAchievementsStudentTest';
  highestScore?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isMockTest: Scalars['Boolean'];
  missionId: Scalars['String'];
  subCategory: Scalars['String'];
  targetScore?: Maybe<Scalars['Float']>;
  testDate?: Maybe<Scalars['DateTime']>;
  testResult?: Maybe<Array<Maybe<TestResult>>>;
  totalScore?: Maybe<Scalars['Float']>;
  type?: Maybe<TestTypeType>;
  userId: Scalars['String'];
};

export type UserType = {
  __typename?: 'UserType';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<RolesType>>>;
  userId?: Maybe<Scalars['String']>;
};

export type WhyThisUniversityInput = {
  universityId: Scalars['ID'];
  /** Ignored for non-server requesters. */
  userId?: Maybe<Scalars['ID']>;
};

export type WhyThisUniversityReason = {
  __typename?: 'WhyThisUniversityReason';
  /** Text content of this reason, in Markdown format. */
  content: Scalars['String'];
};

export type WhyThisUniversityResult = {
  __typename?: 'WhyThisUniversityResult';
  /** List of reasons why the studnet might be a bad match for this university. */
  badMatchReasons: Array<WhyThisUniversityReason>;
  /** List of reasons why the student might be a good match for this university. */
  goodMatchReasons: Array<WhyThisUniversityReason>;
};

export type YearStateType = {
  __typename?: 'YearStateType';
  hours: Scalars['Int'];
  year: Scalars['Int'];
};

export type CategoryPoints = {
  __typename?: 'categoryPoints';
  category: Scalars['String'];
  points: Scalars['Int'];
};

export type CopySubjectsInput = {
  fromMissionId: Scalars['String'];
  toMissionId: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateStudentSubjectInput = {
  actualGrade?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  gradeFormat?: Maybe<Scalars['String']>;
  grades?: Maybe<Scalars['JSON']>;
  level?: Maybe<Scalars['String']>;
  maxGrade?: Maybe<Scalars['String']>;
  missionId: Scalars['String'];
  predictGrade?: Maybe<Scalars['String']>;
  selfStudy?: Maybe<Scalars['Boolean']>;
  subjectId: Scalars['String'];
  targetGrade?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  yearSchedule: Scalars['String'];
};

export type ExperienceInput = {
  applicationDeadline?: Maybe<Scalars['String']>;
  /** Internship and experience id */
  id?: Maybe<Scalars['String']>;
  impactFactor?: Maybe<Scalars['Int']>;
  industry?: Maybe<Scalars['String']>;
  /** the mission id */
  missionId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  programProvider?: Maybe<ExperienceProviderType>;
  /** userId */
  userId: Scalars['String'];
};

export type ExtracurricularInput = {
  activityType?: Maybe<Scalars['String']>;
  crimsonCapstone?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  hoursPerWeek?: Maybe<Scalars['Float']>;
  /** extracurricular activity id */
  id?: Maybe<Scalars['String']>;
  impactFactor?: Maybe<Scalars['Int']>;
  /** the mission id */
  missionId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  programProvider?: Maybe<EclProviderType>;
  schoolYear?: Maybe<Array<Maybe<Scalars['Int']>>>;
  subType?: Maybe<Scalars['String']>;
  timeOfParticipation?: Maybe<Scalars['String']>;
  /** userId */
  userId: Scalars['String'];
  weeksPerYear?: Maybe<Scalars['Float']>;
};

export type HonorInput = {
  award?: Maybe<Scalars['String']>;
  awardLevel?: Maybe<Scalars['String']>;
  awardReceived?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  /** Honor id */
  id?: Maybe<Scalars['String']>;
  levelOfRecognition?: Maybe<LevelOfRecognitionType>;
  /** the mission id */
  missionId: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  schoolYear?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** userId */
  userId: Scalars['String'];
};

export type InitRoadmapBuilderRes = {
  __typename?: 'initRoadmapBuilderRes';
  message?: Maybe<Scalars['String']>;
  roadmapBuilderId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type InitStudentInfoResponseType = {
  __typename?: 'initStudentInfoResponseType';
  modifiedMissionCount?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type InitializationInfo = {
  __typename?: 'initializationInfo';
  /** Academic year end */
  academicYearEnd?: Maybe<MonthType>;
  /** Academic year start */
  academicYearStart?: Maybe<MonthType>;
  currentGradeLevel?: Maybe<Scalars['Int']>;
  /** current education */
  educationBackground?: Maybe<EducationBackground>;
  finalGradeLevel?: Maybe<Scalars['Int']>;
  /** The student's country use to describe grade or year level */
  gradeLevelType?: Maybe<GradeLevelType>;
  semesterBreak?: Maybe<Array<Maybe<SemesterBreakOutput>>>;
  semesters?: Maybe<Scalars['Int']>;
  startGradeLevel?: Maybe<Scalars['Int']>;
  toggleActionItems?: Maybe<Scalars['Boolean']>;
  toggleIntentedPortfolios?: Maybe<Scalars['Boolean']>;
  toggleIntentedPrograms?: Maybe<Scalars['Boolean']>;
  toggleIntentedResearch?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type IntendedInput = {
  toggleActionExperience?: Maybe<Scalars['Boolean']>;
  toggleIntentedPortfolios?: Maybe<Scalars['Boolean']>;
  toggleIntentedPrograms?: Maybe<Scalars['Boolean']>;
  toggleIntentedResearch?: Maybe<Scalars['Boolean']>;
};

export type MissionIdResult = {
  __typename?: 'missionIdResult';
  missionId?: Maybe<Scalars['String']>;
};

export type MissionObject = {
  __typename?: 'missionObject';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Scalars['String']>>>;
  startAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MissionObjectType = {
  __typename?: 'missionObjectType';
  actionItems?: Maybe<Array<Maybe<ActionItem>>>;
  attr?: Maybe<Scalars['String']>;
  badge?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  commonApp?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  experience?: Maybe<Experience>;
  extracurricular?: Maybe<ExtracurricularActivity>;
  grade?: Maybe<Scalars['Int']>;
  honor?: Maybe<Honor>;
  id: Scalars['String'];
  integrationSource?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherSubcategory?: Maybe<OtherSubcategories>;
  portfolios?: Maybe<StudentPortfolios>;
  program?: Maybe<StudentPrograms>;
  reading?: Maybe<Reading>;
  research?: Maybe<StudentResearch>;
  schoolAcademic?: Maybe<StudentAcademic>;
  secondaryCategory?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<Maybe<Subjects>>>;
  test?: Maybe<StudentTest>;
  title?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Onboarding field checker category */
export type OnboardingFieldCheckerCategory = {
  __typename?: 'onboardingFieldCheckerCategory';
  completion?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<Maybe<OnboardingFieldCheckerItem>>>;
  name?: Maybe<Scalars['String']>;
};

/** Onboarding field checker item */
export type OnboardingFieldCheckerItem = {
  __typename?: 'onboardingFieldCheckerItem';
  completion?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Onboarding field checker result */
export type OnboardingFieldCheckerResult = {
  __typename?: 'onboardingFieldCheckerResult';
  categories?: Maybe<Array<Maybe<OnboardingFieldCheckerCategory>>>;
  completion?: Maybe<Scalars['Float']>;
};

/** Onboarding field checker skip */
export type OnboardingFieldCheckerSkip = {
  __typename?: 'onboardingFieldCheckerSkip';
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type OtherInput = {
  bookCover?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  pathfinderDescription?: Maybe<Scalars['String']>;
  pathfinderId?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subcategory: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /** userId */
  userId: Scalars['String'];
};

export type PanelDetailType = {
  __typename?: 'panelDetailType';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ReadingInput = {
  author?: Maybe<Scalars['String']>;
  bookCover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  missionId?: Maybe<Scalars['ID']>;
  resourceId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  /** userId */
  userId: Scalars['String'];
};

export type ResultType = {
  __typename?: 'resultType';
  /** success or not */
  success: Scalars['Boolean'];
};

export type RoadmapBuilderSelectionDetail = {
  __typename?: 'roadmapBuilderSelectionDetail';
  id: Scalars['String'];
  schoolYears: Array<Scalars['Int']>;
  targetScore?: Maybe<Scalars['Int']>;
};

export type RoadmapBuilderSelectionDetailInput = {
  id: Scalars['String'];
  schoolYears: Array<Scalars['Int']>;
  targetScore?: Maybe<Scalars['Int']>;
};

export type RoadmapBuilderVisibleRes = {
  __typename?: 'roadmapBuilderVisibleRes';
  inProgress: Scalars['Boolean'];
  isInit: Scalars['Boolean'];
  roadmapBuilderId?: Maybe<Scalars['String']>;
  roadmapBuilderVisible: Scalars['Boolean'];
};

export type SchoolInput = {
  country?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['String']>;
  graduationDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  uniqueProgression?: Maybe<UniqueProgressionType>;
  /** userId */
  userId: Scalars['String'];
};

/** School options */
export type SchoolType = {
  __typename?: 'schoolType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SemesterBreakInput = {
  end: MonthType;
  start: MonthType;
};

export type SemesterBreakOutput = {
  __typename?: 'semesterBreakOutput';
  end: MonthType;
  start: MonthType;
};

/** SOP check group */
export type SopCheckGroup = {
  __typename?: 'sopCheckGroup';
  expand: Scalars['Boolean'];
  finishAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  items?: Maybe<Array<Maybe<SopCheckItem>>>;
  name: Scalars['String'];
  scope: Scalars['String'];
  startAt?: Maybe<Scalars['Date']>;
  status?: Maybe<SopStatus>;
  target?: Maybe<Scalars['String']>;
};

/** Sop Check Item */
export type SopCheckItem = {
  __typename?: 'sopCheckItem';
  changeLog?: Maybe<Array<Maybe<SopCheckItemChangeLog>>>;
  displayName?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['Date']>;
  groupId: Scalars['String'];
  id: Scalars['String'];
  itemId: Scalars['String'];
  planedEndAt?: Maybe<Scalars['Date']>;
  planedStartAt?: Maybe<Scalars['Date']>;
  scope: Scalars['String'];
  staffUsers?: Maybe<Array<Maybe<SopStaffUserInfo>>>;
  startAt?: Maybe<Scalars['Date']>;
  status?: Maybe<SopStatus>;
  target?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Sop Check Item Change log */
export type SopCheckItemChangeLog = {
  __typename?: 'sopCheckItemChangeLog';
  changeType: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  originalData?: Maybe<Scalars['JSON']>;
  reason: Scalars['String'];
  staff?: Maybe<SopStaffUserInfo>;
  staffUserId?: Maybe<Scalars['String']>;
};

/** SOP check scope */
export type SopCheckScope = {
  __typename?: 'sopCheckScope';
  expand: Scalars['Boolean'];
  groups?: Maybe<Array<Maybe<SopCheckGroup>>>;
  hidden: Scalars['Boolean'];
  id: Scalars['String'];
  subTitle: Scalars['String'];
  title: Scalars['String'];
};

/** Sop Group Metric */
export type SopGroupMetric = {
  __typename?: 'sopGroupMetric';
  completed?: Maybe<Scalars['Int']>;
  completedButLate?: Maybe<Scalars['Int']>;
  delay?: Maybe<Scalars['Int']>;
  upcoming?: Maybe<Scalars['Int']>;
};

/** student sop instance */
export type SopInstance = {
  __typename?: 'sopInstance';
  canEditStartDay?: Maybe<Scalars['Boolean']>;
  finishAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  lastCheckAt?: Maybe<Scalars['DateTime']>;
  pauseAt?: Maybe<Scalars['DateTime']>;
  resumeAt?: Maybe<Scalars['DateTime']>;
  scopes?: Maybe<Array<Maybe<SopCheckScope>>>;
  startAt?: Maybe<Scalars['Date']>;
  status?: Maybe<SopStatus>;
  userId?: Maybe<Scalars['String']>;
};

/** Sop students list */
export type SopMyStudentsList = {
  __typename?: 'sopMyStudentsList';
  billingCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Maybe<SopStudentInfo>>>;
};

/** Sop resume date */
export type SopResumeDate = {
  __typename?: 'sopResumeDate';
  context?: Maybe<Scalars['String']>;
  pauseDate?: Maybe<Scalars['DateTime']>;
  resumeDate?: Maybe<Scalars['DateTime']>;
};

/** Sop Staff User Info */
export type SopStaffUserInfo = {
  __typename?: 'sopStaffUserInfo';
  avatar?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  staffRole?: Maybe<Scalars['String']>;
  staffUserId: Scalars['String'];
};

/** Sop Student Info */
export type SopStudentInfo = {
  __typename?: 'sopStudentInfo';
  appYear?: Maybe<Scalars['String']>;
  clientReportMetric?: Maybe<SopGroupMetric>;
  info?: Maybe<SopUserInfo>;
  meetingStatusMetric?: Maybe<SopGroupMetric>;
  progressTrackMetric?: Maybe<SopGroupMetric>;
  serviceTeam?: Maybe<Array<Maybe<SopUserInfo>>>;
  serviceTeamMetric?: Maybe<SopGroupMetric>;
  startDate?: Maybe<Scalars['String']>;
  targets?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Sop User Info */
export type SopUserInfo = {
  __typename?: 'sopUserInfo';
  avatar?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userId: Scalars['String'];
};

export type SortConfigType = {
  direction: Scalars['String'];
  key: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** Ssa Tracking Category */
export type SsaTrackingCategory = {
  __typename?: 'ssaTrackingCategory';
  completion?: Maybe<Scalars['Float']>;
  mappings?: Maybe<Array<Maybe<SsaTrackingMapping>>>;
  name?: Maybe<Scalars['String']>;
};

/** Ssa Tracking Mapping */
export type SsaTrackingMapping = {
  __typename?: 'ssaTrackingMapping';
  completion?: Maybe<Scalars['Float']>;
  nameInApp?: Maybe<Scalars['String']>;
  nameInSsa?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ssa Tracking Result */
export type SsaTrackingResult = {
  __typename?: 'ssaTrackingResult';
  categories?: Maybe<Array<Maybe<SsaTrackingCategory>>>;
  completion?: Maybe<Scalars['Float']>;
};

/** Ssa Tracking Skip */
export type SsaTrackingSkip = {
  __typename?: 'ssaTrackingSkip';
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type StudentAcademicInput = {
  GPAScale?: Maybe<Scalars['Float']>;
  GPAWeight: Scalars['Boolean'];
  actualClassRank?: Maybe<ClassRankType>;
  actualGPA?: Maybe<Scalars['Float']>;
  classSize?: Maybe<Scalars['Int']>;
  cumulativeGPA?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isGPA: Scalars['Boolean'];
  level?: Maybe<Scalars['Int']>;
  missionId: Scalars['String'];
  targetClassRank?: Maybe<ClassRankType>;
  targetCumulativeGPA?: Maybe<Scalars['Float']>;
  targetGPA?: Maybe<Scalars['Float']>;
  userId: Scalars['String'];
};

export type StudentPortfoliosInput = {
  advisorName?: Maybe<Scalars['String']>;
  advisorUserId?: Maybe<Scalars['String']>;
  /** student program id */
  id?: Maybe<Scalars['String']>;
  /** the mission id */
  missionId: Scalars['String'];
  preparationEndDate?: Maybe<Scalars['String']>;
  preparationRequired?: Maybe<Scalars['Boolean']>;
  preparationStartDate?: Maybe<Scalars['String']>;
  provider?: Maybe<PortfoliosProgramProviderType>;
  type?: Maybe<PortfoliosEnumType>;
  /** userId */
  userId: Scalars['String'];
};

export type StudentProgramInput = {
  actualGrade?: Maybe<Scalars['String']>;
  applicationOutcome?: Maybe<Scalars['String']>;
  applicationPeriodEnd?: Maybe<Scalars['String']>;
  applicationPeriodStart?: Maybe<Scalars['String']>;
  applicationStatus?: Maybe<Scalars['String']>;
  credit?: Maybe<Scalars['Boolean']>;
  crimsonSupport?: Maybe<Scalars['Boolean']>;
  gradeFormat?: Maybe<Scalars['String']>;
  /** student program id */
  id?: Maybe<Scalars['String']>;
  impactFactor?: Maybe<Scalars['Int']>;
  institution?: Maybe<Scalars['String']>;
  maxGrade?: Maybe<Scalars['String']>;
  /** the mission id */
  missionId: Scalars['String'];
  resourceCategoryId?: Maybe<Scalars['String']>;
  resourceCategoryName?: Maybe<Scalars['String']>;
  targetGrade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /** userId */
  userId: Scalars['String'];
};

export type StudentResearchInput = {
  advisorName?: Maybe<Scalars['String']>;
  advisorUserId?: Maybe<Scalars['String']>;
  affiliation?: Maybe<Scalars['String']>;
  criProgram?: Maybe<CriProgramType>;
  date?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  /** student program id */
  id?: Maybe<Scalars['String']>;
  impactFactor?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  /** the mission id */
  missionId: Scalars['String'];
  provider?: Maybe<ProgramProviderType>;
  publication?: Maybe<Scalars['Boolean']>;
  publisherName?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  /** Set to `true` to avoid creating or deleting predefined tasks as part of this upsert. */
  skipPredefinedTasks?: Maybe<Scalars['Boolean']>;
  subField?: Maybe<Scalars['String']>;
  /** userId */
  userId: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};

export type StudentSchool = {
  __typename?: 'studentSchool';
  country?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['String']>;
  graduationDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  uniqueProgression?: Maybe<UniqueProgressionType>;
  userId?: Maybe<Scalars['String']>;
};

export type StudentSubjectInput = {
  actualGrade?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  gradeFormat?: Maybe<Scalars['String']>;
  grades?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  level?: Maybe<Scalars['String']>;
  maxGrade?: Maybe<Scalars['String']>;
  missionId: Scalars['String'];
  predictGrade?: Maybe<Scalars['String']>;
  selfStudy?: Maybe<Scalars['Boolean']>;
  semester?: Maybe<Scalars['String']>;
  subjectId: Scalars['String'];
  targetGrade?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type Subjects = {
  __typename?: 'subjects';
  actualGrade?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  gradeFormat?: Maybe<Scalars['String']>;
  grades?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  maxGrade?: Maybe<Scalars['String']>;
  missionId?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  predictGrade?: Maybe<Scalars['String']>;
  selfStudy?: Maybe<Scalars['Boolean']>;
  semester?: Maybe<Scalars['String']>;
  subject?: Maybe<SubjectCategory>;
  subjectId?: Maybe<Scalars['String']>;
  targetGrade?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  yearSchedule?: Maybe<Scalars['String']>;
};

export type SubjectsInput = {
  gradeFormat: Scalars['String'];
  level?: Maybe<Scalars['String']>;
  maxGrade: Scalars['String'];
  missionId: Scalars['String'];
  semester?: Maybe<Scalars['String']>;
  semesters?: Maybe<Array<Scalars['String']>>;
  subjectIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type TestInput = {
  id?: Maybe<Scalars['String']>;
  isMockTest: Scalars['Boolean'];
  missionId?: Maybe<Scalars['String']>;
  targetScore?: Maybe<Scalars['Float']>;
  testDate: Scalars['String'];
  testResult?: Maybe<Array<TestResultInput>>;
  totalScore?: Maybe<Scalars['Float']>;
  /** This type means which test type is, like TOEFL */
  type: TestTypeType;
  userId: Scalars['String'];
};

export type TestOptOutInput = {
  lngTestOptOutEU?: Maybe<Scalars['Boolean']>;
  lngTestOptOutUK?: Maybe<Scalars['Boolean']>;
  lngTestOptOutUS?: Maybe<Scalars['Boolean']>;
  testOptOutEU?: Maybe<Scalars['Boolean']>;
  testOptOutUK?: Maybe<Scalars['Boolean']>;
  testOptOutUS?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type TestResultInput = {
  id?: Maybe<Scalars['String']>;
  /** For each test, the type of each test item */
  key: Scalars['String'];
  studentTestId?: Maybe<Scalars['String']>;
  /** For each test, the score of each test item */
  value?: Maybe<Scalars['String']>;
};

export type TriggerEventResult = {
  __typename?: 'triggerEventResult';
  /** success or not */
  success: Scalars['Boolean'];
};
