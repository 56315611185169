import { SecionContainer, SecionTitle, GreyText } from './style';
import React from 'react';
import { HelpOutline } from '@styled-icons/material';
import { Tooltip } from 'antd';
import { CR_COLORS } from 'src/theme';
export type SectionWithTitleProps = {
  children?: React.ReactNode;
  title: React.ReactNode;
  containerStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  content?: React.ReactNode;
  required?: boolean;
  tooltip?: string;
};

const SectionWithTitle = ({
  children,
  title,
  containerStyle,
  titleStyle,
  content,
  contentStyle,
  required,
  tooltip,
}: SectionWithTitleProps): JSX.Element => {
  return (
    <SecionContainer style={containerStyle}>
      <SecionTitle style={titleStyle}>
        {required && <span style={{ color: 'var(--color-salmon)', marginRight: 3 }}>{'*'}</span>}
        {title}
        {tooltip && (
          <Tooltip
            overlayInnerStyle={{
              borderRadius: 8,
              background: 'rgba(29, 30, 43, 0.8)',
              padding: 16,
              width: 268,
            }}
            title={tooltip}
            getTooltipContainer={(o) => o.parentElement as HTMLElement}
          >
            <HelpOutline style={{ marginLeft: 5 }} color={CR_COLORS.COOL_GREY} width={18} height={18} />
          </Tooltip>
        )}
      </SecionTitle>
      {children}
      {!!content && <GreyText style={contentStyle}>{content}</GreyText>}
    </SecionContainer>
  );
};

export default SectionWithTitle;
