import { ResourceCenterController } from './controller';
import { TStore, reducer, INITIAL_STORE, Actions } from './store';
import { TDispatch } from './type';
import React, { useReducer, useCallback, createContext, useEffect } from 'react';

type TContext<A extends { name: string; payload: unknown }> = {
  controller: ResourceCenterController;
  state: TStore;
  dispatch: TDispatch<A>;
};

export type TResourceCenterContext = TContext<Actions>;

export const resourceCenterContext = createContext<TResourceCenterContext>(null as unknown as TResourceCenterContext);

export const withResourceCenterContextProvider = <P,>(Comp: React.ComponentType<P>): ((p: P) => JSX.Element) => {
  const controller = new ResourceCenterController();
  const EnhancedComp = (props: P): JSX.Element => {
    const [state, _dispatch] = useReducer(reducer, INITIAL_STORE);
    const dispatch = useCallback(
      <A extends Actions>(name: A['name'], payload?: A['payload']) => _dispatch({ name, payload } as A),
      [_dispatch],
    );
    useEffect(() => {
      controller.setParams(state, dispatch);
    }, [state, dispatch]);
    return (
      <resourceCenterContext.Provider
        value={{
          state,
          dispatch,
          controller,
        }}
      >
        <Comp {...props} />
      </resourceCenterContext.Provider>
    );
  };
  return EnhancedComp;
};
