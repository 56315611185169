import styled from 'styled-components';
import { Checkbox as AntCheckbox, Input as AntInput, Popover, DatePicker, Button } from 'antd';
const { RangePicker: AntRangePicker } = DatePicker;
const { TextArea } = AntInput;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-left: 4px;

  & > span {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-cool-grey);
  }
`;

export const ListContainer = styled.div`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Desc = styled.div`
  display: 'flex';
  align-items: 'flex-start';
  &:hover,
  &:focus {
    border: 1px solid rgba(70, 74, 201, 0.8);
    box-shadow: 0px 6px 26px rgba(158, 158, 158, 0.1);
    border-radius: 4px;
  }
`;

export const Checkbox = styled(AntCheckbox)`
  margin: 0 11px;
`;

// substituded with TaskTextArea
export const DescInput = styled(AntInput)`
  font-size: 14px;
  width: 380px;
  padding: 0;
  box-shadow: 0px 6px 20px rgba(169, 172, 192, 0.3);
  border-radius: 2px;
  height: 25px;
  z-index: 1;
  margin-right: 30px;
  &:hover,
  &:focus {
    box-shadow: 0px 6px 20px rgba(169, 172, 192, 0.3);
  }
`;

export const DescSpan = styled.span<{ checked: boolean; fullWidth?: boolean }>`
  flex: 1 0 auto;
  display: block;
  max-width: ${({ fullWidth }) => (fullWidth ? 'auto' : '380px')};
  font-size: 14px;
  text-decoration: ${(props) => (props.checked ? 'line-through' : 'none')};
  overflow-wrap: break-word;
`;

export const InputContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: flex-end;
`;

export const TaskTextArea = styled(TextArea)`
  min-width: 80%;
  border: 1px solid white !important;
  border-radius: 4px;
  width: auto !important;
  .ant-input {
    padding-left: 12px;
  }
  .ant-input::placeholder {
    color: var(--color-cool-grey);
  }
`;

export const AddButton = styled(Button)`
  padding: 3px;
`;

export const RangePicker = styled(AntRangePicker)`
  padding: 0 !important;
  width: 260px;
`;

export const RangePickerPopover = styled(Popover)``;

export const ChangeDate = styled.span`
  flex: 0 0 auto;
  margin-right: 8px;
  margin-top: 1px;
  height: 22px;
  line-height: 24px;
  font-size: 12px;
  color: var(--color-cool-grey);
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: var(--color-indigo);
  }
`;

export const RemoveButtonContainer = styled.div`
  height: 20px;
  svg {
    height: 20px;
  }
`;

export const InputStyle = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  justify-content: space-between;
  box-shadow: 0px 6px 26px rgba(158, 158, 158, 0.1);
  border: 1px solid transparent;
  &:hover,
  &:focus {
    border: 1px solid rgba(70, 74, 201, 0.8) !important;
    box-shadow: 0px 6px 26px rgba(158, 158, 158, 0.1) !important;
    border-radius: 4px;
  }
  .ant-picker-input > input {
    font-size: 12px;
    color: var(--color-cool-grey);
  }
`;
