export const ICON_PATHS = [
  '/static/activityIcons/4-leaves-blue.svg',
  '/static/activityIcons/4-leaves-green.svg',
  '/static/activityIcons/4-leaves-orange.svg',
  '/static/activityIcons/4-leaves-purple.svg',
  '/static/activityIcons/4-leaves-salmon.svg',
  '/static/activityIcons/4-leaves-yellow.svg',
  '/static/activityIcons/circle-point-blue.svg',
  '/static/activityIcons/circle-point-green.svg',
  '/static/activityIcons/circle-point-orange.svg',
  '/static/activityIcons/circle-point-purple.svg',
  '/static/activityIcons/circle-point-salmon.svg',
  '/static/activityIcons/circle-point-yellow.svg',
  '/static/activityIcons/circle-spin-blue.svg',
  '/static/activityIcons/circle-spin-green.svg',
  '/static/activityIcons/circle-spin-orange.svg',
  '/static/activityIcons/circle-spin-purple.svg',
  '/static/activityIcons/circle-spin-salmon.svg',
  '/static/activityIcons/circle-spin-yellow.svg',
  '/static/activityIcons/flower-bell-blue.svg',
  '/static/activityIcons/flower-bell-green.svg',
  '/static/activityIcons/flower-bell-orange.svg',
  '/static/activityIcons/flower-bell-purple.svg',
  '/static/activityIcons/flower-bell-salmon.svg',
  '/static/activityIcons/flower-bell-yellow.svg',
  '/static/activityIcons/flower-spin-blue.svg',
  '/static/activityIcons/flower-spin-green.svg',
  '/static/activityIcons/flower-spin-orange.svg',
  '/static/activityIcons/flower-spin-purple.svg',
  '/static/activityIcons/flower-spin-salmon.svg',
  '/static/activityIcons/flower-spin-yellow.svg',
  '/static/activityIcons/flower-tulip-blue.svg',
  '/static/activityIcons/flower-tulip-green.svg',
  '/static/activityIcons/flower-tulip-orange.svg',
  '/static/activityIcons/flower-tulip-purple.svg',
  '/static/activityIcons/flower-tulip-salmon.svg',
  '/static/activityIcons/flower-tulip-yellow.svg',
  '/static/activityIcons/lightening-blue.svg',
  '/static/activityIcons/lightening-green.svg',
  '/static/activityIcons/lightening-orange.svg',
  '/static/activityIcons/lightening-purple.svg',
  '/static/activityIcons/lightening-salmon.svg',
  '/static/activityIcons/lightening-yellow.svg',
  '/static/activityIcons/star-cosmos-blue.svg',
  '/static/activityIcons/star-cosmos-green.svg',
  '/static/activityIcons/star-cosmos-orange.svg',
  '/static/activityIcons/star-cosmos-purple.svg',
  '/static/activityIcons/star-cosmos-salmon.svg',
  '/static/activityIcons/star-cosmos-yellow.svg',
  '/static/activityIcons/star-shape-blue.svg',
  '/static/activityIcons/star-shape-green.svg',
  '/static/activityIcons/star-shape-orange.svg',
  '/static/activityIcons/star-shape-purple.svg',
  '/static/activityIcons/star-shape-salmon.svg',
  '/static/activityIcons/star-shape-yellow.svg',
  '/static/activityIcons/star-sparkle-blue.svg',
  '/static/activityIcons/star-sparkle-green.svg',
  '/static/activityIcons/star-sparkle-orange.svg',
  '/static/activityIcons/star-sparkle-purple.svg',
  '/static/activityIcons/star-sparkle-salmon.svg',
  '/static/activityIcons/star-sparkle-yellow.svg',
];
