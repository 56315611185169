import styled from 'styled-components';
import React from 'react';
type Props = {
  text?: string;
  position?: 'row' | 'column';
  textStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  secondText?: string;
};
const StyledContainer = styled.div<{ position: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => props.position};
  align-items: center;
  justify-content: center;
`;
const StyledText = styled.div<{ position: string }>`
  max-width: 274px;
  margin-top: ${(props) => (props.position === 'column' ? '24px' : '0')};
  color: var(--color-stone);
  margin-left: ${(props) => (props.position === 'row' ? '16px' : '')};
  text-align: center;
`;
const EmptyGhost = ({ text, position = 'column', textStyle, containerStyle, secondText }: Props): JSX.Element => {
  return (
    <StyledContainer position={position} style={containerStyle}>
      <img width="120px" src="/static/dashboard/empty_ghost.svg" />
      <StyledText position={position} style={textStyle}>
        <div>{text}</div>
        {secondText && <div>{secondText}</div>}
      </StyledText>
    </StyledContainer>
  );
};
export default EmptyGhost;
