import React, { createContext, useState } from 'react';

interface TemplateRoadmapIdContextType {
  templateRoadmapId: string | null;
  setTemplateRoadmapId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const TemplateRoadmapIdContext = createContext<TemplateRoadmapIdContextType>({
  templateRoadmapId: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTemplateRoadmapId: () => {},
});

export const TemplateRoadmapIdProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [templateRoadmapId, setTemplateRoadmapId] = useState<string | null>(null);

  return (
    <TemplateRoadmapIdContext.Provider value={{ templateRoadmapId, setTemplateRoadmapId }}>
      {children}
    </TemplateRoadmapIdContext.Provider>
  );
};
