import styled from 'styled-components';
import { ExpandMore } from '@styled-icons/material/ExpandMore';
import { theme } from 'src/web-shared-components/theme';
import { motion } from 'framer-motion';

export const MoreLessIcon = styled(ExpandMore)<{ $open?: boolean }>`
  width: 18px;
  height: 18px;
  transform: rotate(${(props) => (props.$open ? '180deg' : '0deg')});
  transition: 0.4s;
  color: ${theme.common.darkNavy};
`;

export const DropdownButton = styled(motion.div)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 8px 16px 8px 20px;
  gap: 10px;
  height: 40px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'none')};

  align-items: center;

  background: #ffffff;
  border-radius: 32px;

  > :first-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: ${theme.common.darkNavy};
  }

  border: 1px solid #e3e7ed;
  transition: border-color 0.4s;

  &:focus {
    border-color: ${theme.common.indigo};
  }
  &:focus-within {
    border-color: ${theme.common.indigo};
  }
  &:hover {
    border-color: ${(props) => (props.disabled ? 'none' : theme.common.indigo)};
  }
`;
