import {
  ROCKET_FILLED_ICON,
  ROCKET_OUTLINE_ICON,
  STAR_FILLED_ICON,
  STAR_FILLED_YELLOW_ICON,
  STAR_OUTLINE_ICON,
} from '../../constants';

import { StarContainer } from './style';

type StarType = 'star' | 'starYellow' | 'rocket';

type RatingProps = {
  rating: number;
  total?: number;
  starSize?: number;
  starType?: StarType;
  style?: React.CSSProperties;
};

const ICON_MAPPING: Record<StarType, { filled: string; outline: string }> = {
  rocket: { filled: ROCKET_FILLED_ICON, outline: ROCKET_OUTLINE_ICON },
  star: { filled: STAR_FILLED_ICON, outline: STAR_OUTLINE_ICON },
  starYellow: { filled: STAR_FILLED_YELLOW_ICON, outline: STAR_OUTLINE_ICON },
};

const Rating = ({ rating, total = 5, starSize = 17, starType = 'star', style }: RatingProps): JSX.Element => {
  if (total <= 0) {
    return <></>;
  }

  return (
    <StarContainer style={style}>
      {new Array(total).fill(0).map((_, idx) => {
        const isFilled = idx < rating;
        return (
          <img
            width={starSize}
            height={starSize}
            key={`rating_star_${idx}`}
            src={isFilled ? ICON_MAPPING[starType].filled : ICON_MAPPING[starType].outline}
            alt={'rating'}
          />
        );
      })}
    </StarContainer>
  );
};

export default Rating;
