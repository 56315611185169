import { ItemStatus } from './types';

export function getCategoryIconSrc(title: string): string {
  switch (title) {
    case 'Academic':
    case 'Academics':
      return '/static/dashboard/academics.svg';

    case 'ECL Activities':
      return '/static/subCategory/extracurriculars.png';

    case 'Testing':
      return '/static/dashboard/testing.svg';

    case 'Others':
    default:
      return '/static/subCategory/readings.svg';
  }
}

export function getCheckIconSrc(status: ItemStatus): string {
  switch (status) {
    case 'GREEN':
      return '/static/rateMyProfile/greenFlag.svg';
    case 'YELLOW':
      return '/static/rateMyProfile/yellowFlag.svg';
    case 'RED':
    default:
      return '/static/rateMyProfile/redFlagV2.svg';
  }
}
