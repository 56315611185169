import styled from 'styled-components';
import { DatePicker } from 'antd';
export const StyledDatePicker = styled(DatePicker)`
  padding: 0px !important;
  cursor: pointer;
  .ant-picker-suffix {
    height: auto;
  }
  .ant-picker-input > input::placeholder {
    color: #a9acc0;
  }
`;
export const StyledErrorMessage = styled.div`
  font-size: 10px;
  color: #dc5758;
`;
