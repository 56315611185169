import { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { HelpOutline } from '@styled-icons/material-outlined';
import {
  StyledSelectionButton,
  StyledItem,
  StyledItemContentContainer,
  StyledButtonGroup,
  StyledItemTitle,
  StyledItemTitleIcon,
  StyledItemSubTitle,
} from './style';
import { SelectionType } from '../types';
// import { InputField } from 'src/components/TableEditableComponents';
import { StudentInitInfoContext } from 'src/context/StudentInitInfoContext';
import { GradeLevelType } from 'src/__generated__/graphqlTypes';
import { RecommendationIcon } from '../common/RecommendationIcon';

const limitNum = {
  summerProgram: 1,
  testing: null,
};

const generateArrayByStartAndEnd = (start: number, end: number) => {
  const array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
};

const RecommendationCard = ({
  disabled: disabledProp = false,
  recommendation,
  selection,
  onChangeSelection,
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recommendation: any;
  selection: SelectionType | undefined;
  onChangeSelection: (selection: SelectionType) => void;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [localRec, setLocalRec] = useState<any>(recommendation);
  const [localSelection, setLocalSelection] = useState<SelectionType | undefined>(selection);
  //targetScore starts
  // const [inputValue, setInputValue] = useState('1500'); //TODO:
  //targetScore ends
  useEffect(() => {
    setLocalRec(recommendation);
  }, [recommendation]);
  useEffect(() => {
    setLocalSelection(selection);
  }, [selection]);
  const { id, category } = localRec;
  const { studentInitInfo } = useContext(StudentInitInfoContext);
  const schoolYears =
    category === 'experience' ||
    category === 'testing' ||
    category === 'eclActivity' ||
    category === 'reading' ||
    category === 'researchPublication'
      ? generateArrayByStartAndEnd(
          studentInitInfo?.currentGradeLevel as number,
          studentInitInfo?.finalGradeLevel as number,
        )
      : generateArrayByStartAndEnd(
          Math.max(localRec.ageGroupMin, studentInitInfo?.currentGradeLevel as number),
          Math.min(localRec.ageGroupMax, studentInitInfo?.finalGradeLevel as number),
        );

  let disabled = disabledProp;
  if (
    limitNum[category as keyof typeof limitNum] &&
    limitNum[category as keyof typeof limitNum] === localSelection?.[category]?.[id]?.schoolYears?.length
  ) {
    // Override `disabled` prop if the category limit has been reached
    disabled = true;
  }

  const toggle = (schoolYear: number, checked: boolean, title: string) => {
    const currentSelection = { ...localSelection };
    if (!localSelection?.[category]) {
      currentSelection[category] = {
        [id]: {
          id,
          schoolYears: [schoolYear],
          title,
        },
      };
      setLocalSelection(currentSelection);
      return onChangeSelection(currentSelection);
    }
    if (!selection?.[category][id]) {
      currentSelection[category][id] = {
        id,
        schoolYears: [schoolYear],
        title,
      };
      setLocalSelection(currentSelection);
      return onChangeSelection(currentSelection);
    }
    let newSchoolYears = null;
    const currentSchoolYears = localSelection[category][id].schoolYears;
    const ifExistIndex = currentSchoolYears.indexOf(schoolYear);
    if (checked) {
      if (disabled) {
        return;
      } else {
        newSchoolYears = currentSchoolYears?.concat([schoolYear]);
      }
    } else {
      currentSchoolYears?.splice(ifExistIndex, 1);
      newSchoolYears = currentSchoolYears;
    }
    currentSelection[category][id].schoolYears = newSchoolYears;
    setLocalSelection(currentSelection);
    return onChangeSelection(currentSelection);
  };
  const itemChecked = selection?.[category]?.[id] !== undefined && selection?.[category]?.[id].schoolYears.length > 0;
  return (
    <StyledItem key={id} checked={itemChecked}>
      <StyledItemContentContainer>
        <div style={{ marginRight: 8 }}>
          <div style={{ marginTop: 4 }}>
            <RecommendationIcon id={id} category={category} title={localRec.title ?? localRec.name} size={24} />
          </div>
        </div>
        <div>
          <StyledItemTitle>
            {category === 'testing' || category === 'eclActivity' ? localRec.name : localRec.title}
            {localRec.description && (
              <>
                {' '}
                <Tooltip title={localRec.description}>
                  <StyledItemTitleIcon>
                    <HelpOutline height="1em" width="1em" />
                  </StyledItemTitleIcon>
                </Tooltip>
              </>
            )}
          </StyledItemTitle>
          <StyledItemSubTitle>
            {category === 'researchPublication'
              ? localRec.question
              : category === 'reading'
              ? localRec.author
              : category === 'summerProgram'
              ? localRec.organizer
              : category === 'eclActivity'
              ? [localRec.activityType, localRec.position].filter(Boolean).join(' • ')
              : ''}
          </StyledItemSubTitle>

          {/* {category === 'testing' && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '12px', marginRight: '8px', color: 'var(--color-cool-grey)' }}>
                Target Score
              </span>
              <InputField value={inputValue} onChangeProps={(value) => setInputValue(value)} />
            </div>
          )} */}
        </div>
      </StyledItemContentContainer>
      <StyledButtonGroup maxWidth={category === 'testing' ? 310 : 232}>
        {schoolYears.map((schoolYear) => {
          const checked = selection?.[category]?.[id]?.schoolYears?.includes(schoolYear) === true;
          return (
            <StyledSelectionButton
              key={schoolYear}
              checked={checked}
              disabled={!checked && disabled}
              onClick={() => {
                if (!disabled) {
                  toggle(
                    schoolYear,
                    !checked,
                    category === 'testing' || category === 'eclActivity' ? localRec.name : localRec.title,
                  );
                }
              }}
            >
              {studentInitInfo?.gradeLevelType === GradeLevelType.Grade ? 'Grade' : 'Year'}
              {` `}
              {schoolYear}
            </StyledSelectionButton>
          );
        })}
      </StyledButtonGroup>
    </StyledItem>
  );
};

export default RecommendationCard;
