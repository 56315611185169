import React, { useContext } from 'react';
import NavBar from 'src/components/NavBar';
import styled from 'styled-components';
import { useLocation, useSearchParams } from 'react-router-dom';
import theme from 'src/web-shared-components/theme';
import { useIsStrategist } from 'src/hooks/useMissionHook/useIsStaffHook';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import Documents from 'src/pages/Profile/Documents';
import MyCrimsonTeam from 'src/pages/Profile/MyCrimsonTeam';
import SsaNavCard from '../SsaNavCard';
import { useFeatureFlag } from 'src/featureSwitches';
import Guardians from '../Guardians';
import MockTestCard from 'src/pages/Testing/MockTestCard';
import SsaTrackingPad from '../SsaTrackingPad';
import OnboardingCheckerPad from '../OnboardingCheckerPad';
import { StrategyRecommenderNavCard } from '../StrategyRecommender/StrategyRecommenderNavCard';
import { RateMyProfile } from 'src/pages/Profile/RateMyProfile';
import { TestOptOut } from 'src/pages/Testing/TestOptOut';

const StyledContainer = styled.div`
  display: flex;
  background: #f3f6fa;
  gap: 24px;
  padding: 32px 40px;
  overflow: auto;
  min-height: 100vh;
  @media (max-width: ${theme.breakPoints.sm}) {
    gap: 17px;
  }
`;
const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 315px;
  width: 24.25%;
  @media (max-width: ${theme.breakPoints.sm}) {
    min-width: 315px;
  }
`;
const StyledPage = styled.div`
  flex: 1;
  width: 75.1%;
  @media (max-width: ${theme.breakPoints.sm}) {
    min-width: 864px;
  }
`;

const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { pathname } = useLocation();
  const GUARDIAN_ROLE = useFeatureFlag('GUARDIAN_ROLE');
  const SSA_SC_INTEGRATION = useFeatureFlag('SSA_SC_INTEGRATION');
  const ONBOARDING_CHECKER = useFeatureFlag('ONBOARDING_CHECKER');

  const hasAiInitialization = useFeatureFlag('SC_RECOMMENDATIONS_ROADMAP_INIT');
  const hasOnDemandStrategyRecommendations = useFeatureFlag('SC_RECOMMENDATIONS_STRATEGY_ONDEMAND');
  const hasRateMyProfile = useFeatureFlag('RATE_MY_PROFILE');

  const [params] = useSearchParams();
  const { currentUser } = useContext(CurrentUserContext);
  const isCurrentUserStrategist = useIsStrategist(currentUser);

  const renderSsaTrackingPad = () => {
    if (params.get('prev') !== '/applications/algorithm') {
      return <></>;
    }
    return <SsaTrackingPad />;
  };

  const renderOnboardingCheckerPad = () => {
    if (params.get('prev') !== '/onboard') {
      return <></>;
    }
    return <OnboardingCheckerPad />;
  };

  return (
    <StyledContainer>
      <StyledLeftContainer>
        <NavBar />
        {hasAiInitialization && hasOnDemandStrategyRecommendations && isCurrentUserStrategist && (
          <StrategyRecommenderNavCard />
        )}

        {pathname.endsWith('personal-info') && (
          <>
            <Documents />
            <MyCrimsonTeam />
            {GUARDIAN_ROLE && <Guardians maxHeight={'auto'} />}
          </>
        )}
        {pathname.endsWith('testing') && (
          <>
            <TestOptOut />
            <MockTestCard />
          </>
        )}
        {SSA_SC_INTEGRATION && <SsaNavCard />}
        {SSA_SC_INTEGRATION && renderSsaTrackingPad()}
        {ONBOARDING_CHECKER && renderOnboardingCheckerPad()}
      </StyledLeftContainer>
      <StyledPage>
        {children}
        {hasRateMyProfile && <RateMyProfile />}
      </StyledPage>
    </StyledContainer>
  );
};
export default Layout;
