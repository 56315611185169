import styled from 'styled-components';
export const StyledItemContentContainer = styled.div`
  display: flex;
`;
export const StyledButtonGroup = styled.div<{ maxWidth: number }>`
  gap: 8px;
  display: flex;
  max-width: ${(props) => `${props.maxWidth}px`};
  flex-flow: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
`;
export const StyledSelectionButton = styled.div<{
  checked: boolean;
  disabled?: boolean;
}>`
  width: 72px;
  font-size: 13px;
  line-height: 14px;
  flex-shrink: 0;
  background: ${(props) =>
    props.checked ? 'var(--color-indigo)' : props.disabled ? 'var(--color-cool-mist)' : '#fff'};
  padding: 4px 0;
  text-align: center;
  border: 1px solid #464ac9;
  border-color: ${(props) => (props.disabled ? 'var(--color-cool-grey)' : 'var(--color-indigo)')};
  color: ${(props) => (props.checked ? '#fff' : props.disabled ? 'var(--color-cool-grey)' : 'var(--color-indigo)')};
  border-radius: 20px;
  &:hover {
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
    background: ${(props) => (props.disabled ? 'var(--color-cool-mist)' : 'var(--color-indigo)')};
    color: ${(props) => (props.disabled ? 'var(--color-cool-grey)' : '#fff')};
  }
`;

export const StyledItem = styled.div<{ checked: boolean }>`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  border-color: ${(props) => (props.checked ? 'var(--color-indigo)' : 'var(--color-pale-grey)')};
  background: ${(props) => (props.checked ? 'var(--color-baby-blue)' : '#fff')};
  gap: 36px;
  &:hover {
    cursor: pointer;
    background: var(--color-baby-blue);
  }
`;

export const StyledItemTitle = styled.div`
  font-family: 'Montserrat-SemiBold';
`;

export const StyledItemTitleIcon = styled.div`
  align-items: center;
  color: var(--color-stone);
  display: inline-block;
  & svg {
    margin-top: -3px;
  }
  &:hover {
    color: var(--color-dark-navy);
  }
`;

export const StyledItemSubTitle = styled.div`
  color: var(--color-stone);
  font-size: 12px;
`;

export const StyledCollapseButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: var(--color-mist);
  border-radius: 50%;
  svg {
    color: var(--color-indigo);
  }
`;

export const StyledHelpersContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const StyledHelperText = styled.span`
  color: var(--color-stone);
  font-size: 12px;
`;

export const StyledHelperTextLink = styled(StyledHelperText)<{ pending?: boolean }>`
  color: var(--color-indigo);
  cursor: pointer;
  opacity: ${({ pending }) => (pending ? 0.6 : 1.0)};
  text-decoration: underline;
  transition: 0.3s all;
`;

export const StyledHelperTooltipText = styled(StyledHelperText)<{ pending?: boolean }>`
  cursor: pointer;
  opacity: ${({ pending }) => (pending ? 0.6 : 1.0)};
  &:hover {
    text-decoration: underline;
  }
`;
