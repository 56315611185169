import { AccessDeniedPanel, Info } from './style';

export const AccessDenied = (): JSX.Element => {
  return (
    <AccessDeniedPanel>
      <div>
        <img src="/static/access_denied.png" />
      </div>
      <Info>{"Oops! You don't have access to view this student's profile"}</Info>
    </AccessDeniedPanel>
  );
};
