import { Select } from 'antd';
import styled from 'styled-components';
import DebounceFetcherSelect from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/DebounceSelect';
import { Search } from '@styled-icons/material';

export const StyledSelectSearch = styled(Select)`
  width: 88%;
  .ant-select-selector {
    padding-left: 0 !important;
  }
`;
export const StyledSelectContainer = styled(DebounceFetcherSelect)`
  width: 88%;
  .ant-select-selector {
    padding-left: 0 !important;
  }
  .ant-select-selection-search {
    left: 0 !important;
  }
`;
export const StyledSelectResult = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
export const StyledSelectPlaceholder = styled.span<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: #a9acc0;
`;
export const StyledSearchIcon = styled(Search)`
  width: 18px !important;
`;
