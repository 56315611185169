import * as zoid from 'zoid/dist/zoid.frameworks';
import * as Logger from '@crimson-education/browser-logger';
import { ApolloProvider } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Main from './main';
import { ApolloClientContext } from './context/ApolloClientContext';
import { getApiClients, getSimpleClient } from './graphql';
import { QUERY_USER_INFO } from './graphql/User';
import config from './config';
import './App.less';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import './utils/extendDayjs';
import { FeatureToggle, useIdentifyUser, FeatureSwitchEnhancer } from 'src/featureSwitches';
import { updateErrorStorage } from './graphql/index.util';
import { RoadmapIdProvider } from './context/RoadmapIdContext';

TimeAgo.addDefaultLocale(en);

zoid.create({
  // The html tag used to render my component
  tag: 'student-center-component',
  // The url that will be loaded in the iframe or popup, when someone includes my component on their page
  url: config.domain,

  props: {
    token: {
      type: 'string',
      isRequired: true,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: false,
    },
    refreshAccessTokens: {
      type: 'function',
      isRequired: false,
    },
    getBearer: {
      type: 'function',
      isRequired: false,
    },
  },
});
const isLocalhost = window.location.hostname === 'localhost';

try {
  Logger.init({
    service: 'student-center-frontend',
    environment: config.environment,
    version: config.version,
    defaultMetadata: {
      application: 'core-student-center',
    },
    reporters: {
      log: true,
      datadog: config.datadogApplicationId
        ? {
            applicationId: config.datadogApplicationId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            clientToken: config.datadogClientToken!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            site: config.datadogSite!,
            proxyUrl: 'https://proxy.crimsoneducation.org/ddog',
            sampleRate: config.environment === 'production' ? 50 : 0,
            replaySampleRate: config.environment === 'production' ? 50 : 0,
            useSecureSessionCookie: !isLocalhost,
            useCrossSiteSessionCookie: !isLocalhost,
            trackFrustrations: true,
            trackInteractions: true,
            forwardConsoleLogs: true,
            trackUserInteractions: true,
            logTransport: config.environment === 'production',
            allowedTracingOrigins: [config.studentCenterAPIUrl, config.crimsonAppAPIUrl, config.roadmapApiClient],
          }
        : undefined,

      amplify:
        !isLocalhost && config.pinpointAnalyticsAppId
          ? {
              region: config.awsRegion,
              identityPoolId: config.pinpointIdentityPoolId,
              analyticsAppId: config.pinpointAnalyticsAppId,
              proxyUrl: config.pinpointProxyUrl,
              autoTrackPageViews: true,
              autoTrackEvents: true,
              autoTrackSessions: true,
            }
          : undefined,
    },
  });
} catch (e) {
  console.warn('Logger init error', e);
}

if (!isLocalhost && config.pinpointAnalyticsAppId) {
  console.debug('StudentCenter pinpoint enabled');
}

if (config.datadogApplicationId) {
  console.debug('StudentCenter Datadog enabled');
}

type onMessageProps = {
  event: string;
  data: string;
};

type AppProps = {
  onMessage?: (props: onMessageProps) => void;
  registerEventEmitter?: (eventName: string, callback: (data: string) => void) => void;
  unregisterEventEmitter?: (eventName: string) => void;
} & JSX.IntrinsicAttributes;

const App: FC<AppProps> = (props: AppProps) => {
  const { onMessage, registerEventEmitter, unregisterEventEmitter } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>(window.xprops?.userId);
  const [loginUserId] = useState<string>(window.xprops?.loggedInUser?.userId);
  const identifyUser = useIdentifyUser();
  useEffect(() => {
    if (loginUserId) {
      identifyUser(loginUserId);
    }
  }, [identifyUser, loginUserId]);
  // To open SC in separate tab, need to create xprops if does not exists
  useEffect(() => {
    if (window.xprops) return;
    const { userId: _userId, token: _token } = queryString.parse(window.location.search);

    if (!_token) {
      return;
    }
    const userId = _userId ? (_userId as string) : '';
    const token = _token ? (_token as string) : '';
    const decoded = jwtDecode<JwtPayload>(token);

    const getLoggedInUserInfo = async () => {
      const client = getSimpleClient(new URL('/graphql', config.crimsonAppAPIUrl).href, token);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await client.query<any>({
        query: QUERY_USER_INFO,
        variables: { userId: decoded.sub?.replace('|', '-') },
      });
      return res;
    };

    getLoggedInUserInfo().then((res) => {
      const { data } = res;
      const { user: loggedInUser } = data;
      let domain = window.location.hostname;
      if (domain && domain?.includes('localhost')) {
        domain = 'http://localhost:3000';
      } else if (domain && domain?.includes('staging')) {
        domain = 'https://staging.app.crimsoneducation.org';
      } else {
        domain = 'https://app.crimsoneducation.org';
      }
      window.xprops = {
        userId: userId,
        domain,
        loggedInUser: {
          userId: loggedInUser.userId,
          email: loggedInUser?.email,
          firstName: loggedInUser?.firstName,
          lastName: loggedInUser?.lastName,
          role: loggedInUser?.roles,
          tenant: loggedInUser?.tenant,
          title: loggedInUser?.title,
        },
        getBearer: () => Promise.resolve(token),
        download: () => Promise.resolve(true),
        authorize: async () => {
          let isAuthorized = false;
          const response = await fetch(`${config.crimsonAppAPIUrl}/authorize`, {
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            try {
              const data = await response.text();
              if (data !== 'OK') {
                throw new Error(data);
              }
            } catch (e) {
              console.log('Create xprops ERROR: ', e);
            }
            isAuthorized = true;
          }
          return isAuthorized;
        },
      };
      setUserId(userId);
      console.log('Created xprops from url: ', window.xprops);
    });
  }, []);

  useEffect(() => {
    if (window != window.top && registerEventEmitter) {
      registerEventEmitter('refresh', (url: string) => {
        // listen to the parent component refresh event,
        // then replace the url into inner url
        // replace users/{userId}/student-center to ''
        if (!url.includes('/staffDashboard')) {
          const regex = /\/users.*student-center/i;
          navigate(url.replace(regex, '') || '/');
        }
      });
    }
    return () => {
      unregisterEventEmitter?.('refresh');
    };
  });

  useEffect(() => {
    const url = location.pathname + location.search;
    updateErrorStorage(true);
    onMessage?.({ event: 'route', data: url });
  }, [location.pathname, location.search, onMessage]);

  if (!userId) {
    return <div>Please input the correct user id</div>;
  }
  const { storyBlokApiClient, studentCenterApiClient, crimsonAppApiClient, roadmapApiClient, pathfinderApiClient } =
    getApiClients(window.xprops);
  return (
    <ApolloClientContext.Provider
      value={{
        studentCenterApiClient,
        crimsonAppApiClient,
        roadmapApiClient,
        storyBlokApiClient,
        pathfinderApiClient,
        onMessage: onMessage,
      }}
    >
      <ApolloProvider client={studentCenterApiClient}>
        <RoadmapIdProvider>
          <FeatureToggle />
          <Main userId={userId as string} />
        </RoadmapIdProvider>
      </ApolloProvider>
    </ApolloClientContext.Provider>
  );
};

type TypeEnhancer = <P extends JSX.IntrinsicAttributes>(Comp: React.ComponentType<P>) => React.ComponentType<P>;
export default (FeatureSwitchEnhancer as TypeEnhancer)(App);
