import React, { createContext, useState } from 'react';

interface RoadmapIdContextType {
  roadmapId: string | null;
  setRoadmapId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const RoadmapIdContext = createContext<RoadmapIdContextType>({
  roadmapId: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRoadmapId: () => {},
});

export const RoadmapIdProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [roadmapId, setRoadmapId] = useState<string | null>(null);

  return <RoadmapIdContext.Provider value={{ roadmapId, setRoadmapId }}>{children}</RoadmapIdContext.Provider>;
};
