import styled from 'styled-components';
import { ChevronLeft } from '@styled-icons/material';
import { Button } from 'antd';
import { Close } from '@styled-icons/material';

export const TopBar = styled.div`
  display: flex;
  justify-content: center;
`;
export const ContentContainer = styled.div<{ padding?: string }>`
  padding: ${(props) => (props?.padding ? `${props.padding}` : 0)};
`;
export const Title = styled.div`
  text-align: center;
  margin: 30px 0 25px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-dark-navy);
`;
export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 25px;
  text-align: center;
`;
export const PreviousButton = styled(ChevronLeft)`
  width: 22px;
  margin-left: -6px;
  color: var(--color-cool-grey);
  cursor: pointer;
`;
export const CloseButton = styled(Close)`
  cursor: pointer;
  width: 18px;
  margin-right: -2px;
  color: var(--color-cool-grey);
`;
export const Steps = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
export const StepPoint = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => (props?.active ? 'var(--color-indigo)' : 'var(--color-pale-grey)')};
  margin-right: 28px;
  &:nth-child(5) {
    margin-right: 0;
  }
`;
export const TwoColumnButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: 512px;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SkipButton = styled.div`
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  color: var(--color-indigo);
`;
export const NextButton = styled(Button)`
  width: 250px;
  height: 40px;
  border-radius: 28px;
`;

export const CancelButton = styled(Button)`
  width: 250px;
  border: 1px solid var(--color-indigo);
  color: var(--color-indigo);
  height: 40px;
  border-radius: 28px;
`;
