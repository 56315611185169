import { FunctionComponent, MouseEventHandler, useContext, useMemo, useState } from 'react';
import * as Styled from './styles';
import CRAvatar from '../Avatar';
import { Button, Input } from 'antd';
import { Send } from '@styled-icons/material/Send';
import { CR_COLORS } from 'src/theme';
import { TReflection } from './types';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { useMutation } from '@apollo/client';
import { CREATE_REFLECTION, UPDATE_REFLECTION } from 'src/graphql/roadmap/Reflections';
import { LoginUserContext } from 'src/context/LoginUserContext';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

type InputMode = 'edit' | 'new';
interface ReflectionInputPros {
  reflection?: TReflection;
  missionId: string;
  onInputSaved?: (reflection: TReflection) => void;
}

const ReflectionInput: FunctionComponent<ReflectionInputPros> = ({ reflection, missionId, onInputSaved }) => {
  const { firstName, lastName, profileImageUrl, userId } = useContext(LoginUserContext);
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const [createReflection] = useMutation(CREATE_REFLECTION, { client: roadmapApiClient });
  const [updateReflection] = useMutation(UPDATE_REFLECTION, { client: roadmapApiClient });

  const [description, setDescription] = useState(reflection?.description || '');
  const canSubmit = useMemo(() => !!description, [description]);
  const [submitting, setSubmitting] = useState(false);
  const mode: InputMode = useMemo(() => (!!reflection ? 'edit' : 'new'), [reflection]);
  const submit: MouseEventHandler = async (e) => {
    e.stopPropagation();
    const input: Partial<TReflection> = { description: description.trim(), missionId };
    if (description) {
      setSubmitting(true);
      const { data, errors } = reflection?.id
        ? await updateReflection({ variables: { reflectionId: reflection.id, input } })
        : await createReflection({ variables: { input } });
      setSubmitting(false);
      if (errors?.length) return;
      setDescription('');
      !!onInputSaved &&
        onInputSaved({
          ...data.reflection,
          createdAt: new Date(data.reflection.createdAt),
          updatedAt: new Date(data.reflection.updatedAt),
          creator: {
            firstName,
            lastName,
            userId,
            profileImageUrl,
          },
        });
    }
  };

  if (!userId) {
    return <></>;
  }
  return (
    <Styled.NoteInputContainer>
      {mode === 'new' ? (
        <CRAvatar
          key={userId}
          image={profileImageUrl || undefined}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          size={24}
        />
      ) : (
        <div style={{ width: 30 }}></div> // Spacer
      )}
      <>
        <Input.TextArea
          value={description}
          disabled={submitting}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Add a reflection"
          autoSize
        />
        <Button disabled={!canSubmit || submitting || !description.trim()} onClick={submit} shape="circle">
          {submitting ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <Send
              height={24}
              color={CR_COLORS.LIGHT_PURPLE}
              opacity={canSubmit && !submitting && description.trim() ? 1 : 0.4}
            />
          )}
        </Button>
      </>
    </Styled.NoteInputContainer>
  );
};

export default ReflectionInput;
