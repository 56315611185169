// Polyfill PDF.js usage of Array#at
import 'array.prototype.at/polyfill';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

ReactDOM.render(
  <Router>
    <App {...window.xprops} />
  </Router>,
  document.getElementById('root'),
);
