import { StudentTest, TestTypeType } from 'src/__generated__/graphqlTypes';
import { Title, TestHeader, TestIcon } from './style';
import TestMissionItem from './TestMissionItem';
import { TestTypeTypeOptions } from '../TestingModal/constant';
import css from './index.module.less';
import { useEffect } from 'react';
import { useTestingSuperScore } from 'src/context/ZustandStore';

export const TestIconMapping = {
  [TestTypeType.Act]: '/static/testing/ACT.png',
  [TestTypeType.Bmat]: '/static/testing/BMAT.png',
  [TestTypeType.Duolingo]: '/static/testing/Duolingo.png',
  [TestTypeType.Gamsat]: '/static/testing/GAMSAT.png',
  [TestTypeType.Gmat]: '/static/testing/GMAT.png',
  [TestTypeType.Gre]: '/static/testing/GRE.png',
  [TestTypeType.Ielts]: '/static/testing/IELTS.png',
  [TestTypeType.Isee]: '/static/testing/ISEE.png',
  [TestTypeType.Lnat]: '/static/testing/LNAT.png',
  [TestTypeType.Lsat]: '/static/testing/LSAT.png',
  [TestTypeType.Mcat]: '/static/testing/MCAT.png',
  [TestTypeType.Pte]: '/static/testing/PTE.png',
  [TestTypeType.Sat]: '/static/testing/SAT.png',
  [TestTypeType.Ssat]: '/static/testing/SSAT.png',
  [TestTypeType.Toefl]: '/static/testing/TOEFL.png',
  [TestTypeType.Tsa]: '/static/testing/TSA.png',
  [TestTypeType.Ucat]: '/static/testing/UCAT.png',
  [TestTypeType.Mat]: '/static/testing/MAT.png',
  [TestTypeType.Hpat]: '/static/testing/HPAT.png',
  [TestTypeType.Step]: '/static/testing/STEP.png',
  [TestTypeType.Nsaa]: '/static/testing/NSAA.png',
  [TestTypeType.Engaa]: '/static/testing/ENGAA.png',
  [TestTypeType.Tmua]: '/static/testing/TMUA.png',
  [TestTypeType.Pat]: '',
  [TestTypeType.Elat]: '',
  [TestTypeType.Hat]: '',
  [TestTypeType.Cat]: '',
  [TestTypeType.Olat]: '',
  [TestTypeType.Mlat]: '',
  [TestTypeType.Phil]: '',
  [TestTypeType.Imat]: '',
  [TestTypeType.Bocconi]: '',
  [TestTypeType.Tumat]: '',
};

enum TESTING_WITH_SUPER_SCORE {
  ACT = 'ACT',
  SAT = 'SAT',
}

const TestMissionGroup = ({
  reload,
  type,
  list,
  category,
  editPermitted,
  deletePermitted,
  isDisplayAtReport,
}: {
  reload: () => void;
  type: string;
  list: StudentTest[];
  category: string;
  editPermitted: boolean;
  deletePermitted: boolean;
  isDisplayAtReport?: boolean | null;
}): JSX.Element => {
  const categorySplitted = category.split(' ');
  const titleSuffix = categorySplitted[categorySplitted.length - 1].replace(/s$/, '');
  const [key, official] = type.split(' ');
  const { label } = TestTypeTypeOptions.find((x) => x.value === key) || { label: key };
  let SUPER_SCORE_VIEW: React.ReactNode = null;
  const { updateSuperScoreForAST, updateSuperScoreForACT } = useTestingSuperScore();
  let superScoreForACT = -1;
  if (key === TESTING_WITH_SUPER_SCORE.ACT && official === 'Official') {
    const englishScoreList = list.map((testing) => {
      const score = Number(testing.testResult?.find((item) => item?.key === 'English')?.value || '-1');
      return score;
    });
    const englishSuperScore = Math.max(...englishScoreList);

    const mathScoreList = list.map((testing) => {
      const score = Number(testing.testResult?.find((item) => item?.key === 'Math')?.value || '-1');
      return score;
    });
    const mathSuperScore = Math.max(...mathScoreList);

    const readingScoreList = list.map((testing) => {
      const score = Number(testing.testResult?.find((item) => item?.key === 'Reading')?.value || '-1');
      return score;
    });
    const readingSuperScore = Math.max(...readingScoreList);

    const scienceScoreList = list.map((testing) => {
      const score = Number(testing.testResult?.find((item) => item?.key === 'Science')?.value || '-1');
      return score;
    });
    const scienceSuperScore = Math.max(...scienceScoreList);

    const compositeScore = Math.round((englishSuperScore + mathSuperScore + readingSuperScore + scienceSuperScore) / 4);

    const superScoreVisible =
      englishScoreList.filter((score) => score !== -1).length >= 1 &&
      mathScoreList.filter((score) => score !== -1).length >= 1 &&
      readingScoreList.filter((score) => score !== -1).length >= 1 &&
      scienceScoreList.filter((score) => score !== -1).length >= 1;

    if (superScoreVisible) {
      superScoreForACT = Math.round((englishSuperScore + mathSuperScore + readingSuperScore + scienceSuperScore) / 4);
    }

    SUPER_SCORE_VIEW = superScoreVisible ? (
      <div className={css.superScoreSection}>
        <div className={css.header}>
          <div className={css.column}>Type</div>
          <div className={css.column}>English</div>
          <div className={css.column}>Math</div>
          <div className={css.column}>Reading</div>
          <div className={css.column}>Science</div>
          <div className={css.column}>Composite</div>
        </div>
        <div className={css.body}>
          <div className={css.column}>Superscore</div>
          <div className={css.column}>{englishSuperScore}</div>
          <div className={css.column}>{mathSuperScore}</div>
          <div className={css.column}>{readingSuperScore}</div>
          <div className={css.column}>{scienceSuperScore}</div>
          <div className={css.column}>{compositeScore}</div>
        </div>
      </div>
    ) : null;
  }
  let superScoreForSAT = -1;
  if (key === TESTING_WITH_SUPER_SCORE.SAT && official === 'Official') {
    const section1ScoreList = list.map((testing) => {
      const score = Number(
        testing.testResult?.find((item) => item?.key === 'Evidence-based reading and writing')?.value || '-1',
      );
      return score;
    });
    const section1SuperScore = Math.max(...section1ScoreList);

    const mathScoreList = list.map((testing) => {
      const score = Number(testing.testResult?.find((item) => item?.key === 'Math')?.value || '-1');
      return score;
    });
    const mathSuperScore = Math.max(...mathScoreList);

    const superScoreVisible =
      section1ScoreList.filter((score) => score !== -1).length >= 1 &&
      mathScoreList.filter((score) => score !== -1).length >= 1;

    if (superScoreVisible) {
      superScoreForSAT = section1SuperScore + mathSuperScore;
    }

    const compositeScore = section1SuperScore + mathSuperScore;

    SUPER_SCORE_VIEW = superScoreVisible ? (
      <div className={css.superScoreSection}>
        <div className={css.header}>
          <div className={css.column}>Type</div>
          <div className={`${css.longColumn}`}>Evidence-based reading and writing</div>
          <div className={css.column}>Math</div>
          <div className={css.column}>Composite</div>
        </div>
        <div className={css.body}>
          <div className={css.column}>Superscore</div>
          <div className={`${css.longColumn}`}>{section1SuperScore}</div>
          <div className={css.column}>{mathSuperScore}</div>
          <div className={css.column}>{compositeScore}</div>
        </div>
      </div>
    ) : null;
  }

  useEffect(() => {
    if (superScoreForACT > -1) {
      updateSuperScoreForACT(superScoreForACT);
    }
  }, [superScoreForACT, updateSuperScoreForACT]);

  useEffect(() => {
    if (superScoreForSAT > -1) {
      updateSuperScoreForAST(superScoreForSAT);
    }
  }, [superScoreForSAT, updateSuperScoreForAST]);

  return (
    <>
      <TestHeader>
        {TestIconMapping[key as keyof typeof TestIconMapping] && (
          <TestIcon src={TestIconMapping[key as keyof typeof TestIconMapping]} />
        )}{' '}
        <Title>{label?.replace(/_/g, ' ') + ' ' + official + ' ' + titleSuffix}</Title>
      </TestHeader>
      {SUPER_SCORE_VIEW}
      {list.map((testMission) => {
        return (
          <TestMissionItem
            key={testMission.id}
            reload={reload}
            testMission={testMission}
            editPermitted={editPermitted}
            deletePermitted={deletePermitted}
            testType={key}
            isDisplayAtReport={isDisplayAtReport}
          />
        );
      })}
    </>
  );
};

export default TestMissionGroup;
