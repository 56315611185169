import { css } from 'styled-components';

export const HideScrollBar = css`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: white;
  }
  &::-webkit-scrollbar-corner {
    background: white;
  }
`;
