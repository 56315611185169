import { StyledContainer } from './style';
import CrimsonTeam from 'src/components/CrimsonTeam';

const MyCrimsonTeam = (): JSX.Element => {
  return (
    <StyledContainer>
      <CrimsonTeam maxHeight={'auto'} />
    </StyledContainer>
  );
};

export default MyCrimsonTeam;
