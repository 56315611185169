import { ICON_PATHS } from './constants';
import { useMemo } from 'react';
type RandomIconProps = {
  seed: string;
  size?: number;
};
const RandomIcon = ({ seed, size = 40 }: RandomIconProps): JSX.Element => {
  const iconPath = useMemo(() => {
    const charCode = (seed || 'a').split('').reduce((prev, curr) => prev + curr.charCodeAt(0), 0);
    const randomIndex = charCode % ICON_PATHS.length;
    return ICON_PATHS[randomIndex];
  }, [seed]);
  return <img src={iconPath} width={size} height={size} />;
};
export default RandomIcon;
