import { gql, useQuery } from '@apollo/client';
import { Suspense, lazy, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';

import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useFeatureFlag } from 'src/featureSwitches';

import { Accordion } from 'src/components/Accordion/Accordion';
import { AIDialog } from 'src/components/AIDialog';
import { StyledButtonFilled } from 'src/web-shared-components/base/Buttons';

import { RateMyProfileKey } from './components/RateMyProfileKey';

import { getCategoryIconSrc, getCheckIconSrc } from './util';
import { StudentProfileRatingData } from './types';

interface RateMyProfileProps {
  data: StudentProfileRatingData;
  isCurrentUserStudent?: boolean;
  onClose: VoidFunction;
}

// Lazy import to avoid bundling ApexCharts into the critical bundle
const RobotTrigger = lazy(() => import('./RobotTrigger').then(({ RobotTrigger }) => ({ default: RobotTrigger })));

function RateMyProfileImpl({ data, isCurrentUserStudent, onClose }: RateMyProfileProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const availableTargetCountries = Array.from(new Set(data.majors.map((it) => it.targetCountry)));
  const [visibleTargetCountry, setVisibleTargetCountry] = useState(availableTargetCountries[0]);

  const availableMajors = data.majors.filter((it) => it.targetCountry === visibleTargetCountry);

  const [majorIndex, setMajorIndex] = useState(0);
  const major = availableMajors[majorIndex];

  function onChangeVisibleTargetCountry(newTargetCountry: string) {
    setVisibleTargetCountry((visibleTargetCountry) => {
      if (newTargetCountry !== visibleTargetCountry) {
        setMajorIndex(0);
      }
      return newTargetCountry;
    });
  }

  const [collapsedCategoryIndexes, setCollapsedCategoryIndexes] = useState<number[]>([]);

  return (
    <>
      <RobotTrigger
        data={data}
        isCurrentUserStudent={isCurrentUserStudent}
        onClose={onClose}
        onTrigger={() => setIsOpen(true)}
      />

      <AIDialog title="Rate my Profile" open={isOpen} onCancel={handleClose}>
        <DialogText>
          Evaluate your university application readiness. Get your Copilot recommendations based on
          <br />
          intended majors and missions from Student Center.
        </DialogText>

        <ContentStack>
          {availableTargetCountries.length > 1 && (
            <TabsContainer>
              <StyledTabs
                items={availableTargetCountries.map((targetCountry, index) => ({
                  active: index === majorIndex,
                  key: targetCountry,
                  label: targetCountry,
                }))}
                onChange={(newTargetCountry) => onChangeVisibleTargetCountry(newTargetCountry)}
              />
            </TabsContainer>
          )}
          <TabsContainer>
            <StyledTabs
              items={availableMajors.map((major, index) => ({
                active: index === majorIndex,
                key: index.toString(),
                label: major.major,
              }))}
              onChange={(activeKey) => setMajorIndex(Number.parseInt(activeKey))}
            />

            <RateMyProfileKey />
          </TabsContainer>

          <ChecksContainer>
            {major.categories
              .filter((category) => category.items.length > 0)
              .map((category, categoryIndex) => (
                <Accordion
                  key={categoryIndex}
                  isExpanded={!collapsedCategoryIndexes.includes(categoryIndex)}
                  style={{
                    border: 'none',
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                  title={
                    <AccordionTitle>
                      <CategoryIcon src={getCategoryIconSrc(category.title)} /> {category.title}
                    </AccordionTitle>
                  }
                  onToggleExpanded={(newExpanded) =>
                    setCollapsedCategoryIndexes((collapsedCategoryIndexes) => {
                      if (newExpanded) {
                        return collapsedCategoryIndexes.filter((v) => v !== categoryIndex);
                      } else {
                        return [...collapsedCategoryIndexes, categoryIndex];
                      }
                    })
                  }
                >
                  <CheckCardStack>
                    {category.items.map((item) => (
                      <CheckCard key={item.id}>
                        <StatusIcon src={getCheckIconSrc(item.status)} />
                        <div>
                          <CheckCardTitle>{item.title}</CheckCardTitle>
                          <CheckCardDescription>{item.description}</CheckCardDescription>
                        </div>
                      </CheckCard>
                    ))}
                  </CheckCardStack>
                </Accordion>
              ))}
          </ChecksContainer>
        </ContentStack>

        <DialogActions>
          <PrimaryButton onClick={handleClose}>Got it</PrimaryButton>
        </DialogActions>
      </AIDialog>
    </>
  );
}

const GET_STUDENT_PROFILE_RATING = gql`
  query GetProfileReview($studentId: ID!) {
    studentProfileRating(studentId: $studentId) {
      id
      majors {
        major
        targetCountry
        categories {
          title
          items {
            id
            flagId
            title
            description
            status
          }
        }
      }
    }
  }
`;

export function RateMyProfile(): JSX.Element | null {
  const [isClosed, setIsClosed] = useState(false);
  const hasStaffFeatureFlag = useFeatureFlag('RATE_MY_PROFILE_STAFF');

  const { currentUser, studentInfo } = useContext(CurrentUserContext);
  const loginUserId = currentUser?.userId;
  const studentUserId = studentInfo?.userId;

  // Only allow the student to see the "Rate my Profile" UI
  const shouldFetch = Boolean(loginUserId && studentUserId && (loginUserId === studentUserId || hasStaffFeatureFlag));

  const { data: currentData, previousData } = useQuery<{ studentProfileRating: StudentProfileRatingData | null }>(
    GET_STUDENT_PROFILE_RATING,
    {
      skip: !shouldFetch,
      variables: { studentId: studentUserId },
    },
  );

  // Apollo doesn't seem to want to want to cache this data correctly, which causes the "Rate my Profile"
  // button to flicker in and out across page transitions. Manually stashing the data and using it when
  // the query returns nothing when the student ID hasn't changed resolves the issue.
  const lastTruthyData = useRef<StudentProfileRatingData | null>(null);
  if (currentData) {
    lastTruthyData.current = currentData.studentProfileRating;
  }
  let data = currentData ?? previousData;
  if (!data && lastTruthyData.current?.id === studentUserId) {
    data = { studentProfileRating: lastTruthyData.current };
  }

  if (!shouldFetch || !data || !data.studentProfileRating || isClosed) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <RateMyProfileImpl
        data={data.studentProfileRating}
        isCurrentUserStudent={studentUserId === loginUserId}
        onClose={() => setIsClosed(true)}
      />
    </Suspense>
  );
}

const AccordionTitle = styled.span`
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  line-height: 26px;
`;

const CategoryIcon = styled.img`
  height: 24px;
  user-select: none;
  width: 24px;
`;

const CheckCardStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CheckCard = styled.div`
  background: #f4f5ff;
  border: 1px solid var(--color-pale-grey);
  border-radius: 8px;
  display: flex;
  gap: 16px;
  padding: 12px 16px;
`;

const CheckCardTitle = styled.div`
  color: var(--color-dark-navy);
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  line-height: 24px;
`;

const CheckCardDescription = styled.div`
  color: var(--color-stone);
  font-family: Montserrat;
  font-size: 12px;
  line-height: 15px;
`;

const ContentStack = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
`;

const ChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
`;

const DialogActions = styled.div`
  display: flex;
  padding: 16px 0 40px;
  justify-content: center;
`;

const DialogText = styled.div`
  color: #000;

  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const TabsContainer = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
`;

const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    height: 48px;
  }
  & .ant-tabs-nav {
    margin: 0;
  }
  & .ant-tabs-tab-btn {
    font-family: Montserrat-SemiBold;
    font-size: 14px;
  }
`;

const PrimaryButton = styled(StyledButtonFilled)`
  border-radius: 28px;
  font-family: Montserrat-Bold;
  font-size: 14px;
  height: 40px;
  padding: 7px 48px;
`;

const StatusIcon = styled.img`
  height: 40px;
  width: 40px;
`;
