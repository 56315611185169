import { CRTextarea } from 'src/components/TableEditableComponents';
import Teams from 'src/components/Teams';
import ActivityLog from 'src/components/ActivityLog';
import MissionStatus from 'src/components/MissionStatus';
import RemoveButton from 'src/components/RemoveButton';
import ActionItems from 'src/components/ActionItems';
import {
  CHANGE_MISSION_TITLE,
  GET_MISSION_BY_ID,
  CHANGE_MISSION_DESCRIPTION,
  CHANGE_MISSION_STATUS,
  CHANGE_MISSION_START_DATE,
  CHANGE_MISSION_DUE_DATE,
} from 'src/graphql/Mission';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { useContext, useEffect, useState } from 'react';
import { CloseIcon } from 'src/web-shared-components/base/Icons';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import {
  Categories,
  MissionCategoryNameMapping,
  MissionDetailObjectType,
  missionStatusObject,
  MissionSubCategoryMapping,
} from 'src/types/mission';
import {
  StyledMissionHeader,
  StyledMissionTitleContainer,
  StyledLabel,
  StyledMissionHeaderRight,
  StyledMissionDesc,
  StyledTeamContainer,
  StyledCloseIcon,
  StyledRemoveButton,
  StyledCategoryContainer,
  StyledRightOutlined,
  StyledRangeDatePickerContainer,
  StyledMissionTextareaContainer,
  StyledActionItemsContainer,
  StyledMoreHoriz,
  StyledTextArea,
} from './style';
import { Drawer } from 'antd';
import LoadingIndicator from '../LoadingIndicator';
import RangeDatePicker from '../TableEditableComponents/RangeDatePicker';
import InternalNotes from '../InternalNotes';
import Reflections from '../Reflections';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useCanAccessInternalNotes } from './util';
import { CategoryOverviewCommunicationContext } from 'src/context/CategoryOverviewCommunicationContext';
import theme from 'src/web-shared-components/theme';
import { useFeatureFlag } from 'src/featureSwitches';
import { replaceDateInTitle } from 'src/utils/replaceDueDateIntitle';
import { ADD_OR_UPDATE_TEST, LOAD_TEST_BY_MISSION_ID } from 'src/graphql/Testing';

type Props = {
  visible: boolean;
  onClose: () => void;
  missionId: string;
  removeMission: () => void;
  editPermitted: boolean;
  showRemoveBtn?: boolean;
};
const MissionDrawer = ({
  visible,
  onClose,
  missionId,
  removeMission,
  editPermitted,
  showRemoveBtn = true,
}: Props): JSX.Element => {
  const { setRefetch } = useContext(CategoryOverviewCommunicationContext);
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const [missionData, setMissionData] = useState<MissionDetailObjectType>();
  const { currentUser } = useContext(CurrentUserContext);
  const canViewInternalNotes = useCanAccessInternalNotes(currentUser);
  const { refetch: refetchMission } = useQuery(GET_MISSION_BY_ID, {
    variables: { missionId },
    client: roadmapApiClient,
    skip: true,
  });
  const { refetch: refetchLoadTestByMissionId } = useQuery(LOAD_TEST_BY_MISSION_ID, {
    skip: true,
  });
  const [changeMissionTitle] = useMutation(CHANGE_MISSION_TITLE, {
    client: roadmapApiClient,
    ignoreResults: true,
  });
  const [changeMissionDescription] = useMutation(CHANGE_MISSION_DESCRIPTION, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [changeMissionStatus] = useMutation(CHANGE_MISSION_STATUS, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [changeMissionStartDate] = useMutation(CHANGE_MISSION_START_DATE, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [changeMissionEndDate] = useMutation(CHANGE_MISSION_DUE_DATE, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [addOrUpdateTest] = useMutation(ADD_OR_UPDATE_TEST);

  const [datePickerType, setDatePickerType] = useState('default');

  const REFLECTIONS = useFeatureFlag('REFLECTIONS');

  useEffect(() => {
    if (missionData?.category === Categories.TEST) {
      async function fetchData() {
        if (missionId) {
          const result = await refetchLoadTestByMissionId({ missionId });
          if (result && result.data && result.data.testRecord) {
            const testRecord = result.data.testRecord;
            const { type, isMockTest } = testRecord;
            if ((type === 'SAT' || type === 'ACT') && !isMockTest) {
              setDatePickerType('startDate');
            }
          }
        }
      }
      fetchData();
    }
  }, [missionData?.category, missionId, refetchLoadTestByMissionId]);

  useEffect(() => {
    async function fetchData() {
      if (visible) {
        if (missionId) {
          const res = await refetchMission({ missionId });
          if (res?.data && res.data.mission) {
            setMissionData(res.data.mission);
          }
        }
      }
    }
    fetchData();
  }, [missionId, refetchMission, visible]);

  const reloadMission = async () => {
    if (missionId) {
      const result = await refetchMission({ missionId });
      if (result && result.data && result.data.mission) {
        setMissionData(result.data.mission);
      }
    }
  };
  const onDateChange = async (dates: RangeValue<moment.Moment>) => {
    if (dates && dates.length > 1) {
      if (!missionData?.startAt && !missionData?.dueDate) {
        await changeMissionStartDate({
          variables: {
            input: {
              missionId,
              startAt: dates[0],
            },
          },
        });
        await changeMissionEndDate({
          variables: {
            input: {
              missionId,
              dueDate: dates[1],
            },
          },
        });
      } else {
        if (
          moment(missionData?.startAt).diff(dates[0], 'days') !== 0 ||
          moment(missionData?.dueDate).diff(dates[1], 'days') !== 0
        ) {
          if (moment(missionData?.startAt).diff(dates[0], 'days') !== 0) {
            await changeMissionStartDate({
              variables: {
                input: {
                  missionId,
                  startAt: dates[0],
                },
              },
            });
          }
          if (moment(missionData?.dueDate).diff(dates[1], 'days') !== 0) {
            await changeMissionEndDate({
              variables: {
                input: {
                  missionId,
                  dueDate: dates[1],
                },
              },
            });
            // if it's a test mission, change mission title with xxx on {{dueDate}}
            if (missionData?.category === Categories.TEST) {
              if (missionData?.title) {
                const date = moment(dates[1]).format('MMMM D, yyyy');
                const newMissionTitle = replaceDateInTitle(missionData?.title, date);
                await changeMissionTitle({
                  variables: {
                    input: {
                      missionId,
                      title: newMissionTitle,
                    },
                  },
                });
                try {
                  const result = await refetchLoadTestByMissionId({ missionId });
                  if (result && result.data && result.data.testRecord) {
                    const testRecord = result.data.testRecord;
                    await addOrUpdateTest({
                      variables: {
                        input: {
                          id: testRecord.id,
                          userId: testRecord.userId,
                          type: testRecord.type,
                          isMockTest: testRecord.isMockTest,
                          testDate: dates[1],
                        },
                      },
                    });
                  }
                } catch (e) {
                  console.warn(e);
                }
              }
            }
          }
          reloadMission();
        }
      }
    }
  };

  return (
    <Drawer
      contentWrapperStyle={{ width: '620px' }}
      bodyStyle={{ padding: '28px 36px' }}
      headerStyle={{ display: 'none' }}
      visible={visible}
      onClose={onClose}
    >
      {!missionData ? (
        <LoadingIndicator />
      ) : (
        <>
          <StyledMissionHeader>
            <MissionStatus
              status={missionData?.status as keyof typeof missionStatusObject}
              onSelectValue={async (value) => {
                await changeMissionStatus({
                  variables: {
                    input: {
                      missionId,
                      status: value,
                    },
                  },
                });
                reloadMission();
                setRefetch(true);
              }}
              editPermitted={editPermitted}
            />
            <StyledMissionHeaderRight>
              {editPermitted && showRemoveBtn && (
                <StyledRemoveButton>
                  <RemoveButton
                    Icon={<StyledMoreHoriz />}
                    onConfirm={() => {
                      onClose();
                      removeMission();
                    }}
                    removeButtonText="Remove a mission"
                    removeModalText={`Are you sure you want to remove this ${
                      MissionSubCategoryMapping[missionData?.subcategory as keyof typeof MissionSubCategoryMapping]
                        ? MissionSubCategoryMapping[missionData?.subcategory as keyof typeof MissionSubCategoryMapping]
                        : 'mission'
                    }?`}
                  />
                </StyledRemoveButton>
              )}
              <StyledCloseIcon onClick={onClose}>
                <CloseIcon />
              </StyledCloseIcon>
            </StyledMissionHeaderRight>
          </StyledMissionHeader>
          <StyledMissionTitleContainer>
            <StyledTextArea>
              <CRTextarea
                placeholder="Mission Name"
                value={missionData?.title === 'Untitled' ? 'Add a mission title' : missionData.title}
                disabled={!editPermitted}
                textStyle={missionData?.title === 'Untitled' ? { color: theme.common.salmon } : {}}
                onChangeProps={async (value) => {
                  await changeMissionTitle({
                    variables: {
                      input: {
                        missionId,
                        title: value,
                      },
                    },
                  });
                  reloadMission();
                }}
                autoSize={{
                  minRows: 1,
                  maxRows: 2,
                }}
              />
            </StyledTextArea>
          </StyledMissionTitleContainer>
          <StyledCategoryContainer>
            <span>{MissionCategoryNameMapping[missionData?.category as keyof typeof MissionCategoryNameMapping]}</span>
            <StyledRightOutlined />
            <span>{missionData?.subcategory}</span>
          </StyledCategoryContainer>
          <StyledRangeDatePickerContainer>
            <RangeDatePicker
              value={[
                missionData?.startAt ? moment(missionData?.startAt as string) : null,
                missionData?.dueDate ? moment(missionData?.dueDate as string) : null,
              ]}
              format="MMM DD, YYYY"
              suffixIcon={null}
              onChange={onDateChange}
              separator={<StyledRightOutlined />}
              disabled={datePickerType !== 'default' ? [!editPermitted, true] : !editPermitted}
            />
          </StyledRangeDatePickerContainer>
          <StyledMissionDesc>
            <StyledLabel>Description</StyledLabel>
            <StyledMissionTextareaContainer>
              <StyledTextArea>
                <CRTextarea
                  placeholder="Enter mission description here"
                  value={missionData?.description}
                  onChangeProps={async (value) => {
                    await changeMissionDescription({
                      variables: {
                        input: {
                          missionId,
                          description: value,
                        },
                      },
                    });
                    reloadMission();
                  }}
                  autoSize={{
                    minRows: 1,
                    maxRows: 4,
                  }}
                  disabled={!editPermitted}
                />
              </StyledTextArea>
            </StyledMissionTextareaContainer>
          </StyledMissionDesc>
          <StyledTeamContainer>
            <StyledLabel>Team</StyledLabel>
            <Teams
              members={missionData?.members as string[]}
              missionId={missionData?.id as string}
              editPermitted={editPermitted}
            />
          </StyledTeamContainer>
          <StyledActionItemsContainer>
            {missionData && <ActionItems mission={missionData} />}
          </StyledActionItemsContainer>
          {REFLECTIONS && (
            <StyledActionItemsContainer>
              <Reflections missionId={missionData.id} />
            </StyledActionItemsContainer>
          )}
          {canViewInternalNotes && missionData ? (
            <StyledActionItemsContainer>
              <InternalNotes studentId={missionData.userId} missionId={missionData.id} editPermitted={editPermitted} />
            </StyledActionItemsContainer>
          ) : null}
          <StyledActionItemsContainer>
            <ActivityLog mission={missionData} />
          </StyledActionItemsContainer>
        </>
      )}
    </Drawer>
  );
};

export default MissionDrawer;
