import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import theme from 'src/web-shared-components/theme';
import { CR_COLORS } from 'src/theme';
import { Button } from 'antd';

export const StyledNavBar = styled.div`
  height: min-content;
  background: white;
  border: 1px solid #e3e7ed;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  padding: 26px 9px;
  color: #73747d;
`;
export const StyledCategoryLabelTab = styled.div<{ $active: boolean }>`
  padding: 10px 0 10px 22px;
  border-radius: 8px;
  font-weight: 500;
  > div {
    display: flex;
    align-items: center;
  }
  img {
    width: 24px;
    margin-right: 15px;
  }
  &:hover {
    cursor: pointer;
    color: #464ac9;
  }
  background: ${(props) => {
    if (props.$active) {
      return '#F4F9FD;';
    }
  }};
  color: ${(props) => {
    if (props.$active) {
      return '#464AC9';
    } else {
      return '#73747d';
    }
  }};
`;
export const StyledCategoryTab = styled(Link)<{ $active: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  padding-right: 9px;
`;
export const StyledSubCategoryTabContainer = styled.div`
  padding: 0 20px 0 40px;
  display: flex;
  flex-direction: column;
`;
export const StyledSubCategoryTab = styled.a`
  display: flex;
  span:first-child {
    margin-top: 4px;
  }
  font-size: 14px;
  padding: 8px 4px;
  color: ${theme.common.stone} !important;
  a,
  a:link,
  a:visited,
  a:focus,
  a:hover,
  a:active {
    color: ${CR_COLORS.STONE};
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
    color: #464ac9 !important;
  }
`;
export const StyledRightOutlined = styled(RightOutlined)`
  margin-right: 7px;
  font-size: 12px;
`;
export const StyledNavBarActiveBar = styled.div<{ $active: boolean }>`
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px;
  background: ${(props) => {
    if (props.$active) {
      return 'var(--color-indigo)';
    }
  }};
`;

export const PreviewButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 32px;
  gap: 10px;
  height: 40px;
  background: white;
  border: 2px solid var(--color-indigo);
  border-radius: 30px;
  color: var(--color-indigo);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  &:active {
    background: transparent;
  }
  &:focus {
    background: transparent;
  }
  &:hover {
    box-shadow: 0px 3px 6px rgba(61, 64, 144, 0.2);
  }
`;
