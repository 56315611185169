import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Switch } from 'antd';

import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useIsStaff } from 'src/hooks/useMissionHook/useIsStaffHook';
import { TestOptOutContainer } from './style';
import { getTestOptOutGql, setTestOptOutGql } from 'src/graphql/Testing';

interface StudentTestOptOutData {
  userId: string;
  testOptOutUS: boolean;
  testOptOutUK: boolean;
  testOptOutEU: boolean;
  lngTestOptOutUS: boolean;
  lngTestOptOutUK: boolean;
  lngTestOptOutEU: boolean;
}
interface TestOptOutOption {
  label: string;
  key: string;
  value?: boolean;
}
export const TestOptOut = (): JSX.Element => {
  const { studentInfo, currentUser } = useContext(CurrentUserContext);
  const { userId } = studentInfo;
  const isStaff = useIsStaff(currentUser);

  const satOptions: Array<TestOptOutOption> = [
    { label: 'US', key: 'testOptOutUS' },
    { label: 'UK', key: 'testOptOutUK' },
    { label: 'EU', key: 'testOptOutEU' },
  ];

  const lngOptions: Array<TestOptOutOption> = [
    { label: 'US', key: 'lngTestOptOutUS' },
    { label: 'UK', key: 'lngTestOptOutUK' },
    { label: 'EU', key: 'lngTestOptOutEU' },
  ];
  const { refetch: getTestOptOut } = useQuery(getTestOptOutGql, { variables: { userId }, skip: !userId });
  const [updateTestOptOut] = useMutation(setTestOptOutGql);

  const [loading, setLoading] = useState<boolean>(false);
  const [testOptOut, setTestOptOut] = useState<StudentTestOptOutData>({
    userId,
    testOptOutEU: false,
    testOptOutUK: false,
    testOptOutUS: false,
    lngTestOptOutUS: false,
    lngTestOptOutUK: false,
    lngTestOptOutEU: false,
  });

  const handleTestOptOutClick = async (e: TestOptOutOption) => {
    setLoading(true);
    try {
      const { data } = await updateTestOptOut({ variables: { input: { userId, [e.key]: e.value } } });
      setTestOptOut({ ...data.setTestOptOut });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userId) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await getTestOptOut();
        setTestOptOut({ ...data.getTestOptOut });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [getTestOptOut, userId]);

  return isStaff ? (
    <TestOptOutContainer>
      <div className="title">
        <div className="bold">Test opt out</div>
        <div className="title-tip">Staff only</div>
      </div>
      <div className="subtitle">Mark if the student chooses not to participate in or foregoing a test.</div>
      <div>
        <div className="header bold">Standardized / Admissions Test</div>
        <div className="options">
          {satOptions.map((x: TestOptOutOption, i: number) => (
            <div key={'sat' + i} className="option">
              {x.label}
              <Switch
                className="switch"
                loading={loading}
                onChange={(checked: boolean) => {
                  handleTestOptOutClick({ value: checked, ...x });
                }}
                checked={testOptOut[x.key as keyof StudentTestOptOutData] as boolean}
              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="header bold">Language Test</div>
        <div className="options">
          {lngOptions.map((x: TestOptOutOption, i: number) => (
            <div key={'lng' + i} className="option">
              {x.label}
              <Switch
                className="switch"
                loading={loading}
                onChange={(checked: boolean) => {
                  handleTestOptOutClick({ value: checked, ...x });
                }}
                checked={testOptOut[x.key as keyof StudentTestOptOutData] as boolean}
              />
            </div>
          ))}
        </div>
      </div>
    </TestOptOutContainer>
  ) : (
    <></>
  );
};
