import { Spin } from 'antd';
import styled from 'styled-components';

export const StyledCenteredSpinner = styled(Spin)`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
