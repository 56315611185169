import { Tag as StyledTag } from './style';
import React from 'react';
import { MotionProps } from 'framer-motion';

type TagProps = {
  title: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
} & MotionProps;

export const Tag = ({ title, onClick, style, className, ...restProps }: TagProps): JSX.Element => {
  return (
    <StyledTag
      className={className}
      onClick={onClick}
      style={onClick ? { cursor: 'pointer', ...style } : style}
      {...restProps}
    >
      {title}
    </StyledTag>
  );
};

export default Tag;
