import theme from 'src/web-shared-components/theme';
import styled from 'styled-components';
import { AddLink } from '@styled-icons/material/AddLink';
import { Form, Input, InputProps, DatePicker as AntDatePicker, Popover, Select } from 'antd';

// index.tsx
export const Container = styled.div`
  padding: 20px;
`;

// TestMissionsGroup.tsx
export const Title = styled.div`
  margin-top: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

// TestMissionItem.tsx
export const ItemContainer = styled.div`
  cursor: pointer;
  min-height: 113px;
  border: 1px solid ${theme.common.paleGrey};
  border-radius: 8px;
  margin: 16px 0;
  padding: 20px 16px 16px 32px;
  &:hover {
    box-shadow: 0px 1px 20px rgb(186, 199, 218, 0.3);
  }
  .firstRow {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .left {
      flex: 1 0 auto;
      .date {
        width: fit-content;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        color: ${theme.common.darkNavy};
        svg {
          margin-left: 4px;
          vertical-align: top;
          color: ${theme.common.coolGrey};
        }
        // fix antd popover scroll position
        div:nth-child(2) {
          position: relative !important;
          width: 165px;
          .ant-popover-arrow {
            z-index: 1;
          }
        }
      }
    }
    .right {
      flex: 0 0 auto;
      display: flex;
      column-gap: 14px;
      align-items: center;
      // fix Teams margin
      div:nth-child(2) {
        margin-right: 4px;
      }
    }
  }
  .secondRow {
    display: flex;
  }
`;

export const AddFileIcon = styled(AddLink)`
  color: ${theme.common.stone};
  vertical-align: 0px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  column-gap: 16px;
  justify-content: flex-start;
  & > div:first-child {
    margin-right: 48px;
  }
`;

export const Col = styled.div<{ nameLength?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) =>
    Number(props?.nameLength) > 25 ? `1 1 auto` : Number(props?.nameLength) > 15 ? '1 1 140px' : '1 1 68px'};
  &:last-child {
    flex: 0 1 140px;
  }
`;

export const FormItem = styled(Form.Item)`
  margin: 0;
  height: 100%;
  .ant-form-item-row {
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    .ant-form-item-label {
      padding: 0;
      flex: 0 0 auto;
    }
    .ant-form-item-label label {
      color: #a9acc0;
      font-size: 12px;
      font-weight: 500;
      height: auto;
    }
  }
  .ant-form-item-explain-error {
    overflow-wrap: anywhere;
  }
`;

export const TestScoreInput = styled(Input)<InputProps & { $ssaStatus?: () => 'warning' | null }>`
  padding-left: 0;
  .ant-input::placeholder {
    color: #a9acc0;
  }
  &[disabled] {
    font-style: italic !important;
    color: var(--color-stone);
  }
  &.ant-input {
    background-color: ${({ $ssaStatus }) => ($ssaStatus?.() === 'warning' ? '#fff3db' : '')};
    border-bottom: ${({ $ssaStatus }) => ($ssaStatus?.() === 'warning' ? '1px solid #fdaa02' : '')};
  }
`;

export const DatePickerPopover = styled(Popover)`
  padding: 0;
`;

export const DatePicker = styled(AntDatePicker)`
  padding: 0 !important;
  .ant-picker-input {
    width: 125px;
    input {
      height: 100%;
    }
    .ant-picker-suffix {
      height: 100%;
      margin: 0;
    }
  }
`;

export const TestHeader = styled.div`
  display: flex;
`;

export const TestIcon = styled.img`
  height: 24px;
  margin-top: auto;
  margin-right: 3px;
`;

export const StyledSelect = styled(Select)`
  &.ant-select-single .ant-select-selector {
    padding-left: 0;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 32px;
  }
`;
