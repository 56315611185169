import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createContext } from 'react';

export const ApolloClientContext = createContext<
  | {
      studentCenterApiClient: ApolloClient<NormalizedCacheObject>;
      crimsonAppApiClient: ApolloClient<NormalizedCacheObject>;
      roadmapApiClient: ApolloClient<NormalizedCacheObject>;
      storyBlokApiClient: ApolloClient<NormalizedCacheObject>;
      pathfinderApiClient: ApolloClient<NormalizedCacheObject>;
      onMessage?: (props: { event: string; data: string }) => void;
    }
  | Record<string, never>
>({});
