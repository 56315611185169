import { Avatar } from 'antd';
import styled from 'styled-components';
import theme from 'src/web-shared-components/theme';
type AvartarProps = {
  firstName?: string;
  lastName?: string;
  shortName?: string;
  image?: string;
  userId: string;
  preFix?: string;
  size: number;
};
const StyledAvatarContainer = styled.div<{ colorIndex: number }>`
  .ant-avatar {
    background-color: ${(props) => `${theme.avatar[`avatar${props.colorIndex}` as keyof typeof theme.avatar]}`};
  }
`;
const AVATAR_COLOURS_COUNT = 14;

export function getAvatarColour(userId = ''): number {
  // Create a "hash" by summing the unicode value of each character in the userId.
  const hash = userId.split('').reduce((acc, curr) => {
    return acc + curr.charCodeAt(0);
  }, 0);

  // Retrieve the modulus of the hash divided by the max colour index.
  // e.g. AVATAR_COLOURS_COUNT = 9, values will be one of 0,1,2,3,4,5,6,7,8.
  return hash % AVATAR_COLOURS_COUNT;
}

export function getInitials(...names: (string | undefined)[]): string {
  const initials = names.map((name) => {
    return name?.charAt(0).toUpperCase();
  });
  return initials.join('');
}

const CRAvatar = ({ firstName, lastName, image, userId, size, preFix, shortName }: AvartarProps): JSX.Element => {
  const colorIndex = getAvatarColour(userId);
  return (
    <StyledAvatarContainer className={`${preFix}-avatar-container`} colorIndex={colorIndex}>
      {image ? (
        <Avatar className={`${preFix}-src-avatar`} src={image} size={size} />
      ) : (
        <Avatar className={`${preFix}-src-avatar`} size={size}>
          {shortName ? shortName : getInitials(firstName, lastName)}
        </Avatar>
      )}
    </StyledAvatarContainer>
  );
};

export default CRAvatar;
