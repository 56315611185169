import { createContext, useState } from 'react';
import { PathfinderGoalDrawerProps } from './PathfinderGoalDrawerContext';

export interface ExplorePathfinderIdeasDrawerProps extends PathfinderGoalDrawerProps {
  pathfinderType: string;
  setPathfinderType: React.Dispatch<React.SetStateAction<string>>;
}

export const ExplorePathfinderIdeasContext = createContext<ExplorePathfinderIdeasDrawerProps>({
  visible: false,
  activedCategory: '',
  activedSubCategory: '',
  pathfinderType: 'US',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPathfinderType: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActivedCategory: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActivedSubCategory: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setVisible: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOnClose: () => {},
  drawerBodyStyle: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerBodyStyle: () => {},
});

export const usePathfinderIdeasDrawerState = (): ExplorePathfinderIdeasDrawerProps => {
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [onClose, setOnClose] = useState<() => void>(() => () => {});
  const [activedCategory, setActivedCategory] = useState('');
  const [pathfinderType, setPathfinderType] = useState('US');
  const [activedSubCategory, setActivedSubCategory] = useState('');
  const [drawerBodyStyle, setDrawerBodyStyle] = useState<React.CSSProperties>({});
  return {
    visible,
    setVisible,
    onClose,
    setOnClose,
    pathfinderType,
    setPathfinderType,
    activedCategory,
    setActivedCategory,
    activedSubCategory,
    setActivedSubCategory,
    drawerBodyStyle,
    setDrawerBodyStyle,
  };
};
