import { DrawerProps } from 'antd';
import { badgeCategoriesMap } from './index.util';
import BadgeVersion from './BadgeVersion';
import PointVersion from './PointVersion';

const ExploreAllPathfinderIdeas = ({
  visible,
  setDrawerVisible,
  onDrawerClose,
  pathfinderType,
  activedCategory = '',
  ...rest
}: DrawerProps & {
  onDrawerClose: () => void;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pathfinderType: string;
  activedCategory?: string;
}): JSX.Element => {
  const isBadge = Object.keys(badgeCategoriesMap).includes(activedCategory);

  return isBadge ? (
    <BadgeVersion
      visible={visible}
      setDrawerVisible={setDrawerVisible}
      onDrawerClose={onDrawerClose}
      pathfinderType={pathfinderType}
      {...rest}
    />
  ) : (
    <PointVersion
      visible={visible}
      setDrawerVisible={setDrawerVisible}
      onDrawerClose={onDrawerClose}
      pathfinderType={pathfinderType}
      {...rest}
    />
  );
};

export default ExploreAllPathfinderIdeas;
