import styled from 'styled-components';

import { Button, Spin as AntSpin } from 'antd';

export const CenteredSpin = styled(AntSpin)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const RecommenderStepIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
`;

export const RecommenderStepIndicator = styled.span<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? '#6C63FF' : '#A9ACC0')};
  border-radius: 4px;
  height: 8px;
  transition: 0.3s all ease;
  width: ${({ active }) => (active ? '40px' : '15px')};
`;

export const RecommenderStepControlsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto 24px;
  width: 100%;
`;

export const RecommenderStepTitle = styled.h2`
  color: #1d1e2b;
  display: flex;
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
`;

export const RecommenderStepHelperText = styled.div`
  color: #1d1e2b;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
`;

export const RecommenderDialogContainer = styled.div`
  background-color: #f3f6fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
  flex: 1;
`;

export const RecommenderDialogGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0 auto;
  position: relative;
`;

export const RecommenderDialogActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const RecommenderFeedbackRoot = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  position: relative;
`;

export const RecommenderFeedbackText = styled.span`
  font-family: 'Montserrat-SemiBold';
`;

export const RecommenderFeedbackButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const RecommenderFeedbackButton = styled(Button).attrs({
  type: 'link',
})`
  align-items: center;
  display: inline-flex;
  padding: 4px;
`;
