import { Col, Row, Skeleton } from 'antd';
import { StyledSection } from '../style';

const LabelInputSkeleton = () => (
  <Col>
    <Skeleton paragraph={false} />
    <Skeleton.Input active />
    <br />
    <br />
  </Col>
);

const TitleSkeleton = () => (
  <div style={{ marginBottom: 25 }}>
    <Skeleton paragraph={false} title={{ width: 220 }} />
  </div>
);

export const LoadingHeaderSection = (): JSX.Element => (
  <StyledSection>
    <Row gutter={[20, 20]} align="middle">
      <Col>
        <Skeleton.Avatar size={100} />
      </Col>
      <Col>
        <Skeleton active paragraph={{ rows: 1 }} title={{ width: 220 }} style={{ width: 280 }} />
      </Col>
    </Row>
  </StyledSection>
);

export const LoadingTeamsSection = (): JSX.Element => (
  <>
    {new Array(3).fill(0).map((_, i) => (
      <Row gutter={[15, 5]} align="middle" key={i}>
        <Col>
          <Skeleton.Avatar size={50} />
        </Col>
        <Col style={{ paddingTop: 18 }}>
          <Skeleton active paragraph={{ rows: 1, width: 80 }} title={false} />
          <Skeleton active paragraph={{ rows: 1, width: 100 }} title={false} />
        </Col>
      </Row>
    ))}
  </>
);

const LoadingSection = ({ rows = 2 }: { rows?: number }): JSX.Element => {
  return (
    <StyledSection>
      <Row>
        <TitleSkeleton />
      </Row>
      <Row gutter={[32, 48]}>
        {new Array(3).fill(0).map((_, i) => (
          <LabelInputSkeleton key={i} />
        ))}
      </Row>
      <Row gutter={[32, 48]}>
        {new Array(4).fill(0).map((_, i) => (
          <LabelInputSkeleton key={i} />
        ))}
      </Row>
      <Row>
        <Skeleton
          active
          title={{
            width: 80,
          }}
          paragraph={{
            rows: rows,
            width: 280,
          }}
          style={{ width: '50%' }}
        />
      </Row>
    </StyledSection>
  );
};

export default LoadingSection;
