import { useState, useEffect } from 'react';
import * as CSS from 'csstype';
import { StyledInput } from './style';

type Props = {
  name?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProps?: (value: any) => void;
  style?: CSS.Properties;
  disabled?: boolean;
};
const Input = ({ value, placeholder = 'Enter', onChangeProps, ...rest }: Props): JSX.Element => {
  const [showValue, setShowValue] = useState('');
  useEffect(() => {
    setShowValue(value);
  }, [value]);
  const onSubmit = () => {
    onChangeProps?.(showValue);
  };
  return (
    <StyledInput
      value={showValue}
      placeholder={placeholder}
      bordered={false}
      onChange={(e) => {
        const value = e.target.value;
        setShowValue(value);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onPressEnter={onSubmit}
      onBlur={onSubmit}
      {...rest}
    />
  );
};

export default Input;
