import { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import {
  ButtonContainer,
  CancelButton,
  Container,
  StyledDebouncedSelect,
  StyledSelect,
  SubmitButton,
  SSMField,
  Title,
} from './style';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { tagRender } from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/DebounceSelect';
import { IOptions } from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/selectOptions';
import { User, Role } from 'src/types/user';
import { debounce, uniqWith } from 'lodash';
import { CurrentUserContext } from 'src/context/CurrentUserContext';

export type PrincipalType = {
  principalUserId: string;
  type: string;
  isPrimary?: boolean;
  tags?: string;
};

export type RelationshipType = {
  principalUserId: string;
  relationUserId: string;
  type: string;
  isPrimary?: boolean;
  tags?: string;
};

export const getValueLabelFromUser = (user: User): IOptions => {
  return {
    key: user.userId,
    text: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
    value: `${user.firstName} ${user.lastName} ${user.userId}`,
  };
};

export const getPrimaryRoleNameFromRoles = (roles: Role[]): string => {
  let primaryRoleName = roles?.filter(
    (role: {
      isPrimary: boolean;
      role: {
        name: string;
      };
    }) => role.isPrimary === true,
  )?.[0]?.role.name;
  if (!primaryRoleName && roles.length) {
    primaryRoleName = roles[0].role.name;
  }
  return primaryRoleName;
};

const getDefaultOptionsByRole = (
  roleName: string,
  users: User[] | undefined,
  idToRolesMapping: { [key: string]: string[] },
): { label: string; value: string; key: string }[] => {
  const defaultOptions: { label: string; value: string; key: string }[] = [];
  if (users) {
    users.forEach((u: User) => {
      if (idToRolesMapping[u.userId]?.includes(roleName)) {
        defaultOptions.push({
          label: `${u.firstName} ${u.lastName}`,
          value: `${u.firstName} ${u.lastName} ${u.userId}`,
          key: u.userId,
        });
      }
    });
  }
  return defaultOptions;
};

const ManageTeamModalMultiSSM = ({
  onSubmit,
  onClose,
  isSubmitting = false,
  loadOptions,
  defaultValues,
  mapUserIdToRelatedRoles,
  canEditSSM,
  canEditStrategist,
  canEditReviewer,
  showAA,
}: {
  onSubmit: (principals: PrincipalType[]) => Promise<void>;
  onClose: () => void;
  isSubmitting?: boolean;
  loadOptions: (role: string, excludeIds?: string[]) => (input: string) => Promise<IOptions[]>;
  defaultValues: User[];
  mapUserIdToRelatedRoles: { [key: string]: string[] };
  canEditSSM: boolean;
  canEditStrategist: boolean;
  canEditReviewer: boolean;
  showAA: boolean;
}): JSX.Element => {
  const [form] = Form.useForm();
  const initialPrimarySSMvalue = getDefaultOptionsByRole('PrimarySSM', defaultValues, mapUserIdToRelatedRoles);
  const initialAssociatedSSMValues = getDefaultOptionsByRole('SSM', defaultValues, mapUserIdToRelatedRoles);
  const initialStrategistValues = getDefaultOptionsByRole('Strategist', defaultValues, mapUserIdToRelatedRoles);
  const initialReviewerValues = getDefaultOptionsByRole('Reviewer', defaultValues, mapUserIdToRelatedRoles);
  const initialAcademicAdvisorValues = getDefaultOptionsByRole(
    'AcademicAdvisor',
    defaultValues,
    mapUserIdToRelatedRoles,
  );
  const [curPrimarySsmValue, setCurPrimarySsmValue] = useState(initialPrimarySSMvalue?.[0]);
  const [curAssociaSsmValues, setCurAssociaSsmValues] = useState(initialAssociatedSSMValues);
  const [SSMOptions, setSSMOptions] = useState<IOptions[]>([]);
  const [primarySSMOptions, setPrimarySSMOptions] = useState<IOptions[]>([]);
  const [associatedSSMOptions, setAssociatedSSMOptions] = useState<Partial<IOptions>[]>([]);
  // for SSM options update
  const [triggerOptionsUpdate, setTriggerOptionsUpdate] = useState<boolean>(false);
  // is loading options
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadPeople = useCallback(
    async (name: string, role: string) => {
      const options = await loadOptions(role)(name);
      return options;
    },
    [loadOptions],
  );

  // only used for ssm since strat and reviewer are using debounced
  const handleSearch = (role: string) => (value: string) => {
    loadPeople(value, role).then((options) => {
      if (role === 'CASE_MANAGER') {
        setSSMOptions(options);
        setTriggerOptionsUpdate(true);
      }
    });
  };

  // set initial ssm options
  useEffect(() => {
    if (!canEditSSM) return;
    setIsLoading(true);
    loadPeople('', 'CASE_MANAGER').then((options) => {
      setIsLoading(false);
      setSSMOptions(options);
      setPrimarySSMOptions(options);
      setAssociatedSSMOptions(options);
      setTriggerOptionsUpdate(true);
    });
  }, [canEditSSM, loadPeople]);

  // bind update associated ssm options
  useEffect(() => {
    if (!canEditSSM) return;
    if (!triggerOptionsUpdate) return;
    const excludeIds = curAssociaSsmValues?.map((v) => v.key);
    setPrimarySSMOptions(SSMOptions.filter((option) => !excludeIds?.includes(option.key)));
    setTriggerOptionsUpdate(false);
  }, [curAssociaSsmValues, canEditSSM, SSMOptions, triggerOptionsUpdate]);

  // bind update primary ssm options
  useEffect(() => {
    if (!canEditSSM) return;
    if (!triggerOptionsUpdate) return;
    setAssociatedSSMOptions(
      uniqWith(
        [...initialAssociatedSSMValues, ...curAssociaSsmValues, ...SSMOptions].filter(
          (o) => curPrimarySsmValue?.key !== o.key,
        ),
        (a, b) => a.key === b.key,
      ),
    );
    setTriggerOptionsUpdate(false);
  }, [
    curPrimarySsmValue,
    canEditSSM,
    SSMOptions,
    triggerOptionsUpdate,
    initialAssociatedSSMValues,
    curAssociaSsmValues,
  ]);

  const submit = async () => {
    const principals: PrincipalType[] = [];
    const { strategist, reviewer, academicAdvisor } = form.getFieldsValue();
    const primarySsm = curPrimarySsmValue;
    const associatedSsm = curAssociaSsmValues;
    const formattedPrimarySsm = !Array.isArray(primarySsm) ? [primarySsm] : primarySsm;

    // note: submitting empty string with a type will delete all members in that type
    // primarySsm field
    if (formattedPrimarySsm.length > 0 && formattedPrimarySsm[0]) {
      formattedPrimarySsm.forEach((u) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'CaseManagerStudent', isPrimary: true });
      });
    } else {
      principals.push({ principalUserId: '', type: 'CaseManagerStudent', isPrimary: true });
    }
    // associatedSsm field
    if (associatedSsm.length > 0) {
      associatedSsm.forEach((u) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'CaseManagerStudent', isPrimary: false });
      });
    } else {
      principals.push({ principalUserId: '', type: 'CaseManagerStudent', isPrimary: false });
    }
    // strategist field
    if (strategist.length > 0) {
      strategist.forEach((u: IOptions) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'StrategistStudent' });
      });
    } else {
      principals.push({ principalUserId: '', type: 'StrategistStudent' });
    }
    // reviewer field
    if (reviewer.length > 0) {
      reviewer.forEach((u: IOptions) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'ReviewerStudent' });
      });
    } else {
      principals.push({ principalUserId: '', type: 'ReviewerStudent' });
    }
    // academic advisor field
    if (academicAdvisor && academicAdvisor.length > 0) {
      academicAdvisor.forEach((u: IOptions) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'AcademicAdvisorStudent' });
      });
    } else {
      principals.push({ principalUserId: '', type: 'AcademicAdvisorStudent' });
    }
    onSubmit(principals);
  };

  const { currentUser } = useContext(CurrentUserContext);
  const isCollegeWise = currentUser?.tenant?.name === 'collegewise';

  return (
    <Container>
      <Title>Manage Team Members</Title>

      {!isCollegeWise && (
        <Row>
          <Col span={24}>
            <SSMField>
              <label className="ssm-field-label">Primary Student Success Manager</label>
              <StyledSelect
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Student Success Manager"
                value={curPrimarySsmValue}
                disabled={!canEditSSM || isLoading}
                showSearch
                allowClear
                options={primarySSMOptions}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(_, option: any) => {
                  setTriggerOptionsUpdate(true);
                  setCurPrimarySsmValue(option);
                }}
                onSearch={debounce(handleSearch('CASE_MANAGER'), 200)}
                optionFilterProp="label"
              />
            </SSMField>
          </Col>
        </Row>
      )}

      {!isCollegeWise && (
        <Row>
          <Col span={24}>
            <SSMField>
              <label className="ssm-field-label">Associated Student Success Managers</label>
              <StyledSelect
                mode="multiple"
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Student Success Manager"
                options={associatedSSMOptions}
                value={curAssociaSsmValues}
                maxTagTextLength={20}
                showSearch
                tagRender={tagRender}
                disabled={!canEditSSM || isLoading}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                onChange={(_, option) => {
                  setCurAssociaSsmValues(option.map((o: IOptions) => ({ label: o.text, value: o.value, key: o.key })));
                  setTriggerOptionsUpdate(true);
                }}
                onSearch={debounce(handleSearch('CASE_MANAGER'), 200)}
                optionFilterProp="label"
              />
            </SSMField>
          </Col>
        </Row>
      )}

      <Form
        form={form}
        layout="vertical"
        name="manage-teams"
        preserve={false}
        onFinish={submit}
        initialValues={{
          strategist: initialStrategistValues,
          reviewer: initialReviewerValues,
          academicAdvisor: initialAcademicAdvisorValues,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Strategist" name="strategist" required={false} shouldUpdate={false}>
              <StyledDebouncedSelect
                mode="multiple"
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Strategist"
                fetchOptions={loadOptions('STRATEGIST')}
                getOptionsOnMount={canEditStrategist}
                maxTagTextLength={20}
                maxCount={100}
                disabled={!canEditStrategist || isLoading}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Reviewer" name="reviewer" required={false} shouldUpdate={false}>
              <StyledDebouncedSelect
                mode="multiple"
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Reviewer"
                fetchOptions={loadOptions('REVIEWER')}
                getOptionsOnMount={canEditReviewer}
                maxTagTextLength={20}
                maxCount={100}
                disabled={!canEditReviewer || isLoading}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              />
            </Form.Item>
          </Col>
        </Row>
        {showAA && (
          <Row>
            <Col span={24}>
              <Form.Item label="Academic Advisor" name="academicAdvisor" required={false} shouldUpdate={false}>
                <StyledDebouncedSelect
                  mode="multiple"
                  size="large"
                  suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                  placeholder="Select Academic Advisor"
                  fetchOptions={loadOptions('ACADEMIC_ADVISOR')}
                  getOptionsOnMount={canEditStrategist}
                  maxTagTextLength={20}
                  maxCount={100}
                  disabled={!canEditStrategist || isLoading}
                  getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <ButtonContainer>
          <Form.Item>
            <CancelButton onClick={onClose} width={150}>
              Cancel
            </CancelButton>
          </Form.Item>
          <Form.Item>
            <SubmitButton type="primary" htmlType="submit" width={150} disabled={isSubmitting}>
              Confirm {isSubmitting && <LoadingOutlined />}
            </SubmitButton>
          </Form.Item>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default ManageTeamModalMultiSSM;
