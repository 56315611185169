import { useState, useEffect } from 'react';
import { Select } from 'antd';
const { Option } = Select;
import { SelectValue } from 'antd/lib/select';
import { SelectOption } from 'src/web-shared-components/base/Fields/Select';
import { StyledSelectSearch } from './style';
const SelectSearch = ({
  value,
  onChangeValue,
  options,
  placeholder,
  disabled,
  allowClear,
  withSuffixIcon,
  className,
}: {
  value: string;
  onChangeValue: (value: SelectValue) => void;
  options: SelectOption[];
  placeholder?: string;
  resultPlaceholder?: string;
  disabled?: boolean;
  allowClear?: boolean;
  withSuffixIcon?: boolean;
  className?: string;
}): JSX.Element => {
  const [showValue, setShowValue] = useState('');
  useEffect(() => {
    setShowValue(value);
  }, [value]);

  return (
    <StyledSelectSearch
      bordered={false}
      onClick={(e) => {
        e.stopPropagation();
      }}
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(value: any) => {
        setShowValue(value);
        onChangeValue(value);
      }}
      disabled={disabled}
      allowClear={allowClear}
      value={showValue}
      suffixIcon={withSuffixIcon ? undefined : null}
      style={{
        maxWidth: '100%',
      }}
      dropdownMatchSelectWidth={250}
      className={className}
    >
      {options.map(({ label, value }, index) => {
        return (
          <Option key={index} value={value}>
            {label}
          </Option>
        );
      })}
    </StyledSelectSearch>
  );
};

export default SelectSearch;
