import styled from 'styled-components';
import { Input, DatePicker, Select } from 'antd';
import { RightOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;

export const StyledMissionContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  padding: 12px 0 2px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin-top: -2px;
`;

export const StyledMissionLeftContainer = styled.div`
  display: flex;
`;
export const StyledMissionIcon = styled.div<{ color?: string }>`
  width: 48px;
  height: 48px;
  background: ${(props) => {
    if (props.color) {
      return props.color;
    }
  }};
  img {
    width: 50%;
  }
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  flex-shrink: 0;
`;
export const StyledMissionTitleContainer = styled.div`
  font-size: 16px;
  input {
    font-size: 16px;
    padding: 0;
    font-weight: 600;
  }
  input::placeholder {
    color: #a9acc0;
  }
`;
export const StyledMissionTitle = styled(Input)`
  font-size: 16px;
  padding: 0;
`;
export const StyledInput = styled(Input)`
  font-size: 12px;
  width: auto;
  padding-left: 0;
`;

export const StyledSelect = styled(Select)`
  font-size: 12px;
  .ant-select-selector {
    height: 27px !important;
    padding: 0 7px 0 0 !important;
  }
  .ant-select-selection-placeholder {
    padding-right: 26px !important;
  }
`;
export const StyledProgramContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledProgramLeftRight = styled.div`
  width: 49%;
`;

export const StyledFormItem = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 10px 16px;
`;

export const StyledFormItemLabel = styled.span`
  display: inline-block;
  width: 160px;
  color: #a9acc0;
`;

export const StyledGreyFormItem = styled(StyledFormItem)`
  background: #f3f6fa;
`;

export const StyledMissionItem = styled.div`
  margin-top: 16px;
`;

export const StyledAddButtonArea = styled.div`
  margin-top: 10px;
  padding-left: 22px;
`;

export const StyledMissionStatus = styled.div<{ status?: string }>`
  background: ${(props) => {
    if (props.status) {
      switch (props.status) {
        case 'PLANNED':
          return '#e9e8ff';
        case 'DONE':
          return '#E0F9F2';
        case 'IN_PROGRESS':
          return '#E8F1FF';
        case 'EXPIRED':
          return '#ffeae4';
        case 'POSTPONED':
          return '#fff2d9';
      }
    }
  }};
  border-radius: 8px;
  padding: 5px 13px;
  color: ${(props) => {
    if (props.status) {
      switch (props.status) {
        case 'PLANNED':
          return '#6c63ff';
        case 'DONE':
          return '#12C39A';
        case 'IN_PROGRESS':
          return '#3F8CFF';
        case 'EXPIRED':
          return '#ff764c';
        case 'POSTPONED':
          return '#fda903';
      }
    }
  }};
`;
export const StyledRightOutlined = styled(RightOutlined)`
  color: #a9acc0;
`;
export const StyledMissionRightContainer = styled.div`
  display: flex;
  /* height: 75px; */
  justify-content: start;
  align-items: end;
  flex-direction: column;
`;
export const StyledRemoveButton = styled.div`
  margin-left: 16px;
  margin-right: 4px;
`;
export const StyledRangePicker = styled(RangePicker)`
  margin-left: 8px;
  .ant-picker-suffix {
    color: #a9acc0;
  }
  width: 230px;
  padding: 0px !important;
  .ant-picker-input > input {
    font-size: 12px !important;
  }
  input::placeholder {
    color: #73747d;
  }
  .ant-picker-suffix {
    height: auto;
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  padding-left: 0px !important;
  cursor: pointer;
  .ant-picker-input > input {
    font-size: 12px !important;
  }
  .ant-picker-suffix {
    height: auto;
  }
`;
export const StyledMissionTableInputContainer = styled.div`
  input::placeholder {
    color: #73747d;
  }
`;
export const StyledMissionTableSelectContainer = styled.div`
  .ant-select-selector {
    line-height: 24px !important;
  }
  .ant-select-selection-placeholder {
    color: #73747d;
    line-height: 24px !important;
  }
  .ant-select-selection-item {
    padding-right: 25px !important;
  }
`;
export const StyledUploadContainer = styled.div`
  .ant-btn {
    background: transparent;
    border-bottom: 1px solid;
  }
  .ant-upload-list {
    max-width: 148px !important;
  }
`;

export const ProgramTimeline = styled.span`
  color: #a9acc0;
  font-size: 12px;
  margin-left: 5px;
`;

export const RatingBadgesContainer = styled.div`
  display: flex;
  margin-right: 16px;
`;

export const RatingButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
`;

export const StyledAddToLibUl = styled.ul`
  text-align: left;
  margin: 0 auto;
  display: inline-block;
  margin-top: 8px;
`;
