import styled from 'styled-components';
import { Upload as AntUpload, Button as AntButton } from 'antd';
import theme from 'src/web-shared-components/theme';

export const Upload = styled(AntUpload)`
  border: none;
  display: flex;
  flex-direction: row-reverse;
  .ant-upload-list {
    max-width: 250px;
    display: flex;
    flex-wrap: wrap;
    .ant-upload-list-item {
      margin: 0;
      .ant-upload-list-item-name {
        padding: 0 3px 0 3px;
      }
    }
  }
`;

export const Button = styled(AntButton)`
  color: ${theme.common.indigo};
  height: 20px;
  border: none;
  border-radius: 0;
  padding: 0;
  span:nth-child(2) {
    height: 20px;
    border-bottom: 1px solid;
    margin-left: 4px;
  }
`;

export const UploadFileItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  .upload-list-item-remove {
    position: absolute;
    z-index: 1;
    right: 6px;
    top: -12px;
    svg {
      font-size: 10px;
      color: ${theme.common.stone};
    }
  }
  .upload-list-item-info {
    margin-right: 12px;
  }
`;
