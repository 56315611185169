import { FunctionComponent, MouseEventHandler, useContext, useMemo, useState } from 'react';
import * as Styled from './styles';
import CRAvatar from '../Avatar';
import { Button, Input } from 'antd';
import { Send } from '@styled-icons/material/Send';
import { CR_COLORS } from 'src/theme';
import { TComment } from './types';
import { useMutation } from '@apollo/client';
import { CREATE_COMMENT, UPDATE_COMMENT } from 'src/graphql/Comments';
import { LoginUserContext } from 'src/context/LoginUserContext';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CommentCategory } from 'src/__generated__/graphqlTypes';
const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

type InputMode = 'edit' | 'new';
interface CommentInputProps {
  comment?: TComment;
  reflectionId?: string;
  internalNoteId?: string;
  relatedItemId?: string;
  relatedItemType?: string;
  onInputSaved?: (comment: TComment) => void;
}

const CommentInput: FunctionComponent<CommentInputProps> = ({
  comment,
  reflectionId,
  internalNoteId,
  onInputSaved,
}) => {
  const { firstName, lastName, profileImageUrl, userId } = useContext(LoginUserContext);
  const [createComment] = useMutation(CREATE_COMMENT);
  const [updateComment] = useMutation(UPDATE_COMMENT);

  const [content, setContent] = useState(comment?.content || '');
  const canSubmit = useMemo(() => !!content, [content]);
  const [submitting, setSubmitting] = useState(false);
  const mode: InputMode = useMemo(() => (!!comment ? 'edit' : 'new'), [comment]);
  const submit: MouseEventHandler = async (e) => {
    e.stopPropagation();
    const input: Partial<TComment> = { content: content.trim() };
    if (reflectionId) {
      input.actionItemId = reflectionId;
      input.category = CommentCategory.Reflection;
    } else if (internalNoteId) {
      input.internalNoteId = internalNoteId;
      input.category = CommentCategory.Escalations;
    }
    if (content) {
      setSubmitting(true);
      const { data, errors } = comment?.id
        ? await updateComment({ variables: { commentId: comment.id, input } })
        : await createComment({ variables: { input } });
      setSubmitting(false);
      if (errors?.length) return;
      setContent('');
      !!onInputSaved &&
        onInputSaved({
          ...data.comment,
          createdAt: new Date(data.comment.createdAt),
          updatedAt: new Date(data.comment.updatedAt),
          creator: {
            firstName,
            lastName,
            userId,
            profileImageUrl,
          },
        });
    }
  };

  if (!userId) {
    return <></>;
  }

  return (
    <Styled.NoteInputContainer>
      {mode === 'new' ? (
        <CRAvatar
          key={userId}
          image={profileImageUrl || undefined}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          size={24}
        />
      ) : (
        <div style={{ width: 30 }}></div> // Spacer
      )}
      <>
        <Input.TextArea
          value={content}
          disabled={submitting}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Add a comment"
          autoSize
        />
        <Button disabled={!canSubmit || submitting || !content.trim()} onClick={submit} shape="circle">
          {submitting ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <Send
              height={24}
              color={CR_COLORS.LIGHT_PURPLE}
              opacity={canSubmit && !submitting && content.trim() ? 1 : 0.4}
            />
          )}
        </Button>
      </>
    </Styled.NoteInputContainer>
  );
};

export default CommentInput;
