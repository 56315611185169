import { useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEventCallback = <Fn extends (...params: any[]) => any>(
  fn: Fn,
): ((...params: Parameters<Fn>) => ReturnType<Fn>) => {
  const fnHolder = useRef(fn);

  useEffect(() => {
    fnHolder.current = fn;
  }, [fn]);

  return useCallback((...params: Parameters<Fn>): ReturnType<Fn> => fnHolder.current(...params), []);
};
