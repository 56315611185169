import { gql } from '@apollo/client';

export {
  CREATE_INTERNAL_NOTE,
  DELETE_INTERNAL_NOTE,
  QUERY_INTERNAL_NOTES,
  QUERY_SERVICE_UPDATES,
  QUERY_SERVICE_UPDATES_LOGS,
  UPDATE_INTERNAL_NOTE,
} from '@crimson-education/core-shared-graphql/internalNotes';

const INTERNAL_NOTE = gql`
  fragment InternalNoteFields on InternalNotes {
    id
    missionId
    content
    creatorUid
    studentUid
    createdAt
    updatedAt
    category
    subCategory
    status
    solvedAt
    solvedBy
    tasksCount
  }
`;

export const UPDATE_ESCALATION = gql`
  ${INTERNAL_NOTE}
  mutation updateEscalation($input: EscalationInput!) {
    note: updateEscalation(input: $input) {
      ...InternalNoteFields
    }
  }
`;

export const GET_ESCALATION_STUDENTS_BY_STATUS = gql`
  query queryEscalationStudentsByStatus($status: [InternalNotesStatus!]) {
    queryEscalationStudentsByStatus(status: $status)
  }
`;
