import { useState, useEffect, useCallback, useRef } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;
type Props = {
  name?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeProps?: (value: any) => void;
  rows?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autoSize?: any;
  maxLength?: number;
  maxWarningLength?: number;
  disabled?: boolean;
  enterSubmit?: boolean;
  delay?: number;
  textStyle?: React.CSSProperties;
  mode?: 'legacy' | 'sync' | 'async';
};
const EditableTextArea = ({
  value,
  placeholder = 'Enter',
  onChangeProps,
  autoSize,
  maxWarningLength,
  disabled = false,
  mode = 'legacy', // legacy mode
  enterSubmit = true,
  textStyle,
  delay = 1000,
  ...rest
}: Props): JSX.Element => {
  // legacy==> only submit when pressenter(when cofig set) and blur
  // sync==> always submit when change(its ok when submit is sync function)
  // async==> do debounced submit with specific delay
  mode = ['legacy', 'sync', 'async'].includes(mode) ? mode : 'legacy';
  const [showValue, setShowValue] = useState('');
  const submitTimerRef = useRef<NodeJS.Timeout | null>(null);
  const lastSubmit = useRef<string>('');
  const focus = useRef<boolean>(false);
  useEffect(() => {
    if (!focus.current || mode === 'legacy') {
      setShowValue(value);
    }
  }, [value, mode]);
  const doSubmit = useCallback(() => {
    if (String(showValue) === lastSubmit.current) {
      return;
    }
    lastSubmit.current = String(showValue);
    onChangeProps?.(showValue);
  }, [showValue, onChangeProps]);
  const handleBlur = useCallback(() => {
    focus.current = false;
    if (mode === 'async') {
      submitTimerRef.current && clearTimeout(submitTimerRef.current);
    }
    doSubmit();
  }, [doSubmit, mode]);
  useEffect(() => {
    // auto-submit-logic
    // for legacy mode, only blur or enter-press could trigger submit
    // for sync mode, every input will trigger submit immediatly
    // for async mode, submit will be debounced
    if (mode === 'legacy' || showValue === value || !focus.current || typeof onChangeProps !== 'function') {
      return;
    }
    if (mode === 'sync') {
      // no time delay
      doSubmit();
      return;
    }
    if (mode === 'async') {
      submitTimerRef.current && clearTimeout(submitTimerRef.current);
      submitTimerRef.current = setTimeout(() => {
        doSubmit();
        submitTimerRef.current = null;
      }, delay);
    }
  }, [doSubmit, showValue, onChangeProps, value, mode, delay]);
  return (
    <>
      <TextArea
        value={showValue}
        placeholder={placeholder}
        bordered={false}
        onChange={(e) => {
          const value = e.target.value;
          setShowValue(value);
        }}
        autoSize={autoSize}
        onPressEnter={() => {
          mode === 'legacy' && enterSubmit && doSubmit();
        }}
        onFocus={() => {
          focus.current = true;
        }}
        onBlur={handleBlur}
        disabled={disabled}
        style={textStyle}
        {...rest}
      />
      {maxWarningLength && (
        <div style={{ float: 'right', color: '#A9ACC0', paddingTop: '2px' }}>
          {showValue && showValue.length > maxWarningLength ? (
            <span style={{ color: '#ED4B53' }}>{showValue ? showValue.length : 0}</span>
          ) : (
            <span>{showValue ? showValue.length : 0}</span>
          )}
          /{maxWarningLength}
        </div>
      )}
    </>
  );
};

export default EditableTextArea;
