import { Close } from '@styled-icons/material';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

type Props = {
  width?: number;
  height?: number;
  onClick?: MouseEventHandler;
};
const StyledIcon = styled(Close)<{ width: number; height: number }>`
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  height: ${(props) => (props?.height ? `${props.height}px` : 'auto')};
  color: #1d1e2b;
  cursor: pointer;
`;
const CloseIcon = ({ width = 20, height = 20, onClick }: Props): JSX.Element => {
  return <StyledIcon width={width} height={height} onClick={onClick} />;
};

export default CloseIcon;
