import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { GET_USERS_BY_IDS } from 'src/graphql/User';
import _ from 'lodash';
import dayjs from 'dayjs';
import ExpandMoreIcon from 'src/web-shared-components/base/Icons/ExpandMoreIcon';
import {
  StyledActivityLogDate,
  StyledActivityLogDesc,
  StyledActivityLogItem,
  StyledTitle,
  StyledViewMoreButton,
  StyledViewMoreContainer,
} from './style';
import { ActivityLogType, MissionDetailObjectType } from 'src/types/mission';
import { templates } from './templates';
import { applyTemplate, hasTemplate, rbacCanAccess } from 'src/utils/templates';
import { CurrentUserContext } from 'src/context/CurrentUserContext';

const perCount = 4;
export default function ActivityLog({ mission }: { mission: MissionDetailObjectType }): JSX.Element {
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { refetch: refetchGetUserByIds } = useQuery(GET_USERS_BY_IDS, { client: crimsonAppApiClient, skip: true });

  // step 1: find userId in values
  // step 2: fetch userName by userId
  // step 3: create a new variable named values={userId: newUserName}
  // step 4 use string replace to replace it.
  const [dataSource, setDataSource] = useState<ActivityLogType[]>([]);
  const [visibleDataSource, setVisibleDataSource] = useState<ActivityLogType[]>([]);
  const [currentVisibleCount, setCurrentVisibleCount] = useState(0);

  useEffect(() => {
    const roles = currentUser?.roles.map((role) => role.roleId);
    async function fetchData() {
      if (mission) {
        const { activityLogs } = mission;
        const sortedActivityLogs = _.orderBy(activityLogs, (v) => new Date(v.createdAt), ['desc']);
        setDataSource(
          sortedActivityLogs.filter(
            (v) => hasTemplate(templates, v.template) && rbacCanAccess(templates, { roles, code: v.template.code }),
          ),
        );
      }
    }
    fetchData();
  }, [mission, refetchGetUserByIds, currentUser]);

  useEffect(() => {
    if (dataSource) {
      if (dataSource.length > perCount) {
        setVisibleDataSource(dataSource.slice(0, perCount));
        setCurrentVisibleCount(perCount);
      } else {
        setCurrentVisibleCount(dataSource.length);
        setVisibleDataSource(dataSource);
      }
    }
  }, [dataSource]);
  const viewMore = () => {
    const nextCount = currentVisibleCount + perCount;
    if (dataSource.length > nextCount) {
      setVisibleDataSource(dataSource.slice(0, nextCount));
      setCurrentVisibleCount(nextCount);
    } else {
      setCurrentVisibleCount(dataSource.length);
      setVisibleDataSource(dataSource);
    }
  };
  return (
    <div>
      <StyledTitle>Activity Log</StyledTitle>
      {visibleDataSource.map((data) => {
        const {
          values,
          template: { code },
        } = data;
        return (
          <StyledActivityLogItem key={data.id}>
            <StyledActivityLogDesc style={{ color: '#73747D' }}>
              {applyTemplate(templates, { code, values })}
            </StyledActivityLogDesc>
            <StyledActivityLogDate>
              {dayjs(data.createdAt).format('MMM DD')} at {dayjs(data.createdAt).format('hh:mm a')}
            </StyledActivityLogDate>
          </StyledActivityLogItem>
        );
      })}
      {currentVisibleCount < dataSource.length ? (
        <StyledViewMoreContainer>
          <StyledViewMoreButton onClick={viewMore}>
            View More
            <ExpandMoreIcon width={20} />
          </StyledViewMoreButton>
        </StyledViewMoreContainer>
      ) : null}
    </div>
  );
}
