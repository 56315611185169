import styled from 'styled-components';

export const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.3px;

  > img {
    transition: all 0.4s;
  }
  > img:hover {
    scale: 1.15;
  }
`;
