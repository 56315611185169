import { gql } from '@apollo/client';

export const QUERY_ECL_BY_IDS_AND_TYPES = gql`
  query GetEclResourcesByIdsAndType($ids: [String!]) {
    eclResources: getEclResourcesByIdsAndType(ids: $ids) {
      id
      title
      categories
      programStartDate
      programEndDate
      competitiveness
      requirements
      eligibility
      description
      ageGroupMin
      ageGroupMax
      ageGroupRemarks
      programTimeline
      locationRemarks
      inWatchlist
      registrationDeadline
      website
      location
      organizer
      awards {
        id
        displayName
        tier
        division {
          id
          name
          levelOfRecognition
        }
      }
      divisions {
        id
        name
        levelOfRecognition
      }

      costs
      forCredit
      impactFactor
      learningFormat
      occurrences {
        programEnd
        programStart
        registrationEnd
        registrationStart
        notes
        status
        title
      }
      prizes
    }
  }
`;

export const CREATE_COMPETITION = gql`
  mutation CreateStudentHonorByEclCompetition(
    $studentId: String!
    $input: EclCompetitionHonorConversionInput!
    $status: String!
  ) {
    res: createStudentHonorByEclCompetition(studentId: $studentId, input: $input, status: $status) {
      id
      userId
      missionId
      category
      award
      levelOfRecognition
      awardLevel
      schoolYear
      resourceId
    }
  }
`;

export const CREATE_SUMMER_PROGRAM = gql`
  mutation CreateStudentActivityByEclSummerProgram(
    $studentId: String!
    $input: EclSummerProgramActivityConversionInput!
    $status: String!
  ) {
    res: createStudentActivityByEclSummerProgram(studentId: $studentId, input: $input, status: $status) {
      id
      userId
      missionId
      type
      credit
      actualGrade
      targetGrade
      maxGrade
      gradeFormat
      applicationPeriodStart
      applicationPeriodEnd
    }
  }
`;

export const GET_ECL_CATEGORIES = gql`
  query getEclResourceCategories {
    getEclResourceCategories {
      id
      name
    }
  }
`;

export const GET_ACTIVITY_HOURS_BY_YEAR = gql`
  query queryActivityHoursByYear($studentUid: String!) {
    queryActivityHoursByYear(studentUid: $studentUid) {
      year
      hours
    }
  }
`;
