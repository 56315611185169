import { Drawer, DrawerProps, Skeleton, Tooltip } from 'antd';
import { Close, KeyboardArrowRight } from '@styled-icons/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  DrawerHeader,
  HeaderContainer,
  PathfinderGoalList,
  CardListContainer,
  CardContainer,
  CardDescription,
  LevelPoints,
  LevelTitle,
  PointsAndLevelContainer,
  DrawerContentContainer,
  IdeaSection,
  IdeaSectionList,
  PathfinderGoalItem,
  Category,
  CardHeader,
  Points,
  DivisionBadge,
  StyledSearch,
  HowToContainer,
  LoadingContainer,
} from './index.style';
import {
  LevelType,
  firstCharUpperCase,
  getTailCharS,
  skeletonLength,
  listArrowRightStyle,
  categoryArrowRightStyle,
} from './index.util';
import md from 'markdown-it';
import { ExplorePathfinderIdeasContext } from 'src/context/ExplorePathfinderIdeasContext';
import { GET_IDEAS } from 'src/graphql/Pathfinder';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { CheckCircle, EmptyPlaceHolder, GoldMedal, LightbulbCircle } from './Icons';
import { EmptyIcon } from '../PathfinderPointHistoryModal/Icons';

type IdeaDataType = {
  category: string;
  subcategory: string;
  ideas: Array<Idea>;
};

type Idea = {
  id: string;
  title: string;
  achieved: number;
  total: number;
  category: string;
  subcategory: string;
  howTo: string;
  hidePoints: boolean;
  levels: Array<LevelType>;
};

const ExploreAllPathfinderIdeas = ({
  visible,
  setDrawerVisible,
  onDrawerClose,
  pathfinderType,
  ...rest
}: DrawerProps & {
  onDrawerClose: () => void;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pathfinderType: string;
}): JSX.Element => {
  const { setVisible } = useContext(ExplorePathfinderIdeasContext);
  const [levels, setLevels] = useState<Array<LevelType>>([]);
  const [goalTitle, setGoalTitle] = useState('');
  const [howTo, setHowTo] = useState('');
  const [hidePoints, setHidePoints] = useState(true);
  const { studentInfo } = useContext(CurrentUserContext);
  const [ideasData, setIdeasData] = useState<Array<IdeaDataType> | undefined>(undefined);
  const [keyWords, setKeyWords] = useState('');
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const {
    data,
    loading: ideasLoading,
    refetch,
  } = useQuery<{ getInspirationIdeas: Array<IdeaDataType> }>(GET_IDEAS, {
    variables: {
      studentId: studentInfo?.userId,
      keyword: keyWords,
      pathfinderType,
      // category,
    },
    skip: !studentInfo?.userId || !visible,
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (keyWords && visible) {
      refetch();
    }
  }, [visible, keyWords, refetch]);
  useEffect(() => {
    if (!ideasData && visible) {
      refetch();
    }
  }, [visible, ideasData, refetch]);
  useEffect(() => {
    if (!ideasLoading) {
      setIdeasData(data?.getInspirationIdeas || []);
    } else {
      setGoalTitle('');
      setHidePoints(true);
      setSkeletonLoading(true);
      setTimeout(() => {
        setSkeletonLoading(false);
      }, 1000);
    }
  }, [data, ideasLoading]);

  const handleDrawerClose = () => {
    setVisible(false);
    setIdeasData(undefined);
    setGoalTitle('');
    setHowTo('');
    setHidePoints(true);
    setKeyWords('');
    setLevels([]);
    onDrawerClose();
  };

  return (
    <Drawer
      {...rest}
      placement="right"
      onClose={() => {
        setDrawerVisible(false);
        handleDrawerClose();
      }}
      visible={visible}
      destroyOnClose
    >
      <DrawerHeader>
        <Close
          style={{
            width: '14px',
            height: '14px',
            color: 'var(--color-stone)',
            cursor: 'pointer',
            position: 'absolute',
            top: '25px',
            right: '25px',
          }}
          onClick={() => {
            setDrawerVisible(false);
            handleDrawerClose();
          }}
        />
      </DrawerHeader>
      <HeaderContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Pathfinder Goals & Points
          <DivisionBadge>{pathfinderType === 'US' ? pathfinderType : 'UK/EU'}</DivisionBadge>
        </div>
        <div
          style={{
            border: '1px solid #E3E7ED',
            boxShadow: '0px 1px 2px rgba(184, 200, 224, 0.2)',
            borderRadius: '30px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '24px',
          }}
        >
          <StyledSearch
            bordered={false}
            allowClear
            style={{ width: '277px' }}
            placeholder="Search by Keywords"
            onPressEnter={(e) => setKeyWords(e?.target?.value)}
            onSearch={(value) => setKeyWords(value)}
          />
        </div>
      </HeaderContainer>
      <DrawerContentContainer>
        <PathfinderGoalList>
          {skeletonLoading || ideasLoading ? (
            <LoadingContainer>
              {Array.from({ length: skeletonLength }, (...args) => args[1]).map((item) => (
                <Skeleton.Button
                  key={item}
                  active={true}
                  size="large"
                  shape="default"
                  block={true}
                  style={{
                    height: '48px',
                    border: '1px solid white',
                    marginBottom: '12px',
                  }}
                />
              ))}
            </LoadingContainer>
          ) : (
            <LoadingContainer>
              {ideasData?.length === 0 && (
                <div
                  style={{
                    height: '550px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <EmptyIcon />
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: 'var(--color-stone)',
                      marginTop: '40px',
                      maxWidth: 425,
                      textAlign: 'center',
                    }}
                  >
                    No matches found, please try a different word.
                  </p>
                </div>
              )}
              {ideasData?.length !== 0 &&
                ideasData?.map((idea) => (
                  <IdeaSectionList key={`${idea.category}-${idea.subcategory}`}>
                    <Category>
                      <span
                        style={{
                          color: 'var(--color-cool-grey)',
                        }}
                      >
                        {idea.category}
                      </span>
                      <KeyboardArrowRight style={categoryArrowRightStyle} />
                      {idea.subcategory}
                    </Category>
                    <IdeaSection>
                      {idea.ideas.map((goal) => (
                        <PathfinderGoalItem
                          key={goal.title}
                          onClick={() => {
                            setLevels(goal?.levels);
                            setGoalTitle(goal?.title);
                            setHowTo(goal?.howTo);
                            setHidePoints(goal?.hidePoints);
                          }}
                        >
                          <Tooltip title={goal.title} placement="top">
                            <div>{goal.title}</div>
                          </Tooltip>
                          <Points>
                            <span className={goal?.achieved > 0 ? 'achieved' : 'total'}>{goal?.achieved}</span>
                            <span className="total">/{goal?.total}</span>
                          </Points>
                          <KeyboardArrowRight style={listArrowRightStyle} />
                        </PathfinderGoalItem>
                      ))}
                    </IdeaSection>
                  </IdeaSectionList>
                ))}
            </LoadingContainer>
          )}
        </PathfinderGoalList>
        <CardListContainer>
          {goalTitle ? (
            <>
              <CardHeader>
                <div className="goal-title">{goalTitle}</div>
                <div className="level-points">Levels & Points</div>
              </CardHeader>
              {levels &&
                levels.map((level) => (
                  <CardContainer
                    key={level.level}
                    style={{
                      background: level?.status === 'Achieved' ? 'rgba(115, 116, 125, 0.05)' : 'white',
                      color: level?.status === 'Achieved' ? 'var(--color-cool-grey)' : 'var(--color-dark-navy)',
                    }}
                  >
                    <CardDescription>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: md({
                            html: true,
                          }).render(level.description as string),
                        }}
                      ></div>
                    </CardDescription>
                    <PointsAndLevelContainer>
                      <LevelPoints>
                        {!hidePoints && (
                          <GoldMedal
                            style={{
                              marginRight: '8px',
                              display: 'flex',
                            }}
                          />
                        )}
                        {!hidePoints && getTailCharS(level.points, 'point')}
                        {level?.status === 'Achieved' && (
                          <div
                            style={{
                              fontFamily: 'Montserrat',
                              fontStyle: 'italic',
                              fontSize: '14px',
                              lineHeight: '20px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircle
                              style={{
                                marginLeft: '4px',
                                marginRight: '8px',
                              }}
                            />
                            Achieved
                          </div>
                        )}
                      </LevelPoints>
                      <LevelTitle>{firstCharUpperCase(level.level)}</LevelTitle>
                    </PointsAndLevelContainer>
                  </CardContainer>
                ))}
              {howTo && (
                <HowToContainer>
                  <p className="row bold-row">
                    <LightbulbCircle
                      style={{
                        marginRight: '4px',
                      }}
                    />
                    How to:
                  </p>
                  <p className="row">{howTo}</p>
                </HowToContainer>
              )}
            </>
          ) : (
            <section
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <EmptyPlaceHolder />
              <div
                style={{
                  width: '330px',
                  fontFamily: 'Montserrat',
                  lineHeight: '20px',
                  color: 'var(--color-stone)',
                  marginTop: '40px',
                  textAlign: 'center',
                  marginLeft: '77px',
                }}
              >
                Select a goal from the list on the left to view details, or search by keyword from the top bar.
              </div>
            </section>
          )}
        </CardListContainer>
      </DrawerContentContainer>
    </Drawer>
  );
};

export default ExploreAllPathfinderIdeas;
