import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Tag = styled(motion.div)`
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  max-width: 237px;
  height: 25px;

  background: #f4f5ff;
  border-radius: 20px;
  transition: opacity 0.4s;
  :hover {
    opacity: 0.7;
  }
  font-size: 11px;
  line-height: 17px;
  color: #464ac9;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
