import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { NavigateNext } from '@styled-icons/material';
import theme from 'src/web-shared-components/theme';
import { InputRef } from 'antd';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { CategoryOverviewCommunicationContext } from 'src/context/CategoryOverviewCommunicationContext';
import {
  DELETE_ACTION_ITEM,
  CHANGE_ACTION_ITEM_STATUS,
  CHANGE_ACTION_ITEM_DESC,
  CHANGE_ACTION_ITEM_DATE,
} from 'src/graphql/ActionItems';
import { ActionItem as ActionItemType, ActionItemStatus } from 'src/types/mission';
import RemoveButton from '../RemoveButton';
import {
  Checkbox,
  RangePickerPopover,
  RangePicker,
  ChangeDate,
  DescSpan,
  RemoveButtonContainer,
  TaskTextArea,
} from './style';

type Props = {
  loadActionItems: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      items: ActionItemType[];
    }>
  >;
  editPermitted: boolean;
  deletePermitted: boolean;
} & (
  | {
      item: Omit<ActionItemType, 'id'> & { id?: string };
      pending: true; // determing if the item is pending or not, if it is pending, it should not be able to be edited or deleted
    }
  | {
      item: ActionItemType;
      pending?: undefined;
    }
);

const ActionItem: React.FC<Props> = ({ loadActionItems, ...props }) => {
  const { item } = props;
  const { setRefetch } = useContext(CategoryOverviewCommunicationContext);
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const [deleteActionItem] = useMutation(DELETE_ACTION_ITEM, { client: roadmapApiClient });
  const [changeActionItemStatus] = useMutation(CHANGE_ACTION_ITEM_STATUS, { client: roadmapApiClient });
  const [changeActionItemDescription] = useMutation(CHANGE_ACTION_ITEM_DESC, { client: roadmapApiClient });
  const [changeActionItemDueDate] = useMutation(CHANGE_ACTION_ITEM_DATE, { client: roadmapApiClient });

  return (
    <ActionItemView
      {...props}
      onChangeDescription={async (description) => {
        await changeActionItemDescription({
          variables: {
            actionItemId: item.id,
            description,
          },
        });
        loadActionItems();
      }}
      onChangeDueDate={async ([startAt, dueDate]) => {
        await changeActionItemDueDate({
          variables: {
            actionItemId: item.id,
            startAt,
            dueDate,
          },
        });
        loadActionItems();
      }}
      onChangeStatus={async (status) => {
        await changeActionItemStatus({
          variables: {
            actionItemId: item.id,
            status: status,
          },
        });
        loadActionItems();
        setRefetch(true);
      }}
      onDelete={async () => {
        await deleteActionItem({
          variables: {
            actionItemId: item.id,
          },
        });
        loadActionItems();
        setRefetch(true);
      }}
    />
  );
};

type DateRange = [start: moment.Moment | null, end: moment.Moment | null];

type ActionItemViewBaseProps = {
  editPermitted: boolean;
  deletePermitted: boolean;
  descriptionProps?: {
    checked?: boolean;
    fullWidth?: boolean;
  };
  onChangeDescription: (newDescription: string) => void | Promise<void>;
  onChangeDueDate: (newRange: DateRange) => void | Promise<void>;
  onChangeStatus: (newStatus: ActionItemStatus) => void | Promise<void>;
  onDelete: () => void | Promise<void>;
};

type ActionItemViewItemProps =
  | {
      item: Omit<ActionItemType, 'id'> & { id?: string };
      pending: true; // determing if the item is pending or not, if it is pending, it should not be able to be edited or deleted
    }
  | {
      item: ActionItemType;
      pending?: undefined;
    };

export const ActionItemView = (props: ActionItemViewBaseProps & ActionItemViewItemProps): JSX.Element => {
  const {
    editPermitted,
    deletePermitted,
    descriptionProps,
    item,
    pending,
    onChangeDescription,
    onChangeDueDate,
    onChangeStatus,
    onDelete,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [checked, setChecked] = useState(item.status === ActionItemStatus.DONE);

  const submitDesc = async (e: React.FocusEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    await onChangeDescription(value);
    setIsEditing(false);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', opacity: pending ? 0.7 : 1 }}>
      <Checkbox
        onChange={async (e) => {
          if (pending) return;
          const value = e.target.checked;
          setChecked(value);
          let status = ActionItemStatus.PLANNED;
          if (value === true) {
            status = ActionItemStatus.DONE;
          }
          await onChangeStatus(status);
        }}
        checked={checked}
        disabled={pending ?? !editPermitted}
      />

      <div style={{ flex: '1 1 auto', marginRight: 20 }}>
        {isEditing ? (
          <TaskTextArea
            ref={inputRef}
            autoSize={{ minRows: 1, maxRows: 7 }}
            bordered={false}
            defaultValue={item.description as string}
            onPressEnter={submitDesc}
            onBlur={submitDesc}
            autoFocus
            disabled={pending ?? !editPermitted}
          />
        ) : (
          <DescSpan
            checked={item.status === ActionItemStatus.DONE}
            {...descriptionProps}
            onClick={() => {
              if (pending) return;
              editPermitted && setIsEditing(true);
            }}
          >
            {item.description}
          </DescSpan>
        )}
      </div>

      <RangePickerPopover
        trigger="hover"
        content={
          <RangePicker
            allowEmpty={[true, true]}
            bordered={false}
            format="MMM DD, YYYY"
            allowClear={false}
            value={[
              item.startAt ? moment(item.startAt as string) : moment(),
              item.dueDate ? moment(item.dueDate as string) : null,
            ]}
            onChange={async (dates) => {
              if (pending) return;
              if (dates) {
                await onChangeDueDate([dates[0] || null, dates[1] || null]);
              }
            }}
            disabled={pending ?? !editPermitted}
          />
        }
      >
        <ChangeDate>
          <span>
            {item.dueDate
              ? `${moment(item.dueDate).format('MMM DD, YYYY')}`
              : editPermitted && <span style={{ color: `${theme.common.salmon}` }}>Set date</span>}
          </span>
          {editPermitted && <NavigateNext height={20} />}
        </ChangeDate>
      </RangePickerPopover>

      {!pending && !item.type?.startsWith('cri') && deletePermitted && (
        <RemoveButtonContainer>
          <RemoveButton
            onConfirm={() => onDelete()}
            removeButtonText="Remove this task"
            removeModalText="Are you sure you want to remove this task?"
          />
        </RemoveButtonContainer>
      )}
    </div>
  );
};

export default ActionItem;
