import PropTypes from 'prop-types';
import { ReactElement, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { DetailedUser, User } from './types/user';
import { fetchAppcuesInfo } from './graphql/Appcues';

export default function AppcuesLoader(props: { loggedInUser: DetailedUser; studentInfo: User }): ReactElement {
  const { loggedInUser, studentInfo } = props;
  const { crimsonAppApiClient } = useContext(ApolloClientContext);

  const { data: _rawStudentDetail } = useQuery(fetchAppcuesInfo, {
    variables: {
      userId: studentInfo.userId,
    },
    client: crimsonAppApiClient,
  });

  const studentInfoDetail = { ..._rawStudentDetail?.user, ..._rawStudentDetail?.demographic };

  useEffect(() => {
    (async () => {
      if (window.Appcues && studentInfo?.userId) {
        const { userId } = loggedInUser;
        const appcuesIdentityInfo = {
          user_roles: loggedInUser.userRoles?.join('|'),
          name: loggedInUser.fullName,
          tenant_id: loggedInUser.tenant?.id,
          tenant_name: loggedInUser.tenant?.name,
          tenant_level: loggedInUser.tenant?.level,
          email: loggedInUser.email,
          student_id: studentInfoDetail?.userId,
          student_name: studentInfoDetail?.fullName,
          student_email: studentInfoDetail?.email,
          student_basic_info_filled: Boolean(
            studentInfoDetail?.gender &&
              studentInfoDetail?.location &&
              studentInfoDetail?.timezone &&
              studentInfoDetail?.studentInfo?.curriculums?.length > 0 &&
              studentInfoDetail?.dob,
          ),
          student_applications_filled: Boolean(
            studentInfoDetail?.studentInfo?.yearOfApplication &&
              (studentInfoDetail?.studentInfo?.major || !!studentInfoDetail?.studentInfo?.majors?.length),
          ),
          student_contact_details_filled: Boolean(studentInfoDetail?.phone && studentInfoDetail?.country),
          student_demographics_filled: Boolean(
            studentInfoDetail?.countriesCitizenship?.length > 0 && studentInfoDetail?.englishProficiency,
          ),
        };
        if (userId) {
          window.Appcues.identify(userId, appcuesIdentityInfo);
        }
      }
    })();
  });
  return <div></div>;
}

AppcuesLoader.propTypes = {
  loggedInUser: PropTypes.object,
  studentInfo: PropTypes.object,
};
