import {
  Controller,
  EclDetailQueryStatus,
  CreateCompetitionInput,
  CreateCompetitionResponse,
  CreateSummerProgramInput,
  CreateSummerProgramResponse,
} from './type';
import { Actions, TStore } from './store';
import { QUERY_ECL_BY_IDS_AND_TYPES, CREATE_COMPETITION, CREATE_SUMMER_PROGRAM } from 'src/graphql/Ecl';
import { studentCenterApiClient } from 'src/graphql';
import { EclResource, MissionCategory, MissionSubCategory } from '../type';
import { difference } from 'lodash';
import { Categories, Subcategories } from 'src/types/mission';
import { trackEvent } from '@crimson-education/browser-logger';
import { ResourceCenterEvents } from '../constants';

export class ResourceCenterController extends Controller<TStore, Actions> {
  openListDrawer = (missionCategory: MissionCategory, missionSubCategory: MissionSubCategory): void => {
    trackEvent({
      message: ResourceCenterEvents.openResourceCenter,
      metadata: {
        missionCategory,
        missionSubCategory,
      },
    });
    this.dispatch?.('resourceCenter/setListDrawerOpen', {
      listDrawerOpen: true,
      missionCategory,
      missionSubCategory,
    });
  };
  closeListDrawer = (): void => {
    this.dispatch?.('resourceCenter/setListDrawerOpen', {
      listDrawerOpen: false,
      missionCategory: Categories.COMPETITIONS_HONORS,
      missionSubCategory: Subcategories.Honor,
    });
  };

  closeAll = (): void => {
    this.dispatch?.('resourceCenter/closeAll');
  };

  openDetailDrawer = (eclId: string): void => {
    trackEvent({
      message: ResourceCenterEvents.viewDetail,
      metadata: {
        resourceId: eclId,
      },
    });
    this.dispatch?.('resourceCenter/openDetailDrawer', {
      detailDrawerOpen: true,
      detailEclId: eclId,
    });
  };
  closeDetailDrawer = (): void => {
    this.dispatch?.('resourceCenter/closeDetailDrawer', {
      detailDrawerOpen: false,
      detailEclId: null,
    });
  };

  openAddMissionModal = (eclId: string): void => {
    this.dispatch?.('resourceCenter/openAddMissionModal', {
      addMissionEclId: eclId,
      addMissionModalOpen: true,
    });
  };
  closeAddMissionModal = (): void => {
    this.dispatch?.('resourceCenter/closeAddMissionModal', {
      addMissionEclId: null,
      addMissionModalOpen: false,
    });
  };

  onCompetitionCreated = (): void => {
    this.dispatch?.('resourceCenter/increaseCreatedCompetitionCount');
  };
  onSummerProgramCreated = (): void => {
    this.dispatch?.('resourceCenter/increaseCreatedSummerProgramCount');
  };

  _constructEclQueryStatus = (ids: string[], status: 'idle' | 'loading' | 'error'): EclDetailQueryStatus => {
    const idsWithStatus: Record<string, typeof status> = {};
    ids.forEach((id) => {
      idsWithStatus[id] = status;
    });
    return idsWithStatus;
  };

  setEclQueryStatus = (ids: string[], status: 'idle' | 'loading' | 'error'): void => {
    const idsWithStatus = this._constructEclQueryStatus(ids, status);
    this.dispatch?.('resourceCenter/setEclResourceQueryStatus', idsWithStatus);
  };

  queueEclIdsToQueryDetail = (ids: string[]): void => {
    if (!ids.length || !this.state) return;
    const { eclResourceQueryStatus, eclResourceDeatil } = this.state;
    const idsWithStatus = Object.keys(eclResourceQueryStatus);
    const idsHaveDetail = Object.keys(eclResourceDeatil);
    const idsToAdd = difference(ids, idsWithStatus, idsHaveDetail);
    if (!idsToAdd.length) return;
    this.setEclQueryStatus(idsToAdd, 'idle');
  };

  removeIdsFromEclQueryStatus = (ids: string[]): void => {
    if (!ids.length || !this.state) return;
    const { eclResourceQueryStatus } = this.state;
    const filtered: EclDetailQueryStatus = {};
    Object.keys(eclResourceQueryStatus)
      .filter((id) => !ids.includes(id))
      .forEach((id) => {
        filtered[id] = eclResourceQueryStatus[id];
      });
    this.dispatch?.('resourceCenter/replaceEclQueryStatus', {
      eclResourceQueryStatus: filtered,
    });
  };

  queryEclDetail = async (ids: string[]): Promise<void> => {
    if (!ids.length || !this.state) return;
    try {
      this.setEclQueryStatus(ids, 'loading');
      const {
        data: { eclResources },
        errors,
      } = await studentCenterApiClient.query({
        query: QUERY_ECL_BY_IDS_AND_TYPES,
        variables: {
          ids,
        },
      });
      if (errors?.length) throw errors;
      const constructed: Record<string, EclResource> = {};
      (eclResources as EclResource[]).forEach((ecl) => {
        constructed[ecl.id] = ecl;
      });
      this.removeIdsFromEclQueryStatus(ids);
      this.dispatch?.('resourceCenter/setEclResourceDetail', constructed);
    } catch (err) {
      // handle error
      this.removeIdsFromEclQueryStatus(ids);
    }
  };

  createCompetition = async (
    studentId: string,
    status: string,
    input: CreateCompetitionInput,
  ): Promise<CreateCompetitionResponse> => {
    const {
      data: { res },
      errors,
    } = await studentCenterApiClient.mutate({
      mutation: CREATE_COMPETITION,
      variables: { studentId, status, input },
    });
    if (!!errors?.length) throw errors;
    return res;
  };

  createSummerProgram = async (
    studentId: string,
    status: string,
    input: CreateSummerProgramInput,
  ): Promise<CreateSummerProgramResponse> => {
    const {
      data: { res },
      errors,
    } = await studentCenterApiClient.mutate({
      mutation: CREATE_SUMMER_PROGRAM,
      variables: {
        studentId,
        status,
        input,
      },
    });
    if (!!errors?.length) throw errors;
    return res;
  };
}
