import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: 592px;
`;
export const StyledSection = styled.div<{ loading?: boolean }>`
  padding: 24px;
  border: 1px solid var(--color-pale-grey);
  border-radius: 8px;
  background: #ffffff;
  margin-bottom: 8px;
  position: relative;
  opacity: ${({ loading }) => (loading ? 0.6 : 1.0)};
  transition: 0.3s ease opacity;
`;
export const StyledSectionHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const StyleSectionHeaderTitle = styled.span`
  align-items: center;
  color: #000000;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
  img {
    height: 25px;
    margin-right: 14px;
  }
`;
export const StyledDashedContent = styled.div`
  padding: 12px;
  background: rgba(244, 245, 255, 0.5);
  border: 1px dashed var(--color-pale-grey);
  border-radius: 8px;
`;
export const StyledColorStone = styled.div`
  color: var(--color-stone);
`;
export const StyleContentTitle = styled.div`
  color: var(--color-dark-navy);
  font-weight: 600;
`;
export const StyledEmptyContainer = styled.div`
  height: 464px;
  background: #ffffff;
  border: 1px solid var(--color-pale-grey);
  border-radius: 8px;
  text-align: center;
`;
