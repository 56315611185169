import { gql } from '@apollo/client';

export const QUERY_LATEST_COMPLETE_SESSION = gql`
  query getLatestCompleteSession($userId: String!) {
    getLatestCompleteSession(userId: $userId) {
      complete
      total
    }
  }
`;

export const QUERY_UPCOMING_SESSION_LIST = gql`
  query getUpcomingSessionList($userId: String!) {
    getUpcomingSessionList(userId: $userId) {
      id
      name
      status
      start
      end
      creatorUserId
      type
      participants {
        userId
        firstName
        lastName
        userRoles
        profileImageUrl
        timezone
        email
        roles {
          userId
          roleId
          isPrimary
          role {
            id
            name
          }
        }
      }
      bookingStatus
    }
  }
`;

export const CONFIRM_BOOKING = gql`
  mutation confirmBooking($eventId: String!, $participantUserId: String!) {
    booking: confirmBooking(eventId: $eventId, participantUserId: $participantUserId) {
      zoomMeetingUrl
      otherUserZoomUrl
    }
  }
`;

export const DECLINE_BOOKING = gql`
  mutation declineBooking($eventId: String!, $participantUserId: String!, $reason: String!) {
    booking: declineBooking(eventId: $eventId, participantUserId: $participantUserId, reason: $reason)
  }
`;

export const QUERY_LAST_MEETING_DAYS_WITH_STRATEGIST = gql`
  query getLastMeetingDaysWithStrategist($userId: String!) {
    getLastMeetingDaysWithStrategist(userId: $userId)
  }
`;

export const QUERY_GET_ADMISSION_GOALS = gql`
  query getAdmissionGoals($userId: String!) {
    getAdmissionGoals(userId: $userId) {
      id
      userId
      targetCountry
      supportSchoolNumber
      goal
    }
  }
`;

export const QUERY_ONBOARDING_QUIZ_RESULT = gql`
  query getCompletedOnboardingQuizResults($userId: String!) {
    result: getCompletedOnboardingQuizResults(userId: $userId) {
      id
      status
      content
    }
  }
`;

// upcoming sessions
export const getUpcomingSessions = gql`
  query fetchEventSchedule($userId: ID!, $date: String, $isReverse: Boolean, $pagination: paginationParams) {
    allEventsSchedule(userId: $userId, date: $date, isReverse: $isReverse, pagination: $pagination) {
      id
      type
      name
      status
      eventStatus
      sessionType
      start
      end
      otherUser {
        userId
        firstName
        lastName
        profileImageUrl
      }
    }
  }
`;

// strategist session
export const getCompletedSessions = gql`
  query completedSessions($studentId: String!, $startDate: String!, $endDate: String!) {
    getSessionsBetweenStudentAndStrategists(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
      id
      sessionNoteLink
      start
      end
      name
      sessionType
      strategist {
        userId
        firstName
        lastName
        profileImageUrl
      }
    }
  }
`;

// tutor session
export const getCompletedLessons = gql`
  query completedLessons($studentId: String!, $startDate: String!, $endDate: String!) {
    getLessonsBetweenStudentAndTutor(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
      tutorId
      tutor {
        userId
        firstName
        lastName
        profileImageUrl
      }
      itemId
      businessScope
      subjectTitle
      count
      lessonsWithReport {
        id
        goal
        progress
        effort
        start
        end
        dynamicFeedbackData
        isRoadblocked
        roadblockDetail
      }
    }
  }
`;

// strategist approve  student report
export const mutationApproveStudentReport = gql`
  mutation submitReportApproval($strategistId: String!, $reportId: String!, $approveAll: Boolean!) {
    submitReportApproval(strategistId: $strategistId, reportId: $reportId, approveAll: $approveAll)
  }
`;

// student report ai summary
export const getAiSummary = gql`
  query MergedReportSummary($reportId: String!) {
    mergedReportSummary(reportId: $reportId) {
      summaryContent
    }
  }
`;

export const updateAiSummary = gql`
  mutation UpdateReportSummary($reportId: String!, $summaryContent: String!) {
    updateReportSummary(reportId: $reportId, summaryContent: $summaryContent) {
      reportId
      summaryContent
    }
  }
`;

// report approval status
export const getReportApprovalStatus = gql`
  query getReportApprovalStatus($reportId: String!) {
    reportApprovalStatus(reportId: $reportId) {
      strategistId
      approved
    }
  }
`;

export const getReportById = gql`
  query studentReportById($reportId: String!) {
    studentReportById(reportId: $reportId) {
      type
      updatedAt
      regenerationCount
    }
  }
`;

// regenerate summary
export const regenerateReportSummary = gql`
  mutation regenerateStudentReportSummary($reportId: String!) {
    regenerateStudentReportSummary(reportId: $reportId) {
      error
      msg
    }
  }
`;
