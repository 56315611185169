import styled from 'styled-components';
import { Menu } from 'antd';
import { AddCircle } from '@styled-icons/material';

export const StyledMenu = styled(Menu)`
  background: linear-gradient(0deg, #f3f6fa, #f3f6fa), linear-gradient(0deg, #e3e7ed, #e3e7ed);
  .ant-dropdown-menu-item {
    color: #73747d;
    &: hover {
      background: transparent;
      color: #464ac9;
    }
  }
`;

export const StyledButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
export const StyledAddCircle = styled(AddCircle)`
  cursor: pointer;
  color: #464ac9;
  width: 20px;
  height: 20px;
`;
