import { useState, useRef } from 'react';
import { useDebounceFn } from 'ahooks';
import { IOptions } from '../selectOptions';
type Props = {
  fetchOptions: (value: string) => Promise<IOptions[]>;
  debounceTimeout?: number;
};
export type useDebounceFetcherReturnType = {
  debounceFetcher: (value: string) => void;
  fetching: boolean;
  options: IOptions[];
};
const useDebounceFetcher = ({ fetchOptions, debounceTimeout }: Props): useDebounceFetcherReturnType => {
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);
  const [options, setOptions] = useState<IOptions[]>([]);

  const { run: debounceFetcher } = useDebounceFn(
    async (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const newOptions = await fetchOptions(value);
      if (fetchId !== fetchRef.current) {
        return;
      }
      setOptions(newOptions);
      setFetching(false);
    },
    {
      wait: debounceTimeout,
    },
  );
  return {
    debounceFetcher,
    fetching,
    options,
  };
};

export default useDebounceFetcher;
