import { gql } from '@apollo/client';

export const SKIP_ROADMAP_BUILDER = gql`
  mutation SkipRoadmapBuilder($roadmapBuilderId: String!) {
    skipRoadmapBuilder(roadmapBuilderId: $roadmapBuilderId) {
      success
    }
  }
`;

export const GET_CURRENT_ACHIEVEMENTS = gql`
  query GetCurrentAchievements($userId: String!) {
    getCurrentAchievements(userId: $userId) {
      summerProgram {
        id
        title
      }
      testing {
        id
        missionId
        type
        highestScore
      }
      academic {
        studentAcademic {
          id
          userId
          missionId
          GPAScale
          actualGPA
          targetGPA
          targetCumulativeGPA
          cumulativeGPA
          GPAWeight
          classSize
          targetClassRank
          actualClassRank
        }
        studentSubjects {
          id
          missionId
          subjectId
          subject {
            id
            name
          }
        }
      }
      reading {
        id
        missionId
        title
        bookCover
      }
      eclActivity {
        id
        title
      }
      honor {
        id
        title
      }
    }
  }
`;
export const UPDATE_RECOMMENDATION_SELECTION = gql`
  mutation updateRecommendationSelection(
    $force: Boolean
    $roadmapBuilderId: String!
    $selection: RoadmapBuilderSelectionInput!
  ) {
    updateRoadmapBuilderRecommendationSelection(
      force: $force
      roadmapBuilderId: $roadmapBuilderId
      selection: $selection
    ) {
      id
    }
  }
`;

export const CREATE_MISSION_FROM_ROADMAP_BUILDER = gql`
  mutation CreateMissionsFromRoadmapBuilder($force: Boolean, $roadmapBuilderId: String!) {
    createMissionsFromRoadmapBuilder(force: $force, roadmapBuilderId: $roadmapBuilderId) {
      eclActivity
      experience
      honor
      reading
      researchPublication
      summerProgram
      testing
    }
  }
`;

export const QUERY_ROADMAPBUIDLER_VISIBLE = gql`
  query RoadmapBuilderVisible($userId: String!) {
    roadmapBuilderVisible(userId: $userId) {
      isInit
      roadmapBuilderVisible
      roadmapBuilderId
      inProgress
    }
  }
`;

export const INVALIDATE_RECOMMENDATIONS = gql`
  mutation InvalidateRoadmapBuilder($roadmapBuilderId: String!) {
    invalidateRoadmapBuilder(roadmapBuilderId: $roadmapBuilderId) {
      success
    }
  }
`;

// N.B. it is important that the order of each category in `recommendation` and
// `recommendationSelection` stays in sync, as otherwise the categories are liable
// to shift around during regeneration
export const GET_RECOMMENDATIONS = gql`
  query GetRoadmapBuilder($roadmapBuilderId: String!, $force: Boolean, $input: GetRoadmapBuilderInput) {
    getRoadmapBuilder(roadmapBuilderId: $roadmapBuilderId, force: $force, input: $input) {
      success
      message
      data {
        id
        recommendation {
          eclActivity {
            id
            name
            majors
            activityType
            description
            position
          }
          experience {
            id
            title
          }
          reading {
            id
            author
            title
          }
          researchPublication {
            id
            title
            question
          }
          summerProgram {
            id
            activityType
            title
            organizer
            programStartDate
            programEndDate
            competitiveness
            ageGroupMin
            ageGroupMax
          }
          honor {
            id
            activityType
            title
            programStartDate
            programEndDate
            competitiveness
            ageGroupMin
            ageGroupMax
          }
          testing {
            id
            name
            testDate
            registrationDeadline
          }
        }
        recommendationSelection {
          eclActivity {
            id
            schoolYears
          }
          experience {
            id
            schoolYears
          }
          reading {
            id
            schoolYears
          }
          researchPublication {
            id
            schoolYears
          }
          summerProgram {
            id
            schoolYears
          }
          honor {
            id
            schoolYears
          }
          testing {
            id
            schoolYears
          }
        }
        recommendationStatus
        status
      }
    }
  }
`;

export const INIT_ROADMAP_BUILDER = gql`
  mutation InitRoadmapBuilder($userId: String!) {
    initRoadmapBuilder(userId: $userId) {
      message
      roadmapBuilderId
      success
    }
  }
`;
