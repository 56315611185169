import styled from 'styled-components';
import { Checkbox, Dropdown, Menu } from 'antd';

import { UserAddOutlined } from '@ant-design/icons';

export const StyledUserAddOutlined = styled(UserAddOutlined)`
  color: #a9acc0;
  &:hover {
    color: #464ac9;
  }
`;
export const StyledCheckbox = styled(Checkbox)``;
export const StyledMemberContainer = styled.div`
  position: relative;
  display: flex;
  > div:not(:first-child) {
    margin-left: -6px;
  }
`;
export const StyledTeamsContainer = styled.div`
  margin-right: 16px;
  .ant-avatar {
    font-size: 12px !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
  }
`;

export const StyledAddMemberNumberIcon = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  span {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    background: #3f8cff;
    border-radius: 50%;
    color: white;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
  }
`;
export const StyledAddMemberIcon = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: 1px dashed #a9acc0;
  width: 28px;
  height: 28px;
  line-height: 28px;
  background: #f3f6fa;
  border-radius: 50%;
  position: relative;
  text-align: center;
  color: #000;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: #464ac9; // theme color: indigo
    border: 1px dashed #464ac9;
  }
`;
export const StyledAddContainer = styled.div`
  position: relative;
`;
export const StyledDropdown = styled.div`
  z-index: 999;
  position: absolute;
  top: 38px;
  width: 250px;
  max-height: 339px;
  background: #ffffff;
  border: 1px solid #e3e7ed;
  box-shadow: 0px 5px 15px rgba(61, 64, 144, 0.2);
  border-radius: 8px;
  padding: 24px 14px 35px 20px;
  overflow: auto;
  color: #1d1e2b;
`;

export const StyledTeamsMemberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
export const StyledTeamsProfile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
`;
export const StyledTeamsDesc = styled.div`
  margin-left: 10px;
  font-size: 12px;
`;
export const StyledTeamsRole = styled.div`
  color: #a9acc0;
`;
export const StyledTeamTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
`;
export const StyledAntDropdown = styled(Dropdown)``;

export const StyledMenu = styled(Menu)`
  width: 100%;
  box-shadow: none;
  border: none;
  max-height: none;
  .ant-dropdown-menu-item {
    padding: 2px 6px 0 6px;
    border-radius: 6px;
  }
  .ant-avatar {
    font-size: 12px !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
  }
  .ant-dropdown-menu-item:first-child:hover {
    background: transparent;
  }
  .ant-dropdown-menu-item {
    margin-bottom: 16px;
  }
  .ant-dropdown-menu-title-content {
    > div {
      margin-top: 0;
    }
  }
`;
