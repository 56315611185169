import Lottie from 'react-lottie-player';
import { ReactNode } from 'react';

import animationData from './animationData.json';

export interface LoadingRocketProps {
  children?: ReactNode;
}

export const LoadingRocket = ({ children }: LoadingRocketProps): JSX.Element => {
  return (
    <>
      <Lottie animationData={animationData} loop play style={{ margin: '0 auto', width: 300, height: 300 }} />
      {children && <div style={{ textAlign: 'center' }}>{children}</div>}
    </>
  );
};
