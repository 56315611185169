import { useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useContext, useEffect } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { QUERY_TEMPLATE_ROADMAP } from 'src/graphql/Roadmap';
import { TemplateRoadmapIdContext } from 'src/context/TemplateRoadmapIdContext';

const useTemplateRoadmapId = (): string | null => {
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const { templateRoadmapId, setTemplateRoadmapId } = useContext(TemplateRoadmapIdContext);
  const { refetch: refetchRoadmap } = useQuery(QUERY_TEMPLATE_ROADMAP, {
    skip: true,
    client: roadmapApiClient,
  });
  useEffect(() => {
    if (!templateRoadmapId) {
      // Perform your API query here to get the roadmapId
      const fetchRoadmapId = async () => {
        try {
          const { data } = await refetchRoadmap();
          const queriedRoadmapId = data?.templateRoadmap?.id;

          if (queriedRoadmapId) {
            setTemplateRoadmapId(queriedRoadmapId);
          } else {
            // Handle error when the queried roadmapId is null
            // You can use Antd notification or any other error handling approach here
            notification.error({
              message: 'Roadmap id is undefined, please refresh the page',
            });
          }
        } catch (error) {
          // Handle error when fetching or parsing the data
          console.error('Error fetching roadmapId:', error);
        }
      };

      fetchRoadmapId();
    }
  }, [refetchRoadmap, templateRoadmapId, setTemplateRoadmapId]);

  return templateRoadmapId;
};

export default useTemplateRoadmapId;
