import styled from 'styled-components';
import { EditOutlined, DownOutlined } from '@ant-design/icons';

export const StyledLabel = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  color: #a9acc0;
  margin-bottom: 6px;
`;

export const StyledFinalGrade = styled.span`
  color: #a9acc0;
`;

export const StyledPlaceholder = styled.span<{ disabled?: boolean; $ssaStatus?: 'warning' }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: #73747d;
  background-color: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '#fff3db' : '')};
  border-bottom: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '1px solid #fdaa02' : '')};
`;

export const StyledField = styled.div`
  display: inline-block;
  margin-right: 40px;
`;

export const StyledEditOutlined = styled(EditOutlined)`
  color: #a9acc0;
  cursor: pointer;
  margin-left: 6px;
`;

export const StyledDownOutlined = styled(DownOutlined)`
  color: #a9acc0;
  cursor: pointer;
  margin-left: 6px;
`;

export const StyledDropdown = styled.div`
  position: relative;
`;

export const StyledDropdownOverlay = styled.div`
  width: 160px;
  position: absolute;
  left: -12px;
  padding: 6px 0;
  background: white;
  border: 1px solid #e3e7ed;
  z-index: 999;
  border-radius: 8px;
`;

export const StyledDropdownOverlayItem = styled.div`
  padding: 5px 12px;
  color: #73747d;
  &:hover {
    cursor: pointer;
    background: #f3f6fa;
  }
`;

export const StyledEducation = styled.div`
  margin-top: 30px;
  margin-bottom: 28px;
`;
