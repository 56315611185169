import styled from 'styled-components';

export const SubHeader = styled.div`
  width: 630px;
  color: #1d1e2b;
  text-align: center;
  margin: 0 auto 24px;
`;
export const StyledMissionCardsContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
`;
export const StyledMissionCard = styled.div`
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  border: 1px dashed var(--color-pale-grey);
  flex: 0 0 49%;
  background: rgba(244, 245, 255, 0.5);
  display: flex;
`;
export const StyledSchoolYearsContainer = styled.div`
  display: flex;
  gap: 4px;
  width: 70px;
`;
export const StyledSchoolYear = styled.div<{ active: boolean }>`
  flex: 1;
  background: ${(props) => (props.active ? 'var(--color-indigo)' : '#dadbf4')};
  height: 6px;
  border-radius: 1px;
`;
export const StyledCategoryText = styled.div`
  font-size: 12px;
  color: var(--color-stone);
`;
export const StyledMissionIcon = styled.div`
  width: 32px;
  margin-right: 12px;
  img {
    width: 100%;
  }
`;
