import { useQuery } from '@apollo/client';

import { Tooltip } from 'antd';

import { LOAD_MISSION } from '@crimson-education/core-shared-graphql';

import { Categories, Subcategories } from 'src/types/mission';

import { StyledHelperTooltipText } from './style';

import { RoadmapBuilderRecommendationCategory } from '../types';

function mapRecommendationCategoryToMissionCategories(category: RoadmapBuilderRecommendationCategory): {
  category: Categories;
  subcategory: Subcategories;
} | null {
  switch (category) {
    case 'eclActivity':
      return { category: Categories.ACTIVITIES, subcategory: Subcategories.Extracurricular_Activities };
    case 'experience':
      return { category: Categories.ACTIVITIES, subcategory: Subcategories.Internship_Experience };
    case 'honor':
      return { category: Categories.COMPETITIONS_HONORS, subcategory: Subcategories.Honor };
    case 'reading':
      return { category: Categories.PERSONAL_DEVELOPMENT, subcategory: Subcategories.Reading };
    case 'researchPublication':
      return { category: Categories.ACADEMICS, subcategory: Subcategories.Research };
    case 'summerProgram':
      return { category: Categories.ACADEMICS, subcategory: Subcategories.University_Programs };
    case 'testing':
      return null;
  }
}

function mapRecommendationCategoryToSecondaryMissionCategories(category: RoadmapBuilderRecommendationCategory): {
  category: Categories;
  subcategory: Subcategories;
} | null {
  switch (category) {
    case 'researchPublication':
      return { category: Categories.ACTIVITIES, subcategory: Subcategories.Research };
    case 'summerProgram':
      return { category: Categories.ACTIVITIES, subcategory: Subcategories.University_Programs };
    default:
      return null;
  }
}

interface MissionListProps {
  category: RoadmapBuilderRecommendationCategory;
  userId: string;
}

export const MissionList = ({ category, userId }: MissionListProps): JSX.Element => {
  const missionCategory = mapRecommendationCategoryToMissionCategories(
    category as RoadmapBuilderRecommendationCategory,
  );
  const secondaryMissionCategory = mapRecommendationCategoryToSecondaryMissionCategories(
    category as RoadmapBuilderRecommendationCategory,
  );

  const { data: primaryMissionList, loading: missionListIsLoading } = useQuery(LOAD_MISSION, {
    variables: {
      userId,
      category: missionCategory?.category,
      subcategory: missionCategory?.subcategory,
    },
    skip: missionCategory === null,
  });
  const { data: secondaryMissionList, loading: secondaryMissionListIsLoading } = useQuery(LOAD_MISSION, {
    variables: {
      userId,
      category: secondaryMissionCategory?.category,
      subcategory: secondaryMissionCategory?.subcategory,
    },
    skip: secondaryMissionCategory === null,
  });

  const isLoadingMissionList =
    missionListIsLoading || (secondaryMissionCategory !== null && secondaryMissionListIsLoading);
  const missionList = [primaryMissionList?.loadMissions, secondaryMissionList?.loadMissions].flat().filter(Boolean);

  if (!missionCategory || isLoadingMissionList || missionList.length === 0) {
    return <></>;
  }

  return (
    <Tooltip
      title={
        <div>
          <ul
            style={{
              listStyleType: 'disc',
              marginLeft: 16,
            }}
          >
            {missionList.map((mission) => (
              <li key={mission.id}>{mission.title}</li>
            ))}
          </ul>
        </div>
      }
      open={isLoadingMissionList ? false : undefined}
      overlayInnerStyle={{
        background: '#1D1E2BCC',
        borderRadius: 8,
        padding: 16,
        width: 360,
      }}
    >
      <StyledHelperTooltipText pending={isLoadingMissionList}>See current missions</StyledHelperTooltipText>
    </Tooltip>
  );
};
