import { useState, useRef, MouseEvent } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { popupModalInfo, ModalReturnType } from 'src/utils/commonModal';
import {
  StyledContainer,
  StyledPopover,
  StyledModalTitle,
  StyledModalDesc,
  StyledButtonGroup,
  StyledModalContainer,
  SubmitButton,
  CancelButton,
} from './style';
export const RemoveConfirmModal = ({
  onClose,
  onConfirm,
  removeModalText,
  removeModalDesc = 'All the related data will be removed once done.',
  title = 'Are you sure',
}: {
  onClose: () => void;
  onConfirm: () => void;
  removeModalText: string;
  removeModalDesc?: string | null;
  title?: string;
}): JSX.Element => {
  return (
    <StyledModalContainer>
      <StyledModalTitle>{title}</StyledModalTitle>
      <StyledModalDesc>
        <div>{removeModalText}</div>
        {removeModalDesc && <div>{removeModalDesc}</div>}
      </StyledModalDesc>
      <StyledButtonGroup>
        <CancelButton width={150} onClick={onClose}>
          Cancel
        </CancelButton>
        <SubmitButton width={150} type="primary" htmlType="submit" onClick={onConfirm}>
          Confirm
        </SubmitButton>
      </StyledButtonGroup>
    </StyledModalContainer>
  );
};
const RemoveButton = ({
  removeButtonText,
  removeModalText,
  onConfirm,
  Icon = <MoreOutlined style={{ color: '#A9ACC0', fontSize: '24px' }} />,
}: {
  removeButtonText: string;
  removeModalText: string;
  onConfirm: () => void;
  Icon?: React.ReactNode;
}): JSX.Element => {
  const removeModalRef = useRef<ModalReturnType | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const togglePopover = (e: MouseEvent) => {
    setShowPopover(!showPopover);
    e.stopPropagation();
  };
  const onModalConfirm = () => {
    removeModalRef.current?.destroy();
    onConfirm();
  };
  const popupRemoveModal = (e: MouseEvent) => {
    setShowPopover(false);
    removeModalRef.current = popupModalInfo({
      width: '500px',
      content: (
        <RemoveConfirmModal
          onClose={() => {
            removeModalRef.current?.destroy();
          }}
          removeModalText={removeModalText}
          onConfirm={onModalConfirm}
        />
      ),
      className: 'popup-modal-info-common-padding',
    });
    e.stopPropagation();
  };
  return (
    <StyledContainer>
      <div onClick={(e) => togglePopover(e)}>{Icon}</div>
      {showPopover && (
        <StyledPopover className="remove-popover" onClick={popupRemoveModal} onMouseOut={togglePopover}>
          {removeButtonText}
        </StyledPopover>
      )}
    </StyledContainer>
  );
};

export default RemoveButton;
