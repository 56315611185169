import { Col, Form, Row } from 'antd';
import { ButtonContainer, CancelButton, Container, StyledSelect, SubmitButton, Title } from './style';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { IOptions } from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/selectOptions';
import { User } from 'src/types/user';

export type PrincipalType = {
  principalUserId: string;
  type: string;
};

export const getValueLabelFromUser = (user: User): IOptions => {
  return {
    key: user.userId,
    text: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
    value: `${user.firstName} ${user.lastName} ${user.userId}`,
  };
};

const getDefaultOptions = (users: User[] | undefined) => {
  return users?.map((u) => ({
    label: `${u.firstName} ${u.lastName}`,
    value: `${u.firstName} ${u.lastName} ${u.userId}`,
    key: u.userId,
  }));
};

const ManageGuardianModal = ({
  onSubmit,
  onClose,
  isSubmitting = false,
  loadOptions,
  defaultValues,
}: {
  onSubmit: (principals: PrincipalType[]) => Promise<void>;
  onClose: () => void;
  isSubmitting?: boolean;
  loadOptions: (role: string) => (input: string) => Promise<IOptions[]>;
  defaultValues: User[];
}): JSX.Element => {
  const [form] = Form.useForm();

  const initialValues = getDefaultOptions(defaultValues);

  const submit = async () => {
    const values = form.getFieldsValue();
    const { guardian } = values;
    const principals: PrincipalType[] = [];

    if (guardian) {
      if (guardian.length > 0) {
        guardian.forEach((u: IOptions) => {
          principals.push({ principalUserId: u.key, type: 'GuardianStudent' });
        });
      } else {
        principals.push({ principalUserId: '', type: 'GuardianStudent' });
      }
    }
    onSubmit(principals);
  };

  return (
    <Container>
      <Title>Manage Guardian Account</Title>
      <Form form={form} layout="vertical" name="manage-guardian" preserve={false} onFinish={submit}>
        <Row>
          <Col span={24}>
            <Form.Item label="Guardian" name="guardian" required={false}>
              <StyledSelect
                mode="multiple"
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select guardian"
                fetchOptions={loadOptions('GUARDIAN')}
                initialValues={initialValues}
                maxTagTextLength={20}
                maxCount={100}
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
        </Row>

        <ButtonContainer>
          <Form.Item>
            <CancelButton onClick={onClose} width={150}>
              Cancel
            </CancelButton>
          </Form.Item>
          <Form.Item>
            <SubmitButton type="primary" htmlType="submit" width={150} disabled={isSubmitting}>
              Confirm {isSubmitting && <LoadingOutlined />}
            </SubmitButton>
          </Form.Item>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default ManageGuardianModal;
