import styled from 'styled-components';
import theme from 'src/web-shared-components/theme';

export const StyledContainer = styled.div`
  background: white;
  border: 1px solid ${theme.common.paleGrey};
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  padding: 24px;
  padding-top: 0;
`;
