import { getConfig } from '../../src/config';
const config = getConfig(window.process.env);

const spaceId = config.contentfulAppDataSpace;
const accessToken = config.contentfulAppDataToken;
export const fetchContentFulData = ({
  content_type,
  query,
  order,
}: {
  content_type: string;
  query: string;
  order: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  return fetch(
    `https://cdn.contentful.com/spaces/${spaceId}/entries?access_token=${accessToken}&content_type=${content_type}&query=${query}&order=${order}`,
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error calling Contentful API');
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};
