import { Select, SelectProps, Spin, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import { IOptions } from './selectOptions';
import useDebounceFetcher from './hooks/useDebounceFetcher';
import './index.less';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

type Props = {
  onBlur?: () => void;
  fetchOptions: (value: string) => Promise<IOptions[]>;
  debounceTimeout?: number;
  maxCount?: number;
  suffixIcon?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue?: (value: any | undefined) => void;
  dropdownMatchSelectWidth?: number;
  placeholder?: string;
  disabled?: boolean;
  mode?: 'multiple' | 'tags';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  size?: SizeType;
  bordered?: boolean;
  getOptionsOnMount?: boolean;
} & SelectProps;

export function tagRender({ label, value, closable, onClose }: CustomTagProps): JSX.Element {
  if (!label) {
    return <div></div>;
  }
  const labelString = label?.toString();
  return (
    <Tag closable={closable} onClose={onClose} title={value}>
      {labelString}
    </Tag>
  );
}

const DebounceFetcherSelect: FC<Props> = (props: Props) => {
  const {
    fetchOptions,
    debounceTimeout = 800,
    onBlur,
    suffixIcon,
    maxCount = 'responsive',
    onChangeValue,
    initialValues,
    size = 'small',
    getOptionsOnMount = false,
    ...rest
  } = props;
  const { debounceFetcher, fetching, options } = useDebounceFetcher({ fetchOptions, debounceTimeout });
  const [value, setValue] = useState(initialValues || '');

  useEffect(() => {
    if (getOptionsOnMount && debounceFetcher) {
      debounceFetcher('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      onBlur={onBlur}
      maxTagCount={maxCount}
      tagRender={tagRender}
      placeholder="Use “,” to separate different words"
      labelInValue
      filterOption={false}
      showArrow={suffixIcon !== undefined}
      showSearch
      value={value}
      onSearch={(value: string) => {
        setValue(value);
        debounceFetcher(value);
      }}
      defaultValue={initialValues}
      onSelect={onChangeValue}
      onChange={(value) => {
        if (!value) {
          onChangeValue?.(value);
        }
      }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      suffixIcon={suffixIcon}
      size={size}
      {...rest}
      options={options.map((option) => ({ ...option, label: option.text, title: option.text }))}
    />
  );
};
export default DebounceFetcherSelect;
