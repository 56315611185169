import { Input } from 'antd';
import { HideScrollBar } from 'src/web-shared-components/utility';
const { Search } = Input;
import styled, { keyframes } from 'styled-components';

const tiltleIn = keyframes`
  0% {
    -webkit-transform: translateX(-150px);
            transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
`;

export const DrawerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-top: 25px;
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  line-height: 29px;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 29px;
  border-bottom: 1px solid var(--color-mist);
  animation: ${tiltleIn} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const DrawerContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const IdeaSectionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Category = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-stone);
  width: 100%;
  margin-top: 8px;
  margin-bottom: 18px;
`;
export const IdeaSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const ListContainer = styled.div`
  display: flex;
`;
export const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 750px;
  overflow-y: scroll;
  padding-top: 32px;
  padding-left: 32px;
  /* hide scrollbar */
  ${HideScrollBar}
  animation: ${tiltleIn} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;
export const CardHeader = styled.div`
  & .goal-title {
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    line-height: 26px;
    max-width: 460px;
    color: var(--color-darkNavy);
  }
  & .level-points {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-cool-grey);
    margin-top: 24px;
    margin-bottom: 16px;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  justify-content: space-between;
  height: 128px;
  .ant-form-item-no-colon {
    height: 20px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: black;
`;
export const HeaderDescription = styled.div`
  height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #73747d;
  margin-top: 12px;
`;
export const HeaderBreadCrumb = styled.div`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #a9acc0;
  margin-top: 34px;
`;

export const BreadCrumbItem = styled.div`
  height: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a9acc0;
  cursor: pointer;
  &.active {
    color: #73747d;
  }
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DivisionBadge = styled.div`
  padding: 2px 8px;
  background: linear-gradient(90deg, rgba(237, 75, 83, 0.15) 11.5%, rgba(255, 118, 76, 0.15) 85.5%);
  border-radius: 4px;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 16px;
  color: var(--color-yellow);
  margin-left: 8px;
`;

export const PathfinderGoalList = styled.div`
  display: flex;
  flex-direction: column;
  height: 750px;
  background-color: white;
  padding-top: 10px;
  overflow-y: scroll;
  padding-right: 50px;
  border-right: 1px solid var(--color-mist);
  width: 450px;
  /* hide scrollbar */
  ${HideScrollBar}
`;

export const PathfinderGoalItem = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  min-height: 48px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-mist);
  margin-bottom: 12px;
  padding-left: 24px;
  padding-right: 18px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-dark-navy);
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid white;
  &:hover {
    border: 1px solid var(--color-light-purple);
    color: var(--color-light-purple);
  }
  div:first-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 4px;
  }
  svg:last-child {
    flex: 0 0 20px;
  }
`;

export const Points = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 8px;
  min-width: 34px;
  justify-content: flex-end;
  & .achieved {
    color: var(--color-dark-navy);
  }
  & .total {
    color: var(--color-cool-grey);
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 12px;
  img {
    height: 26px;
  }
`;

export const ArrowBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  color: var(--color-stone);
  cursor: pointer;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 22px 20px;
  background-color: white;
  border: 1px solid var(--color-pale-grey);
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  width: 538px;
  &.assigned {
    border: 1px solid var(--color-indigo);
    box-shadow: 0px 5px 15px rgba(61, 64, 144, 0.2);
  }
  &.disabled {
    background: rgba(var(--rgb-color-pale-grey), 0.5);
    border: 1px solid var(--color-pale-grey);
    color: var(--color-cool-grey);
    box-shadow: none;
  }
`;

export const HowToContainer = styled(CardContainer)`
  background: rgba(70, 74, 201, 0.05);
  border-radius: 4px;
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .row {
    font-size: 14px;
    line-height: 20px;
    color: var(--color-dark-navy);
    display: flex;
    align-items: center;
  }
  & .bold-row {
    font-family: 'Montserrat-Bold';
  }
`;
export const CardDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 16px;
  &.disabled {
    color: var(--color-stone);
  }
  & ul {
    padding-left: 15px;
    list-style-type: disc;
    margin-bottom: 1em;
  }
`;

export const PointsAndLevelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LevelPoints = styled.div`
  color: var(--color-blue);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  img {
    height: 27px;
  }
`;

export const LevelTitle = styled.div`
  display: flex;
  align-items: center;
  color: rgba(var(--rgb-color-indigo), 0.85);
  background-color: var(--color-baby-blue);
  padding: 2px 12px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  &.disabled {
    color: var(--color-cool-grey);
    background-color: rgba(var(--rgb-color-cool-grey), 0.2);
  }
`;

export const DatePickerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;
`;

export const DatePickerHeader = styled.div`
  color: var(--color-font-black);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledSearch = styled(Search)`
  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    background: transparent;
  }
  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border: 0px;
    background: transparent;
    & ::hover svg {
      color: var(--color-indigo);
    }
  }
`;

export const LoadingContainer = styled.div`
  animation: ${tiltleIn} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const DateLabel = styled.div`
  color: var(--color-dark-navy);
  font-size: 14px;
  span {
    color: var(--color-cool-grey);
  }
`;
