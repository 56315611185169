import React, { ComponentType, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from 'src/components/Layout';
import { FeatureSwitches, useFeatureSwitches } from 'src/featureSwitches';
import { lazyRetry } from 'src/utils/lazyRetry';
import { useIsStaff } from 'src/hooks/useMissionHook/useIsStaffHook';
import { CurrentUserContext } from 'src/context/CurrentUserContext';

export const ALL_ROUTES_PATH = {
  personal_info: '/personal-info',
};

const CoverPageComponent = lazyRetry(() => import('src/pages/Cover'));
const AcademicsPageComponent = lazyRetry(() => import('src/pages/Academics'));
const ActivitiesPageComponent = lazyRetry(() => import('src/pages/Activities'));
const TestingPageComponent = lazyRetry(() => import('src/pages/Testing'));
const HonorsPageComponent = lazyRetry(() => import('src/pages/Honors'));
const PersonalDevelopmentPageComponent = lazyRetry(() => import('src/pages/PersonalDevelopment'));
const CareerPageComponent = lazyRetry(() => import('src/pages/Career'));
const StudentDashboardComponent = lazyRetry(() => import('src/pages/StudentDashboard'));
const ProfilePageComponent = lazyRetry(() => import('src/pages/Profile'));
const CrimsonJourneyComponent = lazyRetry(() => import('src/pages/CrimsonJourney'));
const StaffDashboardComponent = lazyRetry(() => import('src/pages/StaffDashboard'));
const PreallocationsComponent = lazyRetry(() => import('src/pages/StaffDashboard/Preallocation'));
// const MyStudents = lazyRetry(() => import('src/pages/StaffDashboard/MyStudents'));
const QuarterlyStudentReport = lazyRetry(() => import('src/pages/QuarterlyStudentReport'));

function CoverPage(): JSX.Element {
  return <CoverPageComponent />;
}

interface RouteDefinition {
  path: string;
  element: ComponentType;
  noNavBar?: boolean;
  when?: (featureSwitches: FeatureSwitches) => boolean;
}

//** add a new route should also add it to redirects **//
const routes: RouteDefinition[] = [
  {
    path: '/',
    element: CoverPage,
    noNavBar: true,
  },
  {
    path: '/academics',
    element: AcademicsPageComponent,
  },
  {
    path: '/activities',
    element: ActivitiesPageComponent,
  },
  {
    path: '/testing',
    element: TestingPageComponent,
  },
  {
    path: '/honors',
    element: HonorsPageComponent,
  },
  {
    path: '/personal-development',
    element: PersonalDevelopmentPageComponent,
  },
  {
    path: '/career',
    element: CareerPageComponent,
  },
  {
    path: '/dashboard',
    element: StudentDashboardComponent,
    noNavBar: true,
  },
  {
    path: '/crimson-journey',
    element: CrimsonJourneyComponent,
    noNavBar: true,
  },
  {
    path: '/staffDashboard/preallocations',
    element: PreallocationsComponent,
    noNavBar: true,
  },
  {
    path: '/staffDashboard',
    element: StaffDashboardComponent,
    noNavBar: true,
  },
  {
    path: ALL_ROUTES_PATH.personal_info,
    element: ProfilePageComponent,
  },
  {
    path: '/quarterly-student-report',
    element: QuarterlyStudentReport,
    noNavBar: true,
  },
];

const AppWrapperRoute: React.FC = () => {
  const featureSwitches = useFeatureSwitches();
  const { currentUser } = useContext(CurrentUserContext);
  const isStaff = useIsStaff(currentUser);
  const routesEnabled = routes.filter((route) => {
    if (typeof route.when === 'function') {
      return route.when(featureSwitches);
    }
    if (route.path === '/') {
      if (isStaff) {
        route.element = StudentDashboardComponent;
      } else {
        route.noNavBar = false;
        route.element = AcademicsPageComponent;
      }
    }

    return true;
  });

  return (
    <Routes>
      {routesEnabled.map((route, index) => {
        return route.noNavBar ? (
          <Route
            key={index}
            path={route.path}
            element={
              <React.Suspense fallback={null}>
                <route.element />
              </React.Suspense>
            }
          />
        ) : (
          <Route
            key={index}
            path={route.path}
            element={
              <Layout>
                <React.Suspense fallback={null}>
                  <route.element />
                </React.Suspense>
              </Layout>
            }
          />
        );
      })}
    </Routes>
  );
};

export default AppWrapperRoute;
