import { useEffect, useMemo } from 'react';
import { useResourceCenterContext } from '../helper';

const EmptyHookComp = (): null => {
  const { state, controller } = useResourceCenterContext();
  const { eclResourceQueryStatus } = state;
  const idsInIdle = useMemo(
    () =>
      Object.entries(eclResourceQueryStatus)
        .filter((o) => o[1] === 'idle')
        .map((o) => o[0]),
    [eclResourceQueryStatus],
  );
  useEffect(() => {
    controller.queryEclDetail(idsInIdle);
  }, [idsInIdle, controller]);
  return null;
};

export default EmptyHookComp;
