import { DetailedUser } from 'src/types/user';
import { Input, InputProps, Select as AntSelect, SelectProps } from 'antd';
import { ExpandMoreIcon } from 'src/web-shared-components/base/Icons';
import React, { useContext, useRef, useState } from 'react';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

const { Option } = AntSelect;

export interface CommonSectionProps {
  user: DetailedUser;
  refetchUser: () => void;
}

export const getNumFromString = (str: string, maxNum: number): number => {
  const hash = str.split('').reduce((acc, curr) => {
    return acc + curr.charCodeAt(0);
  }, 0);
  return hash % maxNum;
};

export const isNotNullOrUndefined = (x: string | null | undefined): boolean => {
  return x !== null && x !== undefined;
};

export const ProfileInput = (props: InputProps): JSX.Element => {
  const { editPermitted } = useContext(CurrentUserContext);
  return <Input disabled={!editPermitted || props.disabled} {...props} />;
};

export const ProfileSelect: React.FC<SelectProps> = React.forwardRef((props, ref) => {
  const { editPermitted } = useContext(CurrentUserContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(ref);
  const [open, setOpen] = useState(false);
  return (
    <AntSelect
      ref={selectRef}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(e: any) => {
        // need to use ref to make sure it focuses
        if (selectRef.current) {
          selectRef.current.focus();
        }
        if (e.target.classList.value.includes('ant-select-item-option')) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }}
      onBlur={() => {
        setOpen(false);
      }}
      open={open}
      suffixIcon={<ExpandMoreIcon width={22} />}
      showSearch
      disabled={!editPermitted || props.disabled}
      {...props}
    />
  );
});

export const isValidHttpUrl = (str: string): boolean => {
  if (!str) return false;
  const pattern = new RegExp(
    '^((https|http)?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str.trim());
};

export function openInNewTab(href: string): void {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: href,
  }).click();
}

// ------------------------------------------------
// Multi Select copied from core app TutorList/index
const StyledTagOption = styled(Option)`
  .curriculum_list_tag_option .ant-select-item-option-state {
    display: none;
  }
`;
const SelectedValue = styled.div`
  display: flex;
  border: 1px solid #e3e7ed;
  border-radius: 4px;
  background: #f4f5ff;
  align-items: center;
  width: fit-content;
`;

const SelectedMultiValue = styled.div`
  display: flex;
  border: 1px solid #e3e7ed;
  padding: 4px 10px;
  border-radius: 4px;
  background: #f4f5ff;
  align-items: center;
  width: fit-content;
`;

const StyledCloseIcon = styled(CloseOutlined)`
  color: white;
  background: #a9acc0;
  border-radius: 50%;
  padding: 2px;
  font-size: 8px;
  margin-left: 4px;
`;
export const StyledShortMultiSelect = styled(AntSelect)`
  min-width: 60px;
  width: min-content !important;
  .ant-select-clear {
    right: 15px !important;
  }
  .ant-select-selector {
    height: 24px !important;
    display: flex;
    align-items: center;
    padding-left: 0 !important;
    min-width: 80px;
    width: min-content !important;
    .ant-select-selection-search {
      left: 0 !important;
      width: 12px !important;
      margin-inline-start: 0 !important;
    }
    .ant-select-selection-item {
      padding-right: 4px !important;
      flex: none;
    }
    .ant-select-selection-placeholder {
      left: 0 !important;
    }
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
    }
  }
  .ant-select-in-form-item {
    width: fit-content;
  }
`;
export const showSelectValue = (type: string, values?: string[] | number[]): string => {
  const show = values && values.length > 1 ? `${type}: ${values.length}` : values?.length === 0 ? '' : `${values}`;
  return show;
};
export const shortMultiSelectOption = (label: string, value: string | number, key: string): JSX.Element => {
  return (
    <StyledTagOption
      label={label}
      value={value}
      key={key}
      style={{ background: 'white' }}
      suffixIcon={null}
      className="curriculum_list_tag_option"
    >
      <SelectedValue>
        {label}
        <StyledCloseIcon />
      </SelectedValue>
    </StyledTagOption>
  );
};

export const multiSelectedOption = (label: string, value: string | number, key: string): JSX.Element => {
  return (
    <StyledTagOption
      label={label}
      value={value}
      key={key}
      style={{ background: 'white' }}
      suffixIcon={null}
      className="curriculum_list_tag_option"
    >
      <SelectedMultiValue>
        {label}
        <StyledCloseIcon />
      </SelectedMultiValue>
    </StyledTagOption>
  );
};
