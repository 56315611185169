import { useContext, CSSProperties } from 'react';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledContainer = styled.div`
  padding: 24px;
  background: url('/static/ssa_nav_card.svg');
  background-size: cover;
  border-radius: 8px;
`;
export const StyledHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
`;
export const StyledDesc = styled.div`
  margin-top: 10px;
  width: 192px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-baby-blue);
`;
export const StyledActionButton = styled.a`
  width: 82px;
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background: #12c39a;
  margin-top: 16px;
`;

type SsaNavCardProps = {
  style?: CSSProperties;
};

const SsaNavCard = (props: SsaNavCardProps): JSX.Element => {
  const { userId } = useContext(CurrentUserContext);
  const ActionButton = () => {
    const domain = window.xprops && window.xprops.domain ? window.xprops.domain : 'http://localhost:3000';
    return (
      <>
        <StyledActionButton
          target="_blank"
          href={`${domain + '/users/' + userId + '/applications/algorithm'}`}
          rel="noreferrer"
        >
          <ArrowRightOutlined />
          &nbsp;&nbsp;
          <span>Start</span>
        </StyledActionButton>
      </>
    );
  };

  const Desc = () => {
    return (
      <>
        <StyledDesc>
          <span>Find out your matching universities based on your profile.</span>
        </StyledDesc>
      </>
    );
  };
  return (
    <>
      <StyledContainer {...props}>
        <StyledHeader>University Match</StyledHeader>
        <Desc />
        <ActionButton />
      </StyledContainer>
    </>
  );
};
export default SsaNavCard;
