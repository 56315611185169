import {
  constructFeatureSwitches,
  constructLDFeatureSwitch,
  transformAndFilterFeatureSwitches,
} from '@crimson-education/feature-switches-web';

import productionFeatures from './features.production.json';
import stagingFeatures from './features.staging.json';
import devFeatures from './features.development.json';

import { isProduction, isStaging, isDev, launchDarkly } from 'src/utils/settings';

const defaults = {
  production: productionFeatures,
  staging: stagingFeatures,
  dev: devFeatures,
};

const { featureSwitches, getFeatureSwitchesAsString, FeatureToggle } = constructFeatureSwitches<typeof devFeatures>(
  defaults,
  {
    isProduction: () => isProduction(),
    isStaging: () => isStaging(),
    isDev: () => isDev(),
  },
);

export { featureSwitches, getFeatureSwitchesAsString, FeatureToggle };

const { useFeatureFlag, enhancer, useFeatureSwitches, useIdentifyUser, FeatureSwitchContext } =
  constructLDFeatureSwitch(launchDarkly.clientSideId, transformAndFilterFeatureSwitches(featureSwitches), {
    hotReload: true,
    waitForReady: isDev(),
    showLog: isDev(),
    proxy: {
      enable: true,
    },
  });

export type FeatureSwitches = ReturnType<typeof useFeatureSwitches>;

export { useFeatureFlag, enhancer as FeatureSwitchEnhancer, useFeatureSwitches, useIdentifyUser, FeatureSwitchContext };
