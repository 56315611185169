import styled from 'styled-components';
import { Popover } from 'antd';

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 43px;
  padding: 0 16px;
  cursor: pointer;
  border: 1px solid transparent;

  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #6d6d77;
  box-sizing: border-box;

  :hover {
    background: #f6f7fa;
    border-color: #464ac9;
  }
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0 !important;
    background: red;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`;
