import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { QUERY_INTERNAL_NOTES } from 'src/graphql/InternalNotes';
import { GET_USERS_BY_IDS } from 'src/graphql/User';
import { User } from 'src/types/user';
import { ExpandMoreIcon } from 'src/web-shared-components/base/Icons';
import { AnimCompShow } from '../ResourceCenter/components';
import InternalNoteCard from './NoteCard';
import NoteInput from './NoteInput';
import * as Styled from './styles';
import { TInternalNote } from './types';
import { useTenantLevel3 } from 'src/hooks/useTenantLevel';

interface InternalNotesProps {
  missionId?: string;
  studentId: string;
  editPermitted: boolean;
}
const InternalNotes: FunctionComponent<InternalNotesProps> = ({ missionId, studentId, editPermitted }) => {
  const [notes, setNotes] = useState<TInternalNote[]>([]);
  const [visibleCount, setVisibleCount] = useState(4);

  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const { data, refetch: fetchInternalNotes } = useQuery(QUERY_INTERNAL_NOTES, { variables: { missionId } });
  const { refetch: fetchUsersByIds } = useQuery(GET_USERS_BY_IDS, { client: crimsonAppApiClient, skip: true });

  useEffect(() => {
    const setNotesWithCreators = async () => {
      const userIds = _.uniq(data.notes.map((note: TInternalNote) => note.creatorUid));
      const { data: userData } = await fetchUsersByIds({ userIds });
      const userMap = userData.usersBasicInfo.reduce((acc: Map<string, User>, user: User) => {
        return acc.set(user.userId, user);
      }, new Map<string, User>());
      const notesWithCreators = data.notes.map((note: TInternalNote) => ({
        ...note,
        creator: userMap.has(note.creatorUid) ? userMap.get(note.creatorUid) : undefined,
      }));
      const orderedNotes = [..._.orderBy(notesWithCreators, ['createdAt'], ['desc'])];
      setNotes(orderedNotes);
    };
    if (data?.notes) {
      setNotesWithCreators();
    }
  }, [data?.notes, fetchUsersByIds]);

  const onClickViewMore = () => {
    setVisibleCount(visibleCount + 4);
  };

  const TENANT_LEVEL3 = useTenantLevel3();

  return (
    <div>
      <Styled.Title>
        Internal Notes <span>Visible and editable by the{TENANT_LEVEL3 && <span> Crimson</span>} staff only</span>
      </Styled.Title>
      <NoteInput
        studentId={studentId}
        missionId={missionId}
        onNoteSaved={() => fetchInternalNotes()}
        editPermitted={editPermitted}
      />
      {notes?.length > 0 ? (
        <Styled.NoteListContainer>
          {notes.slice(0, visibleCount).map((note: TInternalNote) => (
            <AnimCompShow key={note.id}>
              <InternalNoteCard studentId={studentId} missionId={missionId} key={note.id} note={note} />
            </AnimCompShow>
          ))}
          {notes?.length > visibleCount ? (
            <Styled.ViewMoreContainer>
              <Styled.ViewMoreButton onClick={onClickViewMore}>
                View Older
                <ExpandMoreIcon width={20} />
              </Styled.ViewMoreButton>
            </Styled.ViewMoreContainer>
          ) : null}
        </Styled.NoteListContainer>
      ) : null}
    </div>
  );
};

export default InternalNotes;
