import { gql } from '@apollo/client';

export const LOAD_PERMISSIONS = gql`
  query permissions($userIds: [String!]!) {
    permissions(userIds: $userIds) {
      action
      resourceType
      resource
    }
  }
`;

export const IS_ACCESSIBLE_BY_ROLE = gql`
  query isAccessibleByRole($targetUserIds: [String]!, $roleIds: [String]!) {
    isAccessibleByRole(targetUserIds: $targetUserIds, roleIds: $roleIds)
  }
`;
