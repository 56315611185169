import React from 'react';
import { useLimitCWUserEditMission } from 'src/context/ZustandStore';

const CWEditAuthProvider: React.FC<{
  children: React.ReactNode;
  missionCreatorId?: string;
}> = ({ children, missionCreatorId = '' }) => {
  const { isLimitCWUserEditMission } = useLimitCWUserEditMission();
  const isMissionCreatedByLoginUser = missionCreatorId ? missionCreatorId === window.xprops?.userId : true;
  return (
    <div
      style={{
        cursor: isLimitCWUserEditMission && !isMissionCreatedByLoginUser ? 'not-allowed' : 'default',
      }}
    >
      <div
        style={{
          pointerEvents: isLimitCWUserEditMission && !isMissionCreatedByLoginUser ? 'none' : 'auto',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CWEditAuthProvider;
