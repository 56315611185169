export const categoriesMap = {
  Academics: 'ACADEMICS',
  Testing: 'TEST',
  Activities: 'ACTIVITIES',
  Honors: 'COMPETITIONS_HONORS',
  'Personal Development': 'PERSONAL_DEVELOPMENT',
  'Majors & Careers': 'CAREERS',
  'Personal info': 'Personal info',
};

export const badgeCategoriesMap = {
  PERSONAL_DEVELOPMENT: 'Personal Development',
  CAREERS: 'Majors & Careers',
};

export const subcategoriesMap = {
  'School Academic': 'School Academic',
  Testing: 'Testing',
  'Academic Research & Publications': 'Academic Research & Publications',
  'Extracurricular Activities': 'Extracurricular Activities',
  'Internship & Work Experience': 'Internship & Work Experience',
  'University Courses & Summer Programs': 'University Courses & Summer Programs',
  'Competitions & Honors': 'Competitions & Honors',
  'Essential Skills & Experiences': 'Essential Skills & Experiences',
  Readings: 'Readings',
  'Relationship Building': 'Relationship Building',
  Writings: 'Writings',
  'Major, Career and Interest Exploration': 'Major, Career and Interest Exploration',
  Networking: 'Networking',
  'School Research & Preparation': 'School Research & Preparation',
  'Academic Skills & Essential Experiences': 'Academic Skills & Essential Experiences',
  'Subject Interest Development': 'Subject Interest Development',
  'NAY Curriculum': 'UK Non-Application Year (NAY) Curriculum',
  'NAY Curriculum/UK Candidacy Building': 'NAY Curriculum/UK Candidacy Building',
  'College Admissions 101': 'College Admissions 101',
};

export type LevelType = {
  id: string;
  description: string;
  isAssigned: boolean;
  level: string;
  points: number;
  status: string | null | boolean;
};

export type GoalType = {
  id: string;
  title: string;
  levels: Array<LevelType>;
};

export const firstCharUpperCase = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const arrowRightStyle = { width: '14px', marginLeft: '8px', marginRight: '8px' };

export const listArrowRightStyle = { height: '17px' };

export const categoryArrowRightStyle = {
  height: '24px',
  // color: 'var(--color-stone)',
  // marginLeft: '12px',
  // marginRight: '12px',
};

export const skeletonLength = 10;

export const arrowBackStyle = {
  width: '16px',
  height: '16px',
  marginRight: '12px',
  color: 'black',
  cursor: 'pointer',
};
export const getTailCharS = (num: number, staticString: string): string => {
  return num === 1 ? `${num} ${staticString}` : `${num} ${staticString}s`;
};
