import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { InteractiveWrapper } from '../style';

export const InputWrapper = styled(InteractiveWrapper)`
  flex-direction: row;
  height: 40px;
  gap: 10px;
  background: #ffffff;
  align-items: center;

  input {
    height: 100%;
    padding: 0;
    margin: 0;
  }
  svg {
    width: 18px;
    height: 18px;
    color: #1d1e2b;
  }
`;
export const StyledInput = styled(AntInput)`
  &::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: #ababb1;
  }
`;
