import { motion, HTMLMotionProps } from 'framer-motion';
type AnimCompShowProps = {
  delay?: number;
  axis?: 'X' | 'Y';
  offset?: number;
  duration?: number;
};
const AnimCompShow = ({
  delay = 0,
  axis = 'Y',
  offset = 30,
  duration = 0.4,
  ...restProps
}: AnimCompShowProps & HTMLMotionProps<'div'>): JSX.Element => {
  return (
    <motion.div
      animate={'show'}
      initial={'hidden'}
      transition={{ duration, delay, type: 'spring' }}
      variants={{
        show: {
          opacity: 1,
          [`translate${axis}`]: 0,
        },
        hidden: {
          opacity: 0,
          [`translate${axis}`]: offset,
        },
      }}
      {...restProps}
    />
  );
};

export default AnimCompShow;
