import { useState, useEffect } from 'react';
import moment from 'moment';
import { StyledDatePicker, StyledErrorMessage } from './style';
type Props = {
  value?: moment.Moment | null | undefined;
  placeholder?: string;
  onChange: (value: moment.Moment | null) => void;
  validator?: (value: moment.Moment | null) => {
    status: 'warning' | 'error' | 'valid';
    message?: string;
  };
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  format?: string;
  suffixIcon?: React.ReactNode;
  allowClear?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
};
const DatePicker = ({ value, onChange, placeholder = 'Select', validator, ...rest }: Props): JSX.Element => {
  const [showValue, setShowValue] = useState<moment.Moment | null | undefined>(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    setShowValue(value);
  }, [value]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledDatePicker
        bordered={showError ? true : false}
        placeholder={placeholder}
        suffixIcon={null}
        value={showValue}
        status={showError ? 'error' : ''}
        onChange={(date) => {
          setShowValue(date);
          if (validator) {
            const result = validator(date);
            if (result.status !== 'valid') {
              setShowError(true);
              if (result.message) {
                setErrorMessage(result.message);
              }
              return;
            } else {
              setShowError(false);
              setErrorMessage('');
            }
          }
          if (moment(date).diff(moment(showValue), 'days') !== 0) {
            onChange(date);
          }
        }}
        {...rest}
      />
      {showError && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </div>
  );
};
export default DatePicker;
