import { useMutation, useQuery } from '@apollo/client';
// useLazyQuery
import { notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import AddCircleButton from 'src/components/AddCircleButton';
import config from 'src/config';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { LoginUserContext } from 'src/context/LoginUserContext';
import { crimsonAppApiClient } from 'src/graphql';
// getRiseTypeStudentSpecialDocLink,
import {
  deleteFile,
  editStudentEssayFolder,
  fetchFilesForUser,
  getEssayFolder,
  getEssayFolderv2,
} from 'src/graphql/User';
import { IconTooltip } from '../style';
import { isValidHttpUrl, openInNewTab } from '../utils';
import { StyledContainer, StyledTitle, StyledTitle2, Divider, TextInput, OpenInNew } from './style';
import UploadedFileList, { MyFile } from './UploadedFileList';
import { useFeatureFlag } from 'src/featureSwitches';
import { useTenantLevel3 } from 'src/hooks/useTenantLevel';

const CRIMSON_ONBOARDING_DOCUMENT_COUNT_LIMIT = 5;
const FLAMINGO_ONBOARDING_DOCUMENT_COUNT_LIMIT = 9999;

export const FILE_SIZE_LIMIT = 25000000;
export const FILE_SIZE_ERROR = `This file is too big. Maximum file size is ${FILE_SIZE_LIMIT / 1000000}MB.`;

const Documents: React.FC = () => {
  const TENANT_LEVEL3 = useTenantLevel3();
  const onboardingDocumentLimitCount = TENANT_LEVEL3
    ? CRIMSON_ONBOARDING_DOCUMENT_COUNT_LIMIT
    : FLAMINGO_ONBOARDING_DOCUMENT_COUNT_LIMIT;

  const { userId, editPermitted, deletePermitted } = useContext(CurrentUserContext);
  const { userId: loginUserId, role, userRoles } = useContext(LoginUserContext);
  const [essayLink, setEssayLink] = useState('');
  const [fileList, setFileList] = useState<MyFile[]>([]);
  const [token, setToken] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const isOwnProfile = loginUserId === userId;
  const canViewRoles = ['STRATEGIST', 'CASE_MANAGER', 'REVIEWER', 'SUPER_ADMIN', 'ACADEMIC_ADVISOR', 'GUARDIAN'];
  const canView =
    userRoles?.some((r: string) => canViewRoles.includes(r)) || canViewRoles.includes(role || '') || isOwnProfile;
  const canEditRoles = ['STRATEGIST', 'CASE_MANAGER', 'REVIEWER', 'SUPER_ADMIN', 'ACADEMIC_ADVISOR'];
  const canEdit =
    (editPermitted &&
      (userRoles?.some((r: string) => canEditRoles.includes(r)) || canEditRoles.includes(role || ''))) ||
    isOwnProfile;

  const AUTO_CREATE_ESSAY_FOLDER = useFeatureFlag('AUTO_CREATE_ESSAY_FOLDER');

  const getEssayFolderGql = AUTO_CREATE_ESSAY_FOLDER ? getEssayFolderv2 : getEssayFolder;

  const { data: essayLinkData, loading: essayFolderLoading } = useQuery(getEssayFolderGql, {
    client: crimsonAppApiClient,
    variables: { userId },
    skip: !canView,
  });

  // Rise Type Student
  // const [generateCrimsonRiseStudentDocLink] = useLazyQuery(getRiseTypeStudentSpecialDocLink, {
  //   client: crimsonAppApiClient,
  //   variables: { userId },
  // });
  // useEffect(() => {
  //   if (userId && essayLinkData?.getEssayFolder) {
  //     generateCrimsonRiseStudentDocLink({
  //       variables: { userId },
  //     });
  //   }
  // }, [userId, essayLinkData?.getEssayFolder, generateCrimsonRiseStudentDocLink]);

  const [updateEssayLinkMutation] = useMutation(editStudentEssayFolder, {
    client: crimsonAppApiClient,
  });
  const { data: filesData, refetch: fetchFilesForUserQuery } = useQuery(fetchFilesForUser, {
    client: crimsonAppApiClient,
    variables: { userId, tag: 'profile' },
    skip: !canView,
  });
  const [deleteFileMutation] = useMutation(deleteFile, {
    client: crimsonAppApiClient,
  });

  const getToken = async () => {
    const token = await window.xprops.getBearer();
    setToken(token);
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    AUTO_CREATE_ESSAY_FOLDER
      ? setEssayLink(essayLinkData?.getEssayFolder || '')
      : setEssayLink(essayLinkData?.user.studentInfo?.essayFolder || '');
  }, [AUTO_CREATE_ESSAY_FOLDER, essayLinkData]);

  useEffect(() => {
    setIsUploading(false);
    setFileList(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filesData?.getUploadedFilesByUserId?.map((f: any) => ({
        uid: f.id,
        name: f.name,
        downloadUrl: `${config.crimsonAppAPIUrl}/files/${f.id}?token=${token}`,
        type: f.name.split('.').pop(),
        status: undefined,
      })) || [],
    );
  }, [filesData, token]);

  const onEditEssayLink = async () => {
    const { data } = await updateEssayLinkMutation({
      variables: {
        userId,
        essayFolder: essayLink,
      },
    });
    if (data?.data) {
      setEssayLink(data.data.essayFolder);
    }
  };

  const uploadFile = async (file: File, tag: string, refType: string) => {
    if (file.size > FILE_SIZE_LIMIT) {
      notification.error({
        message: 'File Upload Failed',
        description: FILE_SIZE_ERROR,
      });
      return;
    }
    setIsUploading(true);
    // add temp file item to display
    setFileList((prevFileList) => [
      ...prevFileList,
      { uid: file.name + Date.now(), name: file.name, type: file.name.split('.').pop(), status: 'uploading' },
    ]);
    const request = new XMLHttpRequest();

    const handleErrors =
      (cause = 'reqError') =>
      async () => {
        const { data } = await fetchFilesForUserQuery({ userId, tag: 'profile' });
        notification.error({
          message: 'File Upload Failed',
          description: `${cause === 'timeout' ? 'Timeout. ' : ''}Please try again later`,
        });
        setIsUploading(false);
        setFileList(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data?.getUploadedFilesByUserId?.map((f: any) => ({
            uid: f.id,
            name: f.name,
            url: `${config.crimsonAppAPIUrl}/files/${f.id}?token=${token}`,
            type: f.name.split('.').pop(),
            status: undefined,
          })) || [],
        );
      };

    request.onerror = handleErrors();
    request.ontimeout = handleErrors('timeout');
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        const status = request.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          notification.success({
            message: 'File uploaded successfully!',
          });
        }
      }
    };
    request.onloadend = () => {
      fetchFilesForUserQuery({ userId, tag: 'profile' });
    };

    const formData = new FormData();
    const metaData = JSON.stringify({
      refType: 'user',
      refs: [
        {
          refId: userId,
          refType,
          tag,
        },
      ],
    });
    formData.append('meta', metaData);
    formData.append('data', file);
    request.timeout = 60000;
    request.open('POST', `${config.crimsonAppAPIUrl}/files`);
    request.setRequestHeader('Authorization', `Bearer ${token}`);
    request.send(formData);
  };

  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...(e.target.files || [])];
    if (!files.length) return;
    const totalFileCount = files.length + fileList.length;
    if (totalFileCount > onboardingDocumentLimitCount) {
      notification.error({
        message: 'File Upload Failed',
        description: `The number of onboarding documents currently exceeds ${onboardingDocumentLimitCount}.`,
      });
      return;
    }
    files.forEach((file) => {
      uploadFile(file, 'profile', 'user');
    });
    e.target.files = null;
    e.target.value = '';
  };

  if (!canView) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledTitle>
        <div>Onboarding Documents</div>
        <form>
          <input id="fileUploadInput" type="file" hidden onChange={onChangeFiles} />
        </form>
        {canEdit && (
          <AddCircleButton
            placement="bottomRight"
            items={[
              {
                text: 'Upload a document',
                func: () => {
                  // First check count limit then trigger input file
                  if (fileList.length >= onboardingDocumentLimitCount) {
                    notification.error({
                      message: 'File Upload Failed',
                      description: `The number of onboarding documents currently exceeds ${onboardingDocumentLimitCount}.`,
                    });
                    return;
                  }
                  document.getElementById('fileUploadInput')?.click();
                },
              },
            ]}
          />
        )}
      </StyledTitle>

      <UploadedFileList
        fileList={fileList}
        canDelete={canEdit && deletePermitted}
        deleteFile={async (id: string) => {
          setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== id));
          await deleteFileMutation({ variables: { id: id } });
          fetchFilesForUserQuery({ userId, tag: 'profile' });
        }}
        uploading={isUploading}
      />

      <Divider />

      <StyledTitle2>
        <img className="folder-icon" src="/static/profilePage/folder.svg" loading="lazy" />
        <span>Essay Folder</span>
      </StyledTitle2>

      <Spin spinning={essayFolderLoading} delay={500}>
        <TextInput
          placeholder="http://"
          form="novalidatedform"
          maxLength={1000}
          autoComplete="off"
          value={essayLink}
          disabled={essayFolderLoading || !canEdit}
          suffix={
            <IconTooltip
              title="Go to the site"
              // can only change inner tooltip style here
              overlayInnerStyle={{
                padding: '16px',
                borderRadius: '8px',
                backgroundColor: 'rgba(29, 30, 43, 0.8)',
                color: 'white',
                fontSize: 14,
              }}
            >
              <OpenInNew
                size={20}
                onClick={() => {
                  if (isValidHttpUrl(essayLink)) {
                    if (!essayLink.startsWith('http://') && !essayLink.startsWith('https://')) {
                      openInNewTab('https://' + essayLink);
                    } else {
                      openInNewTab(essayLink);
                    }
                  }
                }}
                isValidUrl={isValidHttpUrl(essayLink)}
              />
            </IconTooltip>
          }
          onChange={(e) => {
            setEssayLink(e.target.value);
          }}
          onBlur={() => {
            onEditEssayLink();
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onEditEssayLink();
            }
          }}
        />
      </Spin>
    </StyledContainer>
  );
};

export default Documents;
