import { createContext, ReactElement, useState } from 'react';
import { InitializationInfo } from 'src/__generated__/graphqlTypes';

export interface StudentInfoProps {
  studentInitInfo?: InitializationInfo;
  setStudentInitInfo?: (initInfo: InitializationInfo) => void;
}
export const StudentInitInfoContext = createContext<StudentInfoProps>({
  studentInitInfo: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStudentInitInfo: () => {},
});

export interface StudentInitInfoContextProviderProps {
  children: ReactElement | ReactElement[];
}

export const CommunicationContextProvider = (props: StudentInitInfoContextProviderProps): JSX.Element => {
  const [studentInitInfo, setStudentInitInfo] = useState<InitializationInfo | undefined>(undefined);
  return (
    <StudentInitInfoContext.Provider
      value={{
        studentInitInfo,
        setStudentInitInfo: (initInfo: InitializationInfo) => {
          setStudentInitInfo(initInfo);
        },
      }}
    >
      {props.children}
    </StudentInitInfoContext.Provider>
  );
};
