import { CSSProperties, ReactNode } from 'react';

import { Content, Header, HeaderButton, HeaderTitle, Root } from './style';

interface AccordionProps {
  children?: ReactNode;
  isExpanded: boolean;
  style?: CSSProperties;
  title: ReactNode;
  onToggleExpanded: (newExpanded: boolean) => void;
}

export const Accordion = ({ children, isExpanded, style, title, onToggleExpanded }: AccordionProps): JSX.Element => {
  return (
    <Root style={style}>
      <Header>
        <HeaderTitle>
          <span>{title}</span>
        </HeaderTitle>
        <div style={{ flex: 1 }} />
        <HeaderButton onClick={() => onToggleExpanded(!isExpanded)}>
          {isExpanded ? (
            <img src="/static/roadmapBuilder/expand_less.svg" />
          ) : (
            <img src="/static/roadmapBuilder/expand_more.svg" />
          )}
        </HeaderButton>
      </Header>
      {isExpanded && <Content>{children}</Content>}
    </Root>
  );
};
