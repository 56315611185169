import { gql, useMutation } from '@apollo/client';
import { useDebounceFn } from 'ahooks';
import { useEffect, useState } from 'react';

import { notification } from 'antd';

type Sentiment = 'Positive' | 'Negative';

interface UseRecommendationFeedbackParams {
  category: string;
  roadmapId: string;
  subcategory: string;
}

export interface UseRecommendationFeedbackHook {
  comments: string;
  isSaving: boolean;
  isSavingComments: boolean;
  reset: () => void;
  sentiment: Sentiment | null;
  setComments: (newComments: string) => void;
  setMissionId: (missionId: string) => Promise<void>;
  updateSentiment: (newSentiment: Sentiment) => void;
}

export const useRecommendationFeedback = (params: UseRecommendationFeedbackParams): UseRecommendationFeedbackHook => {
  const [comments, setComments] = useState<string>('');
  const [feedbackId, setFeedbackId] = useState<string | null>(null);
  const [sentiment, setSentiment] = useState<Sentiment | null>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isSavingComments, setIsSavingComments] = useState(false);

  const [createFeedback] = useMutation(CREATE_FEEDBACK);
  const [updateFeedback] = useMutation(UPDATE_FEEDBACK);

  const { run: saveComments } = useDebounceFn(
    async (newComments: string) => {
      if (!feedbackId) return;

      setIsSavingComments(true);
      try {
        await updateFeedback({
          variables: {
            input: {
              id: feedbackId,
              comments: newComments,
            },
          },
        });
      } catch (error) {
        notification.error({
          message: 'Something went wrong saving your comments. Please try again',
        });
      } finally {
        setIsSavingComments(false);
      }
    },
    { wait: 250 },
  );

  useEffect(() => {
    saveComments(comments);
  }, [comments, saveComments]);

  const setMissionId = async (missionId: string) => {
    if (!feedbackId) return;

    await updateFeedback({
      variables: {
        input: {
          id: feedbackId,
          missionId,
        },
      },
    });
  };

  const updateSentiment = async (newSentiment: Sentiment) => {
    if (newSentiment === sentiment) return;

    setIsSaving(true);
    try {
      if (feedbackId) {
        await updateFeedback({
          variables: {
            input: {
              id: feedbackId,
              comments: '',
              sentiment: newSentiment,
            },
          },
        });
        setComments('');
        setSentiment(newSentiment);
      } else {
        const result = await createFeedback({
          variables: {
            input: {
              sentiment: newSentiment,
              ...params,
            },
          },
        });

        const data = result.data?.createRecommenderFeedback;
        if (data) {
          setFeedbackId(data.id);
          setSentiment(newSentiment);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong saving your feedback. Please try again',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const reset = () => {
    setFeedbackId(null);
    setSentiment(null);
  };

  return { comments, isSaving, isSavingComments, reset, sentiment, setComments, setMissionId, updateSentiment };
};

const CREATE_FEEDBACK = gql`
  mutation CreateRecommenderFeedback($input: CreateRecommenderFeedbackInput!) {
    createRecommenderFeedback(input: $input) {
      id
    }
  }
`;

const UPDATE_FEEDBACK = gql`
  mutation UpdateRecommenderFeedback($input: UpdateRecommenderFeedbackInput!) {
    updateRecommenderFeedback(input: $input) {
      id
    }
  }
`;
