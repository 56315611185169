import styled from 'styled-components';

import { getCheckIconSrc } from '../util';

export function RateMyProfileKey(): JSX.Element {
  return (
    <KeyContainer>
      <KeyItem>
        <KeyItemIcon src={getCheckIconSrc('RED')} />
        <KeyItemText>Urgent</KeyItemText>
      </KeyItem>
      <KeyItem>
        <KeyItemIcon src={getCheckIconSrc('YELLOW')} />
        <KeyItemText>Warning</KeyItemText>
      </KeyItem>
      <KeyItem>
        <KeyItemIcon src={getCheckIconSrc('GREEN')} />
        <KeyItemText>Well Done</KeyItemText>
      </KeyItem>
    </KeyContainer>
  );
}

const KeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const KeyItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const KeyItemIcon = styled.img`
  height: 20px;
  width: 20px;
`;

const KeyItemText = styled.span`
  color: var(--color-dark-navy);
  font-size: 14px;
  line-height: 24px;
`;
