import styled from 'styled-components';

export const AccessDeniedPanel = styled.div`
  background: #f3f6fa;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
  gap: 40px;
`;

export const Info = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #73747d;
`;
