import { FunctionComponent, useState, Children, MouseEventHandler } from 'react';
import { MenuContext } from './context';
import * as Styled from './styles';

interface MenuProps {
  Icon: React.ReactNode;
  children: React.ReactNode;
}
const Menu: FunctionComponent<MenuProps> = ({ Icon, children }) => {
  const [show, setShow] = useState(false);
  const options = Children.toArray(children);
  const togglePopover: MouseEventHandler<HTMLButtonElement> = (e) => {
    setShow(!show);
    e.stopPropagation();
  };
  return (
    <MenuContext.Provider value={{ visible: show, setVisibility: setShow }}>
      <Styled.Container>
        <Styled.Button initial={{ x: -2 }} animate={{ x: 0 }} onClick={togglePopover}>
          {Icon}
        </Styled.Button>
        {show ? <Styled.Menu>{Children.map(options, (child) => child)}</Styled.Menu> : null}
      </Styled.Container>
    </MenuContext.Provider>
  );
};

export default Menu;
