import { createContext } from 'react';
export interface PathfinderGoalDrawerProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  setOnClose: React.Dispatch<React.SetStateAction<() => void>>;
  activedCategory: string;
  setActivedCategory: React.Dispatch<React.SetStateAction<string>>;
  activedSubCategory: string;
  setActivedSubCategory: React.Dispatch<React.SetStateAction<string>>;
  drawerBodyStyle: React.CSSProperties;
  setDrawerBodyStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>;
}

export const PathfinderGoalDrawerContext = createContext<PathfinderGoalDrawerProps>({
  visible: false,
  activedCategory: '',
  activedSubCategory: '',
  // pathfinderType: 'US',
  // // eslint-disable-next-line @typescript-eslint/no-empty-function
  // setPathfinderType: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActivedCategory: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActivedSubCategory: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setVisible: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOnClose: () => {},
  drawerBodyStyle: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerBodyStyle: () => {},
});
