import {
  Form,
  Input,
  SelectProps,
  DatePicker as AntDatePicker,
  Popover,
  Switch as AntSwitch,
  Tooltip,
  InputProps,
} from 'antd';
import theme from 'src/web-shared-components/theme';
import styled, { css } from 'styled-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ProfileInput, ProfileSelect } from './utils';

export const StyledSection = styled.section`
  width: 100%;
  padding: 32px 36px 26px;
  border: 1px solid ${theme.common.paleGrey};
  margin-bottom: 20px;
  background: white;
  border-radius: 8px;
`;

export const StyledSectionTitle = styled.div`
  color: ${theme.common.darkNavy};
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
  .warning-text {
    color: var(--color-yellow);
    font-size: 12px;
    margin-left: 18px;
    font-weight: 500;
  }
`;

export const StyledSecondarySectionTitle = styled.div`
  color: ${theme.common.darkNavy};
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Row = styled.div<{ wrapItems?: boolean }>`
  display: flex;
  flex-wrap: ${({ wrapItems }) => (wrapItems ? 'wrap' : 'nowrap')};
  row-gap: ${({ wrapItems }) => (wrapItems ? '22px' : '0')};
  column-gap: 8px;
  justify-content: flex-start;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Col = styled.div<{ flex?: string }>`
  flex: ${({ flex }) => (flex ? flex : '1 0 120px')};
`;

export const FormItem = styled(Form.Item)`
  margin: 0;
  height: 100%;
  .ant-form-item-row {
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    .ant-form-item-label {
      padding: 0;
      flex: 0 0 auto;
      label {
        color: var(--color-cool-grey);
        font-size: 12px;
        font-weight: 500;
        height: auto;
        width: 100%;
      }
    }
    .ant-form-item-control {
      height: 22px;
      .ant-form-item-control-input {
        min-height: 22px;
      }
    }
    .ant-input-group-addon {
      border: 0px;
      padding: 0px;
      background-color: transparent;
      .ant-select {
        margin: 0px;
        width: 80px;
      }
    }
  }
  .ant-form-item-explain-error {
    overflow-wrap: anywhere;
  }
`;

const inputValueOverflow = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TextInput = styled(ProfileInput)<InputProps>`
  ${inputValueOverflow}
  padding: 0;
  color: #000;
  font-weight: 500;
  &::placeholder {
    color: var(--color-pale-grey);
  }
  &[disabled]::placeholder {
    color: var(--color-cool-grey) !important;
  }
  input {
    height: 22px;
  }
`;

export const LocationInput = styled.input`
  ${inputValueOverflow}
  border: none;
  outline: none;
  padding-left: 0;
  width: auto;
  &:focus {
    outline: none !important;
  }
  &::placeholder {
    color: var(--color-pale-grey) !important;
  }
`;

export const TextArea = styled(Input.TextArea)`
  padding-left: 0;
  min-height: 200px;
  &::placeholder {
    color: var(--color-pale-grey) !important;
  }
`;

export const Select = styled(ProfileSelect)<SelectProps & { $ssaStatus?: 'warning' }>`
  line-height: 22px;
  height: 22px;
  display: flex;
  &.ant-select-disabled {
    .ant-select-selection-item {
      font-size: 14px;
      font-weight: 500;
      font-style: italic;
      background-color: var(--color-mist);
      color: rgba(29, 30, 43, 0.8);
      display: inline-block;
      padding-right: 3px;
      height: 20px;
    }
  }
  .ant-select-selector {
    height: 22px !important;
    padding: 0 !important;
    min-width: 60px;
    width: min-content !important;
    &::after {
      height: 22px;
    }
    .ant-select-selection-search {
      left: 0 !important;
      width: 100%;
    }
    .ant-select-selection-item {
      padding-right: 4px !important;
      flex: none;
      background-color: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '#fff3db' : '')};
      border-bottom: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '1px solid #fdaa02' : '')};
    }
    .ant-select-selection-placeholder {
      background-color: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '#fff3db' : '')};
      border-bottom: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '1px solid #fdaa02' : '')};
    }
  }
  .ant-select-in-form-item {
    width: fit-content;
  }
  .ant-select-arrow {
    position: initial;
    margin: 0;
    margin-top: 6px;
  }
`;

export const LongValueSelect = styled(Select)<{ width: number }>`
  .ant-select-selector {
    width: auto !important;
    .ant-select-selection-item {
      max-width: ${({ width }) => width}px !important;
    }
    .ant-select-selection-placeholder {
      padding-right: 4px !important;
      width: min-content !important;
      flex: none;
      color: var(--color-pale-grey);
    }
  }
`;

export const MultiSelect = styled(ProfileSelect)<SelectProps & { $ssaStatus?: 'warning' }>`
  line-height: 22px;
  height: 22px;
  display: flex;
  .ant-select-selector {
    height: 22px !important;
    padding: 0 !important;
    border-left: none !important;
    &::after {
      height: 22px;
      margin-top: 0;
    }
    .ant-select-selection-overflow {
      height: 22px;
      flex-wrap: nowrap;
      min-width: 63px;
      .ant-select-selection-overflow-item {
        height: 22px;
        .ant-select-selection-item {
          height: 20px;
          line-height: 20px;
          margin-top: 0;
          background-color: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '#fff3db' : '')};
          border-bottom: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '1px solid #fdaa02' : '')};
        }
        .ant-select-selection-search {
          height: 22px;
          margin: 0;
          input {
            height: 22px;
          }
        }
      }
    }
    .ant-select-selection-placeholder {
      min-width: 63px;
      overflow: inherit;
      left: 0;
      color: #bfbfbf;
      background-color: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '#fff3db' : '')};
      border-bottom: ${({ $ssaStatus }) => ($ssaStatus === 'warning' ? '1px solid #fdaa02' : '')};
    }
  }
  .ant-select-arrow {
    position: initial;
    margin: 0;
    margin-top: 7.5px;
  }
`;

export const DisabledValue = styled.div<{ width?: string; isNoValue?: boolean }>`
  width: ${({ width }) => (width ? width : 'auto')};
  padding-right: 2px;
  span {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    background-color: ${({ isNoValue }) => (isNoValue ? 'transparent' : 'var(--color-mist)')};
    color: ${({ isNoValue }) => (isNoValue ? 'var(--color-cool-grey)' : 'rgba(29, 30, 43, 0.8)')};
    padding-right: 3px;
    line-height: 20px;
    width: 100%;
  }
`;

export const DatePickerPopover = styled(Popover)`
  padding: 0;
  padding-top: 2px;
  font-weight: 500;
`;

export const IconTooltip = styled(Tooltip)``;

export const IconTooltipContent = styled.div``;

export const StyledDatePicker = styled(AntDatePicker)<{ width: number }>`
  padding: 0 !important;
  .ant-picker-input {
    width: ${({ width }) => width}px !important;
    input {
      height: 100%;
      &::placeholder {
        color: var(--color-pale-grey);
      }
    }
    .ant-picker-suffix {
      height: 100%;
      margin-left: 0;
      svg {
        color: var(--color-cool-grey);
      }
    }
  }
`;

export const DatePickerContainer = styled.div`
  width: fit-content;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.common.darkNavy};
  svg {
    margin-left: 4px;
    vertical-align: top;
    color: var(--color-pale-grey);
  }
  // fix antd popover scroll position
  div:nth-child(2) {
    position: relative !important;
    width: 165px;
    .ant-popover-arrow {
      z-index: 1;
    }
  }
`;

export const Switch = styled(AntSwitch)`
  color: var(--color-indigo);
`;

export const QuestionIcon = styled(QuestionCircleOutlined)`
  font-size: 13.3px;
  cursor: pointer;
`;

export const LinkValue = styled.a`
  text-decoration: underline !important;
`;
