import roles from '@crimson-education/common-config/lib/authorization/legacy/roles';
import { useMemo } from 'react';
import { User } from 'src/types/user';

export const useIsStaff = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.CASE_MANAGER, roles.TUTOR, roles.SUPER_ADMIN, roles.STRATEGIST, roles.ACADEMIC_ADVISOR];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsGuardian = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.GUARDIAN];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsStudent = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.STUDENT];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useCanViewInternalInfo = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.CASE_MANAGER, roles.REVIEWER, roles.SUPER_ADMIN, roles.STRATEGIST, roles.ACADEMIC_ADVISOR];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsStrategist = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.STRATEGIST];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsReviewer = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.REVIEWER];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsTutor = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.TUTOR];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsSSMOrSA = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.CASE_MANAGER, roles.SUPER_ADMIN];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsStrategistOrSA = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.STRATEGIST, roles.SUPER_ADMIN];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsAcademicAdvisor = (user?: User): boolean => {
  return useMemo(() => {
    const ACL = [roles.ACADEMIC_ADVISOR];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};
