import { HideScrollBar } from 'src/web-shared-components/utility';
import styled from 'styled-components';

export const DrawerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;
export const ListContainer = styled.div`
  display: flex;
`;
export const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 520px;
  margin-top: 18px;
  overflow-y: scroll;
  /* hide scrollbar */
  ${HideScrollBar}
`;
export const FormContainer = styled.div`
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  justify-content: space-between;
  height: 128px;
  .ant-form-item-no-colon {
    height: 20px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: black;
`;
export const HeaderDescription = styled.div`
  height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #73747d;
  margin-top: 12px;
`;
export const HeaderBreadCrumb = styled.div`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #a9acc0;
  margin-top: 34px;
`;

export const BreadCrumbItem = styled.div`
  height: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a9acc0;
  cursor: pointer;
  &.active {
    color: #73747d;
  }
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PathfinderGoalList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 648px;
  background-color: white;
  padding-top: 18px;
  overflow-y: scroll;
  /* hide scrollbar */
  ${HideScrollBar}
`;

export const PathfinderGoalItem = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  min-height: 48px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-mist);
  margin-bottom: 12px;
  padding-left: 24px;
  padding-right: 18px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-dark-navy);
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid white;
  &:hover {
    border: 1px solid var(--color-light-purple);
    color: var(--color-light-purple);
  }
  div:first-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 4px;
  }
  svg:last-child {
    flex: 0 0 20px;
  }
`;

export const ArrowBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  color: var(--color-stone);
  cursor: pointer;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 22px 20px;
  background-color: white;
  border: 1px solid var(--color-pale-grey);
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  &.assigned {
    border: 1px solid var(--color-indigo);
    box-shadow: 0px 5px 15px rgba(61, 64, 144, 0.2);
  }
  &.disabled {
    background: rgba(var(--rgb-color-pale-grey), 0.5);
    border: 1px solid var(--color-pale-grey);
    color: var(--color-cool-grey);
    box-shadow: none;
  }
`;

export const CardDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 16px;
  &.disabled {
    color: var(--color-stone);
  }
  & ul {
    padding-left: 15px;
    list-style-type: disc;
    margin-bottom: 1em;
  }
`;

export const PointsAndLevelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LevelPoints = styled.div`
  color: var(--color-blue);
  font-weight: 500;
  font-size: 14px;
`;

export const LevelTitle = styled.div`
  display: flex;
  align-items: center;
  color: rgba(var(--rgb-color-indigo), 0.85);
  background-color: var(--color-baby-blue);
  padding: 2px 12px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  &.disabled {
    color: var(--color-cool-grey);
    background-color: rgba(var(--rgb-color-cool-grey), 0.2);
  }
`;

export const DatePickerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;
`;

export const DatePickerHeader = styled.div`
  color: var(--color-font-black);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 12px;
  img {
    height: 26px;
  }
`;

export const DateLabel = styled.div`
  color: var(--color-dark-navy);
  font-size: 14px;
  span {
    color: var(--color-cool-grey);
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  & .title {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 20px;
    color: var(--color-cool-grey);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    & .left-line {
      background: var(--color-pale-grey);
      height: 1px;
      width: 13px;
    }
    & .right-line {
      background: var(--color-pale-grey);
      height: 1px;
      width: 270px;
    }
  }
`;
