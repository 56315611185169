import { HighlightedSpan } from './style';
import {
  displayDate,
  displayName,
  displayBadge,
  displayDescription,
  displayStatus,
  TemplateVariables,
  Templates,
} from 'src/utils/templates';
import _ from 'lodash';

export const templates: Templates = {
  // Mission
  ACTIVITY_TOPIC_MISSION_CREATE: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;created this mission
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_STATUS: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the status from&nbsp;
          <HighlightedSpan>&quot;{displayStatus(oldState)}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayStatus(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_STARTDATE: {
    rbac: ['*'],
    render: ({ operator, mission }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;set the start date to&nbsp;
          <HighlightedSpan>&quot;{displayDate(mission?.startAt)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, mission }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;set the due date to&nbsp;
          <HighlightedSpan>&quot;{displayDate(mission?.dueDate)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_DESCRIPTION: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added a description
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_STARTDATE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the start date from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the due date from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_DESCRIPTION: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the description from&nbsp;
          <HighlightedSpan>&quot;{displayDescription(oldState)}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDescription(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_TITLE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the title from &nbsp;
          <HighlightedSpan>&quot;{oldState}&quot;</HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>&quot;{newState}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_COMPLETE: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;marked this mission as Completed
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;removed this mission
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE_DESCRIPTION: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;removed the description
        </span>
      );
    },
  },
  // Task
  ACTIVITY_TOPIC_MISSION_ADD_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added a task&nbsp;
          <HighlightedSpan>&quot;{actionItem?.description}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_ACTION_ITEM_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;set the due date of the task&nbsp;
          <HighlightedSpan>&quot;{actionItem?.description}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(actionItem?.dueDate)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_ACTION_ITEM_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, actionItem, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the due date of the task &nbsp;
          <HighlightedSpan>&quot;{actionItem?.description}&quot;</HighlightedSpan>&nbsp;from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the task from&nbsp;
          <HighlightedSpan>&quot;{oldState}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{newState}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_COMPLETE_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;marked the task&nbsp;
          <HighlightedSpan>&quot;{actionItem?.description}&quot;</HighlightedSpan>&nbsp;as Completed
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;removed the task of&nbsp;
          <HighlightedSpan>&quot;{actionItem?.description}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  // Team Member
  ACTIVITY_TOPIC_MISSION_ADD_MEMBER: {
    rbac: ['*'],
    render: ({ operator, member }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added team member&nbsp;
          <HighlightedSpan>{displayName(member)}</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE_MEMBER: {
    rbac: ['*'],
    render: ({ operator, member }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;removed team member&nbsp;
          <HighlightedSpan>{displayName(member)}</HighlightedSpan>
        </span>
      );
    },
  },
  // Internal Note
  MISSION_INTERNAL_NOTE_CREATE: {
    rbac: ['strategist', 'tutor', 'case_manager', 'super_admin'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added an internal note
        </span>
      );
    },
  },
  MISSION_INTERNAL_NOTE_DELETE: {
    rbac: ['strategist', 'tutor', 'case_manager', 'super_admin'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;removed an internal note
        </span>
      );
    },
  },
  // Honors & Compettions
  ACTIVITY_TOPIC_HONOR_UPDATED: {
    rbac: ['*'],
    render: ({ operator, change }: TemplateVariables) => {
      // Have a mapping of Honor Fields to log fillers here...
      let value = change.value;
      if (Array.isArray(value)) value = value.join(', ');
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp; set the value of {_.startCase(change.key)} to
          &nbsp;
          <HighlightedSpan>{value}</HighlightedSpan>
        </span>
      );
    },
  },
  // Reflections
  ACTICITY_TOPIC_REFLECTION_CREATED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added a reflection
        </span>
      );
    },
  },
  ACTICITY_TOPIC_REFLECTION_DELETED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;removed a reflection
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_REFLECTION_COMPLETED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;marked the reflection as Completed
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_REFLECTION_ADD_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, reflection }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;set the due date of the reflection to&nbsp;
          <HighlightedSpan>&quot;{displayDate(reflection?.dueDate)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_REFLECTION_CHANGE_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed the due date of the reflection
          from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>&nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_BADGE_CREATED: {
    rbac: ['*'],
    render: ({ operator, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;assigned a{' '}
          <HighlightedSpan>{displayBadge(newState)}</HighlightedSpan> badge for this mission.
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_BADGE_DELETED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;removed badge.
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_BADGE_CHANGE: {
    rbac: ['*'],
    render: ({ operator, newState, oldState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;changed badge from{' '}
          <HighlightedSpan>{displayBadge(oldState)}</HighlightedSpan> to{' '}
          <HighlightedSpan>{displayBadge(newState)}</HighlightedSpan>.
        </span>
      );
    },
  },
};
