import WatchListTooltip from './WatchListTooltip';
import { WatchListCommentContainer } from './style';

const WatchListComment = (): JSX.Element => {
  return (
    <WatchListCommentContainer>
      <WatchListTooltip />

      <span>{'Highly recommended'}</span>
    </WatchListCommentContainer>
  );
};

export default WatchListComment;
