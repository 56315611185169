import styled from 'styled-components';
import { PlusOutlined as AntPlusOutlined } from '@ant-design/icons';

export const StyledSection = styled.section`
  width: 100%;
  padding: 36px;
  border: 1px solid #e3e7ed;
  margin-bottom: 20px;
  background: white;
  border-radius: 8px;
  overflow: overlay;
`;

export const StyledSectionTitle = styled.div`
  color: #1d1e2b;
  font-size: 20px;
  font-weight: 700;
`;

export const StyledSectionHeader = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AddTestButton = styled.div<{ on?: 'overview' | 'empty' }>`
  width: ${({ on }) => (on === 'overview' ? '150px' : '199px')};
  height: ${({ on }) => (on === 'overview' ? '38px' : '40px')};
  font-weight: 700;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: #464ac9; /* Indigo */
  box-shadow: 0px 4px 18px rgba(55, 47, 182, 0.2);
  border-radius: 28px;
`;

export const EmptyPageContainer = styled.div`
  height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 18px;
  padding-top: 110px;
  & > div {
    &:first-of-type {
      color: #1d1e2b;
      font-weight: 700;
      font-size: 20px;
      padding-top: 10px;
    }
    &:nth-of-type(2) {
      color: #73747d;
      width: 410px;
      font-size: 14px;
      text-align: center;
    }
  }
`;

export const PlusOutlined = styled(AntPlusOutlined)`
  font-size: 14px;
  color: #fff;
  padding-right: 14px;
`;

export const TestOptOutContainer = styled.div`
  padding: 20px 24px;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  font-family: 'Montserrat';
  background: linear-gradient(to bottom, #dcdfff 0%, #fbfbff 30%, #ffffff 100%);
  display: flex;
  .title {
    display: flex;
    justify-content: space-between;
    .bold {
      color: #1d1e2b;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .title-tip {
      color: #fdaa02;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .header {
    margin-bottom: 8px;
    font-size: 14px;
    .bold {
      font-weight: 600;
    }
  }

  .subtitle {
    color: #73747d;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }
  .options {
    display: flex;
    gap: 16px;
    line-height: 20px;
    justify-content: space-between;
    .option {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      color: #1d1e2b;
      .switch {
        line-height: 14px;
        margin-left: 4px;
        transform: scale(0.9);
        .ant-switch-handle {
          transform: scale(0.7);
        }
      }
    }
  }
`;
