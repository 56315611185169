import styled from 'styled-components';
import { Select } from 'antd';
export const StyledSelect = styled(Select)`
  .ant-select-selector {
    /* height: 20px !important; */
    padding: 0 24px 0 0 !important;
    min-width: 80px;
    .ant-select-selection-search {
      left: 0 !important;
      width: 100%;
    }
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    padding-right: 4px !important;
  }
  /* .ant-select-selection-item {
    line-height: 20px !important;
  } */
  .ant-select-arrow {
    /* top: 55%; */
    right: 8px;
  }
`;
export const StyledMultiSelect = styled.div`
  .ant-select-multiple.ant-select-sm .ant-select-selection-search {
    margin-inline-start: 0px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    padding-left: 0;
    left: 0;
  }
`;
