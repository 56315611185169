import { EclResource, MissionCategory, MissionSubCategory } from '../type';
import { EclDetailQueryStatus } from './type';
import { Categories, Subcategories } from 'src/types/mission';

export const INITIAL_STORE: TStore = {
  listDrawerOpen: false,
  detailDrawerOpen: false,
  missionCategory: Categories.COMPETITIONS_HONORS,
  missionSubCategory: Subcategories.Honor,
  eclResourceDeatil: {},
  eclResourceQueryStatus: {},
  detailEclId: null,
  addMissionModalOpen: false,
  addMissionEclId: null,
  createdCompetitionCount: 0,
  createdSummerProgramCount: 0,
};

export type TStore = {
  listDrawerOpen: boolean;
  detailDrawerOpen: boolean;
  addMissionModalOpen: boolean;
  missionCategory: MissionCategory;
  missionSubCategory: MissionSubCategory;
  eclResourceDeatil: Record<string, EclResource>;
  eclResourceQueryStatus: EclDetailQueryStatus;
  detailEclId: string | null;
  addMissionEclId: string | null;
  createdCompetitionCount: number;
  createdSummerProgramCount: number;
};

export type TActionWithCustomPayload<N, P> = {
  name: N;
  payload: P;
};

export type TAction<N, K extends keyof TStore> = TActionWithCustomPayload<N, Pick<TStore, K>>;

export type TActionWithoutPayload<N> = TActionWithCustomPayload<N, undefined>;

export type TActionWithPartialPayload<N, K extends keyof TStore> = TActionWithCustomPayload<
  N,
  Partial<Pick<TStore, K>>
>;

type SetListDrawerOpen = TAction<
  'resourceCenter/setListDrawerOpen',
  'listDrawerOpen' | 'missionCategory' | 'missionSubCategory'
>;
type OpenDetailDrawer = TAction<'resourceCenter/openDetailDrawer', 'detailDrawerOpen' | 'detailEclId'>;
type CloseDetailDrawer = TAction<'resourceCenter/closeDetailDrawer', 'detailDrawerOpen' | 'detailEclId'>;
type SetEclResourceDeatil = TActionWithCustomPayload<
  'resourceCenter/setEclResourceDetail',
  TStore['eclResourceDeatil']
>;
type SetEclResourceQueryStatus = TActionWithCustomPayload<
  'resourceCenter/setEclResourceQueryStatus',
  TStore['eclResourceQueryStatus']
>;
type ReplaceEclResourceQueryStatus = TAction<'resourceCenter/replaceEclQueryStatus', 'eclResourceQueryStatus'>;
type OpenAddMissionModal = TAction<'resourceCenter/openAddMissionModal', 'addMissionEclId' | 'addMissionModalOpen'>;
type CloseAddMissionModal = TAction<'resourceCenter/closeAddMissionModal', 'addMissionModalOpen' | 'addMissionEclId'>;
type IncreaseCreatedCompetitionCount = TActionWithoutPayload<'resourceCenter/increaseCreatedCompetitionCount'>;
type IncreaseCreatedSummerProgramCount = TActionWithoutPayload<'resourceCenter/increaseCreatedSummerProgramCount'>;
type CloseAll = TActionWithoutPayload<'resourceCenter/closeAll'>;
export type Actions =
  | SetListDrawerOpen
  | SetEclResourceDeatil
  | SetEclResourceQueryStatus
  | ReplaceEclResourceQueryStatus
  | OpenDetailDrawer
  | CloseDetailDrawer
  | OpenAddMissionModal
  | CloseAddMissionModal
  | IncreaseCreatedCompetitionCount
  | IncreaseCreatedSummerProgramCount
  | CloseAll;

export const reducer = <A extends Actions>(state: TStore, action: A): TStore => {
  switch (action.name) {
    case 'resourceCenter/setListDrawerOpen':
      const {
        listDrawerOpen,
        missionCategory = Categories.COMPETITIONS_HONORS,
        missionSubCategory = Subcategories.Honor,
      } = action.payload;
      return {
        ...state,
        listDrawerOpen,
        missionCategory,
        missionSubCategory,
      };
    case 'resourceCenter/openDetailDrawer':
    case 'resourceCenter/closeDetailDrawer':
      const { detailDrawerOpen, detailEclId } = action.payload;
      return {
        ...state,
        detailDrawerOpen,
        detailEclId,
      };
    case 'resourceCenter/setEclResourceDetail':
      const { ...resourceDetail } = action.payload;
      return {
        ...state,
        eclResourceDeatil: {
          ...state.eclResourceDeatil,
          ...resourceDetail,
        },
      };
    case 'resourceCenter/setEclResourceQueryStatus':
      const { ...eclQueryStatus } = action.payload;
      return {
        ...state,
        eclResourceQueryStatus: {
          ...state.eclResourceQueryStatus,
          ...eclQueryStatus,
        },
      };
    case 'resourceCenter/replaceEclQueryStatus':
      const { eclResourceQueryStatus } = action.payload;
      return {
        ...state,
        eclResourceQueryStatus,
      };
    case 'resourceCenter/openAddMissionModal':
    case 'resourceCenter/closeAddMissionModal':
      const { addMissionEclId, addMissionModalOpen } = action.payload;
      return {
        ...state,
        addMissionEclId,
        addMissionModalOpen,
      };
    case 'resourceCenter/increaseCreatedCompetitionCount':
      return {
        ...state,
        createdCompetitionCount: state.createdCompetitionCount + 1,
      };
    case 'resourceCenter/increaseCreatedSummerProgramCount':
      return {
        ...state,
        createdSummerProgramCount: state.createdSummerProgramCount + 1,
      };
    case 'resourceCenter/closeAll':
      return {
        ...state,
        listDrawerOpen: false,
        detailDrawerOpen: false,
        addMissionModalOpen: false,
      };
    default:
      return state;
  }
};
