import { gql } from '@apollo/client';

export const INIT_STUDENT_INFO = gql`
  mutation initStudentInfo($input: InitStudentAcademicInput!) {
    initStudentInfo(input: $input) {
      success
      modifiedMissionCount
    }
  }
`;

export const LOAD_STUDENT_INITIALIZATION_INFO = gql`
  query initializationInfo($userId: String!) {
    initializationInfo(userId: $userId) {
      userId
      educationBackground
      gradeLevelType
      currentGradeLevel
      startGradeLevel
      finalGradeLevel
      academicYearStart
      academicYearEnd
    }
  }
`;
