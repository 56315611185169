import { create } from 'zustand';

export interface ISuperScoreStore {
  superScoreForSAT: number;
  superScoreForACT: number;
  updateSuperScoreForAST: (newValue: number) => void;
  updateSuperScoreForACT: (newValue: number) => void;
}

export const useTestingSuperScore = create<ISuperScoreStore>((set) => ({
  superScoreForSAT: 0,
  updateSuperScoreForAST: (newValue: number) =>
    set((state: ISuperScoreStore) => ({
      ...state,
      superScoreForSAT: newValue,
    })),
  superScoreForACT: 0,
  updateSuperScoreForACT: (newValue: number) =>
    set((state: ISuperScoreStore) => ({
      ...state,
      superScoreForACT: newValue,
    })),
}));

export interface ILimitCWUserEditMissionStore {
  isLimitCWUserEditMission: boolean;
  updateIsLimitCWUserEditMission: (newValue: boolean) => void;
}

export const useLimitCWUserEditMission = create<ILimitCWUserEditMissionStore>((set) => ({
  isLimitCWUserEditMission: false,
  updateIsLimitCWUserEditMission: (newValue: boolean) =>
    set((state: ILimitCWUserEditMissionStore) => ({
      ...state,
      isLimitCWUserEditMission: newValue,
    })),
}));
