import RandomIcon from 'src/components/RandomIcon';
import { TestIconMapping } from 'src/pages/Testing/TestingMissionsSection/TestMissionsGroup';

interface RecommendationIconProps {
  id: string;
  category: string;
  size?: number;
  title: string;
}

export const RecommendationIcon = ({ id, category, size, title }: RecommendationIconProps): JSX.Element | null => {
  if (category === 'testing') {
    const testName = title as keyof typeof TestIconMapping;
    if (TestIconMapping[testName]) {
      return <img height={size} width={size} src={TestIconMapping[testName]} alt="" />;
    } else {
      return null;
    }
  } else {
    return <RandomIcon seed={id} size={size} />;
  }
};
