import styled from 'styled-components';

export const Root = styled.div<{ loading?: boolean }>`
  padding: 12px;
  border: 1px solid var(--color-pale-grey);
  border-radius: 8px;
  background: #ffffff;
  position: relative;
  opacity: ${({ loading }) => (loading ? 0.6 : 1.0)};
  transition: 0.3s ease opacity;
`;

export const HeaderButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: var(--color-mist);
  border-radius: 50%;
  svg {
    color: var(--color-indigo);
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export const HeaderTitle = styled.span`
  align-items: center;
  color: #000000;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 13px;
`;

export const Content = styled.div`
  padding: 12px 0 0 0;
`;
