import { Rule } from 'antd/lib/form';
import { GradeResultType, gradeValidations } from '@crimson-education/common-config';
import { TestTypeType } from 'src/__generated__/graphqlTypes';

function isNumber(value: string) {
  const numberRegExp = /^\d+(\.\d+)?$/;
  return numberRegExp.test(value);
}

// Check if input is within an inclusive-inclusive range.
function isInRange(value: string, lower: number, upper: number) {
  if (!isNumber(value)) return false;
  const num = Number(value);
  return num >= lower && num <= upper;
}

const integerRangeTestScoreRules = (required: boolean, lower: number, upper: number): Rule[] => {
  return [
    { required: required },
    {
      validator: (_: Rule, value: string): Promise<Error | void> => {
        if (!value) {
          return Promise.resolve();
        }
        if (!Number.isInteger(Number(value))) {
          return Promise.reject(new Error('Input needs to be an integer'));
        }
        return isInRange(value, lower, upper)
          ? Promise.resolve()
          : Promise.reject(new Error(`Input needs to be between ${lower} and ${upper}`));
      },
    },
  ];
};

const integerTestScoreRules = (required: boolean): Rule[] => {
  return [
    { required: required },
    {
      validator: (_: Rule, value: string): Promise<Error | void> => {
        if (!value) {
          return Promise.resolve();
        }
        if (!Number.isInteger(Number(value))) {
          return Promise.reject(new Error('Input needs to be an integer'));
        }
        return Promise.resolve();
      },
    },
  ];
};

const floatTestScoreRules = (required: boolean): Rule[] => {
  return [
    { required: required },
    {
      validator: (_: Rule, value: string): Promise<Error | void> => {
        if (!value) {
          return Promise.resolve();
        }

        // if (!/^\d+(\.\d?)+$/.test(value)) {
        if (Number.isNaN(Number(value))) {
          return Promise.reject(new Error('Input needs to be a number'));
        }
        return Promise.resolve();
      },
    },
  ];
};

const stringTestRules = (required: boolean): Rule[] => {
  return [{ required: required }];
};

export type TestSubjectType = {
  name: string;
  rules: Rule[] | null;
  isTotalField?: boolean;
  isFutureTest?: boolean;
};

export const APTITUDE_TESTING_WITH_2_SECTION_MODIFY = [
  TestTypeType.Tsa,
  TestTypeType.Cat,
  TestTypeType.Olat,
  TestTypeType.HistAdmisAssess,
  TestTypeType.Hat,
  TestTypeType.Mlat,
  TestTypeType.PsychAdmisAssess,
  TestTypeType.Pat,
  TestTypeType.HistArtAdmisAssess,
  TestTypeType.Elat,
  TestTypeType.Lnat,
  TestTypeType.AptitudeTestingCsat,
  TestTypeType.AptitudeTestingEcaa,
];

// if GradeResultType has the given commonConfigGradeType, use it; if not, use above default rules
const subjectResultRules = (
  commonConfigGradeType: string,
  required = true,
  isFutureTest = false,
  useShortErrMsg = false,
  defaultType: 'string' | 'integer' | 'float' = 'integer',
): Rule[] => {
  if (APTITUDE_TESTING_WITH_2_SECTION_MODIFY.some((testingType) => testingType === commonConfigGradeType)) {
    return integerRangeTestScoreRules(required, 0, 100);
  }

  if (Object.values(GradeResultType).includes(commonConfigGradeType)) {
    const { validationMessage } = gradeValidations.gradeResultValidations[commonConfigGradeType];
    let { validator } = gradeValidations.gradeResultValidations[commonConfigGradeType];
    // special cases
    if (GradeResultType.ACT_WRITING === commonConfigGradeType) {
      validator = (value: string) => isInRange(value, 0, 12);
    }
    if (GradeResultType.SAT_REASONING_ESSAY === commonConfigGradeType) {
      validator = (value: string) => isInRange(value, 0, 24);
    }
    return [
      { required: GradeResultType.ACT_WRITING === commonConfigGradeType ? false : required },
      {
        validator: (_: Rule, value: string): Promise<Error | void> => {
          if (value?.startsWith('Auto')) return Promise.resolve();
          if (isFutureTest && !value) {
            return Promise.resolve();
          } else if (value && !validator(value, {})) {
            let errMessage = typeof validationMessage === 'function' ? validationMessage({}) : validationMessage;
            errMessage = useShortErrMsg ? errMessage.replace(/must be between /, '').replace('and', '-') : errMessage;
            return Promise.reject(new Error(errMessage));
          } else {
            return Promise.resolve();
          }
        },
      },
    ];
  } else {
    if (commonConfigGradeType === 'UNISR_MED_ADMIS_ASSESS_60') {
      return integerRangeTestScoreRules(required, 0, 60);
    }
    if (commonConfigGradeType === 'COMENIUS_MED_ADMIS_TEST_400') {
      return integerRangeTestScoreRules(required, 0, 400);
    }
    if (commonConfigGradeType === 'COMENIUS_MED_ADMIS_TEST_800') {
      return integerRangeTestScoreRules(required, 0, 800);
    }
    if (commonConfigGradeType.startsWith('APTITUDE_TESTING_ESAT_')) {
      return integerRangeTestScoreRules(required, 0, 40);
    }
    if (defaultType === 'integer') {
      return integerTestScoreRules(required);
    } else if (defaultType === 'string') {
      return stringTestRules(required);
    } else {
      return floatTestScoreRules(required);
    }
  }
};

const TestNameMap: { [key: string]: string } = {
  ARCHITECTURE_ADMISSIONS: 'ARCH_ADMIS_ASSESS',
};

export const mapTestToSubjects = (
  name: string,
  required = true,
  useShortErrMsg = false,
  isFutureTest = false,
): TestSubjectType[] => {
  const map: Record<string, TestSubjectType[]> = {
    ACT: [
      {
        name: 'English',
        rules: subjectResultRules(GradeResultType.ACT_ENGLISH, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Math',
        rules: subjectResultRules(GradeResultType.ACT_MATH, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Reading',
        rules: subjectResultRules(GradeResultType.ACT_READING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Science',
        rules: subjectResultRules(GradeResultType.ACT_SCIENCE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Writing',
        rules: subjectResultRules(GradeResultType.ACT_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Composite',
        rules: subjectResultRules(GradeResultType.ACT_OVERALL_SCORE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    DUOLINGO: [
      {
        name: 'Literacy',
        rules: subjectResultRules(GradeResultType.Duolingo, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Comprehension',
        rules: subjectResultRules(GradeResultType.Duolingo, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Conversation',
        rules: subjectResultRules(GradeResultType.Duolingo, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Production',
        rules: subjectResultRules(GradeResultType.Duolingo, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Overall',
        rules: subjectResultRules(GradeResultType.Duolingo, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    GMAT: [
      {
        name: 'Integrated Reasoning',
        rules: subjectResultRules(GradeResultType.GMAT_REASONING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative',
        rules: subjectResultRules(GradeResultType.GMAT_QUANTITATIVE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Analytical Writing',
        rules: subjectResultRules(GradeResultType.GMAT_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Verbal',
        rules: subjectResultRules(GradeResultType.GMAT_VERBAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.GMAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    GRE: [
      {
        name: 'Verbal Reasoning',
        rules: subjectResultRules(GradeResultType.GRE_VERBAL_REASONING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative Reasoning',
        rules: subjectResultRules(GradeResultType.GRE_QUANTITATIVE_REASONING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Analytical Writing',
        rules: subjectResultRules(GradeResultType.GRE_ANALYTICAL_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.GRE_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    IELTS: [
      {
        name: 'Listening',
        rules: subjectResultRules(GradeResultType.IELTS_LISTENING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Reading',
        rules: subjectResultRules(GradeResultType.IELTS_READING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Speaking',
        rules: subjectResultRules(GradeResultType.IELTS_SPEAKING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Writing',
        rules: subjectResultRules(GradeResultType.IELTS_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Overall Band Score',
        rules: subjectResultRules(GradeResultType.IELTS_OVERALL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ISEE: [
      {
        name: 'Verbal Reasoning',
        rules: subjectResultRules(GradeResultType.ISEE_VERBAL_REASONING, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
      {
        name: 'Reading Comprehension',
        rules: subjectResultRules(GradeResultType.ISEE_READING_COMPREHENSION, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative Reasoning',
        rules: subjectResultRules(GradeResultType.ISEE_QUANTITATIVE_REASONING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Mathematics Achievement',
        rules: subjectResultRules(GradeResultType.ISEE_MATH, required, isFutureTest, useShortErrMsg),
      },
    ],
    LNAT: [
      {
        name: 'Section 1',
        rules: subjectResultRules(GradeResultType.LNAT_SECTION_A, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.LNAT_SECTION_A, required, isFutureTest, useShortErrMsg),
      },
    ],
    LSAT: [
      {
        name: 'Score',
        rules: subjectResultRules(GradeResultType.LSAT_SCORE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
      {
        name: '%Rank',
        rules: subjectResultRules(GradeResultType.LSAT_PERCENTILE, required, isFutureTest, useShortErrMsg),
      },
    ],
    MCAT: [
      {
        name: 'Chemical and Physical Foundations of Biological Systems',
        rules: subjectResultRules(
          GradeResultType.MCAT_CHEMICAL_AND_PHYSICAL_FOUNDATIONS,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Critical Analysis and Reasoning Skills',
        rules: subjectResultRules(
          GradeResultType.MCAT_CRITICAL_ANALYSIS_AND_REASONING_SKILLS,
          required,
          useShortErrMsg,
        ),
      },
      {
        name: 'Biological and Biochemical Foundations of Living Systems',
        rules: subjectResultRules(
          GradeResultType.MCAT_BIOLOGICAL_AND_BIOCHEMICAL_FOUNDATIONS,
          required,
          useShortErrMsg,
        ),
      },
      {
        name: 'Pyschological, Social, and Biological Foundations of Behavior',
        rules: subjectResultRules(
          GradeResultType.MCAT_PHYSIOGILOGICAL_FOUNDATIONS,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.MCAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    PTE: [
      {
        name: 'Listening',
        rules: subjectResultRules(GradeResultType.PTE_LISTENING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Reading',
        rules: subjectResultRules(GradeResultType.PTE_READING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Speaking',
        rules: subjectResultRules(GradeResultType.PTE_SPEAKING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Writing',
        rules: subjectResultRules(GradeResultType.PTE_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Overall',
        rules: subjectResultRules(GradeResultType.PTE_OVERALL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    SAT: [
      {
        name: 'Evidence-based reading and writing',
        rules: subjectResultRules(GradeResultType.SAT_SUBJECT_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Math',
        rules: subjectResultRules(GradeResultType.SAT_SUBJECT_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.SAT_REASONING_OVERALL_SCORE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    SSAT: [
      {
        name: 'Verbal',
        rules: subjectResultRules(GradeResultType.SSAT_VERBAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative',
        rules: subjectResultRules(GradeResultType.SSAT_QUANTITATIVE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Reading',
        rules: subjectResultRules(GradeResultType.SSAT_READING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.SSAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    TOEFL: [
      {
        name: 'Listening',
        rules: subjectResultRules(GradeResultType.TOEFL_LISTENING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Reading',
        rules: subjectResultRules(GradeResultType.TOEFL_READING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Speaking',
        rules: subjectResultRules(GradeResultType.TOEFL_SPEAKING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Writing',
        rules: subjectResultRules(GradeResultType.TOEFL_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.TOEFL_OVERALL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    TSA: [
      {
        name: 'Section 1',
        rules: subjectResultRules(GradeResultType.TSA_SECTION_1, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.TSA_SECTION_1, required, isFutureTest, useShortErrMsg),
      },
    ],
    UCAT: [
      {
        name: 'Verbal Reasoning',
        rules: subjectResultRules(GradeResultType.UCAT_AR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Decision Making',
        rules: subjectResultRules(GradeResultType.UCAT_DM_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative Reasoning',
        rules: subjectResultRules(GradeResultType.UCAT_QR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Abstract Reasoning',
        rules: subjectResultRules(GradeResultType.UCAT_AR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Situational Judgement (Band)',
        rules: subjectResultRules(GradeResultType.UCAT_SJT_SCORE, false, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(
          GradeResultType.UCAT_TOTAL_COGNITIVE_SUBTEST_SCORE,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
        isTotalField: true,
      },
    ],
    BMAT: [
      {
        name: 'Section 1: Aptitude and Skills',
        rules: subjectResultRules(GradeResultType.BMAT_SECTION_1, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
      {
        name: 'Section 2: Scientific Knowledge and Applications',
        rules: subjectResultRules(GradeResultType.BMAT_SECTION_2, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 3: Writing Task (Quality of Content)',
        rules: subjectResultRules(GradeResultType.BMAT_SECTION_3, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 3: Writing Task (Quality of English)',
        rules: subjectResultRules(GradeResultType.BMAT_SECTION_3, required, isFutureTest, useShortErrMsg),
      },
    ],
    GAMSAT: [
      {
        name: 'Section I: Reasoning in Humanities and Social Sciences',
        rules: subjectResultRules(GradeResultType.GAMSAT_SECTION_I_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section II: Written Communication',
        rules: subjectResultRules(GradeResultType.GAMSAT_SECTION_II_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section III: Reasoning in Biological & Physical Science',
        rules: subjectResultRules(GradeResultType.GAMSAT_SECTION_III_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Overall',
        rules: subjectResultRules(GradeResultType.GAMSAT_OVERALL_SCORE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    MAT: [
      {
        name: 'Question 1',
        rules: subjectResultRules(GradeResultType.MAT_Q1, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Question 2-7',
        rules: subjectResultRules(GradeResultType.MAT_Q2_Q7, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.MAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    TMUA: [
      {
        name: 'Paper 1',
        rules: subjectResultRules(GradeResultType.TMUA_PAPER_1, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Paper 2',
        rules: subjectResultRules(GradeResultType.TMUA_PAPER_2, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.TMUA_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    NSAA: [
      {
        name: 'Section A',
        rules: subjectResultRules(GradeResultType.NSAA_SECTION_A, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section B',
        rules: subjectResultRules(GradeResultType.NSAA_SECTION_B, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.NSAA_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ENGAA: [
      {
        name: 'Section 1 Part A',
        rules: subjectResultRules(GradeResultType.ENGAA_SECTION_1A, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
      {
        name: 'Section 1 Part B',
        rules: subjectResultRules(GradeResultType.ENGAA_SECTION_1B, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.ENGAA_SECTION_2, required, isFutureTest, useShortErrMsg),
      },
    ],
    STEP: [
      {
        name: 'Mathematics II',
        rules: subjectResultRules(GradeResultType.STEP_MATH_II, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
      {
        name: 'Mathematics III',
        rules: subjectResultRules(GradeResultType.STEP_MATH_III, required, isFutureTest, useShortErrMsg),
      },
    ],
    HPAT: [
      {
        name: 'Logical Reasoning and Problem Solving',
        rules: subjectResultRules(GradeResultType.HPAT_LOGICAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Interpersonal Understanding',
        rules: subjectResultRules(GradeResultType.HPAT_INTERPERSONAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Non-verbal Reasoning',
        rules: subjectResultRules(GradeResultType.HPAT_NON_VERBAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.HPAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    PAT: [
      {
        name: 'section 1',
        rules: subjectResultRules(GradeResultType.PAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'section 2',
        rules: subjectResultRules(GradeResultType.PAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
    ],
    ELAT: [
      {
        name: 'section 1',
        rules: subjectResultRules(GradeResultType.ELAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'section 2',
        rules: subjectResultRules(GradeResultType.ELAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
    ],
    HAT: [
      {
        name: 'Section 1',
        rules: subjectResultRules(GradeResultType.HAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.HAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
    ],
    CAT: [
      {
        name: 'Section 1',
        rules: subjectResultRules(GradeResultType.CAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.CAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
    ],
    OLAT: [
      {
        name: 'Section 1',
        rules: subjectResultRules(GradeResultType.OLAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.OLAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
    ],
    MLAT: [
      {
        name: 'Section 1',
        rules: subjectResultRules(GradeResultType.MLAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.MLAT_TOTAL, required, isFutureTest, useShortErrMsg),
      },
    ],
    PHIL: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.PHIL_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    IMAT: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.IMAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    BOCCONI: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.BOCCONI_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    TUMAT: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.TUMAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    PSAT: [
      {
        name: 'Evidence-based reading and writing',
        rules: subjectResultRules(GradeResultType.PSAT_READING_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Math',
        rules: subjectResultRules(GradeResultType.PSAT_MATH, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.PSAT_OVERALL_SCORE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ISAT: [
      {
        name: 'Critical Reasoning SCORE',
        rules: subjectResultRules(GradeResultType.UK_ISAT_CR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Critical Reasoning %ILE',
        rules: subjectResultRules(GradeResultType.UK_ISAT_CR_PERCENTILE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative Reasoning SCORE',
        rules: subjectResultRules(GradeResultType.UK_ISAT_QR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative Reasoning %ILE',
        rules: subjectResultRules(GradeResultType.UK_ISAT_QR_PERCENTILE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Overall SCORE',
        rules: subjectResultRules(GradeResultType.UK_ISAT_TOTAL_SCORE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
      {
        name: 'Overall %ILE',
        rules: subjectResultRules(GradeResultType.UK_ISAT_PERCENTILE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    // below are EU testings
    PCE: [
      {
        name: 'Combined PCE + Secondary Certificate',
        rules: subjectResultRules(GradeResultType.EU_PCE_COMBINED, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_PCE_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    OMPTA: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_OMPT_A, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    OMPTB: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_OMPT_B, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    OMPTC: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_OMPT_C, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    OMPTD: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_OMPT_D, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    VWOPHYSICS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_VWO_PHYSICS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    VWOCHEMISTRY: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_VWO_CHEMISTRY, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    VWOMATHA: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_VWO_MATH_A, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    VWOMATHB: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_VWO_MATH_B, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    VWOMATHC: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_VWO_MATH_C, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    VWOBIOLOGY: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_VWO_BIOLOGY, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    EUHUMAT: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_HUMAT, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    UCATCAMILLUS: [
      {
        name: 'Verbal Reasoning',
        rules: subjectResultRules(GradeResultType.EU_UCAT_CAMILLUS_VR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Decision Making',
        rules: subjectResultRules(GradeResultType.EU_UCAT_CAMILLUS_DM_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Quantitative Reasoning',
        rules: subjectResultRules(GradeResultType.EU_UCAT_CAMILLUS_QR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Abstract Reasoning',
        rules: subjectResultRules(GradeResultType.EU_UCAT_CAMILLUS_AR_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Situational Judgement',
        rules: subjectResultRules(GradeResultType.EU_UCAT_CAMILLUS_SJ_SCORE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_UCAT_CAMILLUS_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    IEUNIVERSITYADMISSIONS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_IE_UNIVERSITY_ADMISSIONS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    EUARCHITECTUREADMISSIONS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_ARCHITECTURE_ADMISSIONS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ETHZURICH: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_ETH_ZURICH, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    EHLADMISSIONS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_EHL_ADMISSIONS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    CCVXBIOLOGY: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_CCVX_BIOLOGY, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    CCVXPHYSICS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_CCVX_PHYSICS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    CCVXCHEMISTRY: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_CCVX_CHEMISTRY, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    CCVXMATHA: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_CCVX_MATH_A, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    CCVXMATHB: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_CCVX_MATH_B, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    BOCCONIONLINE: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_BOCCONI_ONLINE, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ENGLISHTOLCI: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_ENLIGSH_TOLC_I, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ENGLISHTOLCE: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_ENLIGSH_TOLC_E, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ENGLISHTOLCF: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_ENLIGSH_TOLC_F, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    STUDIENKOLLEG: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_STUDIENKOLLEG, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    EBAU: [
      {
        name: 'Combined EBAU + Secondary Certificate',
        rules: subjectResultRules(GradeResultType.EU_EBAU_COMBINED, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.EU_EBAU, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    // extra uk testing
    ARCHAEOLOGY_ADMIS_ASSESS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.ARCHAEOLOGY_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ARCH_ADMIS_ASSESS: [
      {
        name: 'Part 1',
        rules: subjectResultRules(GradeResultType.ARCH_ADMIS_ASSESS_1, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Part 2',
        rules: subjectResultRules(GradeResultType.ARCH_ADMIS_ASSESS_2, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.ARCH_ADMIS_ASSESS_total, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    MOD_MED_LANG_ADMIS_ASSESS: [
      {
        name: 'Section A',
        rules: subjectResultRules(GradeResultType.MOD_MED_LANG_ADMIS_ASSESS_A, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section B',
        rules: subjectResultRules(GradeResultType.MOD_MED_LANG_ADMIS_ASSESS_B, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(
          GradeResultType.MOD_MED_LANG_ADMIS_ASSESS_total,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
        isTotalField: true,
      },
    ],
    ENGL_ADMIS_ASSESS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.ENGL_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    GEOG_ADMIS_ASSESS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.GEOG_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    HIST_ADMIS_ASSESS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.HIST_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    HIST_ART_ADMIS_ASSESS: [
      {
        name: 'section 1',
        rules: subjectResultRules(GradeResultType.HIST_ART_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'section 2',
        rules: subjectResultRules(GradeResultType.HIST_ART_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
      },
    ],
    // HIST_SOC_POL_SCI_ADMIS_ASSESS 0-100
    HIST_SOC_POL_SCI_ADMIS_ASSESS: [
      {
        name: 'Section 1',
        rules: subjectResultRules(
          GradeResultType.HIST_SOC_POL_SCI_ADMIS_ASSESS,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(
          GradeResultType.HIST_SOC_POL_SCI_ADMIS_ASSESS,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
    ],
    LING_ADMIS_ASSESS: [
      {
        name: 'Part 1',
        rules: subjectResultRules(GradeResultType.LING_ADMIS_ASSESS_1, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Part 2',
        rules: subjectResultRules(GradeResultType.LING_ADMIS_ASSESS_2, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Part 3',
        rules: subjectResultRules(GradeResultType.LING_ADMIS_ASSESS_3, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.LING_ADMIS_ASSESS_total, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    MATH_ADMIS_ASSESS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.MATH_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    MUS_ADMIS_ASSESS: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.MUS_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    PHIL_ADMIS_ASSESS: [
      {
        name: 'Part 1',
        rules: subjectResultRules(GradeResultType.PHIL_ADMIS_ASSESS_1, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Part 2',
        rules: subjectResultRules(GradeResultType.PHIL_ADMIS_ASSESS_2, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.PHIL_ADMIS_ASSESS_total, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    PSYCH_ADMIS_ASSESS: [
      {
        name: 'Section 1',
        rules: subjectResultRules(GradeResultType.PSYCH_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(GradeResultType.PSYCH_ADMIS_ASSESS, required, isFutureTest, useShortErrMsg),
      },
    ],
    APTITUDE_TESTING_ESAT_BIOLOGY_CHEMISTRY: [
      {
        name: 'Section 1',
        rules: subjectResultRules(
          'APTITUDE_TESTING_ESAT_BIOLOGY_CHEMISTRY/section1',
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(
          'APTITUDE_TESTING_ESAT_BIOLOGY_CHEMISTRY/section2',
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 3',
        rules: subjectResultRules(
          'APTITUDE_TESTING_ESAT_BIOLOGY_CHEMISTRY/section3',
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
    ],
    APTITUDE_TESTING_ESAT_MATH_PHYSICS: [
      {
        name: 'Section 1',
        rules: subjectResultRules(
          'APTITUDE_TESTING_ESAT_MATH_PHYSICS/section1',
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(
          'APTITUDE_TESTING_ESAT_MATH_PHYSICS/section2',
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 3',
        rules: subjectResultRules(
          'APTITUDE_TESTING_ESAT_MATH_PHYSICS/section3',
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
    ],
    APTITUDE_TESTING_ECAA: [
      {
        name: 'Section 1',
        rules: subjectResultRules(`APTITUDE_TESTING_ECAA`, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(`APTITUDE_TESTING_ECAA`, required, isFutureTest, useShortErrMsg),
      },
    ],
    APTITUDE_TESTING_CSAT: [
      {
        name: 'Section 1',
        rules: subjectResultRules(`APTITUDE_TESTING_CSAT`, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(`APTITUDE_TESTING_CSAT`, required, isFutureTest, useShortErrMsg),
      },
    ],
    AHCAAT: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.AHCAAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    BSMAT: [
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.BSMAT_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ENEM: [
      {
        name: 'Language',
        rules: subjectResultRules(GradeResultType.ENEM_LANGUAGE, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Mathematics',
        rules: subjectResultRules(GradeResultType.ENEM_MATHEMATICS, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Natural Science',
        rules: subjectResultRules(GradeResultType.ENEM_NATURAL_SCIENCES, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Writing',
        rules: subjectResultRules(GradeResultType.ENEM_WRITING, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Humanities',
        rules: subjectResultRules(GradeResultType.ENEM_HUMANITIES, required, isFutureTest, useShortErrMsg),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.ENEM_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    AP_SUBJECTS_TEST: [
      {
        name: 'Subject',
        rules: subjectResultRules('AP_SUBJECTS', required, isFutureTest, useShortErrMsg, 'string'),
        isTotalField: true,
      },
      {
        name: 'Score',
        rules: subjectResultRules('AP_SUBJECTS_TOTAL', required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    IB_SUBJECTS_TEST: [
      {
        name: 'Subject',
        rules: subjectResultRules('IB_SUBJECTS', required, isFutureTest, useShortErrMsg, 'string'),
        isTotalField: true,
      },
      {
        name: 'Total',
        rules: subjectResultRules('IB_SUBJECTS_TOTAL', required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    ARCHITECTURE_TIL_TEST: [
      {
        name: 'Section 1',
        rules: subjectResultRules(
          GradeResultType.ARCHITECTURE_TIL_TEST_SECTION_1,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 2',
        rules: subjectResultRules(
          GradeResultType.ARCHITECTURE_TIL_TEST_SECTION_2,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 3',
        rules: subjectResultRules(
          GradeResultType.ARCHITECTURE_TIL_TEST_SECTION_3,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 4',
        rules: subjectResultRules(
          GradeResultType.ARCHITECTURE_TIL_TEST_SECTION_4,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Section 5',
        rules: subjectResultRules(
          GradeResultType.ARCHITECTURE_TIL_TEST_SECTION_5,
          required,
          isFutureTest,
          useShortErrMsg,
        ),
      },
      {
        name: 'Total',
        rules: subjectResultRules(GradeResultType.ARCHITECTURE_TIL_TEST_TOTAL, required, isFutureTest, useShortErrMsg),
        isTotalField: true,
      },
    ],
    UNISR_MED_ADMIS_ASSESS: [
      {
        name: 'Total',
        rules: subjectResultRules('UNISR_MED_ADMIS_ASSESS_60', required, isFutureTest, useShortErrMsg, 'string'),
        isTotalField: true,
      },
    ],
    COMENIUS_MED_ADMIS_TEST: [
      {
        name: 'Biology',
        rules: subjectResultRules('COMENIUS_MED_ADMIS_TEST_400', required, isFutureTest, useShortErrMsg, 'string'),
      },
      {
        name: 'Chemistry',
        rules: subjectResultRules('COMENIUS_MED_ADMIS_TEST_400', required, isFutureTest, useShortErrMsg, 'string'),
      },
      {
        name: 'Total',
        rules: subjectResultRules('COMENIUS_MED_ADMIS_TEST_800', required, isFutureTest, useShortErrMsg, 'string'),
        isTotalField: true,
      },
    ],
  };
  return map[TestNameMap[name] || name];
};
