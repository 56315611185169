import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { useMutation, useQuery } from '@apollo/client';
import { Send } from '@styled-icons/material/Send';
import { ADD_ACTION_ITEM, LOAD_ACTION_ITEMS } from 'src/graphql/ActionItems';
import { ActionItem as ActionItemType, MissionDetailObjectType } from 'src/types/mission';
import { CR_COLORS } from 'src/theme';
import { CategoryOverviewCommunicationContext } from 'src/context/CategoryOverviewCommunicationContext';
import ActionItem from './ActionItem';
import { TaskTextArea, Container, ListContainer, Title, AddButton, InputContainer, InputStyle } from './style';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { PredefinedTaskToggle } from '../PredefinedTaskToggle';

type Props = {
  mission: MissionDetailObjectType;
};

const ActionItems: React.FC<Props> = ({ mission }) => {
  const { setRefetch } = useContext(CategoryOverviewCommunicationContext);
  const [addValue, setAddValue] = useState('');
  const [addDate, setAddDate] = useState('');
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const { editPermitted, deletePermitted } = useContext(CurrentUserContext);
  const [actionItems, setActionItems] = useState<ActionItemType[]>([]);
  const [pendingActionItems, setPendingActionItems] = useState<Omit<ActionItemType, 'id'>[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datePickerRef = useRef<any>(null);

  const updatePendingActionItems = useCallback((actionItems: Omit<ActionItemType, 'id'>[]) => {
    setPendingActionItems(actionItems);
  }, []);

  const { data, refetch: loadActionItems } = useQuery<{ items: ActionItemType[] }>(LOAD_ACTION_ITEMS, {
    variables: {
      missionId: mission.id,
    },
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (data?.items) {
      setActionItems(data?.items);
    }
  }, [data?.items]);
  const [addActionItem, { loading: submittingTask }] = useMutation(ADD_ACTION_ITEM, { client: roadmapApiClient });

  useEffect(() => {
    loadActionItems();
  }, [mission, loadActionItems]);

  const onAdd: React.MouseEventHandler<HTMLElement> = async () => {
    if (!addValue) return;
    if (!addDate) {
      datePickerRef.current?.focus();
      return;
    }
    await addActionItem({
      variables: {
        input: {
          missionId: mission.id,
          description: addValue,
          dueDate: new Date(addDate),
        },
      },
    });
    setAddValue('');
    setAddDate('');
    loadActionItems();
    setRefetch(true);
  };

  const onNewActionItems = (items: ActionItemType[]) => {
    setActionItems(actionItems.concat(items));
    loadActionItems();
  };
  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
        <Title>
          Task{' '}
          <span>{`${
            actionItems.length
              ? Number(actionItems.length) > 1
                ? '(' + actionItems.length + ' tasks)'
                : '(1 task)'
              : ''
          }`}</span>
        </Title>
        {editPermitted && (
          <PredefinedTaskToggle
            mission={mission}
            updatePendingActionItems={updatePendingActionItems}
            onNewActionItems={onNewActionItems}
          />
        )}
      </div>
      <ListContainer>
        {actionItems?.slice().map((item) => (
          <ActionItem
            key={item.id}
            item={item}
            loadActionItems={loadActionItems}
            editPermitted={editPermitted}
            deletePermitted={deletePermitted}
          />
        ))}
        {pendingActionItems.map((item, index) => (
          <ActionItem
            pending
            key={'pendingActionItems' + index}
            item={item}
            loadActionItems={loadActionItems}
            editPermitted={false}
            deletePermitted={false}
          />
        ))}
      </ListContainer>
      {editPermitted && (
        <InputContainer>
          <InputStyle>
            <TaskTextArea
              autoSize={{ minRows: 1, maxRows: 8 }}
              bordered={false}
              placeholder="Add a task"
              value={addValue}
              onChange={(e) => {
                setAddValue(e.target.value);
              }}
              disabled={!editPermitted}
            />
            <DatePicker
              ref={datePickerRef}
              bordered={false}
              placeholder={'Due date'}
              suffixIcon={!addDate && <img src="static/eventFill.svg" />}
              autoFocus={false}
              value={addDate ? moment(addDate) : null}
              getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              onChange={(val) => {
                val ? setAddDate(val.toLocaleString()) : setAddDate('');
              }}
              style={{ width: '95px', padding: 0 }}
              disabled={!editPermitted}
            />
          </InputStyle>
          <AddButton
            type="link"
            onClick={onAdd}
            loading={submittingTask}
            disabled={submittingTask || !addValue || !addDate}
          >
            <Send
              height={24}
              color={CR_COLORS.LIGHT_PURPLE}
              opacity={submittingTask || !addValue || !addDate ? 0.4 : 1}
            />
          </AddButton>
        </InputContainer>
      )}
    </Container>
  );
};

export default ActionItems;
