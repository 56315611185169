import styled from 'styled-components';
import { Drawer } from 'antd';
import { ChevronRight, MoreHoriz } from '@styled-icons/material';
import { StyledHeader04Style } from 'src/styles/common';

export const StyledMissionDrawer = styled(Drawer)`
  width: 620px;
  padding: 28px 40px;
`;
export const StyledMissionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledMissionHeaderRight = styled.div`
  display: flex;
`;
export const StyledLabel = styled.div`
  color: #a9acc0;
  margin-bottom: 6px;
  font-size: 12px;
`;
export const StyledMissionTitleContainer = styled.div`
  textarea.ant-input {
    ${StyledHeader04Style}
    padding: 4px;
  }
  margin-top: 22px;
`;
export const StyledMissionDesc = styled.div`
  margin-top: 33px;
`;
export const StyledMissionTextareaContainer = styled.div`
  textarea.ant-input {
    padding: 4px;
  }
`;

export const StyledTeamContainer = styled.div`
  margin-top: 20px;
`;
export const StyledCloseIcon = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;
export const StyledRemoveButton = styled.div`
  cursor: pointer;
`;
export const StyledCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #464ac9;
`;
export const StyledRightOutlined = styled(ChevronRight)`
  width: 20px;
  color: #a9acc0;
`;
export const StyledRangeDatePickerContainer = styled.div`
  margin-top: 16px;
`;
export const StyledActionItemsContainer = styled.div`
  margin-top: 40px;
`;
export const StyledMoreHoriz = styled(MoreHoriz)`
  width: 18px;
`;
export const StyledTextArea = styled.div`
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    border-color: #e3e7ed;
  }
  textarea.ant-input {
    resize: none;
  }
`;
