import { resourceCenterContext, TResourceCenterContext } from './viewController';
import { useContext, useEffect, useRef, useState } from 'react';

export const useResourceCenterContext = (): TResourceCenterContext => useContext(resourceCenterContext);

export const useSafeLoading = (initialValue = true): [boolean, (v: boolean) => void] => {
  const isUnmounted = useRef(false);
  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);
  const [isLoading, setLoading] = useState(initialValue);
  const _setLoading = (value: boolean) => {
    if (isUnmounted.current) return;
    setLoading(value);
  };
  return [isLoading, _setLoading];
};
