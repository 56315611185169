import { useState, useEffect, ReactElement, JSXElementConstructor } from 'react';
import { SelectOption } from 'src/web-shared-components/base/Fields/Select';
import ExpandMoreIcon from 'src/web-shared-components/base/Icons/ExpandMoreIcon';
import { StyledSelect } from './style';
import * as CSS from 'csstype';

const SelectField = ({
  value,
  onChangeValue,
  options,
  placeholder = 'Select',
  dropdownMatchSelectWidth,
  onDropdownVisibleChange,
  onBlur,
  disabled = false,
  mode,
  defaultOpen,
  ...rest
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue?: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  disabled?: boolean;
  options: SelectOption[];
  placeholder?: string | React.ReactNode;
  dropdownMatchSelectWidth?: number | boolean | undefined;
  suffixIcon?: React.ReactNode;
  mode?: 'multiple' | 'tags';
  showSearch?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tagRender?: (props: any) => ReactElement<any, string | JSXElementConstructor<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDropdownVisibleChange?: (open: boolean, value: any) => void;
  showArrow?: boolean;
  style?: CSS.Properties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any | undefined;
  allowClear?: boolean;
  dropdownStyle?: CSS.Properties;
  onMouseLeave?: () => void;
  defaultOpen?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPopupContainer?: (props: any) => HTMLElement;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [showValue, setShowValue] = useState<any>();
  useEffect(() => {
    setShowValue(value);
  }, [value]);
  const [open, setOpen] = useState(defaultOpen ? defaultOpen : false);
  return (
    <StyledSelect
      value={showValue}
      disabled={disabled}
      bordered={false}
      suffixIcon={<ExpandMoreIcon width={16} />}
      placeholder={placeholder}
      size="small"
      open={open}
      onChange={(value) => {
        if (!value) {
          setShowValue(value);
          onChangeValue?.(value);
        } else {
          if (mode === 'multiple') {
            setShowValue(value);
            onChangeValue?.(value);
          }
        }
      }}
      onSelect={(value) => {
        if (mode !== 'multiple') {
          setShowValue(value);
          onChangeValue?.(value);
        }
      }}
      onDropdownVisibleChange={(open) => {
        setOpen(open);
        onDropdownVisibleChange?.(open, showValue);
      }}
      onClick={(e) => e.stopPropagation()}
      tagRender={({ label }) => {
        return (
          <div>
            {label}
            {showValue && showValue.indexOf(label) !== showValue.length - 1 ? ', ' : ''}
          </div>
        );
      }}
      onBlur={() => {
        onBlur?.(showValue);
      }}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      showSearch
      optionFilterProp="label"
      options={options}
      mode={mode}
      {...rest}
    />
  );
};

export default SelectField;
