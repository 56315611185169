import React, { useState, useRef } from 'react';
import { InputProps as AntInputProps, InputRef } from 'antd/lib/input';
import { StyledInput, InputWrapper } from './style';

type InputProps = {
  rightIcon?: JSX.Element | ((focused: boolean) => JSX.Element | null);
  inputStyle?: React.CSSProperties;
} & AntInputProps;
const Input = ({ rightIcon, onFocus, onBlur, className, inputStyle, ...restProps }: InputProps): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const _onFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused(true);
    onFocus && onFocus(e);
  };
  const _onBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused(false);
    onBlur && onBlur(e);
  };
  const inputRef = useRef<InputRef>(null);
  return (
    <InputWrapper className={className}>
      <StyledInput
        ref={inputRef}
        onFocus={_onFocus}
        onBlur={_onBlur}
        bordered={false}
        style={inputStyle}
        {...restProps}
      />
      {typeof rightIcon === 'function' ? rightIcon(focused) : rightIcon}
    </InputWrapper>
  );
};
export default Input;
