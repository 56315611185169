import { StyledButtonFilled, StyledButtonOutlined } from 'src/web-shared-components/base/Buttons';
import theme from 'src/web-shared-components/theme';
import styled from 'styled-components';
import { SwapRightOutlined } from '@ant-design/icons';

export const StyledMissionContainer = styled.div`
  margin-top: 10px;
  display: flex;
  width: 775px;
  height: 584px;
  background: #f3f6fa;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  padding: 40px 54px;
`;

export const StyledTextArea = styled.div`
  border: 1px solid #e3e7ed;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  &:hover {
    border-color: #e3e7ed;
  }
  textarea.ant-input {
    resize: none;
  }
`;

export const StyledDate = styled.div`
  display: flex;
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  column-gap: 10px;
`;

export const SubmitButton = styled(StyledButtonFilled)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;

export const CancelButton = styled(StyledButtonOutlined)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;

export const StyledRightOutlined = styled(SwapRightOutlined)`
  width: 20px;
  color: #a9acc0;
`;
