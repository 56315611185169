import { useState, useEffect } from 'react';
import { IOptions } from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/selectOptions';
import { StyledSelectContainer } from '../SelectSearch/style';
const SelectFetcherSearch = ({
  value,
  onChangeValue,
  fetchOptions,
  placeholder,
  dropdownMatchSelectWidth,
  disabled = false,
  allowClear = false,
}: {
  value: string;
  onChangeValue: (value: string | undefined) => void;
  fetchOptions: (value: string) => Promise<IOptions[]>;
  placeholder?: string;
  dropdownMatchSelectWidth?: number;
  disabled?: boolean;
  allowClear?: boolean;
}): JSX.Element => {
  const [showValue, setShowValue] = useState<string | undefined>('');
  useEffect(() => {
    setShowValue(value);
  }, [value]);
  return (
    <StyledSelectContainer
      disabled={disabled}
      bordered={false}
      placeholder={placeholder}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      fetchOptions={fetchOptions}
      onChangeValue={(value: string | undefined) => {
        setShowValue(value);
        onChangeValue(value);
      }}
      value={showValue}
      allowClear={allowClear}
    />
  );
};

export default SelectFetcherSearch;
