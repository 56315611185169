import { gql } from '@apollo/client';

export const appcuesStudentInfoType = gql`
  fragment appcuesStudentInfoType on studentInfoType {
    userId
    yearOfApplication
    major
    majors
    curriculums
  }
`;

export const detailedAppcuesUserType = gql`
  ${appcuesStudentInfoType}
  fragment detailedAppcuesUserType on userType {
    userId
    fullName
    email
    gender
    location
    phone
    country
    dob
    timezone
    profileImageUrl
    studentInfo {
      ...appcuesStudentInfoType
    }
  }
`;

export const fetchAppcuesInfo = gql`
  ${detailedAppcuesUserType}
  query fetchAppcuesInfo($userId: String!) {
    user: user(userId: $userId) {
      ...detailedAppcuesUserType
    }
    demographic: getStudentDemographic(userId: $userId) {
      countriesCitizenship
      englishProficiency
    }
  }
`;
