import { TestTypeType } from 'src/__generated__/graphqlTypes';

export const TestTypeTypeOptions: {
  value: TestTypeType | string;
  label: string;
  disabled?: boolean;
}[] = [
  {
    value: TestTypeType.Act,
    label: 'ACT',
  },
  {
    value: TestTypeType.Ahcaat,
    label: 'AHCAAT',
  },
  {
    value: TestTypeType.ApSubjectsTest,
    label: 'AP Subject Test',
  },
  {
    value: TestTypeType.ArchitectureTilTest,
    label: 'Architecture TIL Test',
  },
  {
    value: TestTypeType.Bocconi,
    label: 'BOCCONI',
  },
  {
    value: TestTypeType.Bsmat,
    label: 'BSMAT',
  },
  {
    value: TestTypeType.Cat,
    label: 'CAT',
  },
  {
    value: TestTypeType.Duolingo,
    label: 'Duolingo',
  },
  {
    value: TestTypeType.Elat,
    label: 'ELAT',
    disabled: true,
  },
  {
    value: TestTypeType.Enem,
    label: 'ENEM',
  },
  {
    value: TestTypeType.Engaa,
    label: 'ENGAA',
    disabled: true,
  },
  {
    value: TestTypeType.Gamsat,
    label: 'GAMSAT',
  },
  {
    value: TestTypeType.Gmat,
    label: 'GMAT',
  },
  {
    value: TestTypeType.Gre,
    label: 'GRE',
  },
  {
    value: TestTypeType.Hat,
    label: 'HAT',
  },
  {
    value: TestTypeType.Hpat,
    label: 'HPAT',
  },
  {
    value: TestTypeType.IbSubjectsTest,
    label: 'IB Subjects Test',
  },
  {
    value: TestTypeType.Ielts,
    label: 'IELTS',
  },
  {
    value: TestTypeType.Imat,
    label: 'IMAT',
  },
  {
    value: TestTypeType.Isat,
    label: 'ISAT',
  },
  {
    value: TestTypeType.Isee,
    label: 'ISEE',
  },
  {
    value: TestTypeType.Lnat,
    label: 'LNAT',
  },
  {
    value: TestTypeType.Lsat,
    label: 'LSAT',
  },
  {
    value: TestTypeType.Mat,
    label: 'MAT',
  },
  {
    value: TestTypeType.Mcat,
    label: 'MCAT',
  },
  {
    value: TestTypeType.Mlat,
    label: 'MLAT',
  },
  {
    value: TestTypeType.Nsaa,
    label: 'NSAA',
    disabled: true,
  },
  {
    value: TestTypeType.Olat,
    label: 'OLAT',
  },
  {
    value: TestTypeType.Pat,
    label: 'PAT',
  },
  {
    value: TestTypeType.Phil,
    label: 'PHILAT',
  },
  {
    value: TestTypeType.Psat,
    label: 'PSAT',
  },
  {
    value: TestTypeType.Pte,
    label: 'PTE',
  },
  {
    value: TestTypeType.Sat,
    label: 'SAT',
  },
  {
    value: TestTypeType.Ssat,
    label: 'SSAT',
  },
  {
    value: TestTypeType.Step,
    label: 'STEP',
  },
  {
    value: TestTypeType.Tmua,
    label: 'TMUA',
  },
  {
    value: TestTypeType.Toefl,
    label: 'TOEFL',
  },
  {
    value: TestTypeType.Tsa,
    label: 'TSA',
  },
  {
    value: TestTypeType.Tumat,
    label: 'TUMAT',
  },
  {
    value: TestTypeType.Ucat,
    label: 'UCAT',
  },
  // EU testing
  {
    value: TestTypeType.Pce,
    label: 'PCE',
  },
  {
    value: TestTypeType.OmptA,
    label: 'Online Mathematics Placement Test-A',
  },
  {
    value: TestTypeType.OmptB,
    label: 'Online Mathematics Placement Test-B',
  },
  {
    value: TestTypeType.OmptC,
    label: 'Online Mathematics Placement Test-C',
  },
  {
    value: TestTypeType.OmptD,
    label: 'Online Mathematics Placement Test-D',
  },
  {
    value: TestTypeType.VwoPhysics,
    label: 'VWO Physics',
  },
  {
    value: TestTypeType.VwoChemistry,
    label: 'VWO Chemistry',
  },
  {
    value: TestTypeType.VwoMathA,
    label: 'VWO Mathematics A',
  },
  {
    value: TestTypeType.VwoMathB,
    label: 'VWO Mathematics B',
  },
  {
    value: TestTypeType.VwoMathC,
    label: 'VWO Mathematics C',
  },
  {
    value: TestTypeType.VwoBiology,
    label: 'VWO Biology',
  },
  {
    value: TestTypeType.Humat,
    label: 'EU HUMAT',
  },
  // {
  //   value: TestTypeType.UcatCamillus,
  //   label: 'UCAT Camillus',
  // },
  {
    value: TestTypeType.IeUniversityAdmissions,
    label: 'IE University Admissions',
  },
  {
    value: TestTypeType.ArchAdmisAssess,
    label: 'EU Architecture Admissions',
  },
  {
    value: TestTypeType.EthZurich,
    label: 'ETH Zurich',
  },
  {
    value: TestTypeType.EhlAdmissions,
    label: 'EHL Admissions',
  },
  {
    value: TestTypeType.CcvxBiology,
    label: 'CCVX Biology',
  },
  {
    value: TestTypeType.CcvxPhysics,
    label: 'CCVX Physics',
  },
  {
    value: TestTypeType.CcvxChemistry,
    label: 'CCVX Chemistry',
  },
  {
    value: TestTypeType.CcvxMathA,
    label: 'CCVX Mathematics A',
  },
  {
    value: TestTypeType.CcvxMathB,
    label: 'CCVX Mathematics B',
  },
  {
    value: TestTypeType.BocconiOnline,
    label: 'Bocconi Online',
  },
  {
    value: TestTypeType.EnglishTolcI,
    label: 'English TOLC-I',
  },
  {
    value: TestTypeType.EnglishTolcE,
    label: 'English TOLC-E',
  },
  {
    value: TestTypeType.EnglishTolcF,
    label: 'English TOLC-F',
  },
  {
    value: TestTypeType.Studienkolleg,
    label: 'Studienkolleg(Feststellungsprüfung)',
  },
  {
    value: TestTypeType.Ebau,
    label: 'EBAU',
  },
  // extra uk testing
  {
    value: TestTypeType.ArchaeologyAdmisAssess,
    label: 'Archaeology Admissions Assessment',
  },
  {
    value: TestTypeType.ArchitectureAdmissions,
    label: 'EU Architecture Admissions Assessment',
  },
  {
    value: TestTypeType.ModMedLangAdmisAssess,
    label: 'Modern and Medieval Languages Admissions Assessment',
  },
  {
    value: TestTypeType.EnglAdmisAssess,
    label: 'CELAT',
  },
  {
    value: TestTypeType.GeogAdmisAssess,
    label: 'Geography Admissions Assessment',
  },
  {
    value: TestTypeType.HistAdmisAssess,
    label: 'History Admissions Assessment',
  },
  {
    value: TestTypeType.HistArtAdmisAssess,
    label: 'History of Art Admissions Assessment',
  },
  {
    value: TestTypeType.HistSocPolSciAdmisAssess,
    label: 'History Social and Political Sciences Admissions Assessment',
  },
  {
    value: TestTypeType.LingAdmisAssess,
    label: 'Linguistics Admissions Assessment',
  },
  {
    value: TestTypeType.MathAdmisAssess,
    label: 'Mathematics Admissions Assessment ',
  },
  {
    value: TestTypeType.MusAdmisAssess,
    label: 'Music Admissions Assessment',
  },
  {
    value: TestTypeType.PhilAdmisAssess,
    label: 'Philosophy Admissions Assessment',
  },
  {
    value: TestTypeType.PsychAdmisAssess,
    label: 'Psychology Admissions Assessment ',
  },
  {
    value: TestTypeType.AptitudeTestingEsatBiologyChemistry,
    label: 'Aptitude Testing ESAT: Biology/Chemistry',
  },
  {
    value: TestTypeType.AptitudeTestingEsatMathPhysics,
    label: 'Aptitude Testing ESAT: Maths/Physics',
  },
  {
    value: TestTypeType.AptitudeTestingCsat,
    label: `Aptitude Testing: Computer Science Aptitudes.`,
  },
  {
    value: TestTypeType.AptitudeTestingEcaa,
    label: `Aptitude Testing: Economics Admissions Assessment.`,
  },
  {
    value: TestTypeType.UnisrMedAdmisAssess,
    label: `UniSR Medicine Admissions Test`,
  },
  {
    value: TestTypeType.ComeniusMedAdmisTest,
    label: `Comenius University Medicine Admission Test`,
  },
  {
    value: TestTypeType.ArchitectureAdmissionsAssessment,
    label: 'UK Architecture Admissions Assessment',
  },
];
