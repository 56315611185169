import { MoreHoriz } from '@styled-icons/material';
import { FunctionComponent, useContext, useState, useEffect } from 'react';
import TimeAgo from 'javascript-time-ago';

import CRAvatar from '../Avatar';
import * as Styled from './styles';
import { TReflection, TComment } from './types';
import CRMenu from '../CrMenu';
import ReflectionInput from './ReflectionInput';
import CommentInput from './CommentInput';
import CommentCard from './CommentCard';
import { CloseIcon } from 'src/web-shared-components/base/Icons';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USERS_BY_IDS } from 'src/graphql/User';
import { DELETE_REFLECTION } from 'src/graphql/roadmap/Reflections';
import { GET_COMMENTS_BY_ACTION_ITEM, BATCH_DELETE_COMMENTS_BY_ACTION_ITEM } from 'src/graphql/Comments';
import { motion } from 'framer-motion';
import { LoginUserContext } from 'src/context/LoginUserContext';
import { AnimCompShow } from '../ResourceCenter/components';
import { User } from 'src/types/user';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import _ from 'lodash';

export type CardMode = 'show' | 'edit' | 'removed';
interface ReflectionCardProps {
  missionId: string;
  reflection: TReflection;
  onRemove?: (reflection: TReflection) => void;
  onEdit?: (newReflection: TReflection) => void;
}
const ReflectionCard: FunctionComponent<ReflectionCardProps> = ({ missionId, reflection, onRemove, onEdit }) => {
  const timeAgo = new TimeAgo('en-US');
  const { userId } = useContext(LoginUserContext);
  const [hideCommentInput, SetHideCommentInput] = useState(true);
  const [comments, setComments] = useState<TComment[]>([]);

  const { crimsonAppApiClient, roadmapApiClient } = useContext(ApolloClientContext);
  const [localReflection, setLocalReflection] = useState(reflection);
  const [mode, setMode] = useState<CardMode>('show');
  const [deleteReflection] = useMutation(DELETE_REFLECTION, { client: roadmapApiClient });
  const [deleteCommentsByReflection] = useMutation(BATCH_DELETE_COMMENTS_BY_ACTION_ITEM);
  const { data, refetch: fetchComments } = useQuery(GET_COMMENTS_BY_ACTION_ITEM, {
    variables: { actionItemId: reflection.id },
  });
  const { refetch: fetchUsersByIds } = useQuery(GET_USERS_BY_IDS, { client: crimsonAppApiClient, skip: true });
  const { editPermitted } = useContext(CurrentUserContext);

  const handleEdit = (reflection: TReflection) => {
    setMode('show');
    setLocalReflection(reflection);
    if (onEdit) {
      onEdit(reflection);
    }
  };

  const handleRemove = async () => {
    const { errors: delCommentErrors } = await deleteCommentsByReflection({
      variables: { actionItemId: localReflection.id },
    });

    if (!delCommentErrors?.length) {
      const { errors } = await deleteReflection({ variables: { reflectionId: localReflection.id } });
      if (!errors?.length) {
        setMode('removed');
        if (onRemove) {
          onRemove(localReflection);
        }
      }
    }
  };

  useEffect(() => {
    const setCommentWithCreators = async () => {
      const userIds = _.uniq(data.comments.map((comment: TComment) => comment.creatorUid));
      const { data: userData } = await fetchUsersByIds({ userIds });
      const userMap = userData.usersBasicInfo.reduce((acc: Map<string, User>, user: User) => {
        return acc.set(user.userId, user);
      }, new Map<string, User>());
      const commentsWithCreators = data.comments.map((comment: TComment) => ({
        ...comment,
        creator: userMap.has(comment.creatorUid) ? userMap.get(comment.creatorUid) : undefined,
      }));
      const orderedComments = [..._.orderBy(commentsWithCreators, ['createdAt'], ['asc'])];
      setComments(orderedComments);
    };

    if (data?.comments && data?.comments.length > 0) {
      setCommentWithCreators();
    }
  }, [data?.comments, fetchUsersByIds]);

  const onCommentRemoved = (del: TComment) => {
    const items = comments.filter((item) => item.id !== del.id);
    setComments(items);
  };

  if (mode === 'removed') {
    return <></>;
  }

  const createdDuration = timeAgo.format(new Date(localReflection.createdAt));
  const isUpdated =
    localReflection.descriptionUpdatedAt && localReflection.createdAt !== localReflection.descriptionUpdatedAt;
  const canAccessOptions = reflection.creatorId === userId;

  return (
    <Styled.NoteContainer>
      <div style={{ display: 'flex' }}>
        <Styled.NoteCreatorContainer>
          <CRAvatar
            size={24}
            firstName={localReflection.creator?.firstName}
            lastName={localReflection.creator?.lastName}
            userId={localReflection.creatorId}
            image={localReflection.creator?.profileImageUrl}
          />
          <Styled.NoteCreatorName>
            {localReflection.creator?.firstName +
              (localReflection.creator?.lastName ? ` ${localReflection.creator.lastName}` : '')}
          </Styled.NoteCreatorName>
          <Styled.NoteCreatedDate>
            {createdDuration} {isUpdated ? <span style={{ marginLeft: '4px', opacity: 0.5 }}>Edited</span> : null}
          </Styled.NoteCreatedDate>
        </Styled.NoteCreatorContainer>
        {mode === 'show' && canAccessOptions ? (
          <Styled.NoteOptionsContainer>
            <CRMenu Icon={<MoreHoriz width={16} color="#A9ACC0" />}>
              <CRMenu.Option onClick={() => setMode('edit')}>Edit</CRMenu.Option>
              <CRMenu.Option onClick={handleRemove}>Remove</CRMenu.Option>
            </CRMenu>
          </Styled.NoteOptionsContainer>
        ) : null}
        {mode === 'edit' ? (
          <motion.div animate={{ rotate: 90 }} exit={{ rotate: 0 }}>
            <CloseIcon width={12} onClick={() => setMode('show')} />
          </motion.div>
        ) : null}
      </div>
      <div>
        {mode === 'show' ? <Styled.NoteContent>{localReflection.description}</Styled.NoteContent> : null}
        {mode === 'edit' ? (
          <ReflectionInput missionId={missionId} reflection={localReflection} onInputSaved={handleEdit} />
        ) : null}
      </div>
      <div style={{ marginLeft: '32px', marginTop: '5px' }}>
        {comments?.length > 0 ? (
          <Styled.NoteListContainer>
            {comments.map((comment: TComment) => (
              <AnimCompShow key={comment.id}>
                <CommentCard
                  key={comment.id}
                  reflectionId={reflection.id}
                  comment={comment}
                  onRemove={onCommentRemoved}
                />
              </AnimCompShow>
            ))}
          </Styled.NoteListContainer>
        ) : null}
        {hideCommentInput
          ? editPermitted && <a onClick={() => SetHideCommentInput(false)}>Comment</a>
          : editPermitted && (
              <CommentInput
                reflectionId={localReflection.id}
                onInputSaved={() => {
                  fetchComments();
                  SetHideCommentInput(true);
                }}
              />
            )}
      </div>
    </Styled.NoteContainer>
  );
};

export default ReflectionCard;
