import { Dropdown, notification } from 'antd';
import { ReactNode } from 'react';

import { StyledMenu, StyledButton, StyledAddCircle } from './style';

export interface MenuItem {
  text: ReactNode;
  func?: () => void;
}

const AddCircleButton = ({
  'data-appcues-id': appcuesId,
  items,
  placement = 'bottomLeft',
}: {
  'data-appcues-id'?: string;
  items: MenuItem[];
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
}): JSX.Element => {
  const defaultFunc = () => {
    notification.open({
      message: 'Unavailable',
      description: 'This feature is in development',
    });
  };
  const newItems = items.map(({ text, func }, index: number) => ({
    key: index,
    label: <StyledButton onClick={func ? func : defaultFunc}>{text}</StyledButton>,
  }));
  const menu = <StyledMenu items={newItems} />;

  return (
    <Dropdown overlay={menu} placement={placement}>
      <StyledAddCircle data-appcues-id={appcuesId} />
    </Dropdown>
  );
};
export default AddCircleButton;
