import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContextProvider, defaultUserValue } from './context/CurrentUserContext';
import { User } from './types/user';
import { QUERY_USER_INFO } from './graphql/User';
import { useQuery } from '@apollo/client';
import { ApolloClientContext } from './context/ApolloClientContext';
import AppWrapperRoute from 'src/routes/routes';
import { StudentInitInfoContext } from 'src/context/StudentInitInfoContext';
import { LoginUserContext } from 'src/context/LoginUserContext';
import { LOAD_STUDENT_INITIALIZATION_INFO } from 'src/graphql/StudentInitialization';
import { InitializationInfo } from 'src/__generated__/graphqlTypes';
import { PathfinderGoalDrawerContext } from './context/PathfinderGoalDrawerContext';
import CreateMissonFromPathfinderGoalDrawer from './components/CreateMissonFromPathfinderGoalDrawer';
import { withResourceCenterContextProvider } from './components/ResourceCenter/viewController';
import { GlobalStyle } from './theme';
import AppcuesLoader from './appcuesLoader';
import { CommunicationContextProvider } from './context/CategoryOverviewCommunicationContext';
import * as Logger from '@crimson-education/browser-logger';
import { getPermissions, usePermissionCheck } from './utils/permission';
import { PermissionAction, PermissionResourceType } from '@crimson-education/common-config/lib/authorization';
import { useFeatureSwitches } from 'src/featureSwitches';
import { ExplorePathfinderIdeasContext, usePathfinderIdeasDrawerState } from './context/ExplorePathfinderIdeasContext';
import { withOrderedModalContext } from './context/OrderedModalContext';
import ExploreAllPathfinderIdeas from './components/ExploreAllPathfinderIdeas';
import useRoadmapId from './hooks/useRoadmapId';
import { SharedUiLibConfig } from './utils/sharedUiLibConfig';
import { TemplateRoadmapIdProvider } from './context/TemplateRoadmapIdContext';
import { useLimitCWUserEditMission } from './context/ZustandStore';

const Main = ({ userId }: { userId: string }): JSX.Element => {
  const [drawerBodyStyle, setDrawerBodyStyle] = useState<React.CSSProperties>({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [onDrawerClose, setOnDrawerClose] = useState<() => void>(() => () => {});
  const [activedCategory, setActivedCategory] = useState('');
  const [activedSubCategory, setActivedSubCategory] = useState('');
  const { crimsonAppApiClient, studentCenterApiClient, roadmapApiClient, pathfinderApiClient } =
    useContext(ApolloClientContext);
  const [studentInfo, setStudentInfo] = useState<User>(defaultUserValue);
  const [studentInitInfo, setStudentInitInfo] = useState<InitializationInfo>();
  const [currentUser, setCurrentUser] = useState<User>(defaultUserValue);
  const loggedInUser = window.xprops?.loggedInUser;
  const { updateIsLimitCWUserEditMission } = useLimitCWUserEditMission();
  useEffect(() => {
    const isStudent = loggedInUser.role === 'STUDENT';
    const isCW = (loggedInUser?.tenant as unknown as Record<string, string>)?.name === 'collegewise';
    const isLimitEditForCWUser = isCW && isStudent;
    updateIsLimitCWUserEditMission(isLimitEditForCWUser);
  }, [loggedInUser, updateIsLimitCWUserEditMission]);
  const currentUserId = loggedInUser?.userId;
  const { refetch: refetchUserInfo } = useQuery<{
    user: User;
  }>(QUERY_USER_INFO, {
    client: crimsonAppApiClient,
    skip: true,
  });
  const {
    data: studentInfoData,
    loading: studentInfoInfoloading,
    refetch: refetchStudentInfo,
  } = useQuery<{
    user: User;
  }>(QUERY_USER_INFO, {
    variables: {
      userId,
    },
    client: crimsonAppApiClient,
  });
  const { data: studentInitInfoData } = useQuery(LOAD_STUDENT_INITIALIZATION_INFO, {
    variables: {
      userId,
    },
  });

  const ALL_FEATURE_FLAGS = useFeatureSwitches();
  const loginUserId = currentUser.userId;
  const permissions = currentUser.permissions;
  const permissionEdit = {
    action: PermissionAction.Edit,
    resourceType: PermissionResourceType.MissionControl,
  };
  const permissionDelete = {
    action: PermissionAction.Edit,
    resourceType: PermissionResourceType.MissionControl,
  };

  const editAble = usePermissionCheck(permissionEdit, [userId], ['STUDENT'], loginUserId, permissions);
  const deleteAble = usePermissionCheck(permissionDelete, [userId], ['STUDENT'], loginUserId, permissions);
  const explorePathfinderIdeasDrawerState = usePathfinderIdeasDrawerState();
  useEffect(() => {
    async function load() {
      const permissions = await getPermissions(currentUserId);
      const roleInfo = loggedInUser.roleInfo;
      let currentUser;
      if (!roleInfo) {
        const { data } = await refetchUserInfo({
          userId: currentUserId,
        });
        if (data && data.user) {
          currentUser = { ...data.user, permissions };
        }
      } else {
        let isValidJson = true;
        try {
          JSON.parse(roleInfo);
        } catch (e) {
          isValidJson = false;
        }
        if (isValidJson) {
          currentUser = {
            userId: loggedInUser.userId,
            fullName: loggedInUser.fullName, //This has not been passed from Crimson App
            firstName: loggedInUser.firstName,
            lastName: loggedInUser.lastName,
            profileImageUrl: loggedInUser.profileImageUrl,
            roles: JSON.parse(roleInfo),
            country: loggedInUser.country, //This has not been passed from Crimson App
            permissions,
            isTest: false,
            // eslint-disable-next-line
            tenant: loggedInUser.tenant as any,
            title: loggedInUser.title,
          };
        } else {
          const { data } = await refetchUserInfo({
            userId: currentUserId,
          });
          if (data && data.user) {
            currentUser = { ...data.user, permissions };
          }
        }
      }
      if (currentUser) {
        setCurrentUser(currentUser);
      } else {
        return;
      }
      const contextMetadata = {
        isAnonymous: 'false',
        userId: loggedInUser.userId,
        tenant: currentUser.tenant,
      };
      try {
        Logger.addMetadata(contextMetadata);
        Logger.setUser({
          id: currentUser.userId,
          email: currentUser.email,
          username: currentUser.email,
          name: `${currentUser.firstName ?? ''} ${currentUser.lastName ?? ''}`.trim(),
        });
        Logger.trackEvent({ message: 'sc-app-ready' });
        Logger.recordSession();
      } catch (e) {
        console.warn('Logger set user error', e);
      }

      if (!studentInfoInfoloading && studentInfoData?.user) {
        setStudentInfo(studentInfoData?.user);
      }
    }
    load();
  }, [currentUserId, loggedInUser, refetchUserInfo, studentInfoData, studentInfoInfoloading]);
  useEffect(() => {
    if (studentInitInfoData && studentInitInfoData.initializationInfo) {
      setStudentInitInfo(studentInitInfoData.initializationInfo);
    }
  }, [studentInitInfoData]);

  // preload images that need to be ready before enter pages
  useEffect(() => {
    const loadImage = (url: string) => {
      const img = new Image();
      img.src = url;
    };
    loadImage('/static/icon/rocket_red.png');
    loadImage('/static/icon/rocket_white.png');
    loadImage('/static/profilePage/profile_cover0.png');
    loadImage('/static/profilePage/profile_cover1.png');
    loadImage('/static/profilePage/profile_cover2.png');
  }, []);

  useRoadmapId();

  return (
    <LoginUserContext.Provider value={window.xprops?.loggedInUser}>
      <CurrentUserContextProvider
        value={{
          currentUser,
          userId,
          studentInfo,
          refetchStudentInfo,
          editPermitted: studentInfoData && studentInfoData.user.isTest ? true : editAble.isPermitted,
          deletePermitted: studentInfoData && studentInfoData.user.isTest ? true : deleteAble.isPermitted,
        }}
      >
        <AppcuesLoader loggedInUser={{ ...window.xprops.loggedInUser }} studentInfo={studentInfo} />
        <StudentInitInfoContext.Provider value={{ studentInitInfo }}>
          <ExplorePathfinderIdeasContext.Provider value={explorePathfinderIdeasDrawerState}>
            <ExploreAllPathfinderIdeas
              pathfinderType={explorePathfinderIdeasDrawerState?.pathfinderType}
              visible={explorePathfinderIdeasDrawerState?.visible}
              setDrawerVisible={explorePathfinderIdeasDrawerState?.setVisible}
              onDrawerClose={explorePathfinderIdeasDrawerState?.onClose}
              activedCategory={explorePathfinderIdeasDrawerState?.activedCategory}
              width={1128}
              headerStyle={{
                display: 'none',
              }}
              destroyOnClose
              bodyStyle={{
                padding: '25px 49px 25px 57px',
                ...explorePathfinderIdeasDrawerState?.drawerBodyStyle,
              }}
            />
            <PathfinderGoalDrawerContext.Provider
              value={{
                visible: drawerVisible,
                setVisible: setDrawerVisible,
                onClose: onDrawerClose,
                setOnClose: setOnDrawerClose,
                activedCategory,
                setActivedCategory,
                activedSubCategory,
                setActivedSubCategory,
                drawerBodyStyle,
                setDrawerBodyStyle,
              }}
            >
              <SharedUiLibConfig
                featureFlags={{
                  featureFlags: ALL_FEATURE_FLAGS as Record<string, boolean>,
                }}
                userInfo={{
                  userId: currentUser.userId,
                  email: currentUser.email,
                  firstName: currentUser.firstName,
                  lastName: currentUser.lastName,
                  profileImageUrl: currentUser.profileImageUrl,
                  roles: currentUser.roles,
                  fullName: currentUser.fullName,
                }}
                apiClients={{
                  coreApiClient: crimsonAppApiClient,
                  studentCenterApiClient,
                  roadmapApiClient,
                  pathfinderApiClient,
                }}
              >
                <CommunicationContextProvider>
                  <GlobalStyle />
                  <TemplateRoadmapIdProvider>
                    <AppWrapperRoute />
                  </TemplateRoadmapIdProvider>
                  <CreateMissonFromPathfinderGoalDrawer
                    visible={drawerVisible}
                    destroyOnClose
                    onDrawerClose={onDrawerClose}
                    setDrawerVisible={setDrawerVisible}
                    width={523}
                    headerStyle={{
                      display: 'none',
                    }}
                    bodyStyle={{
                      padding: '25px 25px 25px 36px',
                      ...drawerBodyStyle,
                    }}
                    activedCategory={activedCategory}
                    activedSubCategory={activedSubCategory}
                  />
                </CommunicationContextProvider>
              </SharedUiLibConfig>
            </PathfinderGoalDrawerContext.Provider>
          </ExplorePathfinderIdeasContext.Provider>
        </StudentInitInfoContext.Provider>
      </CurrentUserContextProvider>
    </LoginUserContext.Provider>
  );
};
export default withResourceCenterContextProvider(withOrderedModalContext(Main));
