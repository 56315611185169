import { gql } from '@apollo/client';

export const LOAD_SCHOOL_ACADEMICS_MISSIONS = gql`
  query loadSchoolAcademicMissions($userId: String!, $category: String!, $subcategory: String!) {
    loadMissions(userId: $userId, category: $category, subcategory: $subcategory) {
      id
      creatorId
      title
      category
      grade
      subcategory
      status
      description
      startAt
      dueDate
      members
      actionItems {
        description
      }
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
        isGPA
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        semester
        yearSchedule
        predictGrade
        grades
        courseName
        orderIndex
        version
        subject {
          id
          curriculumId
          name
          curriculum {
            shortName
            levels
          }
        }
      }
    }
  }
`;

// no such query available yet
export const LOAD_SCHOOL_ACADEMIC_BY_ID = gql`
  query mission($missionId: String!) {
    mission(missionId: $missionId) {
      id
      title
      category
      subcategory
      status
      description
      startAt
      dueDate
      members
      schoolAcademic {
        id
        userId
        missionId
        targetGPA
        actualGPA
        GPAScale
        cumulativeGPA
        targetCumulativeGPA
        GPAWeight
        classSize
        targetClassRank
        actualClassRank
        isGPA
      }
      subjects {
        id
        missionId
        subjectId
        targetGrade
        actualGrade
        maxGrade
        gradeFormat
        level
        selfStudy
        subject {
          id
          curriculumId
          name
        }
      }
    }
  }
`;

export const GET_CURRICULUMS = gql`
  query getCurriculums($name: String!) {
    getCurriculums(name: $name) {
      id
      name
      shortName
      levels
      type
      maxGrade
    }
  }
`;

export const LOAD_SUBJECTS = gql`
  query loadSubjectsByCurriculumAndName($curriculumId: Int!, $name: String!) {
    loadSubjectsByCurriculumAndName(curriculumId: $curriculumId, name: $name) {
      id
      curriculumId
      name
    }
  }
`;

export const UPDATE_SCHOOL_WORK_INFO = gql`
  mutation addOrUpdateStudentAcademic($input: studentAcademicInput!) {
    addOrUpdateStudentAcademic(input: $input) {
      success
    }
  }
`;

export const ADD_STUDENT_SUBJECTS = gql`
  mutation addStudentSubjects($input: subjectsInput!) {
    addStudentSubjects(input: $input) {
      id
      missionId
      subjectId
      targetGrade
      actualGrade
      gradeFormat
      maxGrade
      level
      semester
      selfStudy
      subject {
        id
        curriculumId
        name
      }
    }
  }
`;

export const COPY_STUDENT_SUBJECTS = gql`
  mutation copyStudentSubjects($input: copySubjectsInput!) {
    copyStudentSubjects(input: $input) {
      success
    }
  }
`;

export const UPDATE_STUDENT_SUBJECTS = gql`
  mutation updateStudentSubject($input: studentSubjectInput!) {
    updateStudentSubject(input: $input) {
      success
    }
  }
`;

export const CREATE_STUDENT_SUBJECTS = gql`
  mutation createStudentSubject($input: createStudentSubjectInput!) {
    createStudentSubject(input: $input) {
      success
    }
  }
`;

export const DELETE_STUDENT_SUBJECTS = gql`
  mutation deleteStudentSubject($studentSubjectId: String!) {
    deleteStudentSubject(studentSubjectId: $studentSubjectId) {
      success
    }
  }
`;

export const GET_FILES_BY_TYPE_AND_REF_ID = gql`
  query getFilesByTypeAndRefId($refType: String!, $refId: String!, $tag: String) {
    getFilesByTypeAndRefId(refType: $refType, refId: $refId, tag: $tag) {
      id
      name
      description
      size
      type
      location
    }
  }
`;

export const SAVE_FILE = gql`
  mutation saveFileUplRef(
    $location: String!
    $sha512: String!
    $name: String!
    $size: String!
    $type: String!
    $refType: String!
    $refId: String!
    $tag: String
  ) {
    saveFileUplRef(
      location: $location
      sha512: $sha512
      name: $name
      size: $size
      type: $type
      refType: $refType
      refId: $refId
      tag: $tag
    ) {
      name
    }
  }
`;

export const GET_SC_UPLOAD_URL = gql`
  query getStudentCenterUploadUrl($refId: String!, $fileName: String!, $contentType: String!) {
    getStudentCenterUploadUrl(refId: $refId, fileName: $fileName, contentType: $contentType) {
      putUrl
      bucket
      key
    }
  }
`;

export const GET_SC_DOWNLOAD_URL = gql`
  query getStudentCenterDownloadUrl($fileId: String!) {
    url: getStudentCenterDownloadUrl(fileId: $fileId)
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFileByFileId($fileId: String!) {
    deleteFileByFileId(fileId: $fileId) {
      id
    }
  }
`;

export const LOAD_CURRENT_CLASS_RANK = gql`
  query Query($userId: String!) {
    loadStudentCurrentClassRank(userId: $userId)
  }
`;

export const LOAD_CURRENT_GRADE_LEVEL = gql`
  query Query($userId: String!) {
    loadCurrentGradeLevel(userId: $userId)
  }
`;
