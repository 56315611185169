import { MoreHoriz } from '@styled-icons/material';
import { FunctionComponent, useContext, useState } from 'react';
import TimeAgo from 'javascript-time-ago';

import CRAvatar from '../Avatar';
import * as Styled from './styles';
import { TComment } from './types';
import CRMenu from '../CrMenu';
import CommentInput from './CommentInput';
import { CloseIcon } from 'src/web-shared-components/base/Icons';
import { useMutation } from '@apollo/client';
import { DELETE_COMMENT } from 'src/graphql/Comments';
import { motion } from 'framer-motion';
import { LoginUserContext } from 'src/context/LoginUserContext';

export type CardMode = 'show' | 'edit' | 'removed';
interface CommentCardProps {
  reflectionId: string;
  comment: TComment;
  onRemove?: (comment: TComment) => void;
  onEdit?: (newComment: TComment) => void;
}
const CommentCard: FunctionComponent<CommentCardProps> = ({ reflectionId, comment, onRemove, onEdit }) => {
  const timeAgo = new TimeAgo('en-US');
  const { userId } = useContext(LoginUserContext);

  const [localComment, setLocalComment] = useState(comment);
  const [mode, setMode] = useState<CardMode>('show');
  const [deleteComment] = useMutation(DELETE_COMMENT);
  const handleEdit = (comment: TComment) => {
    setMode('show');
    setLocalComment(comment);
    if (onEdit) {
      onEdit(comment);
    }
  };
  const handleRemove = async () => {
    const { errors } = await deleteComment({ variables: { commentId: localComment.id } });
    if (!errors?.length) {
      setMode('removed');
      if (onRemove) {
        onRemove(localComment);
      }
    }
  };
  if (mode === 'removed') {
    return <></>;
  }
  const createdDuration = timeAgo.format(new Date(localComment.createdAt));
  const isUpdated = localComment.createdAt !== localComment.updatedAt;
  const canAccessOptions = comment.creatorUid === userId;
  return (
    <Styled.NoteContainer>
      <div style={{ display: 'flex' }}>
        <Styled.NoteCreatorContainer>
          <CRAvatar
            size={24}
            firstName={localComment.creator?.firstName}
            lastName={localComment.creator?.lastName}
            userId={localComment.creatorUid}
            image={localComment.creator?.profileImageUrl}
          />
          <Styled.NoteCreatorName>
            {localComment.creator?.firstName +
              (localComment.creator?.lastName ? ` ${localComment.creator.lastName}` : '')}
          </Styled.NoteCreatorName>
          <Styled.NoteCreatedDate>
            {createdDuration} {isUpdated ? <span style={{ marginLeft: '4px', opacity: 0.5 }}>Edited</span> : null}
          </Styled.NoteCreatedDate>
        </Styled.NoteCreatorContainer>
        {mode === 'show' && canAccessOptions ? (
          <Styled.NoteOptionsContainer>
            <CRMenu Icon={<MoreHoriz width={16} color="#A9ACC0" />}>
              <CRMenu.Option onClick={() => setMode('edit')}>Edit</CRMenu.Option>
              <CRMenu.Option onClick={handleRemove}>Remove</CRMenu.Option>
            </CRMenu>
          </Styled.NoteOptionsContainer>
        ) : null}
        {mode === 'edit' ? (
          <motion.div animate={{ rotate: 90 }} exit={{ rotate: 0 }}>
            <CloseIcon width={12} onClick={() => setMode('show')} />
          </motion.div>
        ) : null}
      </div>
      <div>
        {mode === 'show' ? <Styled.NoteContent>{localComment.content}</Styled.NoteContent> : null}
        {mode === 'edit' ? (
          <CommentInput reflectionId={reflectionId} comment={localComment} onInputSaved={handleEdit} />
        ) : null}
      </div>
    </Styled.NoteContainer>
  );
};

export default CommentCard;
