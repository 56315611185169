import { useContext } from 'react';
import { LoginUserContext } from 'src/context/LoginUserContext';

const CW_TENANT_NAME = `collegewise`;

export const useTenantLevels = (levels: number[]): boolean => {
  const loggedInUser = useContext(LoginUserContext);
  const level = loggedInUser?.tenant?.level;
  if (!loggedInUser) {
    throw new Error('No logged-in information found.');
  }
  if (!level) {
    return true;
  }
  return levels.includes(level);
};

export const useTenantDomain = (): string => {
  const loggedInUser = useContext(LoginUserContext);
  if (!loggedInUser) {
    throw new Error('No logged-in information found.');
  }
  const tenantDomain = loggedInUser?.tenant?.domain;
  return tenantDomain || 'Invalid Tenant Domain';
};

export const useTenantName = (): string => {
  const loggedInUser = useContext(LoginUserContext);
  if (!loggedInUser) {
    throw new Error('No logged-in information found.');
  }
  const tenantName = loggedInUser?.tenant?.name;
  return tenantName || 'Invalid Tenant Name';
};

export const useIsCW = (): boolean => {
  const loggedInUser = useContext(LoginUserContext);
  if (!loggedInUser) {
    throw new Error('No logged-in information found.');
  }
  const tenantName = loggedInUser?.tenant?.name;
  return tenantName === CW_TENANT_NAME;
};

export const useTenantLevel = (level: number): boolean => {
  return useTenantLevels([level]);
};

export const useTenantLevel1 = (): boolean => {
  return useTenantLevel(1);
};

export const useTenantLevel2 = (): boolean => {
  return useTenantLevel(2);
};

export const useTenantLevel3 = (): boolean => {
  return useTenantLevel(3);
};

export const useTenantLevel2Or3 = (): boolean => {
  return useTenantLevels([2, 3]);
};
