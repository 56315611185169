import { MoreHoriz } from '@styled-icons/material';
import { FunctionComponent, useContext, useState } from 'react';
import TimeAgo from 'javascript-time-ago';

import CRAvatar from '../Avatar';
import * as Styled from './styles';
import { TInternalNote } from './types';
import CRMenu from '../CrMenu';
import NoteInput from './NoteInput';
import { CloseIcon } from 'src/web-shared-components/base/Icons';
import { useMutation } from '@apollo/client';
import { DELETE_INTERNAL_NOTE } from 'src/graphql/InternalNotes';
import { motion } from 'framer-motion';
import { LoginUserContext } from 'src/context/LoginUserContext';

export type NoteMode = 'show' | 'edit' | 'removed';
interface InternalNoteCardProps {
  missionId?: string;
  studentId: string;
  note: TInternalNote;
  onRemove?: (note: TInternalNote) => void;
  onEdit?: (newNote: TInternalNote) => void;
}
const InternalNoteCard: FunctionComponent<InternalNoteCardProps> = ({
  studentId,
  missionId,
  note,
  onRemove,
  onEdit,
}) => {
  const timeAgo = new TimeAgo('en-US');
  const { userId } = useContext(LoginUserContext);

  const [localNote, setLocalNote] = useState(note);
  const [mode, setMode] = useState<NoteMode>('show');
  const [deleteInternalNote] = useMutation(DELETE_INTERNAL_NOTE);
  const handleEdit = (note: TInternalNote) => {
    setMode('show');
    setLocalNote(note);
    if (onEdit) {
      onEdit(note);
    }
  };
  const handleRemove = async () => {
    const { errors } = await deleteInternalNote({ variables: { noteId: localNote.id } });
    if (!errors?.length) {
      setMode('removed');
      if (onRemove) {
        onRemove(localNote);
      }
    }
  };
  if (mode === 'removed') {
    return <></>;
  }
  const createdDuration = timeAgo.format(new Date(localNote.createdAt));
  const isUpdated = localNote.createdAt !== localNote.updatedAt;
  const canAccessOptions = note.creatorUid === userId;
  return (
    <Styled.NoteContainer>
      <div style={{ display: 'flex' }}>
        <Styled.NoteCreatorContainer>
          <CRAvatar
            size={24}
            firstName={localNote.creator?.firstName}
            lastName={localNote.creator?.lastName}
            userId={localNote.creatorUid}
            image={localNote.creator?.profileImageUrl}
          />
          <Styled.NoteCreatorName>
            {localNote.creator?.firstName + (localNote.creator?.lastName ? ` ${localNote.creator.lastName}` : '')}
          </Styled.NoteCreatorName>
          <Styled.NoteCreatedDate>
            {createdDuration} {isUpdated ? <span style={{ marginLeft: '4px', opacity: 0.5 }}>Edited</span> : null}
          </Styled.NoteCreatedDate>
        </Styled.NoteCreatorContainer>
        {mode === 'show' && canAccessOptions ? (
          <Styled.NoteOptionsContainer>
            <CRMenu Icon={<MoreHoriz width={16} color="#A9ACC0" />}>
              <CRMenu.Option onClick={() => setMode('edit')}>Edit</CRMenu.Option>
              <CRMenu.Option onClick={handleRemove}>Remove</CRMenu.Option>
            </CRMenu>
          </Styled.NoteOptionsContainer>
        ) : null}
        {mode === 'edit' ? (
          <motion.div animate={{ rotate: 90 }} exit={{ rotate: 0 }}>
            <CloseIcon width={12} onClick={() => setMode('show')} />
          </motion.div>
        ) : null}
      </div>
      <div>
        {mode === 'show' ? <Styled.NoteContent>{localNote.content}</Styled.NoteContent> : null}
        {mode === 'edit' ? (
          <NoteInput
            studentId={studentId}
            missionId={missionId}
            note={localNote}
            onNoteSaved={handleEdit}
            editPermitted={true}
          />
        ) : null}
      </div>
    </Styled.NoteContainer>
  );
};

export default InternalNoteCard;
