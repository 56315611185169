import { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
// import { ApolloClientContext } from 'src/context/ApolloClientContext';
import {
  ButtonContainer,
  CancelButton,
  Container,
  StyledDebouncedSelect,
  StyledSelect,
  SubmitButton,
  SSMField,
  RegionStragistField,
  RegionStragistSubField,
  Title,
} from './style';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { tagRender } from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/DebounceSelect';
import { IOptions } from 'src/web-shared-components/base/Fields/DebounceFetcherSelect/selectOptions';
import { User } from 'src/types/user';
import { AdmissionGoalType } from 'src/pages/Profile/ApplicationsSection/AddmissionGoal';
import { debounce, uniq, uniqWith } from 'lodash';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { PrincipalType, RelationshipType } from './ManageTeamModalMultiSSM';
// import { useQuery } from '@apollo/client';
// import { fetchProfilePageUserInfo } from 'src/graphql/User';
const RelationTag = {
  US: 'United States',
  UK: 'United Kingdom',
  EU: 'Europe',
  CA: 'Canada',
  GENERAL: 'General',
};

export type RelationTagKey = keyof typeof RelationTag;
export type RegionalStrategist = {
  tag: RelationTagKey;
  options?: { label: string; value: string; key: string }[];
};

const getSelectOptionsByUsers = (users: User[]): { label: string; value: string; key: string }[] => {
  return users.map((u) => ({
    label: `${u.firstName} ${u.lastName}`,
    value: `${u.firstName} ${u.lastName} ${u.userId}`,
    key: u.userId,
  }));
};

const getPrimarySSM = (relationships: RelationshipType[], teamMembers: User[]): User[] => {
  const users = relationships
    .filter((r) => r.type === 'CaseManagerStudent' && r.isPrimary)
    .map((r) => teamMembers.find((u) => u.userId === r.principalUserId));
  return users.filter((u) => u) as User[];
};

const getSSM = (relationships: RelationshipType[], teamMembers: User[]): User[] => {
  const users = relationships
    .filter((r) => r.type === 'CaseManagerStudent' && !r.isPrimary)
    .map((r) => teamMembers.find((u) => u.userId === r.principalUserId));
  return users.filter((u) => u) as User[];
};

const getRegionalStrategist = (
  tag: RelationTagKey,
  relationships: RelationshipType[],
  teamMembers: User[],
): RegionalStrategist => {
  const options = relationships
    .filter((r) => {
      if (r.type !== 'StrategistStudent') {
        return false;
      }
      if (r.tags?.includes(tag)) {
        return true;
      }
      return tag === 'GENERAL' && !r.tags;
    })
    .map((r) => {
      const members = teamMembers.filter((u) => u.userId === r.principalUserId);
      return getSelectOptionsByUsers(members);
    })
    .flat();
  return { tag, options: options };
};

const getReviewer = (relationships: RelationshipType[], teamMembers: User[]): User[] => {
  const users = relationships
    .filter((r) => r.type === 'ReviewerStudent')
    .map((r) => teamMembers.find((u) => u.userId === r.principalUserId));
  return users.filter((u) => u) as User[];
};

const getAcademicAdvisor = (relationships: RelationshipType[], teamMembers: User[]): User[] => {
  const users = relationships
    .filter((r) => r.type === 'AcademicAdvisorStudent')
    .map((r) => teamMembers.find((u) => u.userId === r.principalUserId));
  return users.filter((u) => u) as User[];
};

const ManageTeamModalSop = ({
  onSubmit,
  onClose,
  isSubmitting = false,
  loadOptions,
  admissionGoals,
  teamMembers,
  teamMembersRelationships,
  canEditSSM,
  canEditStrategist,
  canEditReviewer,
  showAA,
}: {
  onSubmit: (principals: PrincipalType[]) => Promise<void>;
  onClose: () => void;
  isSubmitting?: boolean;
  loadOptions: (role: string, excludeIds?: string[]) => (input: string) => Promise<IOptions[]>;
  admissionGoals: AdmissionGoalType[];
  teamMembers: User[];
  teamMembersRelationships: RelationshipType[];
  canEditSSM: boolean;
  canEditStrategist: boolean;
  canEditReviewer: boolean;
  showAA: boolean;
}): JSX.Element => {
  const [form] = Form.useForm();
  const initialPrimarySSMvalue = getSelectOptionsByUsers(getPrimarySSM(teamMembersRelationships, teamMembers));
  const initialAssociatedSSMValues = getSelectOptionsByUsers(getSSM(teamMembersRelationships, teamMembers));
  const initialReviewerValues = getSelectOptionsByUsers(getReviewer(teamMembersRelationships, teamMembers));
  const initialAcademicAdvisorValues = getSelectOptionsByUsers(
    getAcademicAdvisor(teamMembersRelationships, teamMembers),
  );
  const [curPrimarySsmValue, setCurPrimarySsmValue] = useState(initialPrimarySSMvalue?.[0]);
  const [curAssociaSsmValues, setCurAssociaSsmValues] = useState(initialAssociatedSSMValues);
  const [SSMOptions, setSSMOptions] = useState<IOptions[]>([]);
  const [primarySSMOptions, setPrimarySSMOptions] = useState<IOptions[]>([]);
  const [associatedSSMOptions, setAssociatedSSMOptions] = useState<Partial<IOptions>[]>([]);
  const [strategistOptions, setStrategistOptions] = useState<Partial<IOptions>[]>([]);
  const [regionalStrategists] = useState<RegionalStrategist[]>(() => {
    const items = Object.keys(RelationTag)
      .filter((c) => admissionGoals.find((a) => a.targetCountry === RelationTag[c as RelationTagKey]))
      .map((c) => {
        return getRegionalStrategist(c as RelationTagKey, teamMembersRelationships, teamMembers);
      });
    items.push(getRegionalStrategist('GENERAL', teamMembersRelationships, teamMembers));
    return items;
  });
  // for SSM options update
  const [triggerOptionsUpdate, setTriggerOptionsUpdate] = useState<boolean>(false);
  // is loading options
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadPeople = useCallback(
    async (name: string, role: string) => {
      const options = await loadOptions(role)(name);
      return options;
    },
    [loadOptions],
  );

  // only used for ssm since strat and reviewer are using debounced
  const handleSearch = (role: string) => (value: string) => {
    loadPeople(value, role).then((options) => {
      if (role === 'CASE_MANAGER') {
        setSSMOptions(options);
        setTriggerOptionsUpdate(true);
      }
      if (role === 'STRATEGIST') {
        setStrategistOptions(options);
      }
    });
  };

  // set initial ssm options
  useEffect(() => {
    if (!canEditSSM) return;
    setIsLoading(true);
    loadPeople('', 'CASE_MANAGER').then((options) => {
      setIsLoading(false);
      setSSMOptions(options);
      setPrimarySSMOptions(options);
      setAssociatedSSMOptions(options);
      setTriggerOptionsUpdate(true);
    });
  }, [canEditSSM, loadPeople]);

  // set initial strategist options
  useEffect(() => {
    if (!canEditStrategist) return;
    setIsLoading(true);
    loadPeople('', 'STRATEGIST').then((options) => {
      setIsLoading(false);
      setStrategistOptions(options);
    });
  }, [canEditStrategist, loadPeople]);

  // bind update associated ssm options
  useEffect(() => {
    if (!canEditSSM) return;
    if (!triggerOptionsUpdate) return;
    const excludeIds = curAssociaSsmValues?.map((v) => v.key);
    setPrimarySSMOptions(SSMOptions.filter((option) => !excludeIds?.includes(option.key)));
    setTriggerOptionsUpdate(false);
  }, [curAssociaSsmValues, canEditSSM, SSMOptions, triggerOptionsUpdate]);

  // bind update primary ssm options
  useEffect(() => {
    if (!canEditSSM) return;
    if (!triggerOptionsUpdate) return;
    setAssociatedSSMOptions(
      uniqWith(
        [...initialAssociatedSSMValues, ...curAssociaSsmValues, ...SSMOptions].filter(
          (o) => curPrimarySsmValue?.key !== o.key,
        ),
        (a, b) => a.key === b.key,
      ),
    );
    setTriggerOptionsUpdate(false);
  }, [
    curPrimarySsmValue,
    canEditSSM,
    SSMOptions,
    triggerOptionsUpdate,
    initialAssociatedSSMValues,
    curAssociaSsmValues,
  ]);

  const submit = async () => {
    const principals: PrincipalType[] = [];
    const { strategist, reviewer, academicAdvisor } = form.getFieldsValue();
    const primarySsm = curPrimarySsmValue;
    const associatedSsm = curAssociaSsmValues;
    const formattedPrimarySsm = !Array.isArray(primarySsm) ? [primarySsm] : primarySsm;

    // note: submitting empty string with a type will delete all members in that type
    // primarySsm field
    if (formattedPrimarySsm.length > 0 && formattedPrimarySsm[0]) {
      formattedPrimarySsm.forEach((u) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'CaseManagerStudent', isPrimary: true });
      });
    } else {
      principals.push({ principalUserId: '', type: 'CaseManagerStudent', isPrimary: true });
    }
    // associatedSsm field
    if (associatedSsm.length > 0) {
      associatedSsm.forEach((u) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'CaseManagerStudent', isPrimary: false });
      });
    } else {
      principals.push({ principalUserId: '', type: 'CaseManagerStudent', isPrimary: false });
    }
    // strategist field
    if (strategist.length > 0) {
      const general = regionalStrategists.find(({ tag }) => tag === 'GENERAL');
      if (general) {
        general.options = strategist;
      }
    } else {
      const general = regionalStrategists.find(({ tag }) => tag === 'GENERAL');
      if (general) {
        general.options = [];
      }
    }
    // reviewer field
    if (reviewer.length > 0) {
      reviewer.forEach((u: IOptions) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'ReviewerStudent' });
      });
    } else {
      principals.push({ principalUserId: '', type: 'ReviewerStudent' });
    }
    // academic advisor field
    if (academicAdvisor && academicAdvisor.length > 0) {
      academicAdvisor.forEach((u: IOptions) => {
        if (!u.key) return;
        principals.push({ principalUserId: u.key, type: 'AcademicAdvisorStudent' });
      });
    } else {
      principals.push({ principalUserId: '', type: 'AcademicAdvisorStudent' });
    }

    // regional strategists
    const result = regionalStrategists
      .map(
        (item) =>
          item.options?.map((u) => ({
            principalUserId: u.key,
            type: 'StrategistStudent',
            tags: item.tag,
          })) || [],
      )
      .flat() as PrincipalType[];
    const items = uniqWith(result, (x: PrincipalType, y: PrincipalType) => {
      if (x.principalUserId === y.principalUserId) {
        const tags = uniq([...(x.tags?.split(',') || []), ...(y.tags?.split(',') || [])]);
        x.tags = tags.join(',');
        y.tags = tags.join(',');
        return true;
      }
      return false;
    });
    if (items.length > 0) {
      principals.push(...items);
    } else {
      principals.push({ principalUserId: '', type: 'StrategistStudent' });
    }
    onSubmit(principals);
  };
  // block my team
  const { currentUser, studentInfo } = useContext(CurrentUserContext);
  const isCollegeWise = currentUser?.tenant?.name === 'collegewise';
  const domain = window.xprops && window.xprops.domain ? window.xprops.domain : 'http://localhost:3000';
  // DONT delete, will use in future
  // const { crimsonAppApiClient } = useContext(ApolloClientContext);
  // const theStudentId = studentInfo.userId;
  // const { data: rawStudentData } = useQuery(fetchProfilePageUserInfo, {
  //   variables: {
  //     userId: theStudentId,
  //   },
  //   fetchPolicy: 'network-only',
  //   skip: !theStudentId,
  //   client: crimsonAppApiClient,
  // });
  return (
    <Container>
      <Title>Manage Team Members</Title>
      {!isCollegeWise && (
        <Row>
          <Col span={24}>
            <SSMField>
              <label className="ssm-field-label">Primary Student Success Manager</label>
              <StyledSelect
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Student Success Manager"
                value={curPrimarySsmValue}
                disabled={!canEditSSM || isLoading}
                showSearch
                allowClear
                options={primarySSMOptions}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(_, option: any) => {
                  setTriggerOptionsUpdate(true);
                  setCurPrimarySsmValue(option);
                }}
                onSearch={debounce(handleSearch('CASE_MANAGER'), 200)}
                optionFilterProp="label"
              />
            </SSMField>
          </Col>
        </Row>
      )}

      {!isCollegeWise && (
        <Row>
          <Col span={24}>
            <SSMField>
              <label className="ssm-field-label">Associated Student Success Managers</label>
              <StyledSelect
                mode="multiple"
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Student Success Manager"
                options={associatedSSMOptions}
                value={curAssociaSsmValues}
                maxTagTextLength={20}
                showSearch
                tagRender={tagRender}
                disabled={!canEditSSM || isLoading}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                onChange={(_, option) => {
                  setCurAssociaSsmValues(option.map((o: IOptions) => ({ label: o.text, value: o.value, key: o.key })));
                  setTriggerOptionsUpdate(true);
                }}
                onSearch={debounce(handleSearch('CASE_MANAGER'), 200)}
                optionFilterProp="label"
              />
            </SSMField>
          </Col>
        </Row>
      )}

      {regionalStrategists.filter(({ tag }) => !['GENERAL', 'CA'].includes(tag)).length > 0 && (
        <Row>
          <Col span={24}>
            <RegionStragistField>
              <Row>
                <Col span={12}>
                  <label className="stra-field-label">Strategist</label>
                </Col>
                <Col span={12}>
                  <Row justify={'end'} align={'middle'}>
                    <label
                      style={{
                        color: '#464ac9',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        window.open(`${domain}/users/${studentInfo.userId}/package`);
                      }}
                    >
                      Allocate Strategist
                    </label>
                    <Tooltip title="Please go to the Programs page to continue with the strategist allocation.">
                      <InfoCircleOutlined
                        style={{
                          marginBottom: 1,
                          marginLeft: 4,
                          color: '#464ac9',
                        }}
                      />
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
              {regionalStrategists
                .filter(({ tag }) => !['GENERAL', 'CA'].includes(tag))
                .map((item, i) => (
                  <RegionStragistSubField key={i}>
                    <label className="stra-subfield-label">{item.tag} Strategist</label>
                    <StyledSelect
                      size="large"
                      suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                      placeholder="Select Strategist"
                      options={strategistOptions}
                      defaultValue={item.options?.[0]}
                      maxTagTextLength={20}
                      showSearch
                      allowClear
                      tagRender={tagRender}
                      // disabled={!canEditStrategist || isLoading}
                      disabled={true}
                      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      // make sure it will not change anything
                      // onChange={(_, option: any) => {
                      //   item.options = option ? [option] : [];
                      // }}
                      onSearch={debounce(handleSearch('STRATEGIST'), 200)}
                      optionFilterProp="label"
                    />
                  </RegionStragistSubField>
                ))}
            </RegionStragistField>
          </Col>
        </Row>
      )}

      <Form
        form={form}
        layout="vertical"
        name="manage-teams"
        preserve={false}
        onFinish={submit}
        initialValues={{
          strategist: regionalStrategists.find(({ tag }) => tag === 'GENERAL')?.options,
          reviewer: initialReviewerValues,
          academicAdvisor: initialAcademicAdvisorValues,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <Row align={'middle'}>
                  <Tooltip title="For scenarios where a student is working with multiple strategists for a single target country or is applying to countries that are not part of US, UK, or EU.">
                    <label className="stra-field-label">Other Strategists</label>
                    <InfoCircleOutlined
                      style={{
                        marginLeft: 4,
                        position: 'relative',
                        top: 2,
                        color: '#464ac9',
                      }}
                    />
                  </Tooltip>
                </Row>
              }
              name="strategist"
              required={false}
              shouldUpdate={false}
            >
              <StyledDebouncedSelect
                mode="multiple"
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Strategist"
                fetchOptions={loadOptions('STRATEGIST')}
                getOptionsOnMount={canEditStrategist}
                maxTagTextLength={20}
                maxCount={100}
                disabled={!canEditStrategist || isLoading}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Reviewer" name="reviewer" required={false} shouldUpdate={false}>
              <StyledDebouncedSelect
                mode="multiple"
                size="large"
                suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                placeholder="Select Reviewer"
                fetchOptions={loadOptions('REVIEWER')}
                getOptionsOnMount={canEditReviewer}
                maxTagTextLength={20}
                maxCount={100}
                disabled={!canEditReviewer || isLoading}
                getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              />
            </Form.Item>
          </Col>
        </Row>
        {showAA && (
          <Row>
            <Col span={24}>
              <Form.Item label="Academic Advisor" name="academicAdvisor" required={false} shouldUpdate={false}>
                <StyledDebouncedSelect
                  mode="multiple"
                  size="large"
                  suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
                  placeholder="Select Academic Advisor"
                  fetchOptions={loadOptions('ACADEMIC_ADVISOR')}
                  getOptionsOnMount={canEditStrategist}
                  maxTagTextLength={20}
                  maxCount={100}
                  disabled={!canEditStrategist || isLoading}
                  getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <ButtonContainer>
          <Form.Item>
            <CancelButton onClick={onClose} width={150}>
              Cancel
            </CancelButton>
          </Form.Item>
          <Form.Item>
            <SubmitButton type="primary" htmlType="submit" width={150} disabled={isSubmitting}>
              Confirm {isSubmitting && <LoadingOutlined />}
            </SubmitButton>
          </Form.Item>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default ManageTeamModalSop;
