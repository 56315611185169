import { Drawer, DrawerProps, notification, Skeleton, Tooltip, DatePicker } from 'antd';
import { ArrowBack, Close, KeyboardArrowRight, Today } from '@styled-icons/material';
import { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { CreateMission, GetRecommendedGoals } from 'src/graphql/Pathfinder';
import { useFeatureFlag } from 'src/featureSwitches';
import { Subcategories } from 'src/types/mission';
import * as _ from 'lodash';
import {
  DrawerHeader,
  HeaderContainer,
  HeaderTitle,
  HeaderDescription,
  HeaderBreadCrumb,
  BreadCrumbItem,
  PathfinderGoalList,
  PathfinderGoalItem,
  CardListContainer,
  FormContainer,
  ArrowBackContainer,
  CardContainer,
  CardDescription,
  LevelPoints,
  LevelTitle,
  PointsAndLevelContainer,
  ButtonGroup,
  Badge,
  DatePickerContainer,
  DatePickerHeader,
  DatePickerRow,
  GroupContainer,
} from './index.style';
import {
  LevelType,
  firstCharUpperCase,
  arrowRightStyle,
  skeletonLength,
  listArrowRightStyle,
  GoalType,
  closeStyle,
  arrowBackStyle,
  subcategoriesMap,
  missionGroupSubcategories,
} from './index.util';
import { PathfinderGoalDrawerContext } from 'src/context/PathfinderGoalDrawerContext';
import { StyledButtonNoBorder, StyledButtonDisabled, StyledButtonFilled } from '../CrButton';
import md from 'markdown-it';
import { CategoryOverviewCommunicationContext } from 'src/context/CategoryOverviewCommunicationContext';
import dayjs from 'dayjs';
import { groupData } from './groupData';
import useRoadmapId from 'src/hooks/useRoadmapId';

const { RangePicker } = DatePicker;

const defaultDataParams = {
  startDate: '',
  endDate: '',
};

const PathfinderGoalDrawer = ({
  visible,
  setDrawerVisible,
  onDrawerClose,
  activedCategory,
  activedSubCategory,
  ...rest
}: DrawerProps & {
  onDrawerClose: () => void;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  activedCategory: string;
  activedSubCategory: string;
}): JSX.Element => {
  const groupedCards = groupData.hasOwnProperty(activedSubCategory)
    ? groupData[activedSubCategory as keyof typeof groupData]
    : undefined;
  const { setRefetch } = useContext(CategoryOverviewCommunicationContext);
  const { editPermitted } = useContext(CurrentUserContext);
  const roadmapId = useRoadmapId();
  const [currentVisibleListType, setCurrentVisibleListType] = useState<'subcategory' | 'goal' | 'level'>(
    !!activedSubCategory ? 'goal' : 'subcategory',
  );
  const { roadmapApiClient } = useContext(ApolloClientContext);
  const [createMission, { loading, error }] = useMutation(CreateMission, {
    client: roadmapApiClient,
  });
  useEffect(() => {
    if (!loading && error) {
      notification.error({
        message: 'Create mission failed',
      });
    }
  }, [loading, error]);
  const { pathfinderApiClient } = useContext(ApolloClientContext);
  const { setDrawerBodyStyle, setVisible, setActivedSubCategory, setActivedCategory } =
    useContext(PathfinderGoalDrawerContext);
  const GROUP_MISSION_FLAG = useFeatureFlag('GROUP_MISSION');
  const isMissionsGroupEnabled =
    missionGroupSubcategories.includes(activedSubCategory as Subcategories) && GROUP_MISSION_FLAG;
  const [goalList, setGoalList] = useState<Array<GoalType>>([]);
  const [levels, setLevels] = useState<Array<LevelType>>([]);
  const [highestLevelIndex, setHighestLevelIndex] = useState<number>(-1);
  const [activeLevelIndex, setActiveLevelIndex] = useState<number>(-1);
  const [dateParams, setDateParams] = useState<{ startDate: string; endDate: string }>(defaultDataParams);
  const [goal, setGoal] = useState<GoalType>();
  const { userId } = useContext(CurrentUserContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { data: goalListData, loading: goalListLoading } = useQuery<{ getRecommendedGoals: Array<GoalType> }>(
    GetRecommendedGoals,
    {
      variables: {
        category: activedCategory,
        subcategory: subcategoriesMap[activedSubCategory as keyof typeof subcategoriesMap],
        studentId: userId,
      },
      skip: !activedCategory || !activedSubCategory || !visible || !userId,
      client: pathfinderApiClient,
      fetchPolicy: 'network-only',
    },
  );
  useEffect(() => {
    setCurrentVisibleListType(!!activedSubCategory ? 'goal' : 'subcategory');
  }, [activedSubCategory]);

  useEffect(() => {
    if (!goalListLoading && goalListData) {
      setGoalList(goalListData?.getRecommendedGoals);
    }
  }, [goalListData, goalListLoading]);

  useEffect(() => {
    setDrawerBodyStyle((drawerBodyStyle) => ({
      ...drawerBodyStyle,
      background: currentVisibleListType === 'level' ? 'var(--color-mist)' : 'white',
    }));
  }, [currentVisibleListType, setDrawerBodyStyle]);

  useEffect(() => {
    if (levels.length !== 0) {
      setHighestLevelIndex(Math.max(_.findLastIndex(levels, 'isAssigned')));
    }
  }, [levels]);

  const getClassName = (index: number): string => {
    return index <= highestLevelIndex ? 'disabled' : index <= activeLevelIndex ? 'assigned' : '';
  };

  const handleDrawerClose = () => {
    setVisible(false);
    onDrawerClose();
    setActivedSubCategory('');
    setActivedCategory('');
    setActiveLevelIndex(-1);
    setDateParams(defaultDataParams);
  };

  const handleBack = () => {
    setDateParams(defaultDataParams);
    setCurrentVisibleListType('goal');
    setActiveLevelIndex(-1);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    if (isMissionsGroupEnabled) {
      // when enabled, batch create missions that are higher than the highest assigned level
      // and smaller than the current selected/active level
      const promises = [];
      for (let i = highestLevelIndex + 1; i <= activeLevelIndex; i++) {
        const level = levels[i];
        const promise = createMission({
          variables: {
            input: {
              roadmapId,
              userId,
              missions: [
                {
                  action: 'add',
                  integrationSource: 'pathfinder',
                  status: 'PLANNED',
                  title: goal?.title + ' - ' + firstCharUpperCase(level.level),
                  linkId: goal?.id,
                  category: activedCategory,
                  subcategory: subcategoriesMap[activedSubCategory as keyof typeof subcategoriesMap],
                  description: level.description,
                  startAt: dateParams.startDate,
                  attr: JSON.stringify({
                    levelIds: _.slice(levels, highestLevelIndex + 1, i + 1).map((level) => level.id),
                    points: _.slice(levels, highestLevelIndex + 1, i + 1).map((level) => level.points),
                    goalId: goal?.id,
                    description: level.description,
                    level: firstCharUpperCase(level.level),
                  }),
                  dueDate: dateParams.endDate,
                },
              ],
            },
          },
        });
        promises.push(promise);
      }
      try {
        await Promise.all(promises);
        if (promises.length > 1) {
          notification.open({
            message: 'Missions Created',
            description: 'New missions have been created successfully.',
          });
        } else {
          notification.open({
            message: 'Mission Created',
            description: 'A new mission has been created successfully.',
          });
        }
        handleDrawerClose();
      } catch (e) {
        console.log(e);
        notification.error({
          message: 'Create missions failed',
        });
      }
    } else {
      const currentLevel = levels[activeLevelIndex];
      const { data } = await createMission({
        variables: {
          input: {
            roadmapId,
            userId,
            missions: [
              {
                action: 'add',
                integrationSource: 'pathfinder',
                status: 'PLANNED',
                title: goal?.title + ' - ' + firstCharUpperCase(currentLevel.level),
                linkId: goal?.id,
                category: activedCategory,
                subcategory: subcategoriesMap[activedSubCategory as keyof typeof subcategoriesMap],
                description: currentLevel.description,
                startAt: dateParams.startDate,
                attr: JSON.stringify({
                  levelIds: _.slice(levels, highestLevelIndex + 1, activeLevelIndex + 1).map((level) => level.id),
                  points: _.slice(levels, highestLevelIndex + 1, activeLevelIndex + 1).map((level) => level.points),
                  goalId: goal?.id,
                  description: currentLevel.description,
                  level: firstCharUpperCase(currentLevel.level),
                }),
                dueDate: dateParams.endDate,
              },
            ],
          },
        },
      });
      if (data) {
        notification.open({
          message: 'Mission Created',
          description: 'A new mission has been created successfully.',
        });
        handleDrawerClose();
      }
    }
    setSubmitting(false);
    setRefetch(true);
  };

  return (
    <Drawer
      {...rest}
      placement="right"
      onClose={() => {
        setDrawerVisible(false);
        handleDrawerClose();
      }}
      visible={visible}
    >
      <DrawerHeader>
        {currentVisibleListType === 'level' && (
          <ArrowBackContainer onClick={() => handleBack()}>
            <ArrowBack style={arrowBackStyle} />
            Back
          </ArrowBackContainer>
        )}
        <Close
          style={closeStyle}
          onClick={() => {
            setDrawerVisible(false);
            handleDrawerClose();
          }}
        />
      </DrawerHeader>
      <HeaderContainer>
        {currentVisibleListType !== 'level' ? (
          <>
            <HeaderTitle>Relevant Pathfinder Goals</HeaderTitle>
            <HeaderDescription>Select a Pathfinder goal and add to the student mission</HeaderDescription>
            <HeaderBreadCrumb>
              <BreadCrumbItem
                className={currentVisibleListType === 'goal' ? '' : 'active'}
                // onClick={() => setCurrentVisibleListType('subcategory')}
              >
                {firstCharUpperCase(activedCategory).replace('_', ' ')}
              </BreadCrumbItem>
              {currentVisibleListType === 'goal' && <KeyboardArrowRight style={arrowRightStyle} />}
              {currentVisibleListType === 'goal' && (
                <BreadCrumbItem
                  className={currentVisibleListType === 'goal' ? 'active' : ''}
                  title={activedSubCategory}
                >
                  {activedSubCategory}
                </BreadCrumbItem>
              )}
            </HeaderBreadCrumb>
          </>
        ) : (
          <>
            <HeaderTitle>{goal?.title}</HeaderTitle>
            <HeaderDescription>Select a level of requirement</HeaderDescription>
          </>
        )}
      </HeaderContainer>
      {currentVisibleListType === 'goal' && (
        <PathfinderGoalList>
          {goalListLoading &&
            Array.from({ length: skeletonLength }, (...args) => args[1]).map((item) => (
              <Skeleton.Button
                key={item}
                active={true}
                size="large"
                shape="default"
                block={true}
                style={{
                  height: '48px',
                  border: '1px solid white',
                  marginBottom: '12px',
                }}
              />
            ))}
          {!goalListLoading &&
            goalList &&
            groupedCards &&
            Object.keys(groupedCards).map((cardGroupKey) => (
              <GroupContainer key={cardGroupKey}>
                <div className="title">
                  <div className="left-line" /># {cardGroupKey} #<div className="right-line" />
                </div>
                {groupedCards[cardGroupKey] &&
                  groupedCards[cardGroupKey].map((goalId) => {
                    const goal = goalList.find((item) => item.id === goalId);
                    return goal ? (
                      <PathfinderGoalItem
                        key={goal.title}
                        onClick={() => {
                          setLevels(goal.levels);
                          setCurrentVisibleListType('level');
                          setGoal(goal);
                        }}
                      >
                        <Tooltip title={goal.title} placement="top">
                          <div>{goal.title}</div>
                        </Tooltip>
                        {goal?.achievedLevel && goal.achievedLevel !== 'NONE' && (
                          <Badge>
                            <img src={`/static/badges/${goal.achievedLevel.toLowerCase()}.svg`} />
                          </Badge>
                        )}
                        <KeyboardArrowRight style={listArrowRightStyle} />
                      </PathfinderGoalItem>
                    ) : (
                      <>not found - {goalId}</>
                    );
                  })}
              </GroupContainer>
            ))}
          {!goalListLoading &&
            goalList &&
            !groupedCards &&
            goalList.map((goal) => (
              <PathfinderGoalItem
                key={goal.title}
                onClick={() => {
                  setLevels(goal.levels);
                  setCurrentVisibleListType('level');
                  setGoal(goal);
                }}
              >
                <Tooltip title={goal.title} placement="top">
                  <div>{goal.title}</div>
                </Tooltip>
                {goal?.achievedLevel && goal.achievedLevel !== 'NONE' && (
                  <Badge>
                    <img src={`/static/badges/${goal.achievedLevel.toLowerCase()}.svg`} />
                  </Badge>
                )}
                <KeyboardArrowRight style={listArrowRightStyle} />
              </PathfinderGoalItem>
            ))}
          {/* groupedCards */}
        </PathfinderGoalList>
      )}
      {currentVisibleListType === 'level' && (
        <CardListContainer>
          {levels &&
            levels.map((level, index) => (
              <CardContainer
                onClick={() => {
                  setActiveLevelIndex((activeIndex) =>
                    index === activeIndex ? activeIndex - 1 : index > highestLevelIndex ? index : activeIndex,
                  );
                }}
                key={level.level}
                className={getClassName(index)}
              >
                <CardDescription className={getClassName(index)}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: md({
                        html: true,
                      }).render(level.description as string),
                    }}
                  ></div>
                </CardDescription>
                <PointsAndLevelContainer>
                  <LevelPoints>
                    {level?.status && (
                      <div>
                        <img src={`/static/badges/${level?.level?.toLowerCase()}.svg`} />
                      </div>
                    )}
                  </LevelPoints>
                  <LevelTitle className={getClassName(index)}>{firstCharUpperCase(level.level)}</LevelTitle>
                </PointsAndLevelContainer>
              </CardContainer>
            ))}
        </CardListContainer>
      )}
      {currentVisibleListType === 'level' && (
        <FormContainer>
          {/* <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={<DateLabel>Start Date</DateLabel>}
                name="startDate"
                colon={false}
                required={false}
                rules={[{ required: true, message: 'Please select a Start Date' }]}
              >
                <DatePicker
                  allowClear={false}
                  suffixIcon={<Today fontVariant="rounded" style={{ height: '22px' }} />}
                  style={{ height: '40px', width: '100%' }}
                  disabledDate={(current) =>
                    current && dateParams.endDate !== '' && current >= dayjs(dateParams.endDate)
                  }
                  onChange={(date) =>
                    setDateParams({
                      ...dateParams,
                      startDate: `${date?.toISOString()}`,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<DateLabel>End Date</DateLabel>}
                name="testDate"
                colon={false}
                required={false}
              >
                <DatePicker
                  allowClear={false}
                  suffixIcon={<Today fontVariant="rounded" style={{ height: '22px' }} />}
                  style={{ height: '40px', width: '100%' }}
                  disabledDate={(current) =>
                    current && dateParams.startDate !== '' && current <= dayjs(dateParams.startDate)
                  }
                  onChange={(date) =>
                    setDateParams({
                      ...dateParams,
                      endDate: `${date?.toISOString()}`,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row> */}
          <DatePickerRow>
            <DatePickerContainer>
              <DatePickerHeader>Timeline</DatePickerHeader>
              <RangePicker
                allowClear={false}
                onChange={(dates) =>
                  setDateParams({
                    startDate: `${dates?.[0]?.toISOString()}`,
                    endDate: `${dates?.[1]?.toISOString()}`,
                  })
                }
                suffixIcon={<Today style={{ height: '20px', color: 'var(--color-dark-navy)' }} />}
              />
            </DatePickerContainer>
          </DatePickerRow>
          <ButtonGroup>
            <StyledButtonNoBorder style={{ color: 'var(--color-stone)' }} onClick={() => handleBack()}>
              Cancel
            </StyledButtonNoBorder>
            {editPermitted &&
            !submitting &&
            dateParams.startDate &&
            dateParams.endDate &&
            (dayjs(dateParams.startDate).isSame(dayjs(dateParams.endDate)) ||
              dayjs(dateParams.startDate).isBefore(dayjs(dateParams.endDate))) &&
            activeLevelIndex > highestLevelIndex ? (
              <StyledButtonFilled onClick={onSubmit}>Add Mission</StyledButtonFilled>
            ) : (
              <StyledButtonDisabled>Add Mission</StyledButtonDisabled>
            )}
          </ButtonGroup>
        </FormContainer>
      )}
    </Drawer>
  );
};

export default PathfinderGoalDrawer;
