/* eslint-disable @typescript-eslint/no-explicit-any */
import { crimsonAppApiClient } from 'src/graphql';
import { IS_ACCESSIBLE_BY_ROLE, LOAD_PERMISSIONS } from 'src/graphql/Permission';
import { validateUserPermissions } from '@crimson-education/common-config/lib/authorization/resolve';
import {
  PermissionAction,
  PermissionResourceQualifier,
  PermissionResourceType,
  StoredPermission,
} from '@crimson-education/common-config/lib/authorization';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useState } from 'react';

export type Permission = {
  action: string;
  resourceType: string;
  resource: string;
};

export type PermissionValidation = {
  action: PermissionAction;
  resourceType: PermissionResourceType;
  resource?: {
    identifier: string;
    qualifier: PermissionResourceQualifier;
  };
};
export const getPermissions = async (userId: string): Promise<StoredPermission[]> => {
  const { data } = await crimsonAppApiClient.query({
    query: LOAD_PERMISSIONS,
    variables: {
      userIds: [userId],
    },
  });
  const result = data.permissions.map((r: { action: string; resourceType: string; resource: string }) => {
    return {
      action: r.action,
      resourceType: r.resourceType,
      resource: r.resource,
    };
  });
  return result;
};

const isAccessibleByRole = async (targetUserIds: string[], roleIds: string[]): Promise<boolean> => {
  const { data } = await crimsonAppApiClient.query({
    query: IS_ACCESSIBLE_BY_ROLE,
    variables: {
      targetUserIds,
      roleIds,
    },
  });
  return data.isAccessibleByRole;
};

export const usePermissionCheck = (
  permissions: PermissionValidation[] | PermissionValidation,
  targetUserIds: string[],
  roleIds: string[],
  loginUserId: string,
  allPermissions: StoredPermission[],
): any => {
  const [permissionInfo, setPermissionInfo] = useState({
    loading: true,
    isPermitted: false,
    failedPermissions: [{}],
  });

  useDeepCompareEffect(() => {
    const hasPermission = allPermissions.filter((t: Permission) => {
      return permissions instanceof Array
        ? permissions.find((v) => v.action === t.action && v.resourceType === t.resourceType)
        : t.resourceType === permissions.resourceType && t.action === permissions.action;
    });
    if (hasPermission.length !== 0) {
      validateUserPermissions(
        {
          getPermissions: () => Promise.resolve(hasPermission),
          isAccessibleByRole: () => isAccessibleByRole(targetUserIds, roleIds),
        },
        loginUserId,
        permissions,
        targetUserIds || [],
      ).then((failedPermissions) => {
        setPermissionInfo({
          loading: false,
          isPermitted: failedPermissions.length === 0,
          failedPermissions,
        });
      });
    }
  }, [loginUserId, permissions, targetUserIds]);

  return permissionInfo;
};
