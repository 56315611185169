import { gql } from '@apollo/client';

export type SsaTrackingCategoryNameType = 'PersonalInfo' | 'Honors' | 'Activities' | 'Testing' | 'Academics';

export type SsaTrackingResult = {
  completion: number;
  categories: {
    name: SsaTrackingCategoryNameType;
    completion: number;
    mappings: {
      nameInSsa: string;
      nameInApp: string;
      value: string;
      completion: number;
    }[];
  }[];
};

export type SsaTrackingSkip = {
  userId: string;
  key: string;
  skip: boolean;
};

export const QUERY_SSA_TRACKING_RESULT = gql`
  query QuerySsaTrackingResult($studentUid: String!) {
    result: querySsaTrackingResult(studentUid: $studentUid) {
      completion
      categories {
        completion
        name
        mappings {
          completion
          nameInSsa
          nameInApp
          value
        }
      }
    }
  }
`;

export const UPSERT_SSA_TRACKING_SKIP = gql`
  mutation upsertSsaTrackingSkip($userId: String!, $key: String!, $skip: Boolean!) {
    result: upsertSsaTrackingSkip(userId: $userId, key: $key, skip: $skip) {
      id
      userId
      key
      skip
    }
  }
`;
