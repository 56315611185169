import { NavigateNext } from '@styled-icons/material';
import { FunctionComponent, useState, useEffect, useContext } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { useQuery, useMutation } from '@apollo/client';
import { GET_REFLECTIONS_BY_MISSION, BATCH_UPDATE_REFLECTIONS_BY_MISSION } from 'src/graphql/roadmap/Reflections';
import { GET_USERS_BY_IDS } from 'src/graphql/User';
import moment from 'moment';
import * as Styled from './styles';
import ReflectionInput from './ReflectionInput';
import { AnimCompShow } from '../ResourceCenter/components';
import { ExpandMoreIcon } from 'src/web-shared-components/base/Icons';
import { TReflection } from './types';
import { User } from 'src/types/user';
import ReflectionCard from './ReflectionCard';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import _ from 'lodash';

interface ReflectionsProps {
  missionId: string;
}

const Reflections: FunctionComponent<ReflectionsProps> = ({ missionId }) => {
  const [checked, setChecked] = useState(false);
  const [startAt, setStartAt] = useState<moment.Moment | null>();
  const [dueDate, setDueDate] = useState<moment.Moment | null>();
  const [reflections, setReflections] = useState<TReflection[]>([]);
  const [visibleCount, setVisibleCount] = useState(1);

  const { crimsonAppApiClient, roadmapApiClient } = useContext(ApolloClientContext);
  const { data, refetch: fetchReflections } = useQuery(GET_REFLECTIONS_BY_MISSION, {
    variables: { missionId },
    client: roadmapApiClient,
  });
  const { refetch: fetchUsersByIds } = useQuery(GET_USERS_BY_IDS, { client: crimsonAppApiClient, skip: true });
  const [batchUpdateReflectionsByMission] = useMutation(BATCH_UPDATE_REFLECTIONS_BY_MISSION, {
    client: roadmapApiClient,
  });
  const { editPermitted } = useContext(CurrentUserContext);

  useEffect(() => {
    const setReflectionsWithCreators = async () => {
      const userIds = _.uniq(data.reflections.map((reflection: TReflection) => reflection.creatorId));
      const { data: userData } = await fetchUsersByIds({ userIds });
      const userMap = userData.usersBasicInfo.reduce((acc: Map<string, User>, user: User) => {
        return acc.set(user.userId, user);
      }, new Map<string, User>());
      const reflectionsWithCreators = data.reflections.map((reflection: TReflection) => ({
        ...reflection,
        creator: userMap.has(reflection.creatorId) ? userMap.get(reflection.creatorId) : undefined,
      }));
      const orderedReflections = [..._.orderBy(reflectionsWithCreators, ['createdAt'], ['desc'])];
      setReflections(orderedReflections);

      const latest = orderedReflections[0] as TReflection;
      setChecked(latest.status === 'DONE');
      setStartAt(latest.startAt ? moment(latest.startAt) : null);
      setDueDate(latest.dueDate ? moment(latest.dueDate) : null);
    };

    if (data?.reflections && data?.reflections.length > 0) {
      setReflectionsWithCreators();
    }
  }, [data?.reflections, fetchUsersByIds]);

  const onClickViewMore = () => {
    setVisibleCount(visibleCount + 1);
  };

  return (
    <div>
      <Styled.TitleContainer>
        <Styled.TitleLeft>Reflections</Styled.TitleLeft>
        <Styled.TitleRight>
          {reflections?.length > 0 && editPermitted && (
            <Styled.Checkbox
              onChange={(e) => {
                const value = e.target.checked;
                setChecked(value);
                batchUpdateReflectionsByMission({ variables: { missionId, status: value ? 'DONE' : 'PLANNED' } });
              }}
              checked={checked}
            >
              <span style={{ fontSize: '14px', color: checked ? '#464ac9' : '#a9acc0' }}>Completed</span>
            </Styled.Checkbox>
          )}
          {reflections?.length > 0 && editPermitted && (
            <Styled.RangePickerPopover
              trigger="hover"
              content={
                <Styled.RangePicker
                  allowEmpty={[true, true]}
                  bordered={false}
                  format="MMM DD, YYYY"
                  allowClear={false}
                  value={[startAt ? startAt : null, dueDate ? dueDate : null]}
                  onChange={async (dates) => {
                    if (dates) {
                      setStartAt(dates[0] || null);
                      setDueDate(dates[1] || null);
                      batchUpdateReflectionsByMission({
                        variables: { missionId, startAt: dates[0]?.toISOString(), dueDate: dates[1]?.toISOString() },
                      });
                    }
                  }}
                />
              }
            >
              <Styled.ChangeDate>
                {dueDate ? (
                  `${moment(dueDate).format('MMM DD, YYYY')}`
                ) : (
                  <span style={{ color: '#a9acc0' }}>Set date</span>
                )}
                <NavigateNext height={20} />
              </Styled.ChangeDate>
            </Styled.RangePickerPopover>
          )}
        </Styled.TitleRight>
      </Styled.TitleContainer>
      {editPermitted && <ReflectionInput missionId={missionId} onInputSaved={() => fetchReflections()} />}
      {reflections?.length > 0 ? (
        <Styled.NoteListContainer>
          {reflections.slice(0, visibleCount).map((reflection: TReflection) => (
            <AnimCompShow key={reflection.id}>
              <ReflectionCard
                missionId={missionId}
                key={reflection.id}
                reflection={reflection}
                onRemove={() => fetchReflections()}
              />
            </AnimCompShow>
          ))}
          {reflections?.length > visibleCount ? (
            <Styled.ViewMoreContainer>
              <Styled.ViewMoreButton onClick={onClickViewMore}>
                View More
                <ExpandMoreIcon width={20} />
              </Styled.ViewMoreButton>
            </Styled.ViewMoreContainer>
          ) : null}
        </Styled.NoteListContainer>
      ) : null}
    </div>
  );
};

export default Reflections;
