/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Input, notification, Tooltip } from 'antd';
import {
  GET_RECOMMENDATIONS,
  INVALIDATE_RECOMMENDATIONS,
  UPDATE_RECOMMENDATION_SELECTION,
} from 'src/graphql/roadmapBuilder';
import { HelpOutline } from '@styled-icons/material-outlined';
import { Cached as RegenerateIcon } from '@styled-icons/material';
import { useEventCallback } from 'src/hooks/useEventCallback';
import {
  StyledContainer,
  StyledSection,
  StyledSectionHeader,
  StyleSectionHeaderTitle,
} from '../CurrentAchievements/style';
import RecommendationCard from './RecommendationCard';
import * as Styled from '../styles';
import {
  StyledCollapseButton,
  StyledHelpersContainer,
  StyledHelperText,
  StyledHelperTooltipText,
  StyledHelperTextLink,
} from './style';
import { useFeatureFlag } from 'src/featureSwitches';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { LoadingRocket } from 'src/components/LoadingRocket';
import { CenteredSpin } from 'src/components/MissionRecommender/shared';
import { useDebounceFn } from 'ahooks';

import { RoadmapBuilderRecommendationCategory, SelectionType } from '../types';
import { MissionList } from './MissionList';

export const CategoryMapping: Record<RoadmapBuilderRecommendationCategory, { showText: string; imgUrl: string }> = {
  testing: {
    showText: 'Testing',
    imgUrl: '/static/dashboard/testing.svg',
  },
  eclActivity: {
    showText: 'ECL Activities',
    imgUrl: '/static/subCategory/extracurriculars.png',
  },
  experience: {
    showText: 'Internships & Work Experience',
    imgUrl: '/static/subCategory/internship.svg',
  },
  summerProgram: {
    showText: 'Summer Programs',
    imgUrl: '/static/subCategory/summerProgram.svg',
  },
  honor: {
    showText: 'Honors',
    imgUrl: '/static/subCategory/honors.svg',
  },
  researchPublication: {
    showText: 'Academic Research & Publications',
    imgUrl: '/static/subCategory/researchPublication.svg',
  },
  reading: {
    showText: 'Readings',
    imgUrl: '/static/subCategory/readings.svg',
  },
};

const CategorySelectionCard = ({
  category,
  categorySelections,
  hintInput,
  loading,
  selection,
  setSelection,
  showExistingMissions,
  userId,
}: {
  category: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categorySelections: any;
  hintInput?: { value: string; onChange: (newValue: string) => void };
  loading: boolean;
  selection: SelectionType | undefined;
  setSelection: React.Dispatch<React.SetStateAction<SelectionType | undefined>>;
  showExistingMissions?: boolean;
  userId: string;
}) => {
  const [status, setStatus] = useState<'more' | 'less'>('less');
  const [hintValue, setHintValue] = useState(hintInput?.value ?? '');

  const MAX_COLLAPSED_ITEMS = category === 'eclActivity' ? 4 : 2;
  const showCategorySelections =
    status === 'more' ? categorySelections : categorySelections.slice(0, MAX_COLLAPSED_ITEMS);

  return (
    <StyledSection loading={loading}>
      {loading && <CenteredSpin />}
      <StyledSectionHeader>
        <StyleSectionHeaderTitle>
          <img src={CategoryMapping[category as keyof typeof CategoryMapping].imgUrl} />
          <span style={{ marginRight: 14 }}>{CategoryMapping[category as keyof typeof CategoryMapping].showText}</span>
          {showExistingMissions && (
            <MissionList category={category as RoadmapBuilderRecommendationCategory} userId={userId} />
          )}
        </StyleSectionHeaderTitle>
        <div style={{ flex: 1 }} />
        {categorySelections.length > MAX_COLLAPSED_ITEMS && (
          <StyledCollapseButton onClick={() => setStatus(status === 'less' ? 'more' : 'less')}>
            {status === 'less' ? (
              <img src="/static/roadmapBuilder/expand_more.svg" />
            ) : (
              <img src="/static/roadmapBuilder/expand_less.svg" />
            )}
          </StyledCollapseButton>
        )}
      </StyledSectionHeader>
      {hintInput && (
        <Input.Search
          enterButton={<Button loading={loading} icon={<RegenerateIcon height={24} width={24} />} />}
          loading={loading}
          placeholder="Give extra instructions"
          value={hintValue}
          style={{ marginBottom: 10 }}
          onChange={(event) => setHintValue(event.target.value)}
          onSearch={(value) => hintInput.onChange(value)}
        />
      )}
      {showCategorySelections.length > 0 &&
        Array.isArray(showCategorySelections) &&
        showCategorySelections.map((item: any) => {
          return (
            <div key={item.id}>
              <RecommendationCard
                disabled={loading}
                recommendation={{ ...item, category }}
                selection={selection}
                onChangeSelection={(selection) => setSelection(selection)}
              />
            </div>
          );
        })}
    </StyledSection>
  );
};

function noop() {
  // noop
}

const Recommendations = ({
  forceRecommendations,
  hideButtons,
  roadmapBuilderId,
  showExistingMissions,
  userId,
  next,
  skipTheStep,
  onRoadmapBuilderError,
  onLoadingStart,
  onLoadingEnd,
}: {
  forceRecommendations?: boolean;
  hideButtons?: boolean;
  next: () => void;
  roadmapBuilderId: string | null;
  showExistingMissions?: boolean;
  userId: string;
  skipTheStep?: () => void;
  onRoadmapBuilderError: () => void;
  onLoadingStart?: (hard: boolean) => void;
  onLoadingEnd?: () => void;
}): JSX.Element => {
  const hasAIRecommendations = useFeatureFlag('SC_RECOMMENDATIONS_ROADMAP_INIT');

  // State for refetching recommendations
  const [isPollingRecommendations, setIsPollingRecommendations] = useState(true);
  const [generationHint, setGenerationHint] = useState('');
  const [pendingUpdateKeys, setPendingUpdateKeys] = useState<string[]>([]);
  const [refetch, setRefetch] = useState(false);

  const [invalidateRoadmapBuilder, { loading: invalidatingRecommendations }] = useMutation(INVALIDATE_RECOMMENDATIONS);
  const { loading: roadmapbuilderLoading, ...getRecommendationsQuery } = useQuery(GET_RECOMMENDATIONS, {
    variables: {
      roadmapBuilderId,
      force: forceRecommendations,
      input: {
        hint: generationHint.trim(),
        updateKeys: pendingUpdateKeys.length === 0 ? null : pendingUpdateKeys,
      },
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5_000,
    skip: !isPollingRecommendations,
  });
  const roadmapBuilderData = getRecommendationsQuery.data ?? getRecommendationsQuery.previousData;
  const [selection, setSelection] = useState<SelectionType | undefined>(undefined);
  const [updateRecommendationSelection] = useMutation(UPDATE_RECOMMENDATION_SELECTION);
  const [updateRecLoading, setUpdateRecLoading] = useState(false);
  const _roadmapBuilderData = useMemo(() => roadmapBuilderData?.getRoadmapBuilder?.data, [roadmapBuilderData]);

  // Poll for generated data
  const onLoadingEndRef = useEventCallback(onLoadingEnd ?? noop);
  useEffect(() => {
    if (!_roadmapBuilderData) return;

    const nextIsPolling = _roadmapBuilderData.recommendationStatus !== 'DONE';
    setIsPollingRecommendations((currentIsPolling) => {
      if (!nextIsPolling && currentIsPolling) {
        setPendingUpdateKeys([]);
        onLoadingEndRef();
      }
      return nextIsPolling;
    });
  }, [_roadmapBuilderData, onLoadingEndRef]);

  // Refetch when needed
  useEffect(() => {
    if (!refetch) return;

    setRefetch(false);
    getRecommendationsQuery
      .refetch({
        input: { hint: generationHint.trim(), updateKeys: pendingUpdateKeys.length === 0 ? null : pendingUpdateKeys },
      })
      .then(() => setIsPollingRecommendations(true));

    // We only want to rerun when `refetch` toggles
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecommendationsQuery.refetch, refetch]);

  useEffect(() => {
    const message = roadmapBuilderData?.getRoadmapBuilder?.message;
    if (message) {
      notification.warning({
        message,
      });
      onRoadmapBuilderError();
    }
  }, [roadmapBuilderData, onRoadmapBuilderError]);
  useEffect(() => {
    if (!_roadmapBuilderData || _roadmapBuilderData.recommendationStatus !== 'DONE') return;
    if (_roadmapBuilderData.recommendationSelection) {
      const selectionData: SelectionType = {};
      Object.keys(_roadmapBuilderData.recommendationSelection).forEach((category) => {
        const categorySelection = _roadmapBuilderData.recommendationSelection[category];
        if (categorySelection && Array.isArray(categorySelection) && categorySelection.length > 0) {
          categorySelection.forEach(({ id, schoolYears }: { id: string; schoolYears: number[] }) => {
            const recItem = _roadmapBuilderData.recommendation[category]?.find((item: any) => item.id === id);
            const title = category === 'testing' || category === 'eclActivity' ? recItem?.name : recItem?.title;
            if (selectionData[category]) {
              selectionData[category][id] = { id, schoolYears, title };
            } else {
              selectionData[category] = {
                [id]: { id, schoolYears, title },
              };
            }
          });
        }
      });

      setSelection((selection) => {
        if (!selection) return selectionData;

        // We need to merge our remote state into our local state, because it's possible the user has
        // unsaved local selections and triggered a partial regeneration.
        const newSelection: SelectionType = {};
        Object.keys(_roadmapBuilderData.recommendation).forEach((category) => {
          newSelection[category] = { ...(selection[category] ?? {}), ...(selectionData[category] ?? {}) };
        });
        return newSelection;
      });
    }
  }, [_roadmapBuilderData]);

  const regenerateRecommendation = (hard = true) => {
    onLoadingStart?.(hard);
    invalidateRoadmapBuilder({
      variables: { roadmapBuilderId },
    })
      .then(() => setRefetch(true))
      .catch(() => {
        notification.error({
          message: 'Failed to regenerate strategy',
          description: 'Please try again',
        });
      });
  };

  const updateRecommendation = async () => {
    const confirmSelection: any = {};
    if (!selection) {
      return;
    }
    Object.keys(selection).forEach((category) => {
      const value = selection[category];
      confirmSelection[category] = Object.values(value).map((item) => {
        return { id: item.id, schoolYears: item.schoolYears };
      });
    });
    //transform data
    await updateRecommendationSelection({
      variables: {
        force: forceRecommendations,
        roadmapBuilderId,
        selection: confirmSelection,
      },
    });
    setUpdateRecLoading(false);
    next();
  };
  const { run: debouncedOnFinish } = useDebounceFn(updateRecommendation, { wait: 500 });

  return (
    <StyledContainer>
      {hasAIRecommendations && !roadmapbuilderLoading && _roadmapBuilderData?.recommendation && (
        <StyledHelpersContainer>
          <Tooltip
            title={
              <div>
                🤖 Copilot&apos;s strategy recommendation uses the following guidelines:
                <ul
                  style={{
                    listStyleType: 'disc',
                    marginLeft: 16,
                  }}
                >
                  <li>11 extracurricular activities, with:</li>
                  <ul
                    style={{
                      listStyleType: 'disc',
                      marginLeft: 16,
                    }}
                  >
                    <li>4 showcasing leadership</li>
                    <li>3 major-related</li>
                    <li>3 school-based</li>
                    <li>2 community service</li>
                    <li>1 art or sport</li>
                  </ul>
                  <li>2 readings related to major(s)</li>
                  <li>1 research project</li>
                </ul>
              </div>
            }
            overlayInnerStyle={{
              background: '#1D1E2BCC',
              borderRadius: 8,
              padding: 16,
              width: 300,
            }}
          >
            <StyledHelperTooltipText>
              <HelpOutline height="1em" width="1em" /> Why did I get these recommendations?
            </StyledHelperTooltipText>
          </Tooltip>

          <StyledHelperText>
            Don&apos;t like the results?{' '}
            <StyledHelperTextLink
              pending={invalidatingRecommendations || pendingUpdateKeys.length > 0}
              onClick={() => regenerateRecommendation()}
            >
              Regenerate
            </StyledHelperTextLink>
          </StyledHelperText>
        </StyledHelpersContainer>
      )}
      <div style={{ height: '472px', overflow: 'auto' }}>
        {(roadmapbuilderLoading || isPollingRecommendations) &&
          pendingUpdateKeys.length === 0 &&
          (hasAIRecommendations ? (
            <LoadingRocket>
              <div style={{ margin: '0 auto', width: 420 }}>
                Copilot is generating mission suggestions based on the student’s profile. This process may take a
                while...
              </div>
            </LoadingRocket>
          ) : (
            <LoadingIndicator />
          ))}
        {(!roadmapbuilderLoading || pendingUpdateKeys.length === 0) &&
          _roadmapBuilderData?.recommendation &&
          Object.keys(_roadmapBuilderData.recommendation).map((category) => {
            const categorySelections =
              _roadmapBuilderData.recommendation[
                category as keyof typeof roadmapBuilderData.getRoadmapBuilder.recommendation
              ];
            return (
              <Fragment key={category}>
                {categorySelections && Array.isArray(categorySelections) && categorySelections.length > 0 && (
                  <CategorySelectionCard
                    category={category}
                    categorySelections={categorySelections}
                    hintInput={
                      hasAIRecommendations && category === 'eclActivity'
                        ? {
                            value: generationHint,
                            onChange: (newHint) => {
                              setGenerationHint(newHint);
                              setPendingUpdateKeys(['eclActivity']);
                              regenerateRecommendation(false);
                            },
                          }
                        : undefined
                    }
                    loading={pendingUpdateKeys.includes(category)}
                    showExistingMissions={showExistingMissions}
                    selection={selection}
                    setSelection={setSelection}
                    userId={userId}
                  />
                )}
              </Fragment>
            );
          })}
      </div>
      <Styled.ButtonContainer>
        {!hideButtons && (
          <Styled.NextButton
            type="primary"
            disabled={!selection}
            loading={updateRecLoading || pendingUpdateKeys.length > 0}
            onClick={() => {
              if (selection) {
                setUpdateRecLoading(true);
                debouncedOnFinish();
              }
            }}
          >
            Next
          </Styled.NextButton>
        )}
        {!hideButtons && skipTheStep && (
          <Styled.SkipButton style={{ marginTop: '20px' }} onClick={skipTheStep}>
            Skip
          </Styled.SkipButton>
        )}
      </Styled.ButtonContainer>
    </StyledContainer>
  );
};
export default Recommendations;
