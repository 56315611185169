import styled from 'styled-components';
import { Upload as AntUpload } from 'antd';
import theme from 'src/web-shared-components/theme';

export const Upload = styled(AntUpload)<{ length: number }>`
  border: none;
  .ant-upload-list {
    max-width: 240px;
    display: flex;
    flex-wrap: wrap;
    margin-top: ${({ length }) => (length > 0 ? '24px' : '0')};
    // this is the list item outer class
    .ant-upload-list-text-container {
      margin-top: 15px;
      margin-right: 6px;
      padding-top: 5px;
      padding-right: 6px;
      text-overflow: ellipsis !important;
      white-space: nowrap;
      overflow: hidden !important;
      &:nth-of-type(1) {
        margin-top: 0;
      }
    }
  }
`;

export const UploadFileItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: flex-end;
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  .upload-list-item-remove {
    position: absolute;
    z-index: 1;
    right: -6px;
    top: -10px;
    svg {
      font-size: 12px;
      color: ${theme.common.stone};
    }
  }
  .upload-list-item-node-container {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden !important;
    .ant-upload-list-item {
      margin: 0;
      .anticon.anticon-paper-clip {
        display: none;
      }
      .ant-upload-list-item-name {
        padding: 0 3px 0 3px;
      }
      .ant-upload-span {
        pointer-events: none;
      }
    }
  }
`;
