import { useContext, useEffect, useState } from 'react';
import sanitize from 'sanitize-filename';
import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Upload, Button, UploadFileItemContainer } from './style';
import { FileRefType } from '@crimson-education/common-config';
import { useMutation, useQuery } from '@apollo/client';
import {
  SAVE_FILE,
  GET_FILES_BY_TYPE_AND_REF_ID,
  GET_SC_DOWNLOAD_URL,
  GET_SC_UPLOAD_URL,
  DELETE_FILE,
} from 'src/graphql/SchoolAcademics';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import type { UploadFile } from 'antd/es/upload/interface';

// upload steps
// 1. getStudentCenterUploadUrl, return (putUrl, bucket, key)
// 2. use putUrl to finish upload
// 3. saveFileUplRef(refId: missionId, location: key)
// 4. getFilesByTypeAndRefId, return ([id, ...])
// 5. if user click existing file -> getStudentCenterDownloadUrl(fileId: id) -> create <link> and download

type Props = {
  missionId?: string;
  createMission?: () => Promise<string>;
  icon?: React.ReactNode;
  text?: string;
  maxCount?: number;
};

interface UploadListItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile;
  fileList: UploadFile[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any;
}

const UploadFiles: React.FC<Props> = ({
  missionId,
  createMission,
  icon = <PlusOutlined width={20} />,
  text = 'Upload',
  maxCount = 5,
}: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const { editPermitted, deletePermitted } = useContext(CurrentUserContext);

  const [saveFile] = useMutation(SAVE_FILE, {
    client: crimsonAppApiClient,
  });
  const [deleteFileByFileId] = useMutation(DELETE_FILE, {
    client: crimsonAppApiClient,
  });
  const { data: fileListData, refetch: getFilesByTypeAndRefId } = useQuery(GET_FILES_BY_TYPE_AND_REF_ID, {
    variables: {
      refType: FileRefType.ACADEMIC,
      refId: missionId,
    },
    client: crimsonAppApiClient,
    skip: !missionId,
  });
  const { refetch: getStudentCenterUploadUrl } = useQuery(GET_SC_UPLOAD_URL, {
    client: crimsonAppApiClient,
    skip: true,
  });
  const { refetch: getStudentCenterDownloadUrl } = useQuery(GET_SC_DOWNLOAD_URL, {
    client: crimsonAppApiClient,
    skip: true,
  });

  const trimFilename = (filename: string) => {
    return filename.length > 10 ? `${filename.substring(0, 10)}...` : filename;
  };

  const uploadFile = async (file: File, propsMissionId?: string) => {
    let missionId = propsMissionId;
    if (!missionId) {
      missionId = await createMission?.();
    }
    // TODO: show error message: no more than 5 file allowed
    if (fileList.length >= maxCount) {
      return;
    }
    const { name, type, size } = file;
    const sanitized = sanitize(name);
    const encoded = encodeURIComponent(sanitized);

    // file upload preview
    const tempList = fileList.slice();
    setFileList([
      ...fileList,
      { uid: 'preview_' + name, fileName: name, name: trimFilename(name), status: 'uploading' },
    ]);
    console.log(fileList);

    // const validMediaTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];
    // if (validMediaTypes.indexOf(type.toLowerCase()) < 0) {
    //   throw new Error(`We aren’t able to support uploads with the type ${type}. Try uploading another files.`);
    // }

    try {
      const res = await getStudentCenterUploadUrl({ refId: missionId, fileName: encoded, contentType: type });
      const { putUrl, key } = res.data.getStudentCenterUploadUrl;
      await fetch(putUrl, {
        method: 'PUT',
        body: file,
      });
      await saveFile({
        variables: {
          location: key,
          sha512: ' ',
          name: name,
          size: `${size}`,
          type: type,
          refId: missionId,
          refType: FileRefType.ACADEMIC,
        },
      });
      await getFilesByTypeAndRefId({
        refType: FileRefType.ACADEMIC,
        refId: missionId,
      });
    } catch {
      setFileList(tempList);
    }
  };

  const UploadedFileItem: React.FC<UploadListItemProps> = ({ file, originNode, actions }) => {
    const [showDelete, setShowDelete] = useState(false);
    return (
      <UploadFileItemContainer
        onMouseEnter={() => {
          if (file.status !== 'uploading' && deletePermitted) {
            setShowDelete(true);
          }
        }}
        onMouseLeave={() => {
          setShowDelete(false);
        }}
      >
        <div
          className="upload-list-item-remove"
          style={{ display: showDelete ? 'block' : 'none' }}
          onClick={() => {
            actions.remove(file);
          }}
        >
          <CloseCircleFilled />
        </div>
        <div
          className="upload-list-item-info"
          onClickCapture={(e) => {
            e.stopPropagation();
            if (file.status === 'uploading') return;
            getStudentCenterDownloadUrl({ fileId: file.uid }).then((res) => {
              const { url } = res.data;
              const fileName = file.fileName || file.name;
              window.xprops.download(fileName, url);
            });
          }}
        >
          {originNode}
        </div>
      </UploadFileItemContainer>
    );
  };

  useEffect(() => {
    if (fileListData) {
      setFileList(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fileListData.getFilesByTypeAndRefId.map((file: any) => {
          const name = trimFilename(file.name);
          return { name: name, uid: file.id, fileName: file.name };
        }),
      );
    }
  }, [fileListData]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Upload
        maxCount={maxCount}
        fileList={fileList}
        previewFile={() => {
          return Promise.resolve('file');
        }}
        showUploadList={{ showRemoveIcon: false }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customRequest={async (args: any) => {
          const { file } = args;
          await uploadFile(file, missionId);
        }}
        onRemove={async (file) => {
          console.log('remove file: ', file.uid);
          await deleteFileByFileId({
            variables: {
              fileId: file.uid,
            },
          });
          await getFilesByTypeAndRefId({
            refType: FileRefType.ACADEMIC,
            refId: missionId,
          });
        }}
        itemRender={(originNode, file, fileList, actions) => (
          <UploadedFileItem originNode={originNode} file={file} fileList={fileList} actions={actions} />
        )}
      >
        {fileList.length < maxCount && editPermitted && <Button icon={icon}>{text}</Button>}
      </Upload>
    </div>
  );
};

export default UploadFiles;
